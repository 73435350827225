import React, { useEffect, useState } from "react";
import { getAllKeySkills } from "../helpers/dataFetcher/jobAttributes/keyskills";
import ReactSelect from "react-select";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import { Button, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
function USVisaStatus(props) {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");
  const [error, setError] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  useEffect(() => {
    fetchAllSkills();
  }, []);
  //redux dispatch
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.User.user);

  async function fetchAllSkills() {
    //Need to change getAllKeySkills() function it return only skills data
    const { data: items } = await getAllKeySkills();
    const data = items.map(iterator => {
      return {
        value: iterator.id,
        label: iterator.name,
      };
    });
    setOptions(data);
  }
  async function closeModal() {
    props.onHide();
  }

  const nonimmigrantOptions = [
    { value: "administrative_processing", label: "Administrative processing" },
    { value: "issued", label: "Issued" },
    { value: "refused", label: "Refused" },
  ];

  const immigrantOptions = [
    { value: "expired", label: "Expired", rating: "safe" },
    { value: "expiring_soon", label: "Expiring soon", rating: "good" },
    { value: "return_to_NVC", label: "Return to NVC", rating: "wild" },
    {
      value: "transfer_in_progress",
      label: "Transfer in Progress",
      rating: "crazy",
    },
  ];
  const groupedOptions = [
    {
      label: "nonimmigrant Visa",
      options: nonimmigrantOptions,
    },
    {
      label: "immigrant Visa",
      options: immigrantOptions,
    },
  ];
  const formatGroupLabel = data => <span>{data.label}</span>;
  return (
    <>
      <div className="form-group mb-4">
        <Row className="mt-3">
          <h5 className="mb-2">US Visa details</h5>
          <Col xs={6}>
            <div className="mb-3 multi-select">
              <label className="form-lables">Status</label>
              <ReactSelect
                className="visa_status_input"
                options={groupedOptions}
                formatGroupLabel={formatGroupLabel}
                onChange={e => {
                  setSelected(e);
                  setError(false);
                }}
              />
              {error ? (
                <span style={{ color: "red" }}>Please enter status</span>
              ) : null}
            </div>
          </Col>
          <Col xs={6}>
            <div className="form-group mb-3">
              <label className="form-lables">Expiry Date</label>
              <input
                className="form-control"
                type="date"
                onChange={e => {
                  setDateErr(false);
                  setExpiryDate(e.target.value);
                }}
              />
              {dateErr ? (
                <span style={{ color: "red" }}>Please enter date</span>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={12} style={{ textAlign: "right" }}>
          <Button
            color="primary"
            onClick={async () => {
              const result = selected.map(item => {
                return item.value;
              });
              const obj = {
                resume_file: accountData.resume_url,
                profile_image: accountData.profile_image,
                dob: "12/10/2002",
                last_name: accountData.last_name,
                first_name: accountData.first_name,
                email: accountData.email,
                gender: accountData.gender,
                description: accountData.description,
                postal_code: props.postal_code,
                country: props.country,
                state: props.state,
                city: props.city,
                address: props.address,
                languages: accountData.languages,
                company_id: accountData.company_id,
                skills: accountData.skills,
                visa_details: result,
              };

              let o = Object.fromEntries(
                Object.entries(obj).filter(([_, v]) => v !== null)
              );
              if (o.visa_details.length > 0) {
                const { status } = await updateProfileNormal("visa_details", o);

                if (status === 200) {
                  const names = selected?.map(item => {
                    return { name: item.label, id: item.value };
                  });
                  dispatch(
                    updateUserAccount({
                      user: {
                        ...accountData,
                        visa_details: names,
                      }
                    })
                  );
                }

                props.onHide();
              } else {
                setError(true);
                setDateErr(true);
                //window.alert("Please enter skills")
              }

              //   await updateProfileNormal("skills", { skills: result });
              //props.onHide();
              //   fetchAllSkills();
            }}
            className="p-3 rounded_btn login_btns btn-default"
          >
            save
          </Button>
          <Button onClick={closeModal} style={{ margin: "1px" }}>
            close
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default USVisaStatus;
