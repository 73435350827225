import React from "react";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from "react-owl-carousel3";
import { Grid, Stack, Typography, Box } from "@mui/material";

import { slidesDataOne, slidesData } from "./CompanyPage/SvgComponents/SlidesData";

function CompaniesCarousal({ slidesDetails }) {

  const options = {
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 6,
      },
      1400: {
        items: 8,
      },
    },
    loop: true,
    lazyLoad: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 1000,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    nav: true,
    navText: [
      "<i className='fa fa-chevron-left'></i>",
      "<i className='fa fa-chevron-right'></i>",
    ],
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          maxHeight: "150px",
          height: "95px",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            minHeight: "50px",
            borderRadius: "6px",
            overflowWrap: "break-word",
            position: "relative",
            padding: "5px 10px",
            fontSize: "12px",
            color: "#444",
            "&:after, &:before": {
              position: "absolute",
              zIndex: "10",
              border: "solid transparent",
              content: '"  "',
              pointerEvents: "none",
            },
            "&:before": {
              borderWidth: "20px",
              top: "0",
              left: "0",
              height: "100%",
              // background: "rgba(250, 204, 21, 0.08)",
            },
            "&:after ": {
              borderWidth: "20px",
              top: "0",
              right: "0",
              height: "100%",
              // backgroundColor: "rgba(250, 204, 21, 0.08)"
            },
          }}
        >
          <OwlCarousel
            nav
            loop
            {...options}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {slidesDetails ? slidesDetails?.map((item) => (
              <Box
                key={item.id}
                sx={{
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item?.component}
              </Box>
            )) : slidesDataOne?.map(item => (
              <Box
                key={item.id}
                sx={{
                  margin: "0 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item?.component}
              </Box>
            ))}
          </OwlCarousel>
        </Stack>
      </Stack>
    </>
  );
}

export default CompaniesCarousal;
