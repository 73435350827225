import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileSidebar } from "../../../store/Layout/actions";
import "../../../assets/css/sidebar/index.css";
import FeatherIcon from "feather-icons-react";
import {
  Checkbox,
  Stack,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import ProfileIcon from "../../../assets/icons/ProfileSidebar/ProfileIcon";
import DashboardIcon from "../../../assets/icons/ProfileSidebar/DashboardIcon";
import ReferredJobsIcon from "../../../assets/icons/ProfileSidebar/ReferredJobsIcon";
import MyReferralsIcon from "../../../assets/icons/ProfileSidebar/MyReferralsIcon";
import AppliedJobsIcon from "../../../assets/icons/ProfileSidebar/AppliedJobsIcon";
import SavedJobsIcon from "../../../assets/icons/ProfileSidebar/SavedJobsIcon";
import ChatsIcon from "../../../assets/icons/ProfileSidebar/ChatsIcon";
import InterviewsIcon from "../../../assets/icons/ProfileSidebar/InterviewsIcon";
import LeadershipBoardIcon from "../../../assets/icons/ProfileSidebar/LeadershipBoardIcon";
import MyEarningsIcon from "../../../assets/icons/ProfileSidebar/MyEarningsIcon";
import SettingsIcon from "../../../assets/icons/ProfileSidebar/SettingsIcon";
import LogoutIcon from "../../../assets/icons/ProfileSidebar/LogoutIcon";
import { openModal } from "../../../components/modals/modalsSlice";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.User.user);
  const routes = [
    {
      id: 1,
      name: "Profile",
      slug: "/profile",
      type: "all",
      type: "all",
      icon: ProfileIcon,
    },
    {
      id: 2,
      name: "Dashboard",
      slug: "/dashboard",
      type: "all",
      type: "all",
      icon: DashboardIcon,
    },

    {
      id: 3,
      name: "Referred Jobs",
      slug: "/refered-jobs",
      type: "all",
      type: "all",
      icon: ReferredJobsIcon,
    },
    {
      id: 4,
      name: "My Referrals",
      slug: "/my-referals",
      type: "all",
      type: "all",
      icon: MyReferralsIcon,
    },

    {
      id: 5,
      name: "Applied Jobs",
      type: "all",
      type: "all",
      icon: AppliedJobsIcon,
      slug: "/applied-jobs",
    },
    {
      id: 6,
      name: "Saved Jobs",
      type: "all",
      type: "all",
      icon: SavedJobsIcon,
      slug: "/my-savedjobs",
    },
    {
      id: 7,
      name: "Chats",
      type: "all",
      type: "all",
      icon: ChatsIcon,
      slug: "/demochat",
      child: ["/jobchat"],
    },
    {
      id: 8,
      name: "Interviews",
      type: "all",
      type: "all",
      icon: InterviewsIcon,
      slug: "/interviews",
    },
    // {
    //   id: 8,
    //   name: "Chat",
    //   type: "all",
    //   type: "all",
    //   icon: "message-circle",
    //   slug: "/chat",
    // },

    // {
    //   id: 10,
    //   name: "Leadership board",
    //   type: "all",
    //   type: "all",
    //   icon: LeadershipBoardIcon,
    //   slug: "/leadership-board",
    // },
    {
      id: 9,
      name: "My Earnings",
      type: "all",
      type: "all",
      icon: MyEarningsIcon,
      slug: "/myearning",
    },
    {
      id: 10,
      name: "Settings",
      type: "normal",
      icon: SettingsIcon,
      slug: "/settings",
      type: "external",
    },
    {
      id: 11,
      name: "Logout",
      type: "all",
      type: "all",
      icon: LogoutIcon,
      slug: "/logout",
    },
  ];

  const { tab } = useSelector(store => store.ProfileSidebar);
  const changeNavigation = item => {
    dispatch(updateProfileSidebar(item.id));
    if (item.name === "Logout") {
      dispatch(
        openModal({
          modalName: "logoutConfirmationModal",
          modalProps: {
            onConfirm: () => {
              navigate("/logout");
            },
            onCancel: () => {},
          },
        })
      );
    } else {
      navigate(item.slug);
    }
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = slug => {
    setHoveredItem(slug);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        border: "0.8px solid #DEDEDE",
        borderRadius: "16px",
        padding: "15px",
        gap: "10px",
        background: "#F9F9F9",
        boxShadow: "0px 2px 54px -35px #0000008C",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      direction="column"
    >
      {routes?.map((item, index) => (
        <React.Fragment key={item.id}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              color: "#5E6162",
              padding: "12px 16px 12px 16px",
              fontSize: "15px",
              borderRadius: "10px",
              cursor: "pointer",
              "&:hover": {
                background: "#056045",
                color: "#E9E9E9",
              },
              ...((location?.pathname === item?.slug ||
                item?.child?.includes(`${location?.pathname}`)) && {
                background: "#056045",
                color: "#E9E9E9",
              }),
              "@media (max-width: 1200px)": {
                fontSize: "12px",
              },
            }}
            onClick={() => changeNavigation(item)}
            onMouseEnter={() => handleMouseEnter(item.slug)}
            onMouseLeave={handleMouseLeave}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.icon(
                location?.pathname === item?.slug ||
                  item?.child?.includes(`${location?.pathname}`) ||
                  hoveredItem === item.slug
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.name}
            </Box>
          </Stack>
        </React.Fragment>
      ))}
    </Stack>
  );
}

export default Sidebar;
