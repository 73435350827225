import React, { useState } from "react";
import { DataGrid, GridToolbar, } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import { Stack, IconButton, Grid, Box, Typography } from "@mui/material";
import Select, { selectClasses } from "@mui/joy/Select";
import Pagination from "@mui/material/Pagination";
import { DataGridStyles } from "../../../assets/MUI/useStyles";
import ComingSoon from "../../../Reusable/comingSoon";


function DashBoardPage2() {
  const [rows, setRows] = useState();
  const classes = DataGridStyles();


  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const styleObj = {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11.5614px",
    color: "rgba(0, 0, 0, 0.8)",
  };

  const rowsData = [].map((each, index) => {
    return {
      id: index,
      each: each,
    };
  });


  const columns = [
    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 300,
      flex: 1,
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Type",
      headerClassName: classes.headerCellStyles,
      headerName: "Type",
      minWidth: 300,
      flex: 1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Designation",
      headerClassName: classes.headerCellStyles,
      headerName: "Designation",
      minWidth: 300,
      flex: 1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "InterviewerName",
      headerClassName: classes.headerCellStyles,
      headerName: "Interviewer Name",
      minWidth: 300,
      flex: 1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "MeetLink",
      headerClassName: classes.headerCellStyles,
      headerName: "MeetLink",
      minWidth: 300,
      flex: 1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);

  };

  return (
    <>
      <Grid
        container
        sx={{
          height: "70vh",
          border: "0.8px solid #DEDEDE",
          borderRadius: "16px",
          background: "#fff",
          boxShadow: "0px 2px 54px -35px #0000008C",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            height: "100%"
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ComingSoon />
        </Grid>
      </Grid>

      {/* <Stack
        direction="column"
        mt={1}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "20px",
          }}
        >Upcomming Interviews </Typography>

        <Box sx={{ height: "70vh", width: "100%", marginTop: "15px" }}>
          <DataGrid
            rows={rowsData}
            columns={columns}
            className={classes.root}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            slots={{
              // toolbar: GridToolbar,
              pagination: props => (
                <Pagination
                  {...props}
                  // onChangePage={(event, newPage) => api.setPage(newPage)}
                  pagination
                  count={Number(0)}
                  pageSize={10}
                  page={Number(page)}
                  onChangeRowsPerPage={() => { }}
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#024430",
                        color: "#ffe7bb",
                        "&:hover": {
                          backgroundColor: "#cde2e7",
                          color: "#066a4c",
                        },
                      },
                    },
                  }}
                />
              ),
            }}

            slotProps={{
              baseButton: { style: { width: "100px" } },
              panel: {
                style: {
                  right: "0px !important",
                  transform: "none !important",
                  inset: "none !important",
                  top: "138px !important",
                },
              },
              filterPanel: {
                style: {
                  right: "0px !important",
                  transform: "none !important",
                  inset: "none !important",
                  top: "138px !important",
                },
              },
              toolbar: { style: {} },
              preferencesPanel: {
                style: {
                  right: "0px !important",
                  transform: "none !important",
                  inset: "none !important",
                  top: "138px !important",
                },
              },
            }}
            style={{ border: "0px" }}

          />
        </Box>
      </Stack> */}
    </>
  );
}

export default DashBoardPage2;
