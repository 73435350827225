import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import { Box, Typography } from "@mui/material";
import { styleObj } from '../../../assets/MUI/useStyles';
import { DataGridStyles } from '../../../assets/MUI/useStyles';
import Pagination from "@mui/material/Pagination";
import { globalTheme } from '../../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./table.css";
import CustomNoRowsFound from "../../../Nodatafound/customNoRowsFound";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { formatdate } from "../../../helpers/common/FormateDate";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";


function ReferMeTable({ referData, page, setPage, loading, totalCount, pageSize }) {
  const classes = DataGridStyles();
  const navigate = useNavigate();

  const rowsData = referData?.map((item, index) => {
    return {
      id: `${item?.id}`,
      userId: `${item?.user_id}`,
      jobPostId: `${item?.job_post_id}`,
      companyId: `${item?.company_details?.company_user_id}`,
      Image: `${imageURL}${item?.company_details?.company?.company_image}`,
      companyName: `${item?.company_details?.company?.company_name ?? "-"}`,
      jobTitle: `${item?.job_title ?? "-"}`,
      location: `${item?.location ?? "-"}`,
      date: `${moment(item?.createdAt).format("DD-MM-YYYY HH:mm")}`,
      sourceType: `${item?.job_apply_type ?? "-"}`,
      referedByEmail: `${item?.referral_user?.email ?? "-"}`,
      referedByName: `${item?.referral_user?.first_name + " " + item?.referral_user?.last_name ?? "-"}`,
      ctc: `${getSymbolFromCurrency(item?.currency) ? getSymbolFromCurrency(item?.currency) : ""} ${item?.max_sal ?? "-"}/${item?.payout?.replace(/_/g, ' ')}`,
      status: `${item?.job_post_pipeline_stage?.stage_name ?? "-"}`,
      commission: (
        item?.job_post_refer_self_commission.length > 0 &&
        item?.job_post_refer_self_commission[0]?.flat_rate !== 0 ?
        `${getSymbolFromCurrency(item?.currency) || ""} ${item?.job_post_refer_self_commission[0]?.flat_rate}/${item?.job_post_refer_self_commission[0]?.payout?.replace("_", " ") || "N/A"}`
        : "-"
      )
    };
  });


  const columns = [
    {
      field: "Image",
      headerName: "Image",
      headerClassName: classes.headerCellStyles,
      flex: 1,
      minWidth: 150,
      renderCell: params => (
        <img
          src={params.value}
          alt="avatar"
          style={{ width: 35, borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "jobTitle",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Title",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => {
        return (
          <span style={styleObj}
            onClick={() => {
              navigate(`../job?id=${row?.jobPostId}&userId=${row?.userId}`);
            }}
          >{value}</span>
        );
      },
    },

    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <span style={styleObj}
        onClick={() => {
          navigate(`../employer-single-list?id=${row?.companyId}&userId=${row?.userId}`);
        }}
        >{value}</span>
      ),
    },

    {
      field: "sourceType",
      headerClassName: classes.headerCellStyles,
      headerName: "Source",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },


    {
      field: "referedByName",
      headerClassName: classes.headerCellStyles,
      headerName: "Referred By",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },




    {
      field: "referedByEmail",
      headerClassName: classes.headerCellStyles,
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "ctc",
      headerClassName: classes.headerCellStyles,
      headerName: "CTC",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "commission",
      headerClassName: classes.headerCellStyles,
      headerName: "Commission",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date & Time",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },

    {
      field: "location",
      headerClassName: classes.headerCellStyles,
      headerName: "Location",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },



    {
      field: "status",
      headerClassName: classes.headerCellStyles,
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "8px",
            backgroundColor:
              value === "Rejected"
                ? "#ffe9e9"
                : value === "Applied"
                  ? "#cde2e7"
                  : value === "On boarded"
                    ? "#d7f6dd"
                    : value === "Technical Evalutation(External)"
                      ? "#ffc6f6"
                      : "rgba(8, 23, 83, 0.2)",
            color:
              value === "Rejected"
                ? "#a10e25"
                : value === "Applied"
                  ? "#066a4c"
                  : value === "On boarded"
                    ? "#066a4c"
                    : value === "Technical Evalutation(External)"
                      ? "#066a4c"
                      : "#066a4c",
            fontWeight: 500,
            fontSize: "12px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
          }}
        >
          {value}
        </div>

      ),
    },
  ];

  return (
    <ThemeProvider theme={globalTheme}>
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: "20px" }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "20px",
            }}
          >Referred Jobs </Typography>
          <GlobalSearch placeholder="Search by Company name, Job title.." />
        </Box>
        <div style={{ height: "70vh", width: "100%" }}>
          <DataGrid
            rows={rowsData}
            className={classes.root}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            loading={loading}

            slots={{
              // loadIcon: LoaderIcon,
              // baseButton: PrimaryButton,
              // basePopper: TablePopper,
              // baseSwitch: PrimarySwitch,
              // toolbar: GridToolbar,
              // noRowsOverlay: CustomNoRowsOverlay,
              pagination: props => (
                <Pagination
                  {...props}
                  // color="primary"
                  count={Number(totalCount)}
                  page={Number(page)}
                  rowsPerPage={Number(pageSize)}
                  onChange={(_, newPage) => setPage(newPage)}
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#024430",
                        color: "#ffe7bb",
                        "&:hover": {
                          backgroundColor: "#cde2e7",
                          color: "#066a4c",
                        },
                      },
                    },
                  }}
                />
              ),
            }}

          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ReferMeTable;
