import React, { useEffect, useState, useRef } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { Stack, CircularProgress, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import "../../assets/css/chat/chat.css";
import startChat from "../../assets/images/start_chat.svg";
// import startChat from '../../assets/images/startChat.png';
import { imageURL } from "../../Constants/commonURLS";
import { useNavigate, useSearchParams } from "react-router-dom";
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    width: "88%",
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "rgba(192, 223, 250, 0.4) !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "rgba(192, 223, 250, 0.4) !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },

  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
    letterSpacing: "-0.005em",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  listBtn: {
    padding: "9px !important"
  },
  searchBox: {
    height: "40px"
  }
});


function JobsListChat({

  appliedJobs,

  setCurrPage,
  currPage,
  jobLoading
}) {
  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();


  const handleSendJobId = async data => {
    navigate(`/jobchat?user-job-post-id=${data.user_job_post_id}&job-post-id=${data.job_post_id}`);
  };

  const getJobPostTime = (date) => {
    if(!date) {
      return ""
    }
    const now = moment();
    const messageTime = moment(date);
  
    if (now.diff(messageTime, 'minutes') < 1) {
      return "Now";
    } else if (now.diff(messageTime, 'minutes') < 60 * 12 && now.isSame(messageTime, 'day')) {
      return messageTime.format('LT');  
    } else if (now.isSame(messageTime, 'day')) {
      return "Today";
    } else if (now.diff(messageTime, 'minutes') < 60 && now.isBefore(messageTime, 'day')) {
      return messageTime.format('LT');  
    } else if (now.diff(messageTime, 'days') < 2) {
      return "Yesterday";
    } else if (now.diff(messageTime, 'days') < 7) {
      return messageTime.format('dddd');  
    } else {
      return messageTime.format('DD/MM/YYYY');  
    }
  };


  return (
    <>
      {jobLoading ? (
        <Stack
          sx={{
            height: "100%",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <>
          {appliedJobs?.length > 0 ? (
            <>
              {appliedJobs?.map((each, index) => (
                <Stack
                  sx={{
                    width: "100%",
                    // overflow: "hidden",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textOverflow: "ellipsis",
                    backgroundColor:
                      searchParams.get("job-post-id") === each?.job_post_id
                        ? "#E6F0EDA3"
                        : "#FFFFFF",
                    padding: "10px !important",
                    cursor: "pointer",
                    borderLeft: searchParams.get("job-post-id") === each?.job_post_id
                      ? "4px solid #066A4C"
                      : "4px solid transparent",
                    gap: "10px",
                    borderBottom: appliedJobs?.length - index - 1 === 0 ? "" : "0.4px solid #DEDEDE"
                  }}
                  key={each?.id}
                  alignItems="flex-start"
                  onClick={(e) => {
                    e.preventDefault();
                    searchParams.set("job-post-id", each?.job_post_id);
                    searchParams.set("user-job-post-id", each?.id);
                    setSearchParams(searchParams);
                  }}
                  direction="row"
                >

                  <Stack 
                  sx={{
                    width: "45px",
                    height: "45px",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  >
                    <Avatar
                      loading="lazy"
                      alt={`${each?.job_post_in_user_job_post?.company?.company_name.charAt(
                        2
                      )}`}
                      src={`${imageURL}${each?.job_post_in_user_job_post?.company?.company_image}`}
                      className={classes.avatar1}
                      sx={{ background: "#cde2e7 !important", color: "rgba(0, 0, 0, 0.7) !important" }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      width: "calc(100% - 45px)",
                    }}
                  >
                    <Box
                    sx={{
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "80%",
                    }}
                    >
                      <Typography className={classes.name} noWrap>
                        {each?.job_post_in_user_job_post?.job_title}
                      </Typography>
                      <Typography className={classes.msg} noWrap>
                        {
                          each?.job_post_in_user_job_post?.company
                            ?.company_name
                        }
                      </Typography>
                    </Box>
                    <Box>
                    <Typography noWrap
                          sx={{
                            fontSize: "12px",
                            color: "#8B8B8B",
                            fontWeight: "500",
                            textAlign: "right",
                          }}
                        >
                          {
                            getJobPostTime(each?.createdAt)
                          }
                        </Typography>
                      {/* <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSendJobId({
                            user_job_post_id: each.id,
                            job_post_id: each.job_post_id,
                          })
                        }}
                      >
                        <Badge
                          badgeContent={`${each?.chatChannelsCount
                            ? each.chatChannelsCount
                            : "0"
                            }`}
                          slotProps={{
                            badge: {
                              style: {
                                backgroundColor: "#29B959",
                                width: "20px",
                                height: "20px",
                                color: "#fff",
                                fontSize: "10px",
                                fontWeight: 500,
                              },
                            },
                          }}
                        >
                          <MessageRoundedIcon
                            style={{ height: "20px", width: "20px" }}
                          />
                        </Badge>
                      </IconButton> */}
                    </Box>
                  </Stack>
                </Stack>
              ))}
            </>
          ) : (
            <div className="contact_no_show">
              <img src={startChat} />
              <p>
                You haven't started a chat please head over to contacts and
                start chat.
                {appliedJobs.length == 0 ? " No search found" : ""}
              </p>
            </div>
          )}
        </>
      )
      }
    </>
  );
}

export default JobsListChat;
