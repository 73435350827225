import React, { useEffect, useState } from "react";
import "./login.css";

import TextField from "@mui/material/TextField";
import { Box, Stack, Button, Typography, Modal } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { updateNumber, sendOtp } from "../helpers/dataFetcher/profile/update";
import OtpInput from "react18-input-otp";
import CountdownTimer from "./CountDown/CountdownTimer";
import { verifyMobileOTP } from "../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import MuiCustomTelInput from "../components/formElements/PhoneNumber/PhoneNumber";
import { parseNumberInput } from "../hooks/customPhoneNumberParse";
import { updateUserAccount } from "../pages/Accounts/accountSlice";
import { matchIsValidTel } from "mui-tel-input";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  height: "fit-contnent",
  padding: "10px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px",
  overflow: "hidden",
  pt: 2,
  px: 4,
  pb: 3,
};


function ChangePhoneNumber() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldNum: "",
      newNum: "",
      confirmNum: ""
    }
  });

  const userData = useSelector(state => state.User.user);


  const obj = {
    update_type_to_send_email: "mobile",
  };
  let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const [otpModalView, setOtpModalView] = useState(false);
  const [otp, setOtp] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleChange = code => setOtp(code);

  console.log(otp, "otpotpotpotp")

  /*Verify otp and update function start*/
  const handleOTP = async () => {
    const { status, data: otpResData } = await verifyMobileOTP(otp);

    if (status === 200) {
      const number = getValues("newNum")?.replace(/\s/g, '') ?? '';
      const { data, status } = await updateNumber("about_me", o, number);
      if (status === 200) {
        toast.success("Updated Successfully!");
        reset()
      } else if (status === 400) {
        toast.error(data?.return_message);
        reset()
      }
    }
    if (status === 400) {
      toast.error(otpResData?.return_message);
      return;
    }

    setOtp("");
    dataFetcher();
    setOtpModalView(false);
    setHasError(false);
  };
  /*Verify otp and update function End*/

  /*send otp function */
  const changeNumber = async data => {

    const { status, data: resData } = await sendOtp("mobile", {
      phone_number: data?.newNum?.replace(/\s/g, '') ?? '',
      old_phone_number: data?.oldNum?.replace(/\s/g, '') ?? '',
    });
    if ([200, 201].includes(status)) {
      setOtpModalView(true);
      toast.success(resData?.return_message ? resData?.return_message : "Successfully  sent");
    }
    if ([422, 400].includes(status)) {
      toast.error(resData?.return_message ?? "Something went wrong");
    }
  };

  async function dataFetcher() {
    const { data, status } = await getProfile();
    if (status === 200) {
      localStorage.setItem("userData", JSON.stringify(data));
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          }
        })
      );
    }
  }

  /*send otp function End*/


  return (
    <div className="main_container">
      <h1 className="password_heading">Update Phone Number</h1>
      <p className="password_paragraph"
        style={{
          color: "#979797"
        }}
      >
        Please enter your current number to change your number
      </p>
      {/* <hr /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(data => changeNumber(data))}
        sx={{
          marginTop: "25px"
        }}
        className="form_container"
      >
        <div className="bank_card_cont">
          <p>Current Number</p>
          <div className="bank_input_filed_card">
            <Controller
              name="oldNum"
              control={control}
              rules={{ required: "Current Phone Number is required", validate: (value) => matchIsValidTel(value) || 'Current Phone Number is invalid' }}
              render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => {
                return (
                  <MuiCustomTelInput
                    {...fieldProps}
                    defaultCountry={"IN"}
                    countryCodeEditable={true}
                    value={value ?? ''}
                    inputRef={fieldRef}
                    error={fieldState.invalid}
                    helperText={
                      errors?.oldNum?.type ? errors?.oldNum?.message : null
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                )
              }}
            />
          </div>
        </div>
        <br />
        <div className="bank_card_cont">
          <p>New Number</p>
          <div className="bank_input_filed_card">
            <Controller
              name="newNum"
              control={control}
              rules={{
                required: "New Phone Number is required",
                validate: {
                  matchesNewNumber: (value) => {
                    const oldNumber = watch("oldNum");
                    return value !== oldNumber || 'New Phone Number should not match with Old Phone Number';
                  },
                  isValidTel: (value) => matchIsValidTel(value) || 'New Phone Number is invalid'
                }
              }}
              render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => {
                return (
                  <MuiCustomTelInput
                    {...fieldProps}
                    defaultCountry={"IN"}
                    countryCodeEditable={true}
                    value={value ?? ''}
                    inputRef={fieldRef}
                    error={fieldState.invalid}
                    helperText={
                      errors?.newNum?.type ? errors?.newNum?.message : null
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                )
              }}
            />
          </div>
        </div>
        <br />
        <div className="bank_card_cont">
          <p>Confirm New Number</p>
          <div className="bank_input_filed_card">
            <Controller
              name="confirmNum"
              control={control}
              rules={{
                required: "Confirm New Number is required",
                validate: {
                  matchesNewNumber: (value) => {
                    const newNumber = watch("newNum");
                    return value === newNumber || 'Confirm New Number must match New Number';
                  },
                  isValidTel: (value) => matchIsValidTel(value) || 'Confirm New Number is invalid'
                }
              }}
              render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => {
                return (
                  <MuiCustomTelInput
                    {...fieldProps}
                    defaultCountry={"IN"}
                    countryCodeEditable={true}
                    value={value ?? ''}
                    inputRef={fieldRef}
                    error={fieldState.invalid}
                    helperText={
                      errors?.confirmNum?.type ? errors?.confirmNum?.message : null
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                )
              }}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="d-flex submit-button mt-4">
          <Stack spacing={2} direction="row">
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{
                borderRadius: "8px",
                marginLeft: "10px",
                boxShadow: "none",
                fontSize: "14px",
                background: "var(--app-button-color1)",
                "&:hover": {
                  boxShadow: "none",
                  background: "var(--app-button-color1)",
                }
              }}
            >
              <span style={{ textTransform: "capitalize" }}> Update now </span>
            </Button>
          </Stack>
        </div>
      </Box>
      {otpModalView ? (
        <>
          <Modal
            // {...props}
            onClose={() => setOtpModalView(false)}
            open={otpModalView}
            style={{ overflowY: "scroll", maxHeight: "100vh" }}
          >
            <Box sx={{ ...style, width: 400 }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                OTP Verify
              </Typography>
              <Stack mb={2} mt={2}>
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus
                  isInputNum={true}
                  inputStyle="otp_input_items"
                  errorStyle={"otp_input_items_error"}
                  hasErrored={hasError}
                  focusStyle={"otp_input_items_focus"}
                  separator={<span> </span>}
                  onSubmit={handleOTP}
                />
              </Stack>

              <CountdownTimer
                mobile={(() => {
                  const newNum = getValues("newNum")?.replace(/\s/g, '') ?? '';
                  const oldNum = getValues("oldNum")?.replace(/\s/g, '') ?? '';

                  return {
                    phone_number: newNum,
                    old_phone_number: oldNum,
                  };
                })()}
                totalSec={20 * 10000}
              />

              <Stack direction="row" justifyContent={"flex-end"}>
                <Button
                  type="submit"
                  size="large"
                  loading={false}
                  onClick={handleOTP}
                  disabled={otp?.length !== 6}
                  loadingPosition="end"
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                    padding: "6px 4px",
                    WebkitBoxOrient: "horizontal",
                    overflow: "hidden",
                    width: "100%",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textOverflow: "ellipsis",
                    "&:hover": {
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>Verify OTP</span>
                </Button>
              </Stack>
            </Box>
          </Modal>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ChangePhoneNumber;
