import React from "react";
import { Link, useNavigate } from "react-router-dom";
import JoineeLogo from "../../assets/images/joinee_logo2.png";
import { Grid, Box, Stack } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
function GlobalSidebar({ anchor, toggleDrawer }) {
  const token = localStorage.getItem("token");

  const routes = [
    {
      id: 1,
      name: "Dashboard",
      slug: "/dashboard",
      // icon: "bx bxs-dashboard",
      icon: "fa-solid fa-home",
    },
    {
      id: 2,
      name: "Profile",
      slug: "/profile",
      // icon: "bx bxs-user",
      icon: "fa-solid fa-user-tie",
    },
    {
      id: 3,
      name: "Referred Me",
      slug: "/refered-jobs",
      // icon: "bx bxs-user-plus",
      icon: "fa-solid fa-gift",
    },
    {
      id: 3,
      name: "My Refferals",
      slug: "/my-referals",
      // icon: "bx bxs-help-circle",
      icon: "fa-solid fa-forward-fast",
    },
    {
      id: 4,
      name: "Interviews",

      // icon: "bx bxs-group",
      icon: "fa-solid fa-users-line",
      slug: "/interviews",
    },
    {
      id: 5,
      name: "Applied Jobs",

      // icon: "bx bxs-file",
      icon: "fa-regular fa-paper-plane",
      slug: "/applied-jobs",
    },
    {
      id: 6,
      name: "Saved Jobs",
      type: "all",
      type: "all",
      icon: "fa-regular fa-floppy-disk",
      slug: "/my-savedjobs",
    },
    {
      id: 7,
      name: "Chats",
      icon: "fa-regular fa-comment-dots",
      slug: "/demochat",
    },
    {
      id: 8,
      name: "Interviews",
      type: "all",
      type: "all",
      icon: "fa-thin fa-clipboard-question",
      slug: "/interviews",
    },
    {
      id: 9,
      name: "Leadership board",
      icon: "fa-solid fa-chess-king",
      slug: "/leadership-board",
    },
    {
      id: 10,
      name: "My Earning",
      icon: "fa-solid fa-dollar-sign",
      slug: "/myearning",
    },
    // {
    //   id: 9,
    //   name: "My Money",

    //   icon: "bx bxs-user-plus",
    //   slug: "/mymoney",
    // },
    // {
    //     id: 10,
    //     name: "Application Status",
    // icon: "bx bxs-user-plus",
    // slug: "/application-status"
    // },
    {
      id: 11,
      name: "Settings",
      type: "normal",
      icon: "fa-solid fa-gear",
      slug: "/settings",
      type: "external",
    },
    {
      id: 12,
      name: "Logout",
      // icon: "bx bx-log-out",
      icon: "fa-solid fa-arrow-right-from-bracket",
      slug: "/logout",
    },
  ];
  const sidebarMain = [
    // { name: "Companies", link: "employer-list", icon: "bx bx-buildings" },
    { name: "Discover Jobs", link: "jobs-list", icon: "bx bx-jobs" },
    { name: "Company", link: "company", icon: "bx bx-jobs" },
    // { name: "About", link: "/about-us", icon: "bx bx-jobs" },
    // { name: "Refer", link: "/refernow", icon: "bx bx-jobs" },
    // { name: "Employers", link: "/employerpage", icon: "bx bx-jobs" },
    // { name: "blogs", link: "blogs", icon: "bx bx-file" },
    // { name: "notifications", link: "notifications", icon: "bx bx-alarm" },
  ];

  return (
    <Grid
      container
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        minHeight: "100vh",
        overflow: "auto",
        background: "#cde2e7",
      }}
    >
      <Grid item xs={12} sm={12}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List style={{ background: "#cde2e7", minHeight: "fit-content" }}>
            <Link to="/" className="d-flex">
              <img
                alt="Joinee"
                src={JoineeLogo}
                style={{
                  width: "105px",
                  height:"34px",
                  margin: "10px",
                  objectFit: "contain",
                }}
              />
            </Link>
            {sidebarMain.map((text, index) => (
              <ListItem
                key={text?.name}
                disablePadding
                className="list_item_text"
              >
                <ListItemButton to={`../${text.link}`}>
                  <Link to={`../${text.link}`} style={{ color: "#066a4c" }}>
                    <ListItemText primary={text?.name} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
          </List>
          {token && (
            <List
              style={{
                background: "#cde2e7",
                height: "fit-content",
                overflow: "auto",
              }}
            >
              {routes.map((text, index) => (
                <ListItem
                  key={text?.name}
                  disablePadding
                  className="list_item_text"
                >
                  <ListItemButton>
                    <ListItemIcon style={{ color: "#4295f5" }}>
                      <i
                        className={text?.icon}
                        style={{ color: "#066a4c" }}
                      ></i>
                    </ListItemIcon>
                    <Link to={`../${text.slug}`} style={{ color: "#066a4c" }}>
                      <ListItemText primary={text?.name} />
                    </Link>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default GlobalSidebar;
