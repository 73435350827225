import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  PrimaryNormalFormHelperText,
  PrimaryCheckbox,
  PrimaryAutoComplete,
  autocompleteProps,
  PrimaryOutlinedButton,
} from "../../../assets/MUI/materialUiStyles";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
  Controller,
} from "react-hook-form";

import { TextField as MuiTextField, InputLabel, Button } from "@mui/material";

import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";

import {
  FormControl,
  Grid,
  Stack,
  Select,
  MenuItem,
  IconButton,
  createFilterOptions,
  Checkbox,
} from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UploadModel from "./ImageUpload";
import { useSelector } from "react-redux";
import { getAllKeySkillsWithStatus } from "../../../helpers/dataFetcher/profile/profile";
import { v4 as uuid } from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  width: "50%",
  p: 4,
  height: "70vh",
  minHeight: "300px",
  overflow: "auto",
};
import { toast } from "react-hot-toast";
import Geolocation from "../../../components/formElements/Geolocation";
import CustomSelect from "../../../components/formElements/CustomSelect";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import TextField from "../../../components/formElements/TextField";
import TextBox from "../../../components/formElements/TextBox";
import moment from "moment";

export default function AddEditWorkExperience({
  type,
  open,
  currentId,
  toggleModel,
  updateOrEditWorExperience,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      skill: [{ skillName: "", experienceyears: "" }],
    },
  });

  const accountData = useSelector(state => state.User.user);
  const [currentData, setCurrentData] = useState(
    accountData?.work_exp?.filter(item => item.id === currentId)[0] || {}
  );

  useEffect(() => {
    setCurrentData(
      accountData?.work_exp?.filter(item => item.id === currentId)[0] ?? {}
    );
  }, [currentId, open, type]);

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "skill",
    });

  const [model, setModel] = useState(false);

  const [skillLoader, setSkillLoader] = useState(true);
  const [keySkills, setKeySkills] = useState([]);

  React.useEffect(() => {
    getKeySKills();
  }, []);

  async function getKeySKills() {
    const { data, status } = await getAllKeySkillsWithStatus();
    setKeySkills(
      data?.data_payload?.map(item => ({
        name: item?.name,
        value: item?.id,
        isActive: item?.is_active,
        id: item?.id,
      }))
    );
    if (status === 200) {
      setSkillLoader(false);
    }
  }

  const filter = createFilterOptions();

  React.useEffect(() => {
    if (type?.includes("update")) {
      setValue("title", currentData.title || "");
      setValue("employer", currentData.employer || "");
      setValue(
        "start_date",
        currentData.start_date
          ? moment(currentData.start_date).format("YYYY-MM-DD")
          : ""
      );
      setValue(
        "end_date",
        currentData.end_date
          ? moment(currentData.end_date).format("YYYY-MM-DD")
          : ""
      );
      setValue(
        "skill",
        currentData?.skill && currentData?.skill?.length > 0
          ? currentData?.skill
          : []
      );
      setValue("location", currentData.location);
      setValue("image", currentData?.image);
      setValue("desc", currentData?.desc);
      setValue("is_currently_working", currentData?.is_currently_working);
      setValue("notice_period", currentData.notice_period);
    } else if (type === "create") {
      reset();
    }
  }, [type, open, currentId, currentData]);

  const handleChange = event => {
    setValue("is_currently_working", event.target.checked);
  };
  console.log(getValues("start_date"));
  function findSelectType(type) {
    switch (type) {
      case "noticeperiod":
        return [
          { name: "Immediately", value: "Immediately" },
          { name: "10 Days", value: "10 Days" },
          { name: "20 Days", value: "20 Days" },
          { name: "30 Days", value: "30 Days" },
          { name: "40 Days", value: "40 Days" },
          { name: "50 Days", value: "50 Days" },
          { name: "60 Days", value: "60 Days" },
          { name: "70 Days", value: "70 Days" },
          { name: "80 Days", value: "80 Days" },
          { name: "90 Days", value: "90 Days" },
          { name: "100 Days", value: "100 Days" },
          { name: "110 Days", value: "110 Days" },
          { name: "120 Days", value: "120 Days" },
          { name: "120+ Days", value: "120+ Days" },
        ];

      case "experienceSection":
        return [
          { name: "0", value: "0" },
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10+", value: "10+" },
        ];

      case "experiencemonths":
        return [
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10", value: "10" },
          { name: "11", value: "11" },
        ];
      default:
        [{ name: "", value: "" }];
    }
  }

  function imageModel(edit) {
    setModel(!model);
  }

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    toast.success("Uploaded successful");
    setValue("image", url);
    imageModel();
    // setTimeout(() => {
    //   // if (!editImage) {
    //   //   imageModel();
    //   // }
    //   // imageModel();
    // }, 1000);
  };

  const currentDate = new Date().toISOString().slice(0, 10);

  const minDate = watch("start_date") ? watch("start_date") : currentDate;

  return (
    <>
      {model && (
        <UploadModel
          open={model}
          toggle={imageModel}
          handleImage={handleImageUpload}
        />
      )}
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        // sx={{ height: "fit-content", width: "50vw", minWidth: "300px",  }}
      >
        <Box sx={{ ...style }}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <BlackTypography
              variant="h2"
              sx={{ marginBottom: "10px", position: "sticky" }}
            >
              {type === "create" ? "Create" : "Edit"} Work Experience
            </BlackTypography>
            <IconButton
              onClick={() => {
                toggleModel();
                reset();
              }}
              sx={{ top: "-4px" }}
            >
              <FeatherIcon icon="x" />
            </IconButton>
          </Stack>

          <form
            onSubmit={handleSubmit(data => {
              updateOrEditWorExperience({ ...data, id: currentData?.id ?? "" });
              reset();
            })}
          >
            <Grid
              container
              spacing={2}
              // sx={{ height: "90%", overflow: "auto" }}
            >
              <Grid item md={12} lg={12} sm={12}>
                <TextField
                  fullWidth
                  placeholder="Enter your title"
                  useFormRef={{ ...register("title", { required: true }) }}
                  id="title"
                  autoFocus
                  type={"text"}
                  label={"Job Title"}
                  required
                  name="title"
                  error={errors.title}
                  // variant="outlined"
                  helperText={errors.title ? "Title is required" : null}
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12}>
                <TextField
                  fullWidth
                  placeholder="Enter your employer"
                  useFormRef={{ ...register("employer", { required: true }) }}
                  id="employer"
                  label="Job employer"
                  type={"text"}
                  name="employer"
                  required
                  error={errors.employer}
                  // variant="outlined"
                  helperText={
                    errors.employer ? "Company name is required" : null
                  }
                />
              </Grid>

              <Grid item md={12} lg={12} sm={12}>
                {!watch("image") ? (
                  <PrimaryOutlinedButton
                    sx={{ maxWidth: "220px" }}
                    onClick={() => {
                      imageModel();
                    }}
                    startIcon={<FeatherIcon icon="edit" size="14" />}
                  >
                    Company image
                  </PrimaryOutlinedButton>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      placeholder="Enter employer image url"
                      useFormRef={{ ...register("image", { required: true }) }}
                      id="wor-exp-employer-outlined"
                      required
                      label="Company Image"
                      type={"text"}
                      name="image"
                      error={errors.image}
                      readOnly={true}
                      helperText={
                        errors.image ? "Company image is required" : null
                      }
                    />
                    <PrimaryOutlinedButton
                      sx={{ maxWidth: "220px", marginTop: "8px" }}
                      onClick={() => {
                        imageModel("edit");
                      }}
                      startIcon={<FeatherIcon icon="edit" size="14" />}
                    >
                      Upload image
                    </PrimaryOutlinedButton>
                  </>
                )}
              </Grid>

              <Grid item md={12} lg={12} sm={12}>
                <Geolocation
                  id="locationtest"
                  name="locationtest"
                  label="Location"
                  placeholder="Enter location"
                  required
                  error={errors?.location}
                  //  limitTags={2}
                  //  multiple
                  useFormRef={{ ...register("location", { required: true }) }}
                  locValue={getValues("location")}
                  selectOnFocus
                  // clearOnBlur
                  freeSolo
                  handleHomeEndKeys
                  variant="standard"
                  onChange={(event, newValue) => {
                    setValue("location", newValue?.description || "");
                    clearErrors("location");
                  }}
                  helperText={errors.location ? "Location is required" : null}
                />
              </Grid>

              <Grid item md={12} lg={12} sm={12}>
                <TextField
                  fullWidth
                  placeholder="Enter your specialization"
                  useFormRef={{ ...register("start_date", { required: true }) }}
                  id="wor-exp-certificate-outlined"
                  type="date"
                  name="start_date"
                  inputProps={{
                    max: currentDate,
                    // min: new Date().toISOString().slice(0, 16)
                  }}
                  required
                  label={"Start Date"}
                  error={errors.start_date}
                  disableFuture={true}
                  helperText={
                    errors.start_date ? "Start date is required" : null
                  }
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12}>
                <Stack
                  direction="row"
                  justifyContent=""
                  alignItems="center"
                  spacing={1}
                >
                  <PrimaryCheckbox
                    checked={
                      watch("is_currently_working")
                        ? watch("is_currently_working")
                        : false
                    }
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <BlackTypography>
                    I am currently working in this role
                  </BlackTypography>
                </Stack>
              </Grid>
              {!watch("is_currently_working") ? (
                <Grid item md={12} lg={12} sm={12}>
                  <TextField
                    fullWidth
                    useFormRef={{
                      ...register("end_date", {
                        required: !watch("is_currently_working"),
                      }),
                    }}
                    id="wor-exp-certificate-outlined"
                    type="date"
                    name="end_date"
                    label="End Date"
                    required
                    inputProps={{
                      // max: new Date().toISOString().slice(0, 10)
                      min: minDate,
                    }}
                    error={errors.end_date}
                    helperText={errors.end_date ? "End date is required" : null}
                  />
                </Grid>
              ) : (
                <Grid item md={12} lg={12} sm={12}>
                  <CustomSelect
                    id="notice_period"
                    name="notice_period"
                    label="Notice Period"
                    placeholder="Select notice period"
                    required
                    value={watch("notice_period")}
                    useFormRef={{
                      ...register("notice_period", {
                        required: watch("is_currently_working"),
                      }),
                    }}
                    setValue={value => {
                      console.log(value, "SettedValue");
                    }}
                    error={errors.notice_period}
                    helperText={
                      errors.notice_period
                        ? "Notice period date is required"
                        : null
                    }
                    options={(findSelectType("noticeperiod") || []).map(
                      item => ({
                        label: item.name,
                        value: item.value,
                      })
                    )}
                    onChange={event => {
                      setValue("notice_period", event?.target?.value || "");
                      clearErrors("notice_period");
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={12} sm={12}>
                <Stack spacing={2}>
                  {fields.map((field, index) => {
                    return (
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems={"center"}
                        key={field.id}
                      >
                        <Box
                          sx={{
                            marginTop: "0px !important",
                            width: "30%",
                          }}
                        >
                          <ThemeProvider theme={globalTheme}>
                            <InputLabel
                              shrink
                              htmlFor="question-outlined"
                              error={errors.question}
                              sx={{
                                height: "fit-content",
                                fontSize: "17px",
                                fontWeight: "500",
                                marginBottom: "-4px",
                              }}
                            >
                              Skill *
                            </InputLabel>
                            <Controller
                              render={props => {
                                return (
                                  <PrimaryAutoComplete
                                    sx={{
                                      backgroundColor: "var(--paper-color)",
                                      border: errors?.skill?.[index]?.skillName
                                        ? "1px solid var(--error-color)"
                                        : "1px solid #c1c1c1",
                                      borderRadius: "8px",
                                      padding: "0px 10px 0px 10px",
                                      overFlow: "visible !important",
                                    }}
                                    {...props}
                                    disableCloseOnSelect
                                    componentsProps={autocompleteProps}
                                    multiple={false}
                                    loading={skillLoader}
                                    loaderText="Fetching Skills"
                                    options={keySkills}
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => (
                                        <SecondaryFilledChip
                                          deleteIcon={
                                            <FeatherIcon icon={"x"} size="14" />
                                          }
                                          label={option.name}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) =>
                                      option?.isActive ? (
                                        <li {...props} key={props.id}>
                                          <Checkbox
                                            icon={
                                              <FeatherIcon
                                                icon="square"
                                                size="14"
                                              />
                                            }
                                            checkedIcon={
                                              <FeatherIcon
                                                icon="check-square"
                                                size="14"
                                              />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.name}
                                        </li>
                                      ) : null
                                    }
                                    getOptionLabel={option =>
                                      option?.name || ""
                                    }
                                    renderInput={params => {
                                      return (
                                        <MuiTextField
                                          {...params}
                                          variant="standard"
                                          ref={params?.InputProps?.ref}
                                        />
                                      );
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);
                                      const { inputValue } = params;
                                      const isExisting = options.some(
                                        option => inputValue === option.name
                                      );
                                      if (inputValue !== "" && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          isActive: true,
                                          id: uuid(),
                                          name: `${inputValue}`,
                                        });
                                      }
                                      return filtered;
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    value={getValues(
                                      `skill.${index}.skillName`
                                    )}
                                    // value={getValues("skillName")}
                                    onChange={async (_, data) => {
                                      const lastIndex = data?.length - 1;
                                      if (data && data[lastIndex]?.inputValue) {
                                        const { data: skill } =
                                          await createKeySkills({
                                            name: data[lastIndex]?.inputValue,
                                            is_active: true,
                                          });
                                        await getKeySKills();
                                        props.field.onChange([
                                          ...getValues("skillName"),
                                          {
                                            name: skill.key_skill_res.name,
                                            value: skill.key_skill_res.id,
                                          },
                                        ]);
                                      } else {
                                        props.field.onChange(data);
                                      }
                                    }}
                                  />
                                );
                              }}
                              rules={{ required: true }}
                              name={`skill.${index}.skillName`}
                              control={control}
                            />

                            <PrimaryNormalFormHelperText
                              error={errors?.skill?.[index]?.skillName}
                            >
                              {errors?.skill?.[index]?.skillName
                                ? "Skill is required"
                                : null}
                            </PrimaryNormalFormHelperText>
                          </ThemeProvider>
                        </Box>
                        <Box
                          sx={{
                            marginTop: "0px !important",
                            width: "30%",
                          }}
                        >
                          <Controller
                            render={({ field }) => (
                              <CustomSelect
                                {...field}
                                id={`skill.${index}.experienceyears`}
                                name={`skill.${index}.experienceyears`}
                                label="Years"
                                placeholder="Years"
                                // required
                                value={watch(`skill.${index}.experienceyears`)}
                                useFormRef={{
                                  ...register(
                                    `skill.${index}.experienceyears`,
                                    {
                                      required: false,
                                    }
                                  ),
                                }}
                                setValue={value => {
                                  console.log(value, "SettedValue");
                                }}
                                error={
                                  errors?.skill?.[index]?.experienceyears &&
                                  true
                                }
                                helperText={
                                  errors?.skill?.[index]?.experienceyears
                                    ? "Experience in years is required"
                                    : null
                                }
                                options={(
                                  findSelectType("experienceSection") || []
                                ).map(item => ({
                                  label: item.name,
                                  value: item.value,
                                }))}
                                onChange={event => {
                                  setValue(
                                    `skill.${index}.experienceyears`,
                                    event?.target?.value || ""
                                  );
                                  clearErrors(`skill.${index}.experienceyears`);
                                }}
                              />
                            )}
                            name={`skill.${index}.experienceyears`}
                            control={control}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: "0px !important",
                            width: "30%",
                          }}
                        >
                          <Controller
                            render={({ field }) => (
                              <CustomSelect
                                {...field}
                                id={`skill.${index}.experiencemonths`}
                                name={`skill.${index}.experiencemonths`}
                                label="Months"
                                placeholder="Months"
                                // required
                                value={watch(`skill.${index}.experiencemonths`)}
                                useFormRef={{
                                  ...register(
                                    `skill.${index}.experiencemonths`,
                                    {
                                      required: false,
                                    }
                                  ),
                                }}
                                setValue={value => {
                                  console.log(value, "SettedValue");
                                }}
                                error={
                                  errors[`skill.${index}.experiencemonths`]
                                }
                                helperText={
                                  errors?.skill?.[index]?.experiencemonths
                                    ? "Experience in months is required"
                                    : null
                                }
                                options={(
                                  findSelectType("experienceSection") || []
                                ).map(item => ({
                                  label: item.name,
                                  value: item.value,
                                }))}
                                onChange={event => {
                                  setValue(
                                    `skill.${index}.experiencemonths`,
                                    event?.target?.value || ""
                                  );
                                  clearErrors(
                                    `skill.${index}.experiencemonths`
                                  );
                                }}
                              />
                            )}
                            name={`skill.${index}.experiencemonths`}
                            control={control}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                            width: "15%",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              append({
                                skillName: "",
                                experienceyears: "",
                                experiencemonths: "",
                              })
                            }
                            sx={{
                              background: "var(--app-color-one)",
                            }}
                          >
                            <FeatherIcon icon="plus" size="14" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (index !== 0) remove(index);
                            }}
                            sx={{
                              background: "var(--app-color-one)",
                            }}
                          >
                            <FeatherIcon icon="minus" size="14" />
                          </IconButton>
                        </Box>
                      </Stack>
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item md={12} lg={12} sm={12}>
                <TextBox
                  fullWidth
                  placeholder="Enter your description"
                  useFormRef={{ ...register("desc", { required: false }) }}
                  minRows={3}
                  label="Description"
                  type={"text"}
                  name="desc"
                  // required
                  error={Boolean(errors.desc)}
                  // variant="outlined"
                  helperText={errors.desc ? "Description is required" : null}
                />
              </Grid>

              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack
                  direction="row"
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    size="small"
                    loading={false}
                    loadingPosition="end"
                    variant="contained"
                    onClick={() => {
                      toggleModel();
                      if (!type?.includes("update")) {
                        reset();
                      }
                    }}
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      background: "#E6F0ED",
                      padding: "5px 15px",
                      fontSize: "14px",
                      border: "0.8px solid var(--app-button-color1)",
                      color: "var(--app-button-color1)",
                      "&:hover": {
                        boxShadow: "none",
                        color: "#fff",
                        background: "var(--app-button-color1)",
                      },
                    }}
                  >
                    <span style={{ textTransform: "capitalize" }}>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    size="large"
                    loading={false}
                    loadingPosition="end"
                    variant="contained"
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                      padding: "5px 15px",
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                      "&:hover": {
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                      },
                    }}
                  >
                    <span style={{ textTransform: "capitalize" }}>Submit</span>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
