import { CircularProgress, Grid, Stack, ToggleButtonGroup, ToggleButton, Skeleton } from "@mui/material";
import CandidateInformation from "./CandidateInformation";
import CandidateExperience from "./WorkExperience";
import AdditionalInformation from './additionalInformation';
import CandidateSkills from "./skills";
import { useEffect, useState } from "react";
import EducationInformation from "./educationalInformation";
import WorkAuthInformation from "./workAuthInformation";
// import CandidateLanguages from "./languages";
// import { viewSingleUserDetailsInKanbanBoard } from "helpers/services/referalPartner";
// import SearchParamHook from "hooks/searchParams";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import ResumeSummary from "./ResumeSummary";
import { getProfileEducationDetails } from "../../../helpers/dataFetcher/profile/update";
import CheckIcon from '@mui/icons-material/Check';
import ProfileIcon from "../../../assets/icons/ProfileIcon";
import ProfileResumeIcon from "../../../assets/icons/ProfileResumeIcon";
import ProfileSkillsIcon from "../../../assets/icons/ProfileSkillsIcon";
import ProfileWorkAuthorizationIcon from "../../../assets/icons/ProfileWorkAuthorizationIcon";
import ProfileExperianceIcon from "../../../assets/icons/ProfileExperianceIcon";
import ProfileEducationIcon from "../../../assets/icons/ProfileEducationIcon";
import ProfileAddInfoIcon from "../../../assets/icons/ProfileAddInfoIcon";
import { updateUserAccount, updateLoadAccount } from "../accountSlice";

export default function ProfileSummary({ accLoading }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("category");

  const [profileTab, setProfileTab] = useState(!searchFilter || searchFilter == "" ? "personal details" : searchFilter);
  const dispatch = useDispatch();

  const accountData = useSelector((state) => state.User.user);
  const accGlobalLoad = useSelector((state) => state.User.accGlobalLoad);
  const [recall, setRecall] = useState(false);


  const getUserProfileDetails = async () => {
    dispatch(
      updateLoadAccount({
        accGlobalLoad: true,
      })
    );
    const { data, status } = await getProfile();
    if (status === 200) {
      localStorage.setItem("userData", JSON.stringify(data));
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          }
        })
      );
    }
    dispatch(
      updateLoadAccount({
        accGlobalLoad: false,
      })
    );

  }

  useEffect(() => {
    getUserProfileDetails();
  }, [recall])

  function handleTypeChange(_, data) {
    if (data) {
      setProfileTab(data);
      searchParams.set("category", data);
      setSearchParams(searchParams);
    }
  }

  const tabData = [
    {
      id: "1",
      keyword: "personal details",
      icon: ProfileIcon
    },
    {
      id: "2",
      keyword: "skills",
      icon: ProfileSkillsIcon
    },
    {
      id: "3",
      keyword: "resume",
      icon: ProfileResumeIcon
    },
    {
      id: "4",
      keyword: "work authorisation",
      icon: ProfileWorkAuthorizationIcon
    },
    {
      id: "7",
      keyword: "experience",
      icon: ProfileExperianceIcon
    },
    {
      id: "5",
      keyword: "education",
      icon: ProfileEducationIcon
    },
    {
      id: "6",
      keyword: "additional information",
      icon: ProfileAddInfoIcon
    },
  ];


  return (
    <>
      {accLoading ? (
        <>
          <Grid
            container
            pb={2}
            spacing={2}
          >
            {Array.from(new Array(1)).map((item, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                key={index}
              >
                <Stack direction="column" spacing={2}>
                  <Skeleton width="100%" height={150} />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Grid container spacing={2} >
          <Grid
            item
            xs={12}
            sm={12}
          >
            <Stack
              direction="column"
              sx={{
                height: "100%",
                border: "0.8px solid #DEDEDE",
                borderRadius: "16px",
                boxShadow: "0px 2px 54px -35px #0000008C",
                background: "#fff",
                minHeight: "60vh",
                userSelect: !accLoading && accGlobalLoad ? "none" : "",
                position: "relative",
              }}
            >
              {!accLoading && accGlobalLoad && (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    zIndex: "99",
                    background: "rgb(255 255 255 / 62%)",
                    borderRadius: "16px",
                  }}
                >
                  <CircularProgress thickness={1} color="primary" />
                </Stack>
              )}

              <Stack
                direction="row"
                sx={{
                  border: "0.8px solid #DEDEDE",
                  borderRadius: "16px 16px 0px 0px",
                  padding: "15px",
                  gap: "12px",
                  background: "#F9F9F9",
                  boxShadow: "0px 2px 54px -35px #0000008C"
                }}
              >
                <ToggleButtonGroup
                  value={profileTab}
                  exclusive
                  onChange={handleTypeChange}
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    display: "flex !important",
                    flexDirection: "row !important",
                    overflow: "scroll",
                    gap: "10px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                  size="small"
                >
                  {tabData?.map((item, index) => (
                    <ToggleButton value={item?.keyword}
                      key={index}
                      sx={{
                        border: "1px solid #DEDEDE",
                        borderRadius: "50px !important",
                        fontSize: "14px",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        padding: "6px 20px",
                        borderLeft: "1px solid #DEDEDE !important",
                        textTransform: "capitalize",
                        background: "#FFFFFF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        "&.Mui-selected": {
                          backgroundColor: "var(--app-button-color1) !important",
                          color: "#fff !important",
                          borderRadius: "50px !important",
                        }
                      }}
                      noWrap
                    >
                      <span style={{ marginRight: "10px" }}>{item?.icon(profileTab === item?.keyword)} </span> <span>{item?.keyword}</span>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
              <Stack
                sx={{
                  padding: "10px"
                }}
              >
                {profileTab === "personal details" ? (
                  <CandidateInformation
                    reCall={recall}
                    setRecall={setRecall}
                  />
                ) : profileTab === "skills" ? (
                  <CandidateSkills />
                ) : profileTab === "resume" ? (
                  <ResumeSummary
                    setRecall={setRecall}
                    reCall={recall}
                  />
                ) : profileTab === "work authorisation" ? (
                  <WorkAuthInformation
                    setRecall={setRecall}
                    reCall={recall}
                  />
                ) : profileTab === "education" ? (
                  <EducationInformation
                    setRecall={setRecall}
                    reCall={recall}
                  />
                ) : profileTab === "additional information" ? (
                  <AdditionalInformation
                    setRecall={setRecall}
                    reCall={recall}
                  />
                ) : profileTab === "experience" ? (
                  <CandidateExperience
                    setRecall={setRecall}
                    reCall={recall}
                  />
                ) : null}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
}