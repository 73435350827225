import React, { useState, useEffect } from "react";
import { Input, InputLabel, Typography, Box, Grid, Stack, IconButton, FormControl, Select, MenuItem } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
    PrimaryOpacitedButton,
} from "../../../assets/MUI/materialUiStyles";
import ProfileEditIcon from "../../../assets/icons/ProfileEditIcon";
import { FormProvider, useForm } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};


export default function ProfileSelectFieldForm({
    textField,
    textValue,
    sx,
    helperText,
    useFormProps,
    submitData,
    options,
    ...rest
}) {

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm();


    const [fieldActive, setFieldActive] = useState(false);

    useEffect(() => {
        setValue(`${rest?.name}`, `${textValue}`)
    }, [fieldActive])


    function checkInputs() {
        if (textValue === "" || textValue === null || textValue === undefined) {
            return true;
        }
        return false;
    }

    async function submitedFunction(event) {
        const result = await submitData(event);

        if (result) {
            handleButtons()
        }
    }


    function handleButtons() {
        setFieldActive(!fieldActive);
    }


    return (
        <Grid container>
            <Grid item xs={4}>
                <Typography variant="h5" component="h5" sx={{ color: "#676767", fontWeight: "400", fontSize: "16px" }}>
                    {textField ?? ""}
                </Typography>
            </Grid>
            <>
                {fieldActive ? (
                    <Grid item xs={8}>
                        <form onSubmit={handleSubmit(submitedFunction)}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <Stack direction="row" spacing={"1"} justifyContent={"center"} alignItems={"center"}>
                                    {/* <Input
                                        fullWidth
                                        {...rest}
                                        {...register(`${rest?.name}`, {...useFormProps})}
                                        error={errors[`${rest?.name}`] ? true : false}
                                        value={watch(`${rest?.name}`)}
                                        sx={[
                                            {

                                            },
                                            sx,
                                        ]}
                                        variant="outlined"
                                    /> */}
                                    <Select
                                        {...rest}
                                        value={watch(`${rest?.name}`)}
                                        {...register(`${rest?.name}`, {...useFormProps})}
              
                                        input={
                                            <Input
                                                sx={[
                                                    {
                                                        width: "100%", 
                                                        textTransform: "capitalize"
                                                    },
                                                    sx,
                                                ]}
                                                {...rest}
                                                error={errors[`${rest?.name}`] ? true : false}
                                                {...register(`${rest?.name}`, {...useFormProps})}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {options?.map(item => {
                                            return (
                                                <MenuItem
                                                    key={item?.value}
                                                    value={item?.value} // style={getStyles(name, personName, theme)}
                                                    sx={{
                                                        textTransform: "capitalize"
                                                    }}
                                                >
                                                    {item?.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <IconButton type="submit" color="success" size="small">
                                        <DoneIcon fontSize="inherit" color="inherit" />
                                    </IconButton>
                                    <IconButton onClick={() => handleButtons()} color="error" size="small">
                                        <CloseIcon fontSize="inherit" color="inherit" />
                                    </IconButton>
                                </Stack>
                                {errors[`${rest?.name}`] ? (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            color: "var(--error-color)",
                                        }}
                                    >
                                        {errors[`${rest?.name}`] ? errors[`${rest?.name}`]?.message : null}
                                    </Typography>
                                ) : null}
                            </FormControl>
                        </form>
                    </Grid>
                ) : (
                    <>
                        {checkInputs() ? (
                            <Grid item xs={8}>
                                <PrimaryOpacitedButton
                                    onClick={() => handleButtons()}
                                    endIcon={<ProfileEditIcon disabled={false} />}
                                >
                                    Add New
                                </PrimaryOpacitedButton>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={8}
                                    sx={{
                                        display: "flex",
                                        direction: "row",
                                        gap: "5px",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        varient="h6"
                                        componenet="h6"
                                        sx={{
                                            overflow: "hidden",
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            letterSpacing: "-0.2px",
                                            // textOverflow: "ellipsis",
                                            // whiteSpace: "wrap",
                                            textTransform:  "capitalize"
                                        }}
                                    >
                                        : {textValue?.replaceAll("_", " ")}
                                    </Typography>
                                    <IconButton onClick={() => handleButtons()} color="success">
                                        <ProfileEditIcon />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </>
        </Grid>
    );
}








// Usase example

{/* <ProfileSelectFieldForm
textField={"First Name"}
id="first_name"
placeholder="Enter First Name"
name="first_name"
type="text"
textValue={accountData?.first_name}
value={watch("first_name")}
submitData={submitData}
handleSubmit={handleSubmit}
error={errors?.first_name ? true : false}
useFormRef={{
  ...register("first_name", {
    required: "First Name is required",
    maxLength: {
      value: 200,
      message: "First name must not exceed 200 characters",
    },
  })
}}
helperText={
  errors?.first_name ? errors?.first_name?.message : null
}
/> */}