import FeatherIcon from "feather-icons-react";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import { formatDistance } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid, Button, ToggleButtonGroup, ToggleButton, Stack, Typography } from "@mui/material";
import { referSelf } from "../../../helpers/dataFetcher/jobs/jobs";
import { toast } from "react-hot-toast";
import getSymbolFromCurrency from "currency-symbol-map";
import DefaultMoney from "../../../assets/icons/DefaultMoney";

const tabData = [
  {
    id: "1",
    keyword: "Job Description"
  },
  {
    id: "2",
    keyword: "Job Requirements"
  },
];


export default function LayoutModalDialog({
  show,
  onHide,
  step,
  setStep,
  details,
  purpose,
  setPurpose,
}) {
  const navigate = useNavigate();
  const [descShow, setDescShow] = React.useState(true);
  const [selected, setSelected] = React.useState(true)

  /*Scrolling by tab */
  const [scrollPosition, setScrollPosition] = useState({ jobDesc: 0, jobReq: 0 });
  const descriptionRef = useRef(null);
  const requirementsRef = useRef(null);

  const handleChange = () => {
    setSelected(!selected);
    setDescShow(!descShow);
  };

  const handleScroll = () => {
    setScrollPosition((prevScrollPosition) => ({
      ...prevScrollPosition,
      [descShow ? 'jobDesc' : 'jobReq']: descShow ? descriptionRef.current.scrollTop : requirementsRef.current.scrollTop,
    }));
  };

  useEffect(() => {
    if (descShow) {
      if (descriptionRef.current) {
        descriptionRef.current.scrollTop = scrollPosition.jobDesc;
      }
    } else {
      if (requirementsRef.current) {
        requirementsRef.current.scrollTop = scrollPosition.jobReq;
      }
    }
  }, [descShow, scrollPosition]);

  const AbbreviateNumber = number => {
    const scaledNumber = number / 1000;
    if (Number.parseFloat(scaledNumber).toFixed(0) > 0) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide("");
          setStep(1);
        }}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center"}
          sx={{
            maxHeight: "fit-content!important"
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />

          <Stack
            direction="row"
            alignItems="center"
            sx={{
              flexShrink: 0,
              overflow: "hidden",
              gap: "5px",
              textOverflow: "ellipsis",
              borderBottom: "0.8px solid rgb(222, 222, 222)"
            }}
          >
            <Typography
              noWrap
              id="layout-modal-title"
              component={Link}
              level="inherit"
              to={`../job?id=${details?.id}`}
              sx={{ fontSize: "25px", fontWeight: "600" }}>{details?.job_title}</Typography>
          </Stack>
          <Grid container>
            <Grid xs={6} sx={{padding: "5px"}}>
              <Typography
                mb="0.25em"
                fontSize={"0.8em"}
                className=" d-flex align-items-center gap-2 justify-content-start"
                component={Link}
                to={`../employer-single-list?id=${details?.company_user_id}`}
                id="layout-modal-description"
                textColor="text.tertiary"

              >
                <FeatherIcon icon="link" size="15" />
                {details?.company_name
                  ? details.company_name
                  : details?.company_details?.company_name}
              </Typography>
            </Grid>
            <Grid xs={6} sx={{padding: "5px"}}>
              <Typography
                mb="0.25em"
                fontSize={"0.8em"}
                className=" d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="clock" size="15" />
                {moment(details?.application_deadline).format("DD-MM-YYYY")}
              </Typography>
            </Grid>

            <Grid xs={6} sx={{padding: "5px"}}>
              <Typography
                mb="0.25em"
                fontSize={"0.8em"}
                className=" d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <DefaultMoney size={15} />

                {getSymbolFromCurrency(details?.currency)}
                {details?.max_sal < 1000 ? `${details?.min_sal}` : AbbreviateNumber(details?.min_sal)}-
                {details?.max_sal < 1000 ? `${details?.max_sal}` : AbbreviateNumber(details?.max_sal)}
                {`/${details?.payout}`}
              </Typography>
            </Grid>
            {details?.job_type && (
              <Grid xs={6} sx={{padding: "5px"}}>
                <Typography
                  mb="0.25em"
                  fontSize={"0.8em"}
                  className=" d-flex align-items-center gap-2 justify-content-start"
                  id="layout-modal-description"
                  textColor="text.tertiary"
                >
                  <FeatherIcon icon="type" size="15" />
                  {/* {details?.job_type?.includes("_") &&
                  details?.job_type?.replace("_", " ")} */}
                  {details?.job_type?.replace("_", "")}
                </Typography>
              </Grid>
            )}

            <Grid xs={6} sx={{padding: "5px"}}>
              <Typography
                mb="0.25em"
                fontSize={"0.8em"}
                className=" d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="globe" size="15" />
                {details?.work_mode?.replace("_", " ")}
              </Typography>
            </Grid>
            <Grid xs={6} sx={{padding: "5px"}}>
              <Typography
                mb="0.25em"
                fontSize={"0.8em"}
                className=" d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="map-pin" size="15" />
                {details?.location}
              </Typography>
            </Grid>
          </Grid>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
              // borderBottom: 1,
              // borderColor: "divider",
            }}
          >
            <ToggleButtonGroup
              value={selected ? "Job Description" : "Job Requirements"}
              exclusive
              onChange={handleChange}
              sx={{
                border: "none",
                borderRadius: "10px",
              }}
              size="small"
            >
              {tabData?.map((item, index) => (
                <ToggleButton value={item?.keyword}
                  key={index}
                  sx={{
                    border: "none",
                    borderRadius: "50px !important",
                    fontSize: "13px",
                    marginRight: "5px",
                    padding: "6px 20px",
                    textTransform: "capitalize",
                    "&.Mui-selected": {
                      backgroundColor: "var(--app-button-color1) !important",
                      color: "#9FE870 !important",
                      borderRadius: "50px !important",
                    }
                  }}
                >
                  {item?.keyword}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Stack>

          <Stack>
            {descShow ? (
              <Box
                ref={descriptionRef}
                className="text-description mb-4 "
                style={{ maxHeight: "20vh", overflowY: "scroll" }}
                dangerouslySetInnerHTML={{ __html: details?.job_desc }}
                onScroll={handleScroll}
              ></Box>
            ) : (
              <Box
                ref={requirementsRef}
                onScroll={handleScroll}
                className="text-description mb-4 "
                style={{ maxHeight: "20vh", overflowY: "scroll" }}
                dangerouslySetInnerHTML={{ __html: details?.job_req }}
              ></Box>
            )}
          </Stack>

          {/* <p className="text_small_p">Job Description</p>
          <div
            className="text-description mb-4 "
            style={{ maxHeight: "20vh", overflowY: "scroll" }}
            dangerouslySetInnerHTML={{ __html: details?.job_desc }}
          ></div> */}

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              type="submit"
              size="small"
              onClick={async () => {
                if (purpose === "self") {
                  const { status } = await referSelf();
                  if (status === 200) setStep(step + 1);
                  else {

                    navigate("/profile");
                    toast.error("Please Update Your Profile First");
                  }
                } else {
                  setStep(step + 1);
                }
              }}
              loadingPosition="end"
              variant="contained"
              sx={{
                borderRadius: "50px",
                boxShadow: "none",
                background: "var(--app-button-color1)",
                padding: "5px 30px",
                "&:hover": {
                  boxShadow: "none",
                  background: "var(--app-button-color1)",
                }
              }}
            >
              <span style={{ textTransform: "capitalize" }}>Next</span>
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
