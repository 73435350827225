import moment from "moment";
import {
  calculateWorkYears,
  isValidDate,
  isValidNumber,
  parseDate,
} from "./CalculateYear";
import getSkillIds from "./handleSkills";
import { updateUserProfileDetails } from "./dataFetcher/profile/profile";
import { closeModal, openModal } from "../components/modals/modalsSlice";

export function getNumberWithDialingCode(num) {
  if (!num) return "";
  const dialCodeRegex = /^\+\d+/;
  if (dialCodeRegex.test(num)) {
    return num; // if number already has a dial code, return as is
  } else {
    return `+1${num}`; // add US dial code (+1) to the number
  }
}
let genderData = ["male", "female"];

function getLanguages(data) {
  if (Array.isArray(data) && data[0]?.hasOwnProperty("language")) {
    // If data is an array of objects
    return data.map(item => item.language);
  } else if (
    Array.isArray(data) &&
    data.every(item => typeof item === "string")
  ) {
    // If data is an array of strings
    return data;
  } else {
    return [""];
  }
}
// Function to transform resume data to match the schema
export async function transformResumeData(
  resumeData,
  resumeUrl,
  updatePhoneNumber,
  updateEmail
) {
  const {
    email,
    name,
    phoneNumber,
    skills,
    workExperience,
    education,
    dob,
    address,
    languages,
    gender,
    linkedIn,
    github,
    website,
    portfolio,
    nationality,
  } = resumeData;

  let links = {
    linkedIn: linkedIn ?? "",
    github: github ?? "",
    website: website ?? "",
    portfolio: portfolio ?? "",
  };
  // Split the full name into first and last names
  const [first_name, last_name] =
    name && name?.split(" ").length > 1
      ? [name.split(" ")[0], name.split(" ").slice(1).join(" ")]
      : [name, "-"];
  let current_company = "-";
  let current_postition = "-";
  let totalExperience = 0;
  let dateofbirth = dob && dob != "null" ? parseDate(dob) : "";
  let genders = gender
    ? genderData.includes(gender?.toLowerCase())
      ? gender.toLowerCase()
      : "prefer_not_to_say"
    : "prefer_not_to_say";
  // Map work experience
  const work_exp = workExperience.map(exp => {
    const lowerCaseDateStr = exp.endDate?.trim().toLowerCase();
    totalExperience += Number(calculateWorkYears(exp.startDate, exp.endDate));
    // Check if the experience entry is ongoing
    if (
      !exp.endDate ||
      ["current", "present", "till"].includes(lowerCaseDateStr)
    ) {
      current_company = exp.company || "-";
      current_postition = exp.title ? exp.title : exp.position ?? "N/A";
    }
    let startDate, endDate;
    if ((exp.startDate && exp.endDate) || exp.startDate) {
      startDate = parseDate(exp.startDate);
      endDate = exp.endDate ? parseDate(exp.endDate) : "";
    } else if (exp.duration) {
      const [startPart, endPart] = exp.duration
        .split(" - ")
        .map(part => part.trim());
      startDate = startPart ? parseDate(startPart) : "";
      endDate = endPart ? parseDate(endPart) : "";
    }
    return {
      title: exp.title ? exp.title : exp.position ?? "N/A",
      employer: exp.company || "",
      location: exp.location || "",
      start_date: isValidDate(startDate) ? startDate : "",
      end_date: isValidDate(endDate) ? endDate : "",
      description: Array.isArray(exp.description)
        ? exp.description?.join()
        : exp.description || "N/A",
    };
  });

  // Map education
  const education_training = education.map(edu => {
    let startDate, endDate;
    if ((edu.startDate && edu.endDate) || edu.startDate) {
      startDate = parseDate(edu.startDate);
      endDate = parseDate(edu.endDate);
    } else if (edu.duration) {
      const [startPart, endPart] = edu.duration
        .split(" - ")
        .map(part => part.trim());
      startDate = startPart ? parseDate(startPart) : "";
      endDate = endPart ? parseDate(endPart) : "";
    }
    return {
      certificate_name: edu.degree || "N/A",
      institute_name: edu.university ? edu.university : edu?.institution || "-",
      location: edu.location || "N/A",
      start_date: isValidDate(startDate) ? startDate : "",
      end_date: isValidDate(endDate) ? endDate : "",
      description: Array.isArray(edu.description)
        ? edu.description?.join
        : edu.description || "N/A",
    };
  });

  // Transform skills into UUID format (assuming you have a function `getSkillIds` to get UUID)
  const skillsUUIDs = await getSkillIds(skills);

  return {
    ...(updateEmail ? { email } : {}),
    first_name: first_name ? first_name : "-",
    last_name: last_name ? last_name : "-",
    ...(isValidDate(dob) ? { dob: dateofbirth } : {}),
    gender: genders,
    nationality: nationality ? nationality : "",
    languages: languages && languages != "null" ? getLanguages(languages) : [],
    update_type: "new",
    resume_file: resumeUrl,
    experience: isValidNumber(totalExperience) ? totalExperience.toFixed(1) : 0,
    address: address && address != "null" ? address : "",
    ...(updatePhoneNumber
      ? { phone_number: getNumberWithDialingCode(phoneNumber) }
      : {}),
    skills: skillsUUIDs,
    work_exp,
    education_training,
    current_company,
    current_postition,
    links,
    work_location: address && address != "null" ? [address] : [],
    current_address: address && typeof address == "string" ? address : "-",
  };
}

export default async function updateUserProfile(
  resumeData,
  resumeUrl,
  updatePhoneNumber,
  updateEmail
) {
  if (!resumeData) return null;
  // Transform the resume data
  const transformedData = await transformResumeData(
    resumeData,
    resumeUrl,
    updatePhoneNumber,
    updateEmail
  );

  await updateUserProfileDetails("resume", transformedData);
  return transformedData;
}
