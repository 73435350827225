import Swal from "sweetalert2";

/**
 * Show a SweetAlert2 alert.
 * @param {Object} options - The options for SweetAlert2.
 * @param {string} options.title - The title of the alert.
 * @param {string} options.text - The text content of the alert.
 * @param {string} [options.icon='info'] - The icon to display (e.g., 'success', 'error', 'warning', 'info').
 * @param {boolean} [options.showCancelButton=false] - Whether to show the cancel button.
 * @param {string} [options.confirmButtonText='OK'] - The text for the confirm button.
 * @param {string} [options.cancelButtonText='Cancel'] - The text for the cancel button.
 * @returns {Promise<SweetAlertResult>} - A promise that resolves with the result of the alert.
 */
export async function showAlert({
  title,
  text,
  //   icon = 'info',
  showCancelButton = false,
  confirmButtonText = "OK",
  cancelButtonText = "Cancel",
} = {}) {
  const result = await Swal.fire({
    title,
    text,
    // icon,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    reverseButtons: true,
    didOpen: () => {
      // Adjust the z-index of the SweetAlert2 modal
      const swalContainer = document.querySelector(".swal2-container");
      if (swalContainer) {
        swalContainer.style.zIndex = "9999";
      }
    },
  });

  return result;
}
