import React, { useEffect } from "react";
import "../../assets/JobList/MainSerachFilter.css";
import { useState } from "react";
import { Grid, Stack, InputAdornment, IconButton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { PlacesAutocomplete } from "../../data/locationSearch";
import { useForm } from "react-hook-form";
import { globalTheme } from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import { OutlinedInput, Button } from "@mui/material";
import JobSearchIcon from "../../assets/icons/JobSearchIcon.jsx";
import JobFilterIcon from "../../assets/icons/JobFilterIcon.jsx";
import TextField from "../../components/formElements/TextField.jsx";

function MainSearchFilterBar() {
  let [searchParams, setSearchParams] = useSearchParams();


  const [searchLocation, setSearchLocation] = useState(searchParams.get("location") ?? "");
  const [titleText, setTitleText] = useState(searchParams.get("title") ?? "");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setTitleText(searchParams.get("title") ?? "")
    setSearchLocation(searchParams.get("location") ?? "")
  }, [searchParams.get("title"), searchParams.get("location")])




  const onSubmit = async data => {
    searchParams.set("title", titleText);
    searchParams.set("location", searchLocation);
    setSearchParams(searchParams);
  };

  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container
            sx={{
              '@media (max-width: 600px)': {
                gap: "10px"
              },
            }}
          >
            <Grid item xs={12} sm={6}>
              <Stack
                sx={{
                  paddingRight: "5px",
                  paddingLeft: "10px",
                  '@media (max-width: 600px)': {
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  },
                }}
              >
                <TextField
                  startAdornment={
                    <InputAdornment
                      position="start"
                    >
                      <IconButton
                        aria-label="search title, company"
                        edge="end"
                        size="small"
                      >
                        <JobSearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  type="text"
                  value={titleText ?? ""}
                  className="input-search-job mb-1"
                  placeholder="Search by companies, job title"
                  sx={{
                    width: "100%",
                    background: "#fff",
                    padding: "17px 16px 17px 16px",
                    borderRadius: "8px",
                  }}
                  onChange={(e) => {
                    setTitleText(e.target.value)
                    if (searchParams.get("title")) {
                      if (e.target.value?.length === 0) {
                        searchParams.set("title", e.target.value);
                        setSearchParams(searchParams);
                      }
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack
                sx={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  '@media (max-width: 600px)': {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  },
                }}
              >
                <PlacesAutocomplete
                  startAdornment={
                    <InputAdornment
                      position="start"
                    >
                      <IconButton
                        aria-label="search title, company"
                        edge="end"
                        size="small"
                      >
                        <JobSearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    width: "100%",
                    background: "#fff",
                    padding: "17px 16px 17px 16px",
                    borderRadius: "8px",
                  }}
                  className="input-search-location"
                  location={searchLocation}
                  setLocation={e => {
                    setSearchLocation(e);
                    if (searchParams.get("location")) {
                      if (e?.length === 0) {
                        searchParams.set("location", e);
                        setSearchParams(searchParams);
                      }
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Stack
                sx={{
                  paddingLeft: "5px",
                  '@media (max-width: 600px)': {
                    paddingLeft: "0px",
                  },
                }}>
                <Button
                  type="submit"
                  size="large"
                  endIcon={<JobFilterIcon fontSize="small" sx={{}} />}
                  loading={false}
                  onClick={() => handleSubmit(onSubmit)}
                  loadingPosition="end"
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                    padding: "6px 4px",
                    WebkitBoxOrient: "horizontal",
                    overflow: "hidden",
                    width: "100%",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textOverflow: "ellipsis",
                    "&:hover": {
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>Find Jobs</span>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
    </>
  );
}
export default MainSearchFilterBar;
