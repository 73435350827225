import React, { useState, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import CropModal from "./cropModel";
import { Col, Row, Card, Form } from "reactstrap";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { ModalDialog, ModalClose } from "@mui/joy";
import { Stack, Grid, Button, Box, Typography } from "@mui/material";
import toast from "react-hot-toast";
import ProfileUpResumeIcon from "../assets/icons/ProfileUpResumeIcon";

function UploadFileModal(props) {
  const { isOpen, toggle, handleImage } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [crop, setCrop] = useState(false);

  const handleAcceptedFiles = files => {

    if (files[0]?.size / 1024 / 1024 > 5) {
      toast.error(`File size too large. File size should be less than 5MB`);
      return;
    }

    if (files && Object.values(files).length > 1) {
      toast.error(`Maximum file limit exceeded! Please select only one file.`);
      return;
    }

    if (!files || !files.length) {
      toast.error(`Incorrect file format selected! Please select a valid image file.`);
      return;
    }

    try {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          crop: false,
        })
      );

      setSelectedFiles(files);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {

    }


  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  function handleCrop() {
    setCrop(false);
  }



  const onDrop = useCallback(acceptedFiles => {
    handleAcceptedFiles(acceptedFiles)
  }, [])


  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isFocused,
    isDragActive
  } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop
  });


  return (
    <Modal
      sx={{
        "& .css-1htn9tb-JoySheet-root-JoyModalDialog-root": {
          background: "white",
          height: "fit-content !important"
        },
      }}
      open={!!isOpen}
    >
      <ModalDialog
        size="lg"
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        layout={"center"}
        sx={{
          height: "fit-content !important"
        }}
      >
        {selectedFiles.length > 0 ? (
          <CropModal
            isOpen={crop}
            toggle={() => {
              setSelectedFiles([]);
              toggle(!isOpen);
            }}
            imageSrc={selectedFiles[0]?.preview}
            type={selectedFiles[0]?.type}
            handleImage={handleImage}
          />
        ) : (
          <>
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.body",
              }}
              onClick={() => toggle(!isOpen)}
            />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700"
              }}
            >
              Upload Image
            </Typography>
            <Stack>
              <form>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    // height: "100%"
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    sx={{
                      width: "100%",
                      border: `1.29px dashed ${isFocused || isDragActive ? "green" : "#DEDEDE"}`,
                      background: "#F9F9F9",
                      padding: "20px",
                      borderRadius: "20.66px",
                    }}
                    {...getRootProps()}
                  >
                    <input   {...getInputProps()} />
                    <Box>
                      <ProfileUpResumeIcon />
                    </Box>
                    <Box mt={2}>
                      <Typography textAlign={"center"}
                        sx={{
                          fontWeight: "600",
                          fontSize: "15px",

                        }}
                      >
                        Click or Drag file(s) here to upload
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography textAlign={"center"}
                        sx={{
                          fontWeight: "500",
                          fontSize: "11px",
                          color: "#A9A7A7",

                        }}
                      >
                        Please only use these following formats: .jpg, .jpeg, .png, .gif, .bmp, .tiff, .svg, .webp <br />
                        Max file size: 5MB | Max files: 1
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>

                <Box mt={1} mb={1}>
                  {selectedFiles.map((f, i) => {
                    return (
                      <div key={i + "-file"}>
                        <Stack className="align-items-center">
                          <Stack className="col-auto">
                            {selectedFiles[0].type.startsWith("image/") ? (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={URL.createObjectURL(f)}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                          <Stack>
                            <p className="text-muted font-weight-bold" style={{ color: "gray", fontSize: "12px" }}>
                              {f.name}
                            </p>
                            <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>
                              <strong>{formatBytes(f.size)}</strong>
                            </p>
                          </Stack>
                        </Stack>
                      </div>
                    );
                  })}
                </Box>
              </form>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Button
                size="small"
                loading={false}
                loadingPosition="end"
                variant="contained"
                onClick={() => {
                  toggle(!isOpen);
                }}
                sx={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  background: "#E6F0ED",
                  padding: "4px 8px",
                  fontSize: "14px",
                  // width: "50%",
                  border: "0.8px solid var(--app-button-color1)",
                  color: "var(--app-button-color1)",
                  "&:hover": {
                    boxShadow: "none",
                    color: "#fff",
                    background: "var(--app-button-color1)",
                  }
                }}

              >
                <span style={{ textTransform: "capitalize" }}>Cancel</span>
              </Button>
              <Button
                size="small"
                loading={false}
                loadingPosition="end"
                variant="contained"
                disabled={!selectedFiles || selectedFiles?.length === 0}
                onClick={() => {
                  if (selectedFiles.length === 0) {
                    toast.error(`Please select a file.`);
                    return;
                  }
                  if (selectedFiles[0]?.type.startsWith("image/")) {
                    setCrop(!crop);
                    return;
                  }
                  handleImage(selectedFiles);
                }}
                sx={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  background: "var(--app-button-color1)",
                  padding: "4px 8px",
                  // width: "50%",
                  fontSize: "14px",
                  "&:hover": {
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                  }
                }}
              >
                <span style={{ textTransform: "capitalize" }}>
                  Save
                </span>
              </Button>
            </Stack>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
}

export default UploadFileModal;
