import { CancelOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import MyModal from "./components/Modal";
import { SecondaryFilledButton, PrimaryButton } from "../../assets/MUI/materialUiStyles";

export default function LogoutConfirmationModal({
  open,
  handleClose,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) {
  return (
    <MyModal
      open={open}
      handleClose={handleClose}
      size="medium"
      sx={{ width: "400px", height: "fit-content" }}
    >
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography sx={{ fontSize: "25px", fontWeight: "700", textAlign: "center" }}>Logout?</Typography>
        <Typography sx={{ fontSize: "15px", fontWeight: "500", textAlign: "center" }}>
          Are you sure you want to logout? You will be redirected to the login page.
        </Typography>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing={3} pt={1}>
          <Button
            type="submit"
            size="small"
            loading={false}
            loadingPosition="end"
            variant="contained"
            onClick={() => {
              // onCancel();
              handleClose();
            }}
            sx={{
              borderRadius: "8px",
              boxShadow: "none",
              background: "#E6F0ED",
              padding: "4px 8px",
              fontSize: "14px",
              border: "0.8px solid var(--app-button-color1)",
              color: "var(--app-button-color1)",
              "&:hover": {
                boxShadow: "none",
                color: "#fff",
                background: "var(--app-button-color1)",
              }
            }}
          >
            <span style={{ textTransform: "capitalize" }}>{cancelText || "Cancel"}</span>
          </Button>
          <Button
            type="submit"
            size="small"
            loading={false}
            loadingPosition="end"
            variant="contained"
            onClick={() => {
              onConfirm();
              handleClose();
            }}
            sx={{
              borderRadius: "8px",
              boxShadow: "none",
              background: "var(--app-button-color1)",
              padding: "4px 8px",
              fontSize: "14px",
              "&:hover": {
                boxShadow: "none",
                background: "var(--app-button-color1)",
              }
            }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {confirmText || "Yes"}
            </span>
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
}
