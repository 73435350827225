import React, { useState, useEffect, lazy } from "react";
import { Box, Typography } from "@mui/material";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import PrivateOutlet from "./PrivateOutlet";
import PublicOutlet from "./PublicOutlet";
import AuthOutlet from "./AuthOutlet";

import ChangePassword from "../Authentication/ChangePassword";
import ChangeEmail from "../Authentication/ChangeEmail";
import Logout from "../Authentication/Logout";
import AboutUs from "../pages/AboutUs/AboutPage";
import ProfilePage from "../pages/Accounts/AccountsIndex";
import AllJobs from "../pages/Accounts/AllJobs/AllJobs";
import AppliedJobs from "../pages/Accounts/AppliedJobs/AppliedJobs";
import DashBoardIndex from "../pages/Accounts/Dashboard/DashboardIndex";
import MyReferals from "../pages/Accounts/MyReferals/myReferal";
import BlogsListIndex from "../pages/Blogs/BlogsListIndex";
import BlogsSingleIndex from "../pages/Blogs/BlogsSingle/BlogsSingle";
import BrowseJobsList from "../pages/BrowseJobsList/Index";
// import Chat from "../pages/Chat";
import ContactUsIndex from "../pages/Contact-Us/ContactUsIndex";
import Employer from "../pages/Employers/EmployerSingle/Index";
import MainEmployerList from "../pages/Employers/List/Index";
import Faq from "../pages/Faq/Faq";
import HomeUI from "../pages/Home/HomeUI";
import InterviewsList from "../pages/interviewlist/InterviewsList";
import MainJobsList from "../pages/JobsList/Index";
import LeadershipBoard from "../pages/Leadershipboard/LeadershipBoard";
import MyEarning from "../pages/MyEarning/Index";
import DemoChat from "../pages/DemoChat/index";
import MyMoney from "../pages/MyMoney/Index";
import NotFound from "../pages/NotFound";
import Pricing from "../pages/Pricing/Pricing";
import ReferNow from "../pages/Refernow/ReferNow";
import ReferNowIndex from "../pages/ReferNowPage/ReferNowIndex";
import EmployersIndex from '../pages/EmployersPage/EmployersIndex'
import Settings from "../pages/settings";
import MySavedJobs from "../pages/Accounts/SavedJobs/MySavedJobs";
import ChatChannelIndex from "../pages/DemoChat/DemoChatChannelIndex";

import UserProfile from "../pages/Accounts/Profiles/UserProfile/UserProfile";
import ReferAndEarnPage from "../pages/LandingPages/ReferAndEarnPage/ReferAndEarnPage";
import CompanyLandingPage from "../pages/LandingPages/CompanyPage/CompanyLandingPage";
import CommonLandingPage from "../pages/LandingPages/CommonPage/CommonLandingPage";
import CareersPage from "../pages/Careers/CareersPage";
import LoginPage from "../Authentication/AuthPages/LoginPage";
import RegisterPage from "../Authentication/AuthPages/RegisterPage";
import BlogsPage from "../pages/Blogs/BlogsPage";
import BlogDetailsPage from "../pages/Blogs/BlogDetailsPage";
import PrivacyPolicyPage from "../pages/CompanyPolicies/PrivacyPolicyPage";
import ForgetPasswordPage from "../Authentication/AuthPages/ForgetPasswordPage";
import ResetPasswordPage from "../Authentication/AuthPages/ResetPasswordPage";
import ProfileLayout from "./ProfileLayout";


export default function AppRoutes() {

  const location = useLocation();
  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  const getPageTitle = path => {
    switch (path) {
      case "/jobs-list":
        return "Jobs";
      case "/employer-list":
        return "Companies";
      case "/employer-list":
        return "Companies";
      case "/profile":
        return "Profile";
      case "/dashboard":
        return "Dashboard";
      case "/refered-jobs":
        return "Refered Jobs";
      case "/my-referals":
        return "My Referals";
      case "/applied-jobs":
        return "Applied jobs";
      case "/my-savedjobs":
        return "Saved Jobs";
      case "/demochat":
        return "Chat";
      case "/interviews":
        return "Interviews";
      case "/leadership-board":
        return "Leadership Board";
      case "/myearning":
        return "My Earnings";
      case "/settings":
        return "Settings";
      case "/login":
        return "Login";
      case "/registration":
        return "Registration";
      case "/forget-password":
        return "Forget Password";
      case "/reset-password":
        return "Reset Password";
      default:
        return "Joinee";
    }
  };


  const routes = useRoutes([
    {
      path: "/",
      element: <PublicOutlet />,
      children: [
        { path: "/", element: <CommonLandingPage /> },
        // { path: "/", element: <HomeUI /> },
        { path: "/company", element: <CompanyLandingPage /> },
        { path: "/common-refer", element: <ReferAndEarnPage /> },
        { path: "/employer-list", element: <MainEmployerList /> },
        { path: "/jobs-list", element: <MainJobsList /> },
        { path: "/logout", element: <Logout /> },
        { path: "/contact-us", element: <ContactUsIndex /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/refernow", element: <ReferNowIndex /> },
        // { path: "/employerpage", element: <EmployersIndex /> },
        { path: "/blogs", element: <BlogsPage /> },
        { path: "/blog/:blogId", element: <BlogDetailsPage /> },
        { path: "/privacy-policy", element: <PrivacyPolicyPage /> },
        // { path: "/job/:id", element: <BrowseJobsList /> },
        // { path: "/employer-single-list/:id", element: <Employer /> },
        { path: "/job", element: <BrowseJobsList /> },
        { path: "/employer-single-list", element: <Employer /> },
        { path: "/user-profile", element: <UserProfile /> },
        { path: "/careers", element: <CareersPage /> }
      ],
    },
    {
      path: "/",
      element: <PrivateOutlet />,
      children: [
        { path: "/profile", element: (<ProfileLayout><ProfilePage /></ProfileLayout>) },
        { path: "/dashboard", element: (<ProfileLayout><DashBoardIndex /></ProfileLayout>) },
        { path: "/refered-jobs", element: (<ProfileLayout> <AllJobs /> </ProfileLayout>) },
        { path: "/my-referals", element: (<ProfileLayout> <MyReferals /> </ProfileLayout>) },
        { path: "/applied-jobs", element: (<ProfileLayout> <AppliedJobs /> </ProfileLayout>) },
        { path: "/interviews", element: (<ProfileLayout><InterviewsList /></ProfileLayout>) },
        { path: "/myearning", element: (<ProfileLayout><MyEarning /></ProfileLayout>) },
        { path: "/settings", element: (<ProfileLayout><Settings /></ProfileLayout>) },
        { path: "/leadership-board", element: (<ProfileLayout> <LeadershipBoard /> </ProfileLayout>) },
        { path: "/demochat", element: (<ProfileLayout> <DemoChat /> </ProfileLayout>) },
        { path: "/jobchat", element: (<ProfileLayout> <ChatChannelIndex /> </ProfileLayout>) },
        { path: "/my-savedjobs", element: (<ProfileLayout> <MySavedJobs /> </ProfileLayout>) },

        { path: "/blogs/:id", element: <BlogsSingleIndex /> },
        // { path: "/refernow", element: <ReferNow /> },
        // { path: "/refernow", element: <ReferNowIndex /> },
        // { path: "/employerpage", element: <EmployersIndex /> },
        { path: "/pricing", element: <Pricing /> },
        { path: "/faq", element: <Faq /> },
        { path: "/mymoney", element: <MyMoney /> },
        // { path: "/employer-single-list/:id", element: <Employer /> },
        { path: "/mymoney", element: <MyMoney /> },

      ],
    },
    {
      path: "/",
      element: <AuthOutlet />,
      children: [
        { path: "/login", element: <LoginPage /> },
        { path: "/registration", element: <RegisterPage /> },
        { path: "/forget-password", element: <ForgetPasswordPage /> },
        { path: "/reset-password", element: <ResetPasswordPage /> },
      ],
    },
    {
      path: "*",
      element: (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <NotFound />
        </Box>
      ),
    },
  ]);

  return routes;
}
