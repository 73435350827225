import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Stack, CircularProgress, Box, Typography, IconButton } from "@mui/material";
import PopoverPopupModel from "../../../components/popover/components/Popover";
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from "react-router-dom";

function AllFiltersListPopups({
    filterPopupsEl,
    setFilterPopupsEl,
    fnAreas,
    keySkills,
    jobTypes,
    workModes,
    jobTypeChange,
    fnAreasChange,
    keySkillsChange,
    workModesChange
}) {

    let [searchParams, setSearchParams] = useSearchParams();
    const urlParams = new URLSearchParams(window.location.search);
    const fnAreasId = urlParams.getAll("fn_areas");
    const keySkillId = urlParams.getAll("key_skills")
    const jobTypeId = urlParams.getAll("job_type")
    const workModeId = urlParams.getAll("work_mode")
    const expParams = urlParams.get("exp")
    const titleParams = urlParams.get("title")
    const locationParams = urlParams.get("location")

    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        setLoading(true);
        let title = [];
        let location = [];
        let exp = [];
        const finalFA = fnAreas?.filter(element => {
            if (fnAreasId.includes(element?.id)) {
                element.paramType = "fn_areas";
                element.countOfJobs = element.countOfJobs;
                element.isChecked = true
                return true;
            }
            return false;
        }) ?? [];
        const finalKeySkills = keySkills?.filter(element => {
            if (keySkillId.includes(element?.id)) {
                element.paramType = "key_skills";
                element.name = element.skill;
                element.countOfJobs = element.jobsCount;
                element.isChecked = true
                return true;
            }
            return false;
        }) ?? [];
        const finalJobTypes = jobTypes?.filter(element => {
            if (jobTypeId.includes(element?.jobType)) {
                element.id = `${element.jobType}`
                element.paramType = "job_type";
                element.name = element?.jobType?.replace("_", " ");
                element.countOfJobs = element.jobPostsCount;
                element.isChecked = true
                return true;
            }
            return false;
        }) ?? [];
        const finalWorkModes = workModes?.filter(element => {
            if (workModeId.includes(element?.workModeValue)) {
                element.id = `${element?.workModeValue}`
                element.paramType = "work_mode";
                element.name = element.workModeShown;
                element.countOfJobs = element.workModeCount;
                element.isChecked = true
                return true;
            }
            return false;
        }) ?? [];
        if (titleParams && titleParams !== "") {
            title = [{ id: "title_1", name: "Title", paramType: "title", countOfJobs: "", isChecked: true }]
        }
        if (locationParams && locationParams !== "") {
            location = [{ id: "location_1", name: "Location", paramType: "location", countOfJobs: "", isChecked: true }]
        }
        if (expParams && expParams !== "") {
            exp = [{ id: "exp_1", name: "Experience", paramType: "exp", countOfJobs: "", isChecked: true }]
        }
        setFilters([...finalFA, ...finalKeySkills, ...finalJobTypes, ...finalWorkModes, ...title, ...location, ...exp]);
        setLoading(false);
    }, [filterPopupsEl])


    const setParameters = data => {
        setSearchParams({
            title:
                searchParams.get("title") !== null ? searchParams.get("title") : "",
            location:
                searchParams.get("location") !== null
                    ? searchParams.get("location")
                    : "",
            fn_areas: searchParams.getAll("fn_areas"),
            salary: searchParams.getAll("salary"),
            key_skills: searchParams.getAll("key_skills"),
            exp: searchParams.get("exp") !== null ? searchParams.get("exp") : "",
            job_type: searchParams.getAll("job_type"),
            work_mode: searchParams.getAll("work_mode"),
            ...data,
        });
    };

    const handleAllFiltersChange = (id, updatedProperties) => {
        setFilters(prevState => {
            return prevState.map(obj => {
                if (obj.id === id) {
                    if (obj.paramType === "fn_areas") {
                        fnAreasChange("_", "remove", id)
                    } else if (obj.paramType === "key_skills") {
                        keySkillsChange("_", "remove", id)
                    } else if (obj.paramType === "job_type") {
                        jobTypeChange("_", "remove", obj.jobType);
                    } else if (obj.paramType === "work_mode") {
                        workModesChange("_", "remove", obj.workModeValue)
                    } else if (obj.paramType === "title") {
                        setParameters({ title: "" })
                    } else if (obj.paramType === "location") {
                        setParameters({ location: "" })
                    } else if (obj.paramType === "exp") {
                        setParameters({ exp: "" })
                    }
                    return { ...obj, ...updatedProperties };
                }
                return obj;
            });
        });
        setFilterPopupsEl(null);

    }

    const removeAllHandler = () => {
        setParameters({
            fn_areas: [],
            key_skills: [],
            job_type: [],
            work_mode: [],
            location: "",
            title: "",
            exp: ""
        })
        // setFilters(prevState => {
        //     return prevState.map(obj => {
        //         return { ...obj, isChecked: false };
        //     });
        // });
        setFilterPopupsEl(null)
    }

    return (
        <PopoverPopupModel
            anchorEl={filterPopupsEl}
            setAnchorEl={setFilterPopupsEl}
            sx={{ maxWidth: "40vw", minWidth: "150px", width: "350px", padding: "8px", overflow: "auto", maxHeight: "400px" }}
            popupAdjust={{ marginTop: "15px" }}
        >
            <Stack
                direction="column"

                sx={{
                    borderRadius: "5px",
                    overflow: "hidden",
                }}
            >
                {loading ? (
                    <>
                        <Stack
                            sx={{
                                height: "100%",
                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress color="success" />
                        </Stack>
                    </>
                ) : (
                    <>
                        {filters?.length > 0 ? (
                            <>
                                <Box sx={{ padding: "10px" }}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{
                                            // border: "1px solid red",
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "700",
                                                color: "#2D6CCA",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => removeAllHandler()}
                                        >
                                            Remove All
                                        </Box>
                                        <Box>
                                            <IconButton
                                                sx={{ color: "gray" }}
                                                onClick={() => setFilterPopupsEl(null)}
                                                size="small"
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                    <ul>
                                        {filters?.map((each, i) => (
                                            <li key={each.id}
                                            // style={{
                                            //     display: "-webkit-box",
                                            //     WebkitBoxOrient: "vertical",
                                            //     WebkitLineClamp: 1,
                                            //     overflow: "hidden",
                                            //     minHeight: "30px"
                                            // }}
                                            >
                                                <label className="cb-container"
                                                // style={{
                                                //     fontSize: "13px",
                                                //     wordWrap: "break-word",
                                                //     overflowWrap: "break-word",
                                                //     overflow: "hidden",
                                                // }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={each.isChecked}
                                                        onChange={e => handleAllFiltersChange(each.id, each.isChecked === true ? { isChecked: false } : { isChecked: true })}
                                                    />
                                                    <span className="text-small">{each.name}</span>
                                                    <span className="checkmark"></span>
                                                    {each.countOfJobs !== "" && (
                                                        <span className="number-item"
                                                            style={{
                                                                fontSize: "13px",
                                                            }}
                                                        > ({each.countOfJobs})</span>
                                                    )}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            </>
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "13px",
                                    textAlign: "center",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    color: "gray"
                                }}
                            >No filters are applied</Typography>
                        )}
                    </>
                )}
            </Stack>
        </PopoverPopupModel>
    )
}

export default AllFiltersListPopups