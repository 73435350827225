import { POST, PUT } from "../../../Constants/methods";
import callApi from "../../callApi";

export async function getPreSignedURL(file) {
  let urlHolder = "";
  let returnData = "";
  const data = await callApi(
    "helper_routes/get_presigned_url",
    {
      content_type: file[0].type,
    },
    POST
  ).then(async req => {
    urlHolder = req.data.url;
    await fetch(req.data.url, {
      method: "PUT",
      body: file[0],
      headers: {  
        "Content-Type": file[0].type,
      },
    })
      .then(req => {
        return req;
      })
      .then(async res => {
        returnData = await urlHolder.split("?")[0];
      });
  });
  return returnData;
}
