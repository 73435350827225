import React, { useState } from "react";
import { Input } from "@mui/joy";
import "../../../assets/componies/companyFilters.css";

import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";

export const CompanyFilters = () => {
  const [showCompany, setShowCompany] = useState(true);
  const [showLocation, setShowLocation] = useState(true);
  const [showIndustry, setShowIndustry] = useState(true);
  const [showDepartments, setShowDepartments] = useState(true);

  const [companyTypes, setCompanyTypes] = useState([
    { id: 0, type: "Foreign MNC (1173)", isChecked: false },
    { id: 1, type: "Corporate (661)", isChecked: false },
    { id: 2, type: "Startup (278)", isChecked: false },
    { id: 4, type: "Indian MNC (252)", isChecked: false },
    { id: 5, type: "Startup (278)", isChecked: false },
    { id: 6, type: "Indian MNC (252)", isChecked: false },
  ]);

  const [location, setLocation] = useState([
    { id: 0, name: "Bangalore/Bengaluru (1232)", isChecked: false },
    { id: 1, name: "Delhi / NCR (1077)", isChecked: false },
    { id: 2, name: "Mumbai (All Areas) (940)", isChecked: false },
    { id: 3, name: "Hyderabad/Secunderabad (778)", isChecked: false },
  ]);

  const [industry, setIndustry] = useState([
    { id: 0, name: "IT Industry (122)", isChecked: false },
    { id: 1, name: "Pharma Industry (107)", isChecked: false },
    { id: 2, name: "Telecom (90)", isChecked: false },
    { id: 3, name: "Medical (78)", isChecked: false },
  ]);

  const [departments, setDepartments] = useState([
    { id: 0, name: "Junior level", isChecked: false },
    { id: 1, name: "Mid level", isChecked: false },
    { id: 2, name: "Senior level", isChecked: false },
  ]);

  return (
    <div className="company_filter_main">
      <div className="company_filter_main_cont">
        <div>
          <span className="company_filters_heading">Filters</span>
          <hr />
        </div>

        <div>
          <p className="comp_filters_headers">
            <span className="company_filters_heading">Industry</span>
            {showIndustry ? (
              <span onClick={() => setShowIndustry(!showIndustry)}>
                <BsChevronUp size={14} className="comp_filter_handle_icon" />
              </span>
            ) : (
              <span onClick={() => setShowIndustry(!showIndustry)}>
                <BsChevronDown size={14} className="comp_filter_handle_icon" />
              </span>
            )}
          </p>

          {showIndustry ? (
            <>
              <Input
                size="sm"
                // style={{ height: "35px" }}
                placeholder="Search by industry..."
                className="comp_filter_input"
              />

              <div className="form-group">
                <ul className="comp-list-checkbox">
                  {industry.map(each => {
                    return (
                      <li key={each.id}>
                        <label className="cb-container">
                          <input
                            type="checkbox"
                            isChecked={each.isChecked}
                            onChange={(e, id) => handleChecked(e, each.id)}
                          />{" "}
                          <span className="text-small">{each.name}</span>
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <span className="company_filter_more">+5 more</span>
              <hr />
            </>
          ) : null}
        </div>
        <div>
          <p className="comp_filters_headers">
            <span className="company_filters_heading">Location</span>
            {showCompany ? (
              <span onClick={() => setShowCompany(!showCompany)}>
                <BsChevronUp size={14} className="comp_filter_handle_icon" />
              </span>
            ) : (
              <span onClick={() => setShowCompany(!showCompany)}>
                <BsChevronDown size={14} className="comp_filter_handle_icon" />
              </span>
            )}
          </p>
          {location ? (
            <>
              <Input
                size="sm"
                // style={{ height: "35px" }}
                placeholder="Search by location..."
                className="comp_filter_input"
              />

              <div className="form-group">
                <ul className="comp-list-checkbox">
                  {companyTypes.map(each => {
                    return (
                      <li key={each.id}>
                        <label className="cb-container">
                          <input
                            type="checkbox"
                            isChecked={each.isChecked}
                            onChange={(e, id) => handleChecked(e, each.id)}
                          />{" "}
                          <span className="text-small">{each.type}</span>
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <span className="company_filter_more">+2 more</span>
              <hr />
            </>
          ) : null}
        </div>
        <div>
          <p className="comp_filters_headers">
            <span className="company_filters_heading">Experience</span>
            {showDepartments ? (
              <span onClick={() => setShowDepartments(!showDepartments)}>
                <BsChevronUp size={14} className="comp_filter_handle_icon" />
              </span>
            ) : (
              <span onClick={() => setShowDepartments(!showDepartments)}>
                <BsChevronDown size={14} className="comp_filter_handle_icon" />
              </span>
            )}
          </p>

          {showDepartments ? (
            <>
              <div className="comp_filter_input_cont">
                <Input size="sm" placeholder="Search departments..." />
              </div>
              <div className="form-group">
                <ul className="comp-list-checkbox">
                  {departments.map(each => {
                    return (
                      <li key={each.id}>
                        <label className="cb-container">
                          <input
                            type="checkbox"
                            isChecked={each.isChecked}
                            onChange={(e, id) => handleChecked(e, each.id)}
                          />{" "}
                          <span className="text-small">{each.name}</span>
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <span className="company_filter_more">+5 more</span>
              <hr />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
