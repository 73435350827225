import React from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/joy/Chip";
import getSymbolFromCurrency from "currency-symbol-map";
import Divider from "@mui/material/Divider";

function SkillsSection(props) {
  return (
    <Stack pt={4} >
      <Typography
        sx={{
          paddingBottom: "12px",
          color: "#000000",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >Skills Required</Typography>
      {props.jobDetails?.key_skills_details?.length > 0 ?
        <Typography noWrap>
          {props.jobDetails?.key_skills_details?.map(each => (
            <>
              &#x2022;{" "}
              <span
                style={{
                  backgroundColor: "#E9F0EE",
                  borderRadius: "4px",
                  fontSize: "14px",
                  padding: "3px",
                }}
              >
                {each?.name}
              </span>{" "}
            </>
          ))}
        </Typography>
        : "--"}
    </Stack>
  );
}

export default SkillsSection;
