import React from 'react';
import { Grid } from "@mui/material";
import BannerSection from './Components/BannerSection';
import CompanyHired from './Components/CompanyHired';
import HiringProcessSection from './Components/HiringProcessSection';
import CompanyTestimonialSection from './Components/CompanyTestimonialSection';
import OurStorySection from '../CommonPage/Components/OurStorySection';
import Questions from '../CommonPage/Questions';
import NewFooter from '../../../layouts/footer/NewFooter';
import NewsLatterSection from '../CommonPage/Components/NewsLatterSection';
import LandingCategoriesSection from '../LandingCategoriesSection';
import TestimonialSectionFirst from '../CommonPage/Components/TestimonialSectionFirst';
import SlideBlogSection from '../CommonPage/Components/SlideBlogSection';
function CompanyLandingPage() {
    return (
        <>
            <Grid container
                sx={{
                    background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
                    minHeight: "100vh",
                    margin: "auto",
                    // paddingLeft: { xs: "40px", sm: "40px", md: "80px", lg: "80px" },
                    paddingLeft: "5%",
                    paddingRight: "5%"

                }}
            >
                {/* Banner Section */}
                <Grid item xs={12} sm={12}>
                    <BannerSection />
                </Grid>

                {/* Company Hired section */}
                <Grid item xs={12} sm={12}>
                    <CompanyHired />
                </Grid>

                {/* Job categories section */}
                {/* <Grid item xs={12} sm={12}>
                    <LandingCategoriesSection />
                </Grid> */}

                {/* Company hiring Process section */}
                <Grid item xs={12} sm={12}>
                    <HiringProcessSection />
                </Grid>

                {/* Testimonial Section  */}
                {/* <Grid item xs={12} sm={12}>
                    <CompanyTestimonialSection />
                </Grid> */}

                {/* Our story section */}
                {/* <Grid item xs={12} sm={12}>
                    <OurStorySection />
                </Grid> */}

                {/* New Testimonial Section */}
                <Grid item xs={12} sm={12} mt={3}>
                    <TestimonialSectionFirst />
                </Grid>

                {/* Slide Blog section */}
                <Grid item xs={12} sm={12}>
                    <SlideBlogSection />
                </Grid>


                {/* FAQ section */}
                <Grid item xs={12} sm={12}>
                    <Questions />
                </Grid>

                {/* Stay touch section */}
                {/* <Grid item xs={12} sm={12}>
            </Grid> */}
            </Grid>
            <NewsLatterSection />
            <NewFooter />
        </>
    )
}

export default CompanyLandingPage