import React, { useEffect, useState } from "react";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { updateProfileNormal } from "../../../helpers/dataFetcher/profile/update";
import UploadFileModal from "../../../Reusable/FileUpload";
import "../../Accounts/Profiles/profile.css";
import { imageURL } from "../../../Constants/commonURLS";
import { Stack, Grid, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link, useNavigate } from "react-router-dom";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import { Controller, useForm } from "react-hook-form";
import { findAllFuncArea } from "../../../helpers/dataFetcher/jobs/jobs";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserByToken,
  getUserWithToken,
  login,
} from "../../../helpers/dataFetcher/profile/profile";

import {
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
  NativeSelect,
} from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { updateUserAccount } from "../../Accounts/accountSlice";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function ImageUpload(props) {
  const [completed, setCompleted] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [jobOptions, setJobOptions] = useState();
  const [selected, setSelected] = useState([]);
  const accountData = useSelector(state => state.User.user);
  const dispatch = useDispatch();


  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    fetchJobRoles();
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const [modelOpen, setModelOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = async data => {
    const result = selected?.map(item => item.id);
    const finalResult = [...result];
    const obj = {
      profile_image: data.profile_image,
      last_name: data.last_name,
      first_name: data.first_name,
      email: data.email,
      // dob: data?.dob?data.dob:"",
      dob: "dob",
      gender: data.gender,
      resume_file: data.resume_file,
      phone_number: data?.phone_number,
      country: data.country,
      state: data.state,
      city: data?.city,
      gender: data?.gender,
      postal_code: data?.postal_code,
      address: data?.address?.description,
      description: data?.about,
      job_role: finalResult,
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    const { status } = await updateProfileNormal("about_me", o);
    if (status !== 200) {
      // toast.error("Something went wrong");
      toast.error("Update carrer profile first");
      return;
    }
    setCompleted(true);

    props.onHide();

    const { data: response } = await getProfile();

    const { status: userStatus, data: userData } = await getUserWithToken({
      Authorization: "Bearer " + localStorage.getItem("token"),
    });
    if (userStatus == 200) {
      dispatch(
        updateUserAccount({
          user: {
            ...userData?.data_payload,
          }
        })
      );
    }
  };

  function disableFutDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return date => {
      return Date.parse(date) > startSeconds;
    };
  }
  useEffect(() => {
    if (accountData) {
      setValue("first_name", accountData.first_name);
      setValue("last_name", accountData.last_name);
      setValue("profile_image", accountData.profile_image);
      setValue("email", accountData.email);
      setValue("dob", dayjs(accountData.dob));
      setValue("gender", accountData.gender?.toLowerCase());
      setValue("resume_file", accountData.resume_url);
      setValue("country", accountData.country);
      setValue("state", accountData.state);
      setValue("city", accountData?.city);
      setValue("phone_number", accountData?.phone_number);
      setValue("postal_code", accountData?.postal_code);
      setValue("address", accountData?.location);
      setValue("about", accountData?.about);
      setValue("job_role", accountData?.job_role);
    }
  }, [accountData]);

  async function fetchJobRoles() {
    const { data: items } = await findAllFuncArea();
    const data = items?.map(iterator => {
      return {
        id: iterator?.id,
        label: iterator?.name,
        value: iterator?.name,
      };
    });
    setJobOptions(data);
  }

  const updateAboutMe = async () => { };

  const toggleUploadModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("profile_image", url.replace(imageURL, ""));
    toggleUploadModel();
  };

  return (
    <>
      <UploadFileModal
        isOpen={modelOpen}
        toggle={toggleUploadModel}
        handleImage={handleImageUpload}
      />
      <Grid container sx={{
        padding: "5px",
        minWidth: "250px",
        height: "fit-content"
      }}>
        <Grid item xs={12} sm={12}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700"
            }}
          >
            Profile
          </Typography>
        </Grid>
        <Grid items xs={12} sm={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={1}
            >
              <Stack
                alignItems="center"
                justifyContent="space-between"

              >
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "50%",
                      padding: "5px",
                      width: "100px",
                      height: "100px"
                    }}
                    onError={e => {
                      e.target.src =
                        "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo=";
                    }}
                    src={`${imageURL}${getValues("profile_image")}`}
                  />
                </Box>
              </Stack>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Button
                  size="small"
                  loading={false}
                  loadingPosition="end"
                  variant="contained"
                  onClick={toggleUploadModel}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                    padding: "4px 8px",
                    fontSize: "14px",
                    width: "100%",
                    "&:hover": {
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    Update Image
                  </span>
                </Button>
              </Box>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
              mt={2}
            >
              <Button
                size="small"
                loading={false}
                loadingPosition="end"
                variant="contained"
                onClick={() => {props.onHide()}}
                sx={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  background: "#E6F0ED",
                  padding: "4px 8px",
                  fontSize: "14px",
                  width: "50%",
                  border: "0.8px solid var(--app-button-color1)",
                  color: "var(--app-button-color1)",
                  "&:hover": {
                    boxShadow: "none",
                    color: "#fff",
                    background: "var(--app-button-color1)",
                  }
                }}

              >
                <span style={{ textTransform: "capitalize" }}>Cancel</span>
              </Button>
              <Button
                type="submit"
                size="small"
                loading={false}
                loadingPosition="end"
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  boxShadow: "none",
                  background: "var(--app-button-color1)",
                  padding: "4px 8px",
                  width: "50%",
                  fontSize: "14px",
                  "&:hover": {
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                  }
                }}
              >
                <span style={{ textTransform: "capitalize" }}>
                  Save
                </span>
              </Button>
            </Stack>
          </form>
        </Grid>

      </Grid>



    </>
  );
}

export default ImageUpload;
