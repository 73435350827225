import React from 'react';
import { Grid } from "@mui/material";
import Sidebar from '../pages/Accounts/Sidebar/Sidebar';

function ProfileLayout({ children }) {
    return (
        <React.Fragment>
            <Grid container
                sx={{
                    background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
                    height: "calc(100vh - 70px)",
                    margin: "auto",
                    paddingLeft: "3%",
                    paddingRight: "3%",
                }}
            >
                {/* Sidebar Section */}
                <Grid item xs={12} sm={2.5}
                    sx={{
                        "@media (max-width: 1050px)": {
                            display: "none"
                        },
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        height: "100%"
                    }}
                >
                    <Sidebar />
                </Grid>

                {/* Side content section */}
                <Grid item xs={12} sm={9.5}
                    sx={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "15px",
                        height: "100%",
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        "@media (max-width: 1050px)": {
                            width: "100%",
                            minWidth: "100%",
                            paddingLeft: "0px",
                        },
                       
                    }}
                >
                    {children}
                </Grid>
            </Grid>

        </React.Fragment>
    )
}

export default ProfileLayout