import React, { useEffect, useState } from "react";
import { Constants } from "../../../Constants/Constants";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import Slider from "@mui/material/Slider";
// import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
// import Input from "@mui/joy/Input";
import { Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Skeleton from "@mui/material/Skeleton";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";
import "../../../assets/css/Jobs/jobsPage.css";
import { Checkbox, Stack, Typography, InputAdornment, IconButton, Button } from "@mui/material";
import TextField from "../../../components/formElements/TextField";
import JobSearchIcon from "../../../assets/icons/JobSearchIcon.jsx";
import AllFiltersListPopups from "./AllFiltersListPopups.js";
function SidePanelFilters(props) {

  let [searchParams, setSearchParams] = useSearchParams();
  const [showJobs, setShowJobs] = useState(true);
  const [work, setWork] = useState(true);
  const [showExperience, setShowExperience] = useState(true);
  const [showCategories, setShowCategories] = useState(true);
  const [showKeySkills, setShowKeySkills] = useState(true);
  const [categoryModal, setCategoryModal] = useState(false);
  const [keySkillModal, setKeySkillModal] = useState(false);
  const [searchFunc, setSearchFunc] = useState("");

  const [searchModalFunc, setSearchModalFunc] = useState("");
  const [searchModalSkill, setSearchModalSkill] = useState("");
  const [filterPopupsEl, setFilterPopupsEl] = useState(null);

  const [searchKeySkill, setSearchKeySkill] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const fnAreasId = urlParams.getAll("fn_areas");
  const keySkillId = urlParams.getAll("key_skills")
  const jobTypeId = urlParams.getAll("job_type")
  const workModeId = urlParams.getAll("work_mode")
  const expParams = urlParams.get("exp")
  const titleParams = urlParams.get("title")
  const locationParams = urlParams.get("location")



  const [experienceLevel, setExperienceLevel] = useState(Number(expParams) ?? 0);

  useEffect(() => {
    setExperienceLevel(Number(expParams))
  }, [expParams])

  const setParameters = data => {
    setSearchParams({
      title:
        searchParams.get("title") !== null ? searchParams.get("title") : "",
      location:
        searchParams.get("location") !== null
          ? searchParams.get("location")
          : "",
      fn_areas: searchParams.getAll("fn_areas"),
      salary: searchParams.getAll("salary"),
      key_skills: searchParams.getAll("key_skills"),
      exp: searchParams.get("exp") !== null ? searchParams.get("exp") : "",
      job_type: searchParams.getAll("job_type"),
      work_mode: searchParams.getAll("work_mode"),
      ...data,
    });
  };

  const handleCloseOfCategories = () => setCategoryModal(!categoryModal);
  const handleCloseOfKeySkills = () => setKeySkillModal(!keySkillModal);

  const handleChecked = (e, type, value) => {
    if (type === "add") {
      setParameters({ job_type: [...searchParams.getAll("job_type"), value] })
    } else {
      setParameters({ job_type: [...searchParams.getAll("job_type").filter(item => item !== value)] })
    }
  };

  const handleCheckedWork = (e, type, value) => {
    if (type === "add") {
      setParameters({ work_mode: [...searchParams.getAll("work_mode"), value] })
    } else {
      setParameters({ work_mode: [...searchParams.getAll("work_mode").filter(item => item !== value)] })
    }
  };

  const handleFuncAreasChange = (e, type, value) => {
    if (type === "add") {
      setParameters({ fn_areas: [...searchParams.getAll("fn_areas"), value] })
    } else {
      setParameters({ fn_areas: [...searchParams.getAll("fn_areas").filter(item => item !== value)] })
    }
  };
  const handleKeySkillChange = (e, type, value) => {
    if (type === "add") {
      setParameters({ key_skills: [...searchParams.getAll("key_skills"), value] })
    } else {
      setParameters({ key_skills: [...searchParams.getAll("key_skills").filter(item => item !== value)] })
    }
  };




  function displayKeyskills() {
    if (!props.keySkills) {
      return (
        <>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </>
      );
    }

    const filterFunctalityAreas = props.keySkills?.filter(each =>
      each.skill.toLowerCase().includes(searchKeySkill.toLowerCase())
    );

    if (filterFunctalityAreas?.length > 0) {
      return filterFunctalityAreas?.slice(0, 4).map((each, i) => {
        return (
          <li key={each.id}>
            <label className="cb-container">
              <input
                type="checkbox"
                checked={(keySkillId && keySkillId.includes(each?.id)) || false}
                onChange={(e) => handleKeySkillChange(e, keySkillId.includes(each?.id) ? "remove" : "add", each?.id)}
              />{" "}
              <span className="text-small">{each.skill}</span>
              <span className="checkmark"></span>
              <span className="number-item">({each.jobsCount})</span>
            </label>
          </li>
        );
      });
    } else {
      return (
        <p
          style={{
            fontSize: "13px",
          }}
        >No data</p>
      )
    }
  }


  function displayFunctanality() {
    if (!props.functionalArea) {
      // Render skeleton view
      return (
        <>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </>
      );
    }

    const filterFunctalityAreas = props?.functionalArea?.filter(each =>
      each.name.toLowerCase().includes(searchFunc.toLowerCase())
    );

    if (filterFunctalityAreas?.length > 0) {
      return filterFunctalityAreas?.slice(0, 4).map((each, i) => {
        return (
          <li key={each.id}>
            <label className="cb-container">
              <input
                type="checkbox"
                checked={(fnAreasId && fnAreasId.includes(each?.id)) || false}
                onChange={(e) => handleFuncAreasChange(e, fnAreasId.includes(each?.id) ? "remove" : "add", each?.id)}
              />
              {" "}
              <span className="text-small">{each.name}</span>
              <span className="checkmark"></span>
              <span className="number-item">({each.countOfJobs})</span>
            </label>
          </li>
        );
      });
    } else {
      return (
        <p
          style={{
            fontSize: "13px",
          }}
        >No data</p>
      )
    }
  }


  const filterFunctalityAreasModal = props?.functionalArea?.filter(each =>
    searchModalFunc.trim() === "" || each.name.toLowerCase().includes(searchModalFunc.toLowerCase())
  );


  const filterSkillsAreasModal = props?.keySkills?.filter(each =>
    searchModalSkill.trim() === "" || each?.skill.toLowerCase().includes(searchModalSkill.toLowerCase())
  );

  const filterAllCount = () => {
    let count = fnAreasId?.length + keySkillId?.length + jobTypeId?.length + workModeId?.length + (expParams && expParams !== "" ? 1 : 0) + (titleParams && titleParams !== "" ? 1 : 0) + (locationParams && locationParams !== "" ? 1 : 0);
    return count;
  }


  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <AllFiltersListPopups
          setFilterPopupsEl={setFilterPopupsEl}
          filterPopupsEl={filterPopupsEl}
          fnAreas={props?.functionalArea}
          keySkills={props?.keySkills}
          jobTypes={props?.jobTypes}
          workModes={props?.workTypes}
          jobTypeChange={handleChecked}
          fnAreasChange={handleFuncAreasChange}
          keySkillsChange={handleKeySkillChange}
          workModesChange={handleCheckedWork}
        />
        {props && (
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              padding: "15px",
              gap: "10px",
              background: "#F9F9F9",
              boxShadow: "0px 2px 54px -35px #0000008C"
            }}
          >
            <Stack
              sx={{
                height: "40px",
              }}
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Typography sx={{
                fontSize: "18px",
                fontWeight: "700",
              }}>Filters</Typography>
              <Typography sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#2D6CCA",
                cursor: "pointer"
              }}
                onClick={(event) => {
                  setFilterPopupsEl(event.currentTarget);
                }}
              >{`${filterAllCount() > 0 ? "Applied(" + filterAllCount() + ")" : ""}`}</Typography>
            </Stack>
            <Stack
              sx={{
                height: "calc(100% - 40px)",
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },

              }}
            >
              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => setShowCategories(!showCategories)}
                >
                  <span className="company_filters_heading">
                    Categories
                  </span>
                  {showCategories ? (
                    <span onClick={() => setShowCategories(!showCategories)}>
                      <BsChevronUp
                        size={14}
                        className="comp_filter_handle_icon"
                      />
                    </span>
                  ) : (
                    <span onClick={() => setShowCategories(!showCategories)}>
                      <BsChevronDown
                        size={14}
                        className="comp_filter_handle_icon"
                      />
                    </span>
                  )}
                </Box>

                {showCategories && (
                  <Box>
                    <TextField
                      startAdornment={
                        <InputAdornment
                          position="start"
                        >
                          <IconButton
                            edge="end"
                            size="small"
                          >
                            <JobSearchIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      }
                      type="text"
                      placeholder="Search categories..."
                      sx={{
                        width: "100%",
                        background: "#fff",
                        padding: "17px 16px 17px 16px",
                        borderRadius: "8px",
                      }}
                      onChange={e => {
                        props.setFuncSearch(e.target.value)
                        setShowCategories(true);
                      }}
                    />
                    <ul className="list-checkbox1">{displayFunctanality()}</ul>
                    {props?.functionalArea?.length > 4 && (
                      <Typography
                        onClick={() => {
                          setCategoryModal(!categoryModal)
                          // setShowCategories(!showCategories);
                        }}
                        sx={{ color: "#066a4c", fontWeight: "bold", fontSize: "15px", cursor: "pointer" }}
                      >
                        {/* {props?.functionalArea?.length > 4
                            ? `+${props?.functionalArea?.length - 4} more`
                            : ""} */}
                        {props?.functionalArea?.length > 4
                          ? `view more`
                          : ""}
                        <ArrowForwardIcon sx={{ paddingLeft: "3px" }} fontSize="small" />
                      </Typography>
                    )}
                    <hr />
                  </Box>
                )}

              </Stack>

              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => setShowKeySkills(!showKeySkills)}
                >
                  <span className="company_filters_heading"> Key Skills</span>
                  {showKeySkills ? (
                    <span onClick={() => setShowKeySkills(!showKeySkills)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowKeySkills(!showKeySkills)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </Box>

                {showKeySkills && (
                  <Box>
                    <TextField
                      startAdornment={
                        <InputAdornment
                          position="start"
                        >
                          <IconButton
                            edge="end"
                            size="small"
                          >
                            <JobSearchIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      }
                      type="text"
                      placeholder="Search keyskills..."
                      sx={{
                        width: "100%",
                        background: "#fff",
                        padding: "17px 16px 17px 16px",
                        borderRadius: "8px",
                      }}
                      onChange={e => {
                        props.setKeySkillSearch(e.target.value)
                        // setSearchKeySkill(e.target.value),
                        setShowKeySkills(true);
                      }}
                    />

                    <ul className="list-checkbox1">{displayKeyskills()}</ul>
                    {props.keySkills?.length > 4 && (
                      <Typography
                        onClick={() => {
                          setKeySkillModal(!keySkillModal)
                          // setShowKeySkills(!showKeySkills);
                        }}
                        sx={{ color: "#066a4c", fontWeight: "bold", fontSize: "15px", cursor: "pointer" }}
                      >
                        {/* {props.keySkills?.length > 4
                          ? `+${props?.functionalArea?.length - 4} more`
                          : ""} */}
                        {props.keySkills?.length > 4
                          ? `view more`
                          : ""}
                        <ArrowForwardIcon sx={{ paddingLeft: "3px" }} />
                      </Typography>
                    )}
                    <hr />
                  </Box>
                )}

              </Stack>

              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => setShowJobs(!showJobs)}
                >
                  <span className="company_filters_heading">
                    {Constants.type}
                  </span>
                  {showJobs ? (
                    <span onClick={() => setShowJobs(!showJobs)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowJobs(!showJobs)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </Box>

                {showJobs && (
                  <div className="form-group">
                    <ul className="list-checkbox1">
                      {props.jobTypes ? (
                        props.jobTypes?.map(each => {
                          return (
                            <li key={each.id}>
                              <label className="cb-container">
                                <input
                                  type="checkbox"
                                  checked={(jobTypeId && jobTypeId.includes(each?.jobType)) || false}
                                  onChange={(e) => handleChecked(e, jobTypeId?.includes(each?.jobType) ? "remove" : "add", each.jobType)}
                                />{" "}
                                <span className="text-small">
                                  {each.jobType.replace("_", " ")}
                                </span>
                                <span className="checkmark"></span>
                                <span className="number-item">
                                  ({each.jobPostsCount})
                                </span>
                              </label>
                            </li>
                          );
                        })
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress color="success" />
                        </Box>
                      )}
                    </ul>
                    <hr />
                  </div>
                )}
              </Stack>


              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => setWork(!work)}
                >
                  <span className="company_filters_heading">Work Mode</span>
                  {work ? (
                    <span onClick={() => setWork(!work)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setWork(!work)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </Box>

                {work && (
                  <div className="form-group">
                    <ul className="list-checkbox1">
                      {props.workTypes ? (
                        props.workTypes?.map((item, id) => {
                          return (
                            <li key={id}>
                              <label className="cb-container">
                                <input
                                  type="checkbox"
                                  checked={(workModeId && workModeId.includes(item?.workModeValue)) || false}
                                  onChange={(e) => handleCheckedWork(e, workModeId.includes(item?.workModeValue) ? "remove" : "add", item?.workModeValue)}
                                />{" "}
                                <span className="text-small">
                                  {item.workModeShown}
                                </span>
                                <span className="checkmark"></span>
                                <span className="number-item">
                                  ({item.workModeCount})
                                </span>
                              </label>
                            </li>
                          );
                        })
                      ) : (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress color="success" />
                        </Box>
                      )}
                    </ul>
                    <hr />
                  </div>
                )}
              </Stack>
              <Stack style={{ marginBottom: "30px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => setShowExperience(!showExperience)}
                >
                  <span className="company_filters_heading">
                    {Constants.experience}
                  </span>
                  {showExperience ? (
                    <span onClick={() => setShowExperience(!showExperience)}>
                      <BsChevronUp size={14} />
                    </span>
                  ) : (
                    <span onClick={() => setShowExperience(!showExperience)}>
                      <BsChevronDown size={14} />
                    </span>
                  )}
                </Box>

                {showExperience && (
                  <div className="form-group pt-4"
                    style={{
                      paddingLeft: "13px",
                      paddingRight: "10px",
                    }}
                  >
                    <Slider
                      defaultValue={1}
                      aria-label="Default"
                      valueLabelDisplay="on"
                      value={experienceLevel}
                      sx={{
                        "& .MuiSlider-track": {
                          backgroundColor: "#cde2e7",
                        },
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#066a4c",
                        },
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "#066a4c",
                          lineHeight: 1.2,
                          fontSize: 11,
                          // background: 'unset',
                          padding: 0,
                          width: 28,
                          height: 28,
                          borderRadius: '50% 50% 50% 0',
                          transformOrigin: 'bottom left',
                          transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                          '&::before': { display: 'none' },
                          '&.MuiSlider-valueLabelOpen': {
                            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                          },
                          '& > *': {
                            transform: 'rotate(45deg)',
                          },
                        },
                      }}
                      step={1}
                      min={0}
                      max={30}
                      onChange={e => {
                        setExperienceLevel(e.target.value);
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "5px"
                      }}
                    >
                      <span>{ (!expParams || expParams === "") ? "Any" : "0 Yrs"}</span>
                      <span>30 Yrs</span>
                    </Box>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setParameters({ exp: experienceLevel === 0 ? "" : experienceLevel });
                      }}
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        padding: "4px 8px",
                        fontSize: "14px",
                        border: "0.8px solid var(--app-button-color1)",
                        background: "var(--app-button-color1)",
                        color: "#fff",
                        "&:hover": {
                          boxShadow: "none",
                          color: "var(--app-button-color1)",
                          background: "#E6F0ED",
                        }
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        Apply
                      </span>
                    </Button>
                  </div>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}

        {categoryModal ? (
          <>
            <Modal
              show={categoryModal}
              onHide={handleCloseOfCategories}
            >
              <Modal.Header closeButton>
                <Modal.Title>{Constants.category}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <TextField
                    startAdornment={
                      <InputAdornment
                        position="start"
                      >
                        <IconButton
                          edge="end"
                          size="small"
                        >
                          <JobSearchIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                    type="text"
                    placeholder="Search categories..."
                    sx={{
                      width: "100%",
                      background: "#fff",
                      padding: "17px 16px 17px 16px",
                      borderRadius: "8px",
                    }}
                    onChange={e => {
                      setSearchModalFunc(e.target.value)
                      // setShowCategories(true);
                    }}
                  />

                  <ul
                    className="list-checkbox1 ShifytScroll"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    {/* {props?.functionalArea?.map((each, i) => { */}
                    {filterFunctalityAreasModal?.map((each, i) => {
                      return (
                        <li key={each.id}>
                          <label className="cb-container">
                            <input
                              type="checkbox"
                              checked={(fnAreasId && fnAreasId.includes(each?.id)) || false}
                              onChange={(e) => handleFuncAreasChange(e, fnAreasId.includes(each?.id) ? "remove" : "add", each?.id)}
                            />{" "}
                            <span className="text-small">
                              {each.name}
                            </span>
                            <span className="checkmark"></span>
                            <span className="number-item">
                              ({each.countOfJobs})
                            </span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={handleCloseOfCategories}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "#E6F0ED",
                    padding: "4px 8px",
                    fontSize: "14px",
                    border: "0.8px solid var(--app-button-color1)",
                    color: "var(--app-button-color1)",
                    "&:hover": {
                      boxShadow: "none",
                      color: "#fff",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}> Close </span>
                </Button>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={handleCloseOfCategories}
                  sx={{
                    borderRadius: "8px",
                    marginLeft: "10px",
                    boxShadow: "none",
                    fontSize: "14px",
                    background: "var(--app-button-color1)",
                    padding: "4px 8px",
                    "&:hover": {
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}> Save Changes</span>
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : null}

        {keySkillModal ? (
          <>
            <Modal show={keySkillModal} onHide={handleCloseOfKeySkills}>
              <Modal.Header closeButton>
                <Modal.Title>Keyskills</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">

                  <TextField
                    startAdornment={
                      <InputAdornment
                        position="start"
                      >
                        <IconButton
                          edge="end"
                          size="small"
                        >
                          <JobSearchIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                    type="text"
                    placeholder="Search keyskills..."
                    sx={{
                      width: "100%",
                      background: "#fff",
                      padding: "17px 16px 17px 16px",
                      borderRadius: "8px",
                    }}
                    onChange={e => {
                      setSearchModalSkill(e.target.value)
                      // setShowCategories(true);
                    }}
                  />

                  <ul
                    className="list-checkbox1 ShifytScroll"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    {/* {props.keySkills?.map((each, i) => { */}
                    {filterSkillsAreasModal?.map((each, i) => {
                      return (
                        <li key={each.id}>
                          <label className="cb-container">
                            <input
                              type="checkbox"
                              checked={(keySkillId && keySkillId.includes(each?.id)) || false}
                              onChange={(e) => handleKeySkillChange(e, keySkillId.includes(each?.id) ? "remove" : "add", each?.id)}
                            />{" "}
                            <span className="text-small">
                              {each.skill}
                            </span>
                            <span className="checkmark"></span>
                            <span className="number-item">
                              ({each.jobsCount})
                            </span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={handleCloseOfKeySkills}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "#E6F0ED",
                    padding: "4px 8px",
                    fontSize: "14px",
                    border: "0.8px solid var(--app-button-color1)",
                    color: "var(--app-button-color1)",
                    "&:hover": {
                      boxShadow: "none",
                      color: "#fff",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}> Close </span>
                </Button>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={handleCloseOfKeySkills}
                  sx={{
                    borderRadius: "8px",
                    marginLeft: "10px",
                    boxShadow: "none",
                    fontSize: "14px",
                    background: "var(--app-button-color1)",
                    padding: "4px 8px",
                    "&:hover": {
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}> Save Changes</span>
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : null}

      </ThemeProvider>
    </>
  );
}
export default SidePanelFilters;
