import React from 'react';
import { Grid, Stack, Typography, Box } from "@mui/material";
import CompaniesCarousal from '../../CompaniesCarousal';
import { slidesData } from '../SvgComponents/SlidesData';

function CompanyHired() {
    return (
        <Grid container sx={{ paddingTop: "40px", paddingBottom: "30px" }}>
            <Grid item xs={12} sm={12}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    sx={{
                        position: "relative",
                    }}
                    direction="row"
                    p={1}
                >

                    <Stack
                        sx={{
                            fontSize: "30px",
                            textAlign: "center",
                            fontWeight: "700",
                            "@media (max-width: 600px)": {
                                fontSize: "20px",
                            },

                        }}
                    >
                        <Box> Engage with a smarter, Faster and More Rewarding </Box>
                        <Box>Recruitment Experience 
                            {/* <span
                            style={{
                                color: "#066a4c"
                            }}
                        >joinee.com</span> */}
                        </Box>
                    </Stack>

                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
            >
                <Stack
                    sx={{
                        width: "80%",
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            width: "95%",
                        },
                    }}
                >
                    <CompaniesCarousal />
                </Stack>
                <Stack
                    sx={{
                        width: "70%",
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            width: "90%",
                        },
                    }}
                >
                    <CompaniesCarousal slidesDetails={slidesData} />
                </Stack>

            </Grid>
        </Grid>
    )
}

export default CompanyHired;