import React from "react";
import { Grid, Stack, Typography, Box, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TwitterIcon from "../../assets/icons/LandingPage/TwitterIcon";
import InstagramIcon from "../../assets/icons/LandingPage/InstagramIcon";
import LinkedinIcon from "../../assets/icons/LandingPage/LinkedinIcon";
import YouTubeIcon from "../../assets/icons/LandingPage/YouTubeIcon";
import FacebookIcon from "../../assets/icons/LandingPage/FacebookIcon";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  footerPolicyLinks: {
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
  },
  footerSocialIcon: {
    background: "#00000014",
    borderRadius: "5px",
    height: "35px",
    width: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function NewFooter() {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <Grid
      container
      sx={{
        paddingTop: "50px",
        paddingBottom: "50px",
        width: "90%",
        margin: "auto",
      }}
    >
      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            fontSize: "218.24px",
            fontWeight: "500",
            fontFamily: "Manrope",

            textAlign: "center",
            "@media (max-width: 1200px)": {
              fontSize: "100px",
            },
            "@media (max-width: 900px)": {
              fontSize: "50px",
            },
            "@media (max-width: 600px)": {
              fontSize: "40px",
            },
          }}
        >
          Joinee.com
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        mb={2}
        sx={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "30px",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Get Started
            </Typography>
            <Stack direction="column" spacing={1} mt={1}>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Login
              </Link>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Join Now
              </Link>
            </Stack>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Company Login
            </Typography>
            <Stack direction="column" spacing={1} mt={1}>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Login
              </Link>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Join Now
              </Link>
            </Stack>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Account Manager Login
            </Typography>
            <Stack direction="column" spacing={1} mt={1}>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Login
              </Link>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Join Now
              </Link>
            </Stack>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Technical Partner Login
            </Typography>
            <Stack direction="column" spacing={1} mt={1}>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Login
              </Link>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Join Now
              </Link>
            </Stack>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Follow Us
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: "10px",
                // "@media (max-width: 900px)": {
                //     gridTemplateColumns: "repeat(2, 1fr)",
                //     gap: "10px",
                // },
              }}
              mt={1}
            >
              <Link
                to="https://www.facebook.com/tryjionee"
                target="_blank"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Facebook
              </Link>
              <Link
                to="https://www.instagram.com/tryjoinee/"
                target="_blank"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Instagram
              </Link>
              <Link
                to="https://x.com/tryjoinee"
                target="_blank"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Twitter
              </Link>
              <Link
                // to="https://syoft.com"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Youtube
              </Link>
              <Link
                to="https://www.linkedin.com/company/tryjoinee/"
                target="_blank"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Linkedin
              </Link>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Quick Links
            </Typography>
            <Stack
              direction="column"
              justifyContent="flex-end"
              spacing={1}
              mt={1}
            >
              <Link
                to="/"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Home
              </Link>
              <Link
                to="../blogs"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Blogs
              </Link>
              <Link
                to="#"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Refer
              </Link>
              <Link
                to="../company"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Companies
              </Link>
              <Link
                to="../about-us"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                About
              </Link>
              <Link
                to="../careers"
                style={{
                  color: "#43595E",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Careers
              </Link>
            </Stack>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider flexItem />
        <Grid
          container
          mt={2}
          sx={{
            "@media (max-width: 900px)": {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                height: "100%",
                "@media (max-width: 900px)": {
                  justifyContent: "center",
                },
              }}
            >
              <Link to="#" className={classes?.footerPolicyLinks}>
                Terms
              </Link>
              <Link to="/privacy-policy" className={classes?.footerPolicyLinks}>
                Privacy
              </Link>
              <Link to="#" className={classes?.footerPolicyLinks}>
                Disclosures
              </Link>
              <Link to="#" className={classes?.footerPolicyLinks}>
                Accessibility
              </Link>
              <Link to="#" className={classes?.footerPolicyLinks}>
                CA Notice at Collection
              </Link>
              <Link to="#" className={classes?.footerPolicyLinks}>
                Cookie Settings
              </Link>
            </Stack>
          </Grid>
          {/* <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={3}
                            sx={{
                                "@media (max-width: 900px)": {
                                    justifyContent: "center",
                                    marginBottom: "20px"
                                },
                            }}
                        >
                            <Link
                                to="#"
                                className={classes?.footerSocialIcon}
                            >
                                <InstagramIcon />
                            </Link>
                            <Link
                                to="#"
                                className={classes?.footerSocialIcon}
                            >
                                <FacebookIcon />
                            </Link>
                            <Link
                                to="#"
                                className={classes?.footerSocialIcon}
                            >
                                <LinkedinIcon />
                            </Link>
                            <Link
                                to="#"
                                className={classes?.footerSocialIcon}
                            >
                                <YouTubeIcon />
                            </Link>
                            <Link
                                to="#"
                                className={classes?.footerSocialIcon}
                            >
                                <TwitterIcon />
                            </Link>
                        </Stack>

                    </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
