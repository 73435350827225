import React, { useEffect, useState } from "react";
import Chip from "@mui/joy/Chip";
// import noJobs from "../../../assets/images/no_jobs.svg";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import Sidebar from "../Sidebar/Sidebar";
import "./Alljobs.css";
import { getAllMyReferals, getAllReferralsSearch } from "../../../helpers/dataFetcher/jobs/jobs";
import moment from "moment";
import { Skeleton } from "@mui/material";
import MyNewReferals from './MyNewReferals';
//TODO PUT A DEFAULT IMAGE AND LOADER HERE
import { styled } from "@mui/system";
import FeatherIcon from "feather-icons-react";
import { imageURL } from "../../../Constants/commonURLS";
import SocketConnection from "../../../hooks/socket";
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';



function MyReferals() {
  const { lastMessage, connectionStatus } = SocketConnection();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("q");
  const [page, setPage] = React.useState(searchParams.get("page") ?? 1);
  const pageSize = 10;
  const userId = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)

  const [totalCount, setTotalCount] = React.useState('')

  const [status, setStatus] = useState(false);
  const [referJob, setReferJob] = useState([]);

  useEffect(() => {
    if (!searchParams.get("page")) {
      searchParams.set("page", 1);
      setSearchParams(searchParams);
    }
    setPage(searchParams.get("page"));
    getAllJobs();
  }, []);


  /*socket integration for earning page start */
  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "kanban" && data.data?.user_id === userId) {
          getAllJobs()
        }
      }
    }
  }, [lastMessage]);

  /*socket integration for earning page End */


  async function getAllJobs() {
    setLoading(true)
    const { data, status } = await getAllMyReferals(page, pageSize, searchFilter ?? "");
    setReferJob(data?.data_payload);
    setTotalCount(data?.total_page_size)
    if (data?.data_payload) {
      setLoading(false)
    }
    if (status === 200) {
      setStatus(true);
    }
  }

  React.useEffect(() => {
    if (referJob.length > 0) {
      searchParams.set("page", page);
      setSearchParams(searchParams);
    }
    getAllJobs()
  }, [page]);

  React.useEffect(() => {
    getAllJobs()
  }, [searchFilter]);


  return (
    <div className="">
      {status || !status ? (
        referJob?.length >= 0 ? (
          <MyNewReferals
            referData={referJob}
            page={page}
            setPage={setPage}
            totalCount={totalCount}
            loading={loading}
            pageSize={pageSize}

          />


        ) : (
          <><div
            style={{
              display: "grid",
              placeItems: "center",
              height: "100%",
              width: "100%",
              marginTop: "70px"
            }}
          >
            <div className="d-flex align-items-center flex-column">
              <img
                src={noJobs}
                style={{ width: "300px", height: "300px" }}
                alt="no jobs"
              />
              <p>No Referals Found</p>
            </div>
          </div></>
        )
      ) : (
        <>
          {Array.from(new Array(15)).map((item, index) => (
            <div className="card-employers" id="card-employers" key={index}>
              <div className="align-items-center comp_card_main_cont">
                <div className="comp_employers_logo">
                  <div
                    className="comp_profile_card_cont"
                    id="comp_profile_card_cont"
                  >
                    <Skeleton
                      variant="rectangular"
                      width={80}
                      height={80}
                    />
                  </div>
                </div>
                <div className="employer_name_cont">
                  <p className="employer_name">
                    <span>
                      <strong className="comp_name">
                        <Skeleton width="100%" />
                      </strong>
                    </span>
                  </p>
                  <div className="d-flex comp_position_cont">
                    <Skeleton width="100%" />
                  </div>
                  <div className="comp_specs_cont">
                    <Skeleton width="100%" />
                  </div>
                </div>
                <div className="comp_employers_redirection_cont"></div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default MyReferals;
