import React, { useState, useEffect } from 'react'
import { Stack, Grid, Menu, MenuItem, Button, CircularProgress, Typography, Divider, Box, Avatar, Skeleton, Tooltip, Chip, IconButton, Checkbox, Card, CardContent } from "@mui/material";
import { imageURL } from "../../Constants/commonURLS";
import JobBagIcon from "../../assets/icons/JobBagIcon";
import JobLocationIcon from "../../assets/icons/JobLocationIcon";
import JobBookIcon from "../../assets/icons/JobBookIcon";
import JobClockIcon from "../../assets/icons/JobClockIcon";
import JobUsersIcon from "../../assets/icons/JobUsersIcon";
import JobShareIcon from "../../assets/icons/JobShareIcon";
import { getCompanyJobsById } from '../../helpers/dataFetcher/companies';
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate, useLocation } from "react-router-dom";
import noJobs from "../../assets/images/nojobsShiyft.png";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { savedJobById } from "../../helpers/dataFetcher/jobs/jobs";

function CompanyJobsCardList({ companyId, companyName }) {

    const location = window.location.host;
    const [loading, setLoading] = useState(true);
    const [jobsData, setJobsData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const navigate = useNavigate();

    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("user_id");

    let rowsPerPage = 4;

    useEffect(() => {
        getAllJobsByCompanyId();
    }, [currPage])


    const abbreviateNumber = number => {
        number = +number
        const scaledNumber = number / 1000;
        if (number >= 1000) {
            return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
        } else {
            return `${Number.parseFloat(number).toFixed(0)}`;
        }
        // return `${scaledNumber.toFixed(0)}k`;
    };


    const getAllJobsByCompanyId = async () => {
        setLoading(true);
        const { data, status } = await getCompanyJobsById(companyId, currPage, rowsPerPage, "", companyName ?? "");
        if ([200, 201].includes(status)) {
            setLoading(false);
        }
        if (data?.pageNo == 1) {
            setJobsData(data?.data_payload)
        } else {
            setJobsData((prevItems) => [...prevItems, ...data?.data_payload]);
        }

        setTotalPage(data?.total_page_size)
        setLoading(false);
    };


    function updateToggle(payload, status) {
        const id = payload?.job_post_id
        const updateStatus = jobsData?.map((each) => {
            if (each?.id === id && status === 201) {
                return {
                    ...each,
                    is_candidate_saved_the_job_post: 1
                }
            }
            else if (each?.id == id && status === 200) {
                return {
                    ...each,
                    is_candidate_saved_the_job_post: 0
                }
            }
            else {
                return {
                    ...each
                }
            }
        })
        setJobsData([...updateStatus])
    }

    const handelSavedJobs = async payload => {
        const { data, status } = await savedJobById(payload);
        if ([200, 201].includes(status)) {
            toast.success(data?.return_message);
        }

        if ([200, 201].includes(status)) {
            updateToggle(payload, status)
        }
        if ([400].includes(status)) {
            toast.error(data?.return_message);
        }
    };

    const handleClick = (event, id) => {
        handleCloseOption(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseOption = id => {
        if (userId) {
            copy(`${location ?? "https://joinee.com"}/job?id=${id}&userId=${userId}`);
        } else {
            copy(`${location ?? "https://joinee.com"}/job?id=${id}`);
        }
        toast.success("Link copied!");
    };

    if (loading) {
        return (
            <Grid container sx={{ paddingTop: "10px", paddingBottom: "40px" }}>
                <Grid item xs={12} sm={12}>
                    <Stack
                        sx={{
                            height: "100%",
                        }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress color="success" />
                    </Stack>
                </Grid>
            </Grid>
        )
    }


    return (
        <Grid container sx={{ paddingTop: "10px", paddingBottom: "40px" }}>
            {jobsData && jobsData?.length > 0 ? (
                <>
                    {jobsData && jobsData?.map((item, index) => (
                        <Grid item xs={12} sm={12} md={6} sx={{ padding: "10px" }} key={item?.id}>
                            <Stack
                                sx={{
                                    width: "100%",
                                    border: "0.8px solid #DEDEDE",
                                    borderRadius: "16px",
                                    padding: "24px",
                                    gap: "10px",
                                    background: "#F9F9F9",
                                    boxShadow: "0px 2px 54px -35px #0000008C"
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignContent={"flex-start"}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignContent={"flex-start"}
                                        sx={{
                                            // display: "-webkit-box",
                                            WebkitBoxOrient: "horizontal",
                                            overflow: "hidden",
                                            width: "100%",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: "44px",
                                                width: "44px",
                                                minWidth: "44px",
                                                borderRadius: "50%",
                                                border: "0.5px solid rgba(0, 0, 0, 0.25)",
                                            }}
                                        >
                                            <Avatar
                                                src={`${imageURL}${item?.company_details?.company_image}`}
                                                style={{
                                                    borderRadius: "50%",
                                                    borderColor: "rgba(0, 0, 0, 0.25)",
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            >
                                                {item?.company_details?.company_name?.charAt(0)}
                                            </Avatar>
                                        </Box>
                                        <Box
                                            sx={{
                                                // flexShrink: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",

                                                cursor: "pointer",
                                                textTransform: "capitalize",
                                                fontSize: "15px",
                                                fontWeight: "600",
                                                color: "#121212",
                                                fontFamily: "Inter,sans-serif",
                                            }}
                                            fontWeight="md"
                                            textColor="primary.plainColor"
                                            mb={0.5}

                                        >
                                            <Typography noWrap
                                                onClick={() =>
                                                    navigate(
                                                        `/job?id=${item?.id}&userId=${userId ?? ""}`
                                                    )
                                                }
                                            >
                                                {item?.job_title ?? ""}
                                                {/* {item?.job_title?.length <= 20
                                                    ? item?.job_title
                                                    : item?.job_title?.slice(0, 18) + ".."} */}
                                            </Typography>
                                            <Typography noWrap
                                                fontWeight="md"
                                                textColor="primary.plainColor"
                                                mb={0.5}
                                                sx={{
                                                    cursor: "pointer",
                                                    textTransform: "capitalize",
                                                    fontSize: "13px",
                                                    fontWeight: "400",
                                                    color: "#121212",
                                                    opacity: "50%",
                                                    fontFamily: "Inter,sans-serif",
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        `/employer-single-list?id=${item?.company_user_id}`
                                                    )
                                                }
                                            >{item?.company_details?.company_name}</Typography>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                    >
                                        <Box>
                                            <Tooltip arrow placement="top" title={`
                                        ${item?.job_post_refer_now_commission?.length > 0 && "Refer Now Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_now_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_now_commission?.[0]?.payout?.split("_")?.join(" ") || "")}
                                    ${(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "")  ? "- Refer Self Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_self_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_self_commission?.[0]?.payout?.split("_")?.join(" ") || "") : ""}
                                    `}>
                                                <Typography noWrap
                                                    fontWeight="md"
                                                    textColor="primary.plainColor"
                                                    mb={0.5}
                                                    sx={{
                                                        cursor: "pointer",
                                                        textTransform: "capitalize",
                                                        fontSize: "16px",
                                                        fontWeight: "Bold",
                                                        color: "#066a4c",
                                                        textAlign: "center",
                                                        // opacity: "50%",
                                                        fontFamily: "Inter,sans-serif",
                                                    }}
                                                >

                                                    {getSymbolFromCurrency(item?.currency) || ""}

                                                    {item?.job_post_refer_now_commission?.length > 0 && (
                                                        <span style={{ color: "#066a4c" }}>
                                                            {abbreviateNumber(item?.job_post_refer_now_commission[0]?.flat_rate)}
                                                        </span>
                                                    )}
                                                    {(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "") ? (
                                                        <span style={{ color: "rgb(212, 133, 49)", marginLeft: "4px" }}>
                                                            {"- "}
                                                            {getSymbolFromCurrency(item?.currency) || ""}
                                                            {abbreviateNumber(item?.job_post_refer_self_commission[0]?.flat_rate)}
                                                        </span>
                                                    ) : ""}
                                                </Typography>
                                            </Tooltip>
                                            <Typography noWrap
                                                fontWeight="md"
                                                textColor="primary.plainColor"
                                                mb={0.5}
                                                sx={{
                                                    cursor: "pointer",
                                                    textTransform: "capitalize",
                                                    fontSize: "13px",
                                                    fontWeight: "500",
                                                    color: "#121212",
                                                    fontFamily: "Inter,sans-serif",
                                                }}
                                            >Earn Upto</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Stack sx={{
                                    gap: "10px",
                                    "& hr": {
                                        height: "22px"
                                    }
                                }}

                                >
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            WebkitBoxOrient: "horizontal",
                                            overflow: "hidden",
                                            width: "100%",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word",
                                            textOverflow: "ellipsis",
                                        }}
                                        direction="row"
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            sx={{
                                                flexShrink: 0,
                                                overflow: "hidden",
                                                gap: "5px",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <JobBagIcon />
                                            <Typography noWrap sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                {`${item?.min_exp}${item?.min_exp_months > 0
                                                    ? `.${item?.min_exp_months}`
                                                    : ""
                                                    }`}
                                                -
                                                {`${item?.max_exp}${item?.max_exp_months > 0
                                                    ? `.${item?.max_exp_months}`
                                                    : ""
                                                    }`}Yrs</Typography>
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            sx={{
                                                flexShrink: 0,
                                                overflow: "hidden",
                                                gap: "5px",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <Typography noWrap sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item?.job_type?.replace("_", " ")}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                flexShrink: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <Typography noWrap sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                <span style={{ fontSize: "15px", fontWeight: "600" }}> {getSymbolFromCurrency(item?.currency) ? getSymbolFromCurrency(item?.currency) : ""}{" "}</span>
                                                {item?.min_sal_by_am <= 1000
                                                    ? item?.min_sal_by_am
                                                    : `${Math.round(item?.min_sal_by_am / 1000, 2)}k`}
                                                -
                                                {item?.max_sal_by_am <= 1000
                                                    ? item?.max_sal
                                                    : `${Math.round(
                                                        item?.max_sal_by_am / 1000,
                                                        2
                                                    )}k`}
                                                {`/${item?.payout}`}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                // flex: 1,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gap: "5px",
                                            }}
                                        >
                                            <JobLocationIcon />
                                            <Typography noWrap sx={{ fontSize: "14px", fontWeight: "500" }} >
                                                {/* {item?.location?.length <= 17 ? item?.location : item?.location?.slice(0, 35) + ".."} */}
                                                {item?.location}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                // flex: 1,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gap: "5px",
                                            }}
                                        >
                                            <JobBookIcon />
                                            <Typography noWrap sx={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item?.educational_qualification_details?.map((elem, index) => (
                                                    <span key={index}>
                                                        {elem?.education_qualification_spec_id?.name}/{elem?.education_qualification_id?.name}. {" "}
                                                    </span>
                                                ))}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignContent="center"
                                            spacing={1}
                                            sx={{
                                                // flex: 1,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <Typography noWrap>
                                                {item?.key_skill_details?.map(each => (
                                                    <>
                                                        &#x2022;{" "}
                                                        <span
                                                            style={{
                                                                backgroundColor: "#E9F0EE",
                                                                borderRadius: "4px",
                                                                fontSize: "14px",
                                                                padding: "3px",
                                                            }}
                                                        >
                                                            {each?.name}
                                                        </span>{" "}
                                                    </>
                                                ))}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            WebkitBoxOrient: "horizontal",
                                            overflow: "hidden",
                                            width: "100%",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word",
                                            textOverflow: "ellipsis",
                                        }}
                                        direction="row"
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                flexShrink: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gap: "5px",
                                            }}
                                        >
                                            <JobClockIcon />
                                            <Typography noWrap sx={{ fontSize: "13.5px", fontWeight: "500" }}>
                                                {Math.floor(
                                                    Math.abs(
                                                        new Date() -
                                                        new Date(
                                                            item?.createdAt
                                                                .slice(0, 10)
                                                                .toLocaleString()
                                                                .replace(/-/g, "/")
                                                        )
                                                    ) /
                                                    (1000 * 3600 * 24)
                                                )} days ago
                                            </Typography>
                                        </Stack>
                                        <span>&#x2022;{" "}</span>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                flexShrink: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gap: "5px",
                                            }}
                                        >
                                            <JobUsersIcon />
                                            <Typography noWrap sx={{ fontSize: "13.5px", fontWeight: "500" }}> {`${item?.applicants_count}`} Applicants</Typography>
                                        </Stack>
                                        <span>&#x2022;{" "}</span>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                // flex: 1,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >

                                            <Typography noWrap sx={{ fontSize: "13.5px", fontWeight: "500" }}>
                                                {Math.floor(
                                                    Math.abs(
                                                        new Date() -
                                                        new Date(
                                                            item?.application_deadline?.slice(0, 10)?.toLocaleString()?.replace(/-/g, "/")
                                                        )
                                                    ) /
                                                    (1000 * 3600 * 24)
                                                ) - Math.floor(
                                                    Math.abs(
                                                        new Date() - new Date()
                                                    ) /
                                                    (1000 * 3600 * 24)
                                                )} {" "}
                                                days left to apply</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        borderTop: "0.8px dashed #BABABA",
                                        paddingTop: "15px",
                                    }}
                                >
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            WebkitBoxOrient: "horizontal",
                                            overflow: "hidden",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word",
                                            textOverflow: "ellipsis",
                                            "& hr": {
                                                height: "22px"
                                            }
                                        }}
                                        direction="row"
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                // flexShrink: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gap: "5px",
                                                cursor: "pointer",
                                                color: item?.is_candidate_saved_the_job_post === 1 ? "var(--app-button-color1)" : "#5E6162",

                                            }}

                                            onClick={() => {
                                                if (token) {
                                                    handelSavedJobs({
                                                        job_post_id: item?.id,
                                                        company_id: item?.company_details?.id,
                                                        type: item?.is_candidate_saved_the_job_post == 0 ? "save" : "unsave",
                                                    });
                                                } else {
                                                    navigate("/login")
                                                }

                                            }}
                                        >
                                            {item?.is_candidate_saved_the_job_post === 1 ? <BookmarkIcon fontSize="small" color="inherit" /> : <BookmarkBorderIcon color="inherit" fontSize="small" />}
                                            <Typography noWrap
                                                sx={{
                                                    marginTop: "-5px",
                                                    color: "#5E6162",
                                                    fontSize: "14px",
                                                    fontWeight: "500"
                                                }}
                                            >{item?.is_candidate_saved_the_job_post === 1 ? "Saved" : "Save Job"}</Typography>
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                // flexShrink: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gap: "5px",
                                                cursor: "pointer"
                                            }}
                                            onClick={event => handleClick(event, item?.id)}
                                        >
                                            <JobShareIcon sx={{ color: "#5E6162" }} />{" "}
                                            <Typography noWrap
                                                sx={{
                                                    color: "#5E6162",
                                                    fontSize: "14px",
                                                    fontWeight: "500"
                                                }}>Share</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={12} >
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{ padding: "10px" }}
                        >
                            {loading ? (
                                <CircularProgress color="success" size={25} />
                            ) : (
                                <>
                                    {currPage >= totalPage ? (
                                        null
                                    ) : (
                                        <Chip
                                            deleteIcon={<ArrowForwardIosIcon color="inherit" fontSize="small" />}
                                            label={"See more"}
                                            onClick={() => { setCurrPage(currPage + 1) }}
                                            disabled={loading}
                                            sx={{
                                                border: "1px solid #40877B",

                                                '& .MuiChip-label': {
                                                    fontWeight: "600",
                                                    display: 'block',
                                                    color: "#40877B",
                                                    whiteSpace: 'normal',
                                                    textAlign: "center",
                                                },
                                            }}
                                        />
                                    )}

                                </>
                            )}

                        </Stack>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} sm={12} >
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: "200px"
                        }}
                    >
                        <img
                            src={noJobs}
                            style={{ width: "300px", height: "300px" }}
                            alt="no jobs"
                        />
                        <p>No Jobs Found</p>
                    </Stack>
                </Grid>
            )}
        </Grid>
    )
}

export default CompanyJobsCardList