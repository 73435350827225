import { Box, Stack, InputAdornment, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "./useDebounce";
import TextField from "../components/formElements/TextField";
import JobSearchIcon from "../assets/icons/JobSearchIcon";

const GlobalSearch = ({ id, placeholder, sx, ...rest }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(searchParams.get("q") ?? "");
  }, []);

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    searchParams.set("q", debouncedQuery);
    setSearchParams(searchParams);
  }, [debouncedQuery]);

  return (
    <Box sx={{ width: sx?.width ? sx?.width : "fit-content", overflow: "hidden" }}>
      <TextField
        placeholder={placeholder ? placeholder : "Search.."}
        value={query}
        sx={{
          width: "100%",
          padding: "15px 5px 15px 5px",
          borderRadius: "8px",
          ...sx
        }}
        {...rest}
        onChange={(e) => {
          e.stopPropagation();
          setQuery(e.target.value);
        }}
        startAdornment={
          <InputAdornment
            position="start"
          >
            <IconButton
              edge="end"
              size="small"
            >
              <JobSearchIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default GlobalSearch;
