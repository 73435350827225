export const JobSeekerData = [
  {
    id: "1",
    name: "Ana",
    location: "Data Scientist",
    comments:
      "One notification from Joinee helped me secure a job that perfectly matches my skills. The timely alert allowed me to apply immediately.",
    image: "/assets/imgs/testimonial/Ana.jpg",
  },
  {
    id: "2",
    name: "Ritika",
    location: "Business Analyst",
    comments:
      "The transparency of Joinee's hiring process is unparalleled. I always knew where I stood in the application process, and the real-time updates kept me informed every step of the way.",
    image: "/assets/imgs/testimonial/Ritika_Kapoor.jpg",
  },
  {
    id: "3",
    name: "Julie",
    location: "Project Manager",
    comments:
      "I was thrilled to learn that I could get paid for referring my friends! When my friend got placed through Joinee, I received a referral bonus. It's a win-win situation!",
    image: "/assets/imgs/testimonial/Julie.jpg",
  },
  {
    id: "4",
    name: "Yuto",
    location: "Tech Architect",
    comments:
      "Finding a remote job during the mix of WFO and WFH models was a breeze with Joinee. The application process was simple and user-friendly, and I secured a fantastic remote position in no time!",
    image: "/assets/imgs/testimonial/Yuto.jpg",
  },
  {
    id: "5",
    name: "Chris",
    location: "Drone Developer",
    comments:
      "A single notification from Joinee introduced me to a job opportunity that was a perfect match for my skills and interests.",
    image: "/assets/imgs/testimonial/Chris.jpg",
  },
  {
    id: "6",
    name: "Shubham",
    location: "SAP Consultant",
    comments:
      "Joinee's timely notification integration across email and the application itself was invaluable during my job search.",
    image: "/assets/imgs/testimonial/Shubham.jpg",
  },
];

export const CompanyData = [
  {
    id: "1",
    name: "Rajheev Sharma",
    location: "Chief Technology Officer",
    comments:
      "Thanks to the whole team at Joinee for making recruitment easy and affordable, without draining our budget.",
    image: "/assets/imgs/testimonial/Rajheev_Sharma.jpg",
  },
  {
    id: "2",
    name: "Ashley ",
    location: "Talent Acquisition",
    comments:
      "Thanks to Joinee, we connected with highly skilled tech developers who perfectly fit our team's needs. The platform's robust screening process ensured that we received quality candidates.",
    image: "/assets/imgs/testimonial/Ashley.jpg",
  },
  {
    id: "3",
    name: "Jenne",
    location: "People Officer",
    comments:
      "Joinee's support team was instrumental in helping us navigate through the hiring process. Their responsiveness and dedication ensured that we found the right tech talent for our company",
    image: "/assets/imgs/testimonial/Jenne.jpg",
  },
  {
    id: "4",
    name: "George",
    location: "TA Specialist",
    comments:
      "We successfully recruited skilled tech developers through Joinee. The platform's innovative features and comprehensive job listings helped us attract and hire talented professionals.",
    image: "/assets/imgs/testimonial/George.jpg",
  },
  {
    id: "5",
    name: "Phil",
    location: "Manager Human Resource",
    comments:
      "Reliable background verification services gave us peace of mind during the hiring process. Their diligent checks ensured that the developers we hired met our company's standards and requirements.",
    image: "/assets/imgs/testimonial/Phil.jpg",
  },
  {
    id: "6",
    name: "Soni Kumari",
    location: "Associate TA",
    comments:
      "Account manager provided excellent support, keeping us informed about every step of the hiring process without causing disruptions. Their proactive updates and transparent communication ensured a smooth hiring experience",
    image: "/assets/imgs/testimonial/Soni_Kumari.jpg",
  },
];
