import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, Tooltip } from "@mui/material";
import { NotificationSwitch } from "../../assets/MUI/globalStyles";
import { NotificationTitleTypography } from "../../assets/MUI/globalStyles";
import { NotificationDescTypography } from "../../assets/MUI/globalStyles";
import { NotificationDescTextTypography } from "../../assets/MUI/globalStyles";
import { updateNotificationChange } from "../../helpers/dataFetcher/settings/notification";
import { getAllNotificationsSettings, getAllNotifications, updateNotifyInAppSettings, updateIndividualNotifyInAppSettings } from "../../helpers/dataFetcher/Notifications/notifications";


function NotificationTable() {

  const [showAll, setShowAll] = useState(true);
  const [showEmailAll, setShowEmailAll] = useState(true);
  const userId = localStorage.getItem("user_id");


  const [NotificationsList, setNotificationsList] = useState([
    {
      id: 0,
      type: "email",
      name: "Matching Job Alerts",
      description: {
        heading:
          "Receive curated job recommendations based on your qualifications and interests.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 2,
      name: "Application Tracking",
      type: "email",
      description: {
        heading:
          "Easily track the progress of your job applications in real-time, all in one place.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 3,
      type: "email",
      name: "Interview Reminder",
      description: {
        heading:
          "Be the first to know about new job openings perfectly matching your skills with personalized email alerts.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 4,
      name: "Job post expiry/deadline",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 5,
      name: "Profile updation after few weeks/months",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 6,
      name: "Interview feedback",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 7,
      name: "chats",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
    {
      id: 8,
      name: "Stage of the stage ",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      inApplication: true,
      inEmail: true,
    },
  ]);

  const [notificationsData, setNotificationsData] = useState([])

  /*Global Check func start here for both normal & email */

  const updateNotifyInApp = async (status, type) => {
    const payload = {
      // type:"in_app",
      type: type,
      is_app_notification: status
    }
    const updateInApp = await updateNotifyInAppSettings(payload, userId)
  }

  const updateIndividualNotifyInApp = async (id) => {

    const result = notificationsData?.filter(each => {
      if (each.id == id) {
        return {
          inApplication: !each.inApplication,
        };
      }

    });
    const inApplicationStatus = result[0]?.inApplication


    const payload = {
      is_app_notification: !inApplicationStatus,
      // is_email_notification: false
    }
    const updateInApp = await updateIndividualNotifyInAppSettings(payload, id)
  }

  const updateIndividualNotifyInEmail = async (id) => {

    const result = notificationsData?.filter(each => {
      if (each.id == id) {
        return {
          inEmail: !each.inEmail,
          is_email_notification: !each?.is_email_notification
        };
      }

    });
    const inEmailStatus = result[0]?.is_email_notification


    const payload = {
      // is_app_notification: !inApplicationStatus,
      is_email_notification: !inEmailStatus
    }
    const updateInApp = await updateIndividualNotifyInAppSettings(payload, id)
  }


  const handleChangeSwitch = async (e) => {
    const type = "in_app"
    const result = notificationsData?.map(each => {
      return {
        ...each,
        inApplication: e.target.checked,
        isActive: e.target.checked,
        is_app_notification: e.target.checked
      };
    });
    // Do something with the updated result
    setNotificationsData(result);
    setShowAll(!showAll);
    const updateSelectAll = await updateNotifyInApp(e.target.checked, type)
  };

  const handleChangeEmailSwitch = async (e) => {
    const type = "email"
    const result = notificationsData?.map(each => {
      return {
        ...each,
        inEmail: e.target.checked,
        is_email_notification: e.target.checked
      };
    });
    // Do something with the updated result
    setNotificationsData(result);
    setShowEmailAll(!showEmailAll);
    const updateSelectAll = await updateNotifyInApp(e.target.checked, type)
  };

  /*Global Check func start here for both normal & email */

  /* Individual checks of both normal & email func start here */
  const handleSwitchIterate = async row => {

    const result = notificationsData?.map(each => {
      if (each.id == row.id) {
        return {
          ...each,
          inApplication: !each.inApplication,
          isActive: !each.isActive,
          is_app_notification: !each?.is_app_notification,
        };
      }
      return each;
    });
    setNotificationsData(result);

    const updateSelectAll = await updateIndividualNotifyInApp(row?.id)
  };

  const handleEmailNotify = async row => {
    const result = notificationsData?.map(each => {
      if (each.type == row.type && each.id == row.id) {
        return {
          ...each,
          inEmail: !each?.inEmail,
          is_email_notification: !each?.is_email_notification
        };
      }
      return each;
    });
    setNotificationsData(result);
    const updateSelectAll = await updateIndividualNotifyInEmail(row?.id)
  };

  /* Individual checks of both normal & email func End here */




  /* Notifications integration start */



  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchNotificationSettings()
  }, [])

  const fetchNotificationSettings = async () => {
    const notificationResponse = await getAllNotificationsSettings()
    const formatData = notificationResponse?.map((each) => {
      return {

        id: each?.id,
        type: "email",
        name: each?.title,
        description: {
          heading:
            each?.short_desc,
          statusDescription:
            each?.long_desc,
        },
        inApplication: each?.is_app_notification,
        inEmail: each?.is_email_notification,
        isActive: each?.is_active,
        is_app_notification: each?.is_app_notification,
        is_email_notification: each?.is_email_notification
      }

    })

    if (formatData?.length > 0) {
      setNotificationsData(formatData)
    }

    // if(status == 200){
    //   console.log("sdssd")
    //   // dispatch(NotificationTags(data))
    //   setNotificationsData(formatData)
    //   setLoading(false)
    // }
  }

  /* Notifications integration start */




  useEffect(() => {
    let emailCount = 0;
    let appCount = 0;
    if (notificationsData) {
      notificationsData.forEach(each => {
        if (each?.inEmail) {
          emailCount += 1;
        }
        if (each?.inApplication) {
          appCount += 1;
        }
      });
      if (emailCount === notificationsData.length) {
        setShowEmailAll(true);
      } else {
        setShowEmailAll(false);
      }
      if (appCount === notificationsData.length) {
        setShowAll(true);
      } else {
        setShowAll(false);
      }
    }
  }, [notificationsData]);
  /*Notifications Integration part end here */
  return (
    <>
      <TableContainer component={Paper} sx={{boxShadow: "none"}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", fontWeight: "700" }}>Title</TableCell>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", fontWeight: "700" }}>Descriptions</TableCell>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", fontWeight: "700", minWidth: "150px" }}>
                <Typography sx={{ fontWeight: "500" }}> In Application </Typography>
                <Tooltip title="Select All" placement="bottom" arrow>
                  <NotificationSwitch
                    onChange={e => handleChangeSwitch(e)}
                    checked={showAll ? showAll : false}
                  />
                </Tooltip>
              </TableCell>
              <TableCell align="start" sx={{ fontFamily: "Inter,sans-serif", minWidth: "150px" }}>
                <Typography sx={{ fontWeight: "500" }}> In Email </Typography>
                <Tooltip title="Select All" placement="bottom" arrow>
                  <NotificationSwitch
                    onChange={e => handleChangeEmailSwitch(e)}
                    checked={showEmailAll ? showEmailAll : false}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationsData?.map(row => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <NotificationTitleTypography>
                    {row.name}
                  </NotificationTitleTypography>
                </TableCell>
                <TableCell align="start">
                  <Grid>
                    <NotificationDescTypography>
                      {row.description.heading}
                    </NotificationDescTypography>
                    <NotificationDescTextTypography>
                      {row.description.statusDescription}
                    </NotificationDescTextTypography>
                  </Grid>
                </TableCell>

                <TableCell align="start">
                  <NotificationSwitch
                    //  checked={
                    //   showAll
                    //     ? showAll
                    //     : row.inApplication
                    //     ? row.inApplication
                    //     : row.inApplication
                    // }

                    // checked={row.inApplication}
                    checked={row.is_app_notification}
                    onChange={e => handleSwitchIterate(row)}
                  />
                </TableCell>
                <TableCell align="start">
                  <NotificationSwitch
                    onChange={e => handleEmailNotify(row)}
                    // checked={
                    //   showEmailAll
                    //     ? showEmailAll
                    //     : row.inEmail
                    //     ? row.inEmail
                    //     : row.inEmail
                    // }
                    checked={row.inEmail}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </>
  );
}

export default NotificationTable;
