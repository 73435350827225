import React, { useState, useCallback } from 'react'
import { Box, IconButton, Grid, Typography, Autocomplete, TextField, MenuItem, List, ListItem, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import Input from "@mui/material/Input";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";

export default function MultiSelect({
  id,
  placeholder,
  label,
  startAdornment,
  options,
  variant,
  value,
  setValue,
  setFormikValue,
  deleteOption,
  sx,
  error,
  helperText,
  required,
  disabled,
  onChange,
  actions,
  multiple,
  createNewHandler,
  valueAccessor,
  labelAccessor,
  onInputChange,
  scrollRef,
  // onKeyUp,
  ...rest
}) {

  /***
   * 
   * Note: Multiselect is not applicable here
   * 
   */


  const [inputValue, setInputValue] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);


  const handleValueChange = (event, newValue) => {

    if (newValue !== '') {
      setValue(newValue?.[valueAccessor]);
      setFormikValue(id, newValue?.[valueAccessor]);
      onChange(newValue);
    };
  };

  const handleCreateOption = (event) => {
    handleValueChange(null, '');
    setMenuOpen(false);
  };


  return (
    <ThemeProvider theme={globalTheme}>
      {/* <Box> */}
      {label ? <InputLabel required={required} sx={{ height: "fit-content", borderColor: error ? "var(--error-color)" : null }}>{label}</InputLabel> : null}
      <Autocomplete
        multiple
        limitTags={2}
        sx={[
          {
            backgroundColor: "var(--paper-color)",
            border: "1px solid #c1c1c1",
            borderRadius: "8px",
            padding: "0px 10px 0px 10px",
            height: "fit-content !important",
            position: "relative !important"
          },
          sx,
          error
            ? {
              borderColor: "var(--error-color)",
            }
            : null,
        ]}
        open={menuOpen}
        onOpen={() => {
          setMenuOpen(true);
        }}
        onClose={() => {
          setMenuOpen(false);
        }}
        disabled={disabled}
        options={options}
        {...rest}
        // getOptionLabel={(option) => option.label}
          getOptionLabel={(option) => {
            if (!option || !option.label) {
                return inputValue;
            }
            return option.label;
        }}
        

          renderOption={(props, option, { selected }) => {
            return (
                <MenuItem
                    {...props}
                    key={props?.id}
                    sx={{ justifyContent: "space-between", fontSize: "13px" }}
                >
                    {option?.label}
                    <Box sx={{ marginLeft: "auto" }}>
                        {selected ? (
                            <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                                <CheckIcon fontSize='small' sx={{ marginLeft: "auto", color: "var(--border-color-1)" }} />
                            </IconButton>
                        ) : null}
                    </Box>
                </MenuItem>
            )
        }}
        renderInput={(params) => (
          <TextField 
          {...params}
            variant={variant}
            placeholder={placeholder}
          />
        )}
      />
      {error ? (
        <Typography
          variant="body2"
          sx={{
            fontSize: '11px',
            fontWeight: '400',
            color: 'var(--error-color)',
          }}
        >
          {helperText}
        </Typography>
      ) : null} 
    </ThemeProvider>
  )
}



// MultiSelect.propTypes = {
//   placeholder: PropTypes.string,
//   label: PropTypes.string,
//   options: PropTypes.array,
//   startAdornment: PropTypes.node,
//   sx: PropTypes.object,
//   error: PropTypes.bool,
//   helperText: PropTypes.string,
//   setValue: PropTypes.func,
//   setFormikValue: PropTypes.func,
//   required: PropTypes.bool,
//   disabled: PropTypes.bool,
//   onChange: PropTypes.func,
//   actions: PropTypes.array,
//   valueAccessor: PropTypes.string,
//   labelAccessor: PropTypes.string,
//   value: PropTypes.any,
// };

// MultiSelect.defaultProps = {
//   placeholder: null,
//   label: null,
//   options: [],
//   startAdornment: <></>,
//   sx: {},
//   error: false,
//   helperText: null,
//   setValue: function () { },
//   setFormikValue: function () { },
//   required: false,
//   disabled: false,
//   onChange: function () { },
//   actions: [],
//   valueAccessor: 'value',
//   labelAccessor: 'label',
// };




// follow this format for better understanding

{/* <MultiSelect
    id="list"
    name="list"
    label="Select List"
    placeholder="Enter list name"
    required
    value={rest.values.dltTemplateId}
    setFormikValue={rest.setFieldValue}
    createNewHandler={(value) => { console.log(value) }}
    error={
        rest.touched.dltTemplateId && Boolean(rest.errors.dltTemplateId)
    }
    selectOnFocus
    // clearOnBlur
    freeSolo
    handleHomeEndKeys
    variant="standard"
    deleteOption={(value) => {
        console.log(value, "jsadassada")
    }}
    helperText={rest.touched.dltTemplateId && rest.errors.dltTemplateId}
    options={(listData || [])
        .map(item => ({
            label: item.title,
            value: item.id,
        }))}
    onInputChange={(e) => {
        if (e.target.value) {
            setFormData({ title: e.target.value, id: "" });
            searchParams.set("list", e.target.value);
            setSearchParams(searchParams);
        }
    }}
    onChange={(e) => {
        console.log(e, "OnChangeValue")
    }}
/>  */}