import * as React from "react";
import { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ChangePassword from "../../Authentication/ChangePassword";
import ChangeEmail from "../../Authentication/ChangeEmail";
import ChangePhoneNumber from "../../Authentication/ChangePhoneNumber";
import BankDetails from "./BankDetails";
import NotificationTable from "./NotificationsTable";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

import ProfileWorkAuthorizationIcon from "../../assets/icons/ProfileWorkAuthorizationIcon";
import ProfileOutlineIcon from "../../assets/icons/ProfileOutlineIcon";
import ProfileSkillsIcon from "../../assets/icons/ProfileSkillsIcon";
import ProfileResumeIcon from "../../assets/icons/ProfileResumeIcon";
import ProfileAddInfoIcon from "../../assets/icons/ProfileAddInfoIcon";

export default function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("category");

  const [profileTab, setProfileTab] = useState(
    !searchFilter || searchFilter == "" ? "password" : searchFilter
  );

  function handleTypeChange(_, data) {
    if (data) {
      setProfileTab(data);
      searchParams.set("category", data);
      setSearchParams(searchParams);
    }
  }

  const tabData = [
    {
      id: "1",
      keyword: "password",
      icon: ProfileWorkAuthorizationIcon,
    },
    {
      id: "2",
      keyword: "email",
      icon: ProfileSkillsIcon,
    },
    {
      id: "3",
      keyword: "phone number",
      icon: ProfileResumeIcon,
    },
    {
      id: "4",
      keyword: "notifications",
      icon: ProfileOutlineIcon,
    },
    // {
    //   id: "5",
    //   keyword: "bank details",
    //   icon: ProfileAddInfoIcon
    // },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Stack
          direction="column"
          sx={{
            minHeight: "80vh",
            border: "0.8px solid #DEDEDE",
            borderRadius: "16px",
            boxShadow: "0px 2px 54px -35px #0000008C",
            background: "#fff",
          }}
        >
          <Stack
            direction="row"
            sx={{
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px 16px 0px 0px",
              padding: "15px",
              gap: "12px",
              background: "#F9F9F9",
              boxShadow: "0px 2px 54px -35px #0000008C",
            }}
          >
            <ToggleButtonGroup
              value={profileTab}
              exclusive
              onChange={handleTypeChange}
              sx={{
                width: "100%",
                borderRadius: "10px",
                display: "flex !important",
                flexDirection: "row !important",
                overflow: "scroll",
                gap: "10px",
                justifyContent: "flex-start !important",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              size="small"
            >
              {tabData?.map((item, index) => (
                <ToggleButton
                  value={item?.keyword}
                  key={index}
                  sx={{
                    border: "1px solid #DEDEDE",
                    borderRadius: "50px !important",
                    fontSize: "14px",
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                    padding: "6px 20px",
                    borderLeft: "1px solid #DEDEDE !important",
                    textTransform: "capitalize",
                    background: "#FFFFFF",
                    "&.Mui-selected": {
                      backgroundColor: "var(--app-button-color1) !important",
                      color: "#fff !important",
                      borderRadius: "50px !important",
                    },
                  }}
                  noWrap
                >
                  <span style={{ marginRight: "10px" }}>
                    {item?.icon(profileTab === item?.keyword)}{" "}
                  </span>{" "}
                  <span>{item?.keyword}</span>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <ThemeProvider theme={globalTheme}>
            <Stack
              sx={{
                padding: "10px",
              }}
            >
              {profileTab === "password" ? (
                <ChangePassword />
              ) : profileTab === "email" ? (
                <ChangeEmail />
              ) : profileTab === "phone number" ? (
                <ChangePhoneNumber />
              ) : profileTab === "notifications" ? (
                <NotificationTable />
              ) : profileTab === "bank details" ? (
                <BankDetails />
              ) : null}
            </Stack>
          </ThemeProvider>
        </Stack>
      </Grid>
    </Grid>
  );
}
