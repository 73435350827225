import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import { Box, ToggleButtonGroup, ToggleButton, Stack } from "@mui/material";
import CompanyOverview from "./overView";
import CompanyJobsList from "./companyJobsList";
import { useSearchParams } from "react-router-dom";
// import noJobs from "../../../assets/images/no_jobs.svg";

import CompanyJobsCardList from "../../BrowseJobsList/CompanyJobsCardList";


function EmployerDescription({ company, list, setJobId }) {
  const [value, setValue] = React.useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("tab");

  const [compTab, setCompTab] = useState(searchFilter ?? "Overview");


  function handleTypeChange(_, data) {
    if (data) {
      setCompTab(data);
      searchParams.set("tab", data);
      setSearchParams(searchParams);
    }
  }

  const tabData = [
    {
      id: "1",
      keyword: "Overview"
    },
    {
      id: "2",
      keyword: "Jobs"
    },
  ];


  return (
    <>
      <section className="company_overview_tabs">
        <Box sx={{ width: "100%", paddingTop: "20px" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
              // borderBottom: 1,
              // borderColor: "divider",
              marginBottom: "20px",
            }}
          >
            <ToggleButtonGroup
              value={compTab}
              exclusive
              onChange={handleTypeChange}
              sx={{
                border: "none",
                borderRadius: "10px",
              }}
              size="small"
            >
              {tabData?.map((item, index) => (
                <ToggleButton value={item?.keyword}
                  key={index}
                  sx={{
                    border: "none",
                    borderRadius: "50px !important",
                    fontSize: "13px",
                    marginRight: "5px",
                    padding: "6px 20px",
                    textTransform: "capitalize",
                    "&.Mui-selected": {
                      backgroundColor: "var(--app-button-color1) !important",
                      color: "#9FE870 !important",
                      borderRadius: "50px !important",
                    }
                  }}
                >
                  {item?.keyword}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Stack>
          {
            compTab === "Overview" ? (
              <CompanyOverview companyData={company} />
            ) : compTab === "Jobs" ? (
              <CompanyJobsCardList companyId={company?.id} companyName={company?.company?.company_name} />
            ) : null}
        </Box>
      </section>
    </>
  );
}

export default EmployerDescription;
