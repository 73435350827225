import parsePhoneNumber from 'libphonenumber-js';

export const parseNumberInput = (number)=>{

    const phoneNumberObj = parsePhoneNumber(number);
    if (phoneNumberObj) {
      const formattedPhoneNumber = phoneNumberObj.formatInternational('International');
    //   console.log('Formatted phone number:', formattedPhoneNumber);
      return formattedPhoneNumber
 
    }
  };