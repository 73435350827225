import React, { useState, useEffect } from "react";
import DashBoardPage1 from "./DashBoardPage1";
import DashBoardPage2 from "./DashBoardPage2";
import {
  getAppliedJobDashboardMatrix,
  getReferredJobDashboardMatrix,
  getMyReferralJobDashboardMatrix
} from "../../../helpers/dataFetcher/jobs/jobs";
import { Grid } from "@mui/material";
import { toast } from "react-hot-toast";

function DashBoardMain() {
  const [myReferedJob, setMyReferedJob] = useState({});
  const [appliedJobs, setAppliedJobs] = useState({})
  const [byRefer, setByRefer] = useState({})

  useEffect(() => {
    getAllJobs();
  }, []);

  async function getAllJobs() {
    try {
      const [myReferData, appliedData, byRefer] = await Promise.all([
        getMyReferralJobDashboardMatrix(),
        getAppliedJobDashboardMatrix(),
        getReferredJobDashboardMatrix()
      ]);

      if ([200, 201].includes(myReferData?.status)) {
        setMyReferedJob(myReferData?.data);
      }
      
      if ([200, 201].includes(appliedData?.status)) {
        setAppliedJobs(appliedData?.data);
      }
      
      if ([200, 201].includes(byRefer?.status)) {
        setByRefer(byRefer?.data);
      }

      
      if ([myReferData?.status, appliedData?.status, byRefer?.status].includes(400)) {
        const errorMessage = myReferData?.return_message || appliedData?.return_message || byRefer?.return_message;
        toast.error(errorMessage);
      }
 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  

  return (
    <>
      <Grid container >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <DashBoardPage1 myReferedJob={myReferedJob} appliedJobs={appliedJobs} byRefer={byRefer} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          mt={1}
        >
          <DashBoardPage2 />
        </Grid>
      </Grid>
    </>
  );
}

export default DashBoardMain;
