import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import { FaLinkedin, FaGithub, FaGlobe, FaBriefcase } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function LinksField({ textField, links }) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#676767", fontWeight: "400", fontSize: "16px" }}
        >
          {textField ?? ""}
        </Typography>
      </Grid>

      <Grid
        item
        xs={8}
        sx={{
          display: "flex",
          direction: "row",
          gap: "5px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {links ? (
          <Stack direction={"row"} spacing={1}>
            <Typography
              variant="span"
              sx={{ fontWeight: 600, fontSize: "15px" }}
            >
              :
            </Typography>
            {links?.linkedIn ? (
              <Link to={links.linkedIn} target="_blank">
                <FaLinkedin size={28} color="#0967c2" title="LinkedIn" />
              </Link>
            ) : null}
            {links?.github ? (
              <Link to={links?.github} target="_blank">
                <FaGithub size={28} color="black" title="GitHub" />
              </Link>
            ) : null}
            {links?.website ? (
              <Link to={links?.website} target="_blank">
                <FaGlobe size={28} color="black" title="Website" />
              </Link>
            ) : null}
            {links?.portfolio ? (
              <Link to={links?.portfolio} target="_blank">
                <FaBriefcase size={28} color="black" title="Portfolio" />
              </Link>
            ) : null}
          </Stack>
        ) : null}
      </Grid>
    </Grid>
  );
}
