import React from "react";
import { Stack, Grid, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { companyFaqData, jobSeekerFaqData } from "../../../data/FaqData";
import { useLocation } from "react-router-dom";

function Questions() {
  const { pathname } = useLocation();
  return (
    <Grid container sx={{ paddingTop: "50px", paddingBottom: "100px" }}>
      <Grid lg={12} md={12} sm={12}>
        <Stack
          sx={{
            margin: "auto",
            width: "60%",
            "@media (max-width: 900px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "40px",
              fontWeight: "500",
            }}
          >
            Frequently asked questions about joinee
          </Typography>

          <Stack
            sx={{
              border: "1px solid",
              borderRadius: "10px",
              overflow: "hidden",
            }}
            mt={3}
          >
            {(pathname === "/" ? jobSeekerFaqData : companyFaqData)?.map(
              (item, index) => (
                <Accordion
                  sx={{
                    boxShadow: "none",
                  }}
                  key={index}
                  id={`accord_${item?.id}`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`accord_${item?.id}`}
                    id={`accord_${item?.id}`}
                    sx={{
                      padding: "10px 15px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                      Q. {item?.que}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails id={`accord_${item?.id}`}>
                    {/* <Typography sx={{ fontSize: "13px" }}>
                      {item?.ans}
                    </Typography> */}
                    <div dangerouslySetInnerHTML={{ __html: item.ans }} style={{fontSize: "13px"}} />
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </Stack>
          <Stack justifyContent="center" alignItems="center" p={2}>
            <Box
              sx={{
                border: "1px solid #066a4c",
                color: "#fff",
                padding: "8px 15px",
                fontSize: "12px",
                fontWeight: "700",
                borderRadius: "50px",
                background: "#066a4c",
                cursor: "pointer",
                "&:hover": {
                  background: "#fff",
                  color: "#000",
                },
              }}
            >
              Still, have any questions? Contact us
            </Box>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Questions;
