import React, { useState, useEffect } from "react";
import "./login.css";
import TextField from "../components/formElements/TextField";

import { Box, Modal, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  updateEmail,
  sendOtpForMail,
} from "../helpers/dataFetcher/profile/update";
import OtpInput from "react18-input-otp";
import CountdownTimer from "./CountDown/CountdownTimer";
import { verifyMobileOTP } from "../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../assets/MUI/globalStyles";
import { updateUserAccount } from "../pages/Accounts/accountSlice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  height: "fit-contnent",
  padding: "10px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px",
  overflow: "hidden",
  pt: 2,
  px: 4,
  pb: 3,
};


function ChangeEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const userData = useSelector(state => state.User);

  const obj = {
    update_type_to_send_email: "email",
  };
  let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const [otpModalView, setOtpModalView] = useState(false);
  const [otp, setOtp] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleChange = code => setOtp(code);


  /*Verify otp and update function */
  const handleOTP = async e => {
    const { status, data } = await verifyMobileOTP(otp);
    if ([200, 201].includes(status)) {
      const newEmail = getValues("newEmail")?.toLowerCase() ?? '';
      const { data: resData, status } = await updateEmail("about_me", o, newEmail);
      if ([200, 201].includes(status)) {
        toast.success(resData?.return_message ?? "Updated Successfully!");
        reset()
      } else if (status === 400) {
        toast.error(resData?.return_message ?? "Something went wrong");
        reset()
      } else if (status === 422) {
        toast.error(resData?.return_message ?? "Something went wrong");
        reset()
      }
    }
    if (status === 400) {
      toast.error(data?.return_message ?? "Something went wrong");
      return;
    }
    if (status === 422) {
      toast.error(data?.return_message ?? "Something went wrong");
      return;
    }
    setOtp("");
    dataFetcher();
    setOtpModalView(false);
    setHasError(false);
  };
  const curr_email = userData?.user?.email
  /*send otp to new email function */
  const changeEmail = async ChangeEmailData => {

    const oldEmail = ChangeEmailData?.oldEmail?.toLowerCase() ?? ''
    const newMail = ChangeEmailData?.newEmail?.toLowerCase() ?? ''
    const confirmMail = ChangeEmailData?.confirmEmail?.toLowerCase() ?? ''
    if (curr_email === oldEmail) {
      const { data, status } = await sendOtpForMail("email", { email: newMail, old_email: oldEmail });
      if (status == 400) {
        toast.error(data?.return_message);
      } else if (status == 200) {
        setOtpModalView(true);
        toast.success(data?.return_message);
      }
    } else {
      toast.error("Incorrect Current Email")
    }
  };


  async function dataFetcher() {
    const { data, status } = await getProfile();
    if (status === 200) {
      localStorage.setItem("userData", JSON.stringify(data));
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          }
        })
      );
    }
  }



  return (
    <ThemeProvider theme={globalTheme}>
      <div className="main_container">
        <h1 className="password_heading">Update Email</h1>
        <p className="password_paragraph"
          style={{
            color: "#979797"
          }}>
          Please enter your current email to change your email address
        </p>
        {/* <hr /> */}
        <Box
          component="form"
          onSubmit={handleSubmit(changeEmail)}
          // noValidate
          sx={{
            marginTop: "25px"
          }}
          className="form_container"
        >

          <div className="bank_card_cont">
            <p>Old Email</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Old email (i.e: example@example.xyz)"
                sx={{
                  background: "#E9E9E966",
                  padding: "13px 16px 13px 16px",
                  borderRadius: "8px",
                }}
                name="oldEmail"
                type="email"
                autoComplete="email"
                error={errors?.oldEmail}
                useFormRef={{
                  ...register("oldEmail", {
                    required: "Old email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email is invalid"
                    }
                  })
                }}
                helperText={
                  errors?.oldEmail ? errors?.oldEmail?.message : null
                }
              />
            </div>
          </div>
          <br />
          <div className="bank_card_cont">
            <p>New Email</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="newEmail"
                type="email"
                placeholder="New email (i.e: example@example.xyz)"
                sx={{
                  background: "#E9E9E966",
                  padding: "13px 16px 13px 16px",
                  borderRadius: "8px",
                }}
                autoComplete="email"
                error={errors?.newEmail}
                useFormRef={{
                  ...register("newEmail", {
                    required: "New email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email is invalid"
                    },
                    validate: {
                      matchesNewEmail: (value) => {
                        const oldEmail = watch("oldEmail");
                        return value !== oldEmail || 'New Email must not match Old Email';
                      }
                    }
                  })
                }}
                helperText={
                  errors?.newEmail ? errors?.newEmail?.message : null
                }
              />

            </div>
          </div>
          <br />
          <div className="bank_card_cont">
            <p>Confirm Email</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="confirmEmail"
                type="email"
                autoComplete="email"
                placeholder="Confirm email (i.e: example@example.xyz)"
                sx={{
                  background: "#E9E9E966",
                  padding: "13px 16px 13px 16px",
                  borderRadius: "8px",
                }}
                error={errors?.confirmEmail}
                useFormRef={{
                  ...register("confirmEmail", {
                    required: "Confirm email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email is invalid"
                    },
                    validate: {
                      matchesNewEmail: (value) => {
                        const newEmail = watch("newEmail");
                        return value === newEmail || 'Confirm Email must should with New Email';
                      },
                    }
                  })
                }}
                helperText={
                  errors?.confirmEmail ? errors?.confirmEmail?.message : null
                }
              />
            </div>

          </div>
          <div className="d-flex submit-button mt-4">
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  marginLeft: "10px",
                  boxShadow: "none",
                  fontSize: "14px",
                  background: "var(--app-button-color1)",
                  "&:hover": {
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                  }
                }}
              >
                <span style={{ textTransform: "capitalize" }}> Update now </span>
              </Button>
            </Stack>
          </div>

        </Box>
        {otpModalView ? (
          <>
            <Modal
              // {...props}
              onClose={() => setOtpModalView(false)}
              open={otpModalView}
              style={{ overflowY: "scroll", maxHeight: "100vh" }}
            >
              <Box sx={{ ...style, width: 400 }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  OTP Verify
                </Typography>
                <Stack mb={2} mt={2}>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    shouldAutoFocus
                    isInputNum={true}
                    inputStyle="otp_input_items"
                    errorStyle={"otp_input_items_error"}
                    hasErrored={hasError}
                    focusStyle={"otp_input_items_focus"}
                    separator={<span> </span>}
                    onSubmit={handleOTP}
                  />
                </Stack>

                <CountdownTimer email={watch("newEmail")?.toLowerCase()} totalSec={20 * 10000} />
                <Stack direction="row" justifyContent={"flex-end"}>
                  <Button
                    type="submit"
                    size="large"
                    loading={false}
                    onClick={handleOTP}
                    disabled={otp?.length !== 6}
                    loadingPosition="end"
                    variant="contained"
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                      padding: "6px 4px",
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                      "&:hover": {
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                      }
                    }}
                  >
                    <span style={{ textTransform: "capitalize" }}>Verify OTP</span>
                  </Button>
                </Stack>
              </Box>
            </Modal>
          </>
        ) : (
          ""
        )}
      </div>
    </ThemeProvider>
  );
}

export default ChangeEmail;
