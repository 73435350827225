import React from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import getSymbolFromCurrency from "currency-symbol-map";
import Divider from "@mui/material/Divider";

function JobInfoDescription(props) {
  return (
    <>
      <Stack pt={4}>
        <Typography 
        sx={{
          paddingBottom: "12px",
          color: "#000000",
          fontSize: "16px",
          fontWeight: "600",
        }}
        >Job Description</Typography>
        {props?.jobDetails?.job_desc?.length > 0 ?
          <Box
            sx={{
              padding: ""
            }}
          >
            <Box
              dangerouslySetInnerHTML={{ __html: props.jobDetails?.job_desc }}
              sx={{
                backgroundColor: "transparent",
                "& p > span": {
                  backgroundColor: "transparent"
                },
                "& p ": {
                  backgroundColor: "transparent"
                },
                "& span": {
                  backgroundColor: "transparent"
                },
                "& div": {
                  backgroundColor: "transparent"
                },
                "& ul li": {
                  backgroundColor: "transparent"
                },
              }}
            ></Box>
          </Box>
          : "--"}
      </Stack>
    </>
  );
}

export default JobInfoDescription;
