import React, { useEffect, useState, lazy, Suspense, memo } from "react";
import { Grid, CircularProgress, Chip, Stack } from "@mui/material";
import MainSearchFilterBar from "../MainSearchFilterBar/MainSearchFilterBar";
import JobsList from "./Alljobs/JobsList";
import {
  findAllJobs,
  findAllFuncArea,
  findJobTypes,
  findKeySkills,
  findSalaryRangeTypes,
  findWorkMode,
} from "../../helpers/dataFetcher/jobs/jobs";
import {
  fetchFilteredJobs,
  applyFilteredJobs,
} from "../../helpers/dataFetcher/jobs/jobs";
import SidePanelFilters from "./JobsListSidePanel/SidePanelFilter";
import { useSearchParams } from "react-router-dom";
// import SmallScreenFilter from "../MainSearchFilterBar/smallScreenFilter";
import { useSelector } from "react-redux";
import "../../assets/css/Jobs/jobsPage.css";
import { socket } from "../../Sockets/Socket";

const SmallScreenFilter = lazy(() =>
  import("../MainSearchFilterBar/smallScreenFilter")
);

function MainJobsList() {
  const [load, setLoad] = useState(true);
  const [jobsList, setJobsList] = useState([]);
  const [filterJobslist, setFilterJobsList] = useState([])
  const [searchJobsList, setSearchJobsList] = useState([])
  const [saveJobsList, setSaveJobsList] = useState([])
  const [saveCheck, setSaveCheck] = useState('')
  const [serched, setSeached] = useState(true);
  const [functionalArea, setFunctionalArea] = useState();
  const [jobTypes, setJobTypes] = useState();
  const [keySkills, setKeySkills] = useState();
  const [filterList, setFilterList] = useState([]);
  const [salaryRange, setSalaryRange] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(3);
  const [filterApplied, setFilterApplied] = useState(true);
  const [workMode, setWorkMode] = useState();

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(null)
  const [mount, setMount] = useState(false);
  /*search */

  const [funcSearch, setFuncSearch] = useState('')
  const [keySkillSearch, setKeySkillSearch] = useState('')


  const userData = useSelector(state => state.User);
  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("user_id");

  useEffect(() => {
    // if (!mount) {
    //   setMount(true);
    //   return;
    // }

    // filterJobsBased();
    fetchAllJobs();
  }, []);


  let [searchParams, _] = useSearchParams();


  const handleListnerEvent = data => {
    if (data?.type === "jobs") {
      setPage(1);
      setJobsList([]);
      filterJobsBased();
    }
  };
  
  useEffect(() => {
    const handleReconnect = () => {
      socket.on("listnerEvent", handleListnerEvent);
    };
  
    socket.on("listnerEvent", handleListnerEvent);
  
    socket.on("reconnect", handleReconnect);
  
    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);


  useEffect(() => {
    if (page >= 1) {
      filterJobsBased()
    }
  }, [page])

  const filterJobsBased = () => {
    fechJobsBasedonOffset(
      searchParams.get("title"),
      searchParams.get("location"),
      searchParams.getAll("fn_areas"),
      searchParams.getAll("job_type"),
      searchParams.get("exp"),
      searchParams.getAll("salary"),
      searchParams.getAll("key_skills"),
      searchParams.getAll("work_mode"),

    );

  };


  useEffect(() => {

    const fnAreasLength = searchParams.getAll("fn_areas")?.length || 0;
    const jobTypeLength = searchParams.getAll("job_type")?.length || 0;
    const keySkillsLength = searchParams.getAll("key_skills")?.length || 0;
    const workModeLength = searchParams.getAll("work_mode")?.length || 0;

    // if (fnAreasLength || jobTypeLength || keySkillsLength || workModeLength) {
      setPage(1);
      setJobsList([]);
      filterJobsBased();
    // }
  }, [searchParams]);


  // Effect for handling filter changes
  useEffect(() => {

    const title = searchParams.get("title");
    const location = searchParams.get("location");
    const exp = searchParams.get("exp");
    const salary = searchParams.get("salary");


    // if (title && title !== "" || location && location !== "" || exp && exp !== "" || salary && salary !== "") {
      setPage(1);
      setJobsList([]);
      filterJobsBased();
    // }

  }, [
    searchParams.get("title"),
    searchParams.get("location"),
    searchParams.get("exp"),
    searchParams.get("salary"),
  ]);



  /*candidate id passing test in progress start*/
  const fechJobsBasedonOffset = async (title, location, fn_area, job_type, exp, salary, key_skills, work_mode,) => {

    setLoad(true);

    // Sample input data
    const inputData = {
      title: title,
      location: location, // or any other value
    };


    // Check if title and location have values
    const hasTitle = inputData.title !== undefined && inputData.title !== null && inputData.title !== '';
    const hasLocation = inputData.location !== undefined && inputData.location !== null && inputData.location !== '';

    // Construct searchParam based on the conditions
    let searchParams = '';
    let locationSearch = location

    if (hasTitle) {
      searchParams = inputData.title;
    }

    // Output the result



    if (token && token !== "") {
      const response = await findAllJobs({
        job_post_title: title,
        location: location,
        job_type: job_type,
        // experience: exp,
        experience: exp ? `0,${exp}` : "",
        salary: salary,
        functional_area: fn_area,
        key_skills: key_skills,
        work_mode: work_mode,
        limit: limit,
        offset: offset,
        pageNo: page,
        pageSize: pageSize,
        candidate_id: userId ?? "",
        searchParam: searchParams

      });

      if (response?.pageNo == 1) {
        setJobsList(response?.data)
      } else {
        setJobsList((prevItems) => [...prevItems, ...(response?.data || [])]);
      }

      setTotalPages(response?.totalPageSize)
      setLoad(false);

    } else {
      const response = await findAllJobs({
        job_post_title: title,
        location: location,
        job_type: job_type,
        // experience: exp,
        experience: exp ? `0,${exp}` : "",
        salary: salary,
        functional_area: fn_area,
        key_skills: key_skills,
        work_mode: work_mode,
        pageNo: page,
        pageSize: pageSize,
        searchParam: searchParams,
        limit: limit,
        offset: offset,
      });


      if (response?.pageNo == 1) {
        setJobsList(response?.data)
      } else {
        setJobsList((prevItems) => [...prevItems, ...(response?.data || [])]);
      }

      setTotalPages(response?.totalPageSize)
      setLoad(false);
    }
  };

  /*candidate id passing test in progress end*/

  const fetchAllJobs = async () => {
    const funcResponse = await findAllFuncArea(funcSearch);
    const fetchJobTypes = await findJobTypes();
    const fetchSalaryRange = await findSalaryRangeTypes();
    const fetchKeySkills = await findKeySkills(keySkillSearch);
    const fetchWorkMode = await findWorkMode();
    setFunctionalArea(funcResponse?.data);
    setJobTypes(fetchJobTypes?.data);
    setKeySkills(fetchKeySkills?.data);
    setWorkMode(fetchWorkMode?.data);
    setSalaryRange(fetchSalaryRange?.data);
  };


  const expFilter = async exp => {
    setFilterList([...filterList, exp]);
    const response = await applyFilteredJobs(exp);
    setJobsList(response?.data);
  };
  const sidebarFilter = e => { };



  /* Search implementation for categories */

  const categoriesGet = async () => {
    const funcResponse = await findAllFuncArea(funcSearch);
    setFunctionalArea(funcResponse?.data);
  }

  const keySkillsGet = async () => {
    const skillResponse = await findKeySkills(keySkillSearch);
    setKeySkills(skillResponse?.data);
  }


  useEffect(() => {
    const timerId = setTimeout(() => {
     if (funcSearch !== undefined && funcSearch !== null) {
      categoriesGet();
    } 
    }, 500);
    return () => clearTimeout(timerId);

  }, [ funcSearch])

  useEffect(() => {
    const timerId = setTimeout(() => {
   
    if (keySkillSearch !== undefined && keySkillSearch !== null) {
      keySkillsGet();
    }

    }, 500);
    return () => clearTimeout(timerId);

  }, [ keySkillSearch])
 


  function updateApplyAndRefer(payload) {
    const id = payload?.job_post_id;
    
    const updateStatus = jobsList.map((each) => {
      if (each?.id === id) {
        return {
          ...each,
          is_candidate_applied_to_job_post: 1
        };
      }
      return each;
    });
    const filteredUpdateStatus = updateStatus.filter(job => job);
    setJobsList(filteredUpdateStatus);
  }

  
  


  return (
    <>
      <Grid container
        sx={{
          background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
          minHeight: "100vh",
          height: "100vh",
          margin: "auto",
          // paddingLeft: { xs: "40px", sm: "40px", md: "80px", lg: "80px" },
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingTop: "30px",
          paddingBottom: "30px"

        }}
      >
        {/* Left Section */}
        <Grid item xs={12} sm={12} md={4} lg={2.5}
          sx={{
            paddingLeft: "5px",
            paddingRight: "5px",
            height: "100%",
            '@media (max-width: 900px)': {
              display: "none",
            },
          }}
        >
          <SidePanelFilters
            setJobsList={setJobsList}
            sidebarFilter={sidebarFilter}
            functionalArea={functionalArea?.sort((a, b) => {
              if (a.countOfJobs === b.countOfJobs) {
                return 0;
              } else if (a.countOfJobs === 0) {
                return 1;
              } else if (b.countOfJobs === 0) {
                return -1;
              } else {
                return b.countOfJobs - a.countOfJobs;
              }
            })}
            jobTypes={jobTypes?.sort((a, b) => {
              if (a.jobPostsCount === b.jobPostsCount) {
                return 0;
              } else if (a.jobPostsCount === 0) {
                return 1;
              } else if (b.jobPostsCount === 0) {
                return -1;
              } else {
                return b.jobPostsCount - a.jobPostsCount;
              }
            })}
            workTypes={workMode?.sort((a, b) => {
              if (a.workModeCount === b.workModeCount) {
                return 0;
              } else if (a.workModeCount === 0) {
                return 1;
              } else if (b.workModeCount === 0) {
                return -1;
              } else {
                return b.workModeCount - a.workModeCount;
              }
            })}
            salaryRange={salaryRange}
            keySkills={keySkills?.sort((a, b) => {
              if (a.jobsCount === b.jobsCount) {
                return 0;
              } else if (a.jobsCount === 0) {
                return 1;
              } else if (b.jobsCount === 0) {
                return -1;
              } else {
                return b.jobsCount - a.jobsCount;
              }
            })}
            updateSkills={data => setKeySkills(data)}
            updateSalaryRange={data => setSalaryRange(data)}
            update={data => setFunctionalArea(data)}
            expFilter={val => expFilter(val)}
            filterJobs={() => filterJobsBased()}
            setFuncSearch={setFuncSearch}
            setKeySkillSearch={setKeySkillSearch}
          />
        </Grid>

        {/* Right section */}
        <Grid item xs={12} sm={12} md={8} lg={9.5}
          sx={{
            height: "100%"
          }}
        >
          <Grid container
            sx={{
              height: "100%"
            }}
          >
            <Grid item xs={12} sm={12}>
              <MainSearchFilterBar
                limit={limit}
                offset={offset}
                setJobsList={setJobsList}
                setLoad={setLoad}
              />
            </Grid>
            <Grid item xs={12} sm={12}
              sx={{
                display: "none",
                '@media (max-width: 900px)': {
                  display: "block",
                  padding: "5px",
                },
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <SmallScreenFilter
                  functionalArea={functionalArea}
                  keySkills={keySkills}
                  salaryRange={salaryRange}
                  jobTypes={jobTypes}
                  workTypes={workMode}
                  filterJobs={() => filterJobsBased()}
                />
              </Suspense>
            </Grid>
            <Grid item xs={12} sm={12}
              sx={{
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                height: "93%"
              }}
            >
              <JobsList
                jobsList={jobsList}
                filterJobslist={filterJobslist}
                saveJobsList={saveJobsList}
                setJobsList={setJobsList}
                searchJobsList={searchJobsList}
                functionalArea={functionalArea}
                jobTypes={jobTypes}
                salaryRange={salaryRange}
                keySkills={keySkills}
                update={data => setFunctionalArea(data)}
                updateSkills={data => setKeySkills(data)}
                updateSalaryRange={data => setSalaryRange(data)}
                expFilter={val => expFilter(val)}
                load={page === 1 ? load : false}
                setSaveCheck={setSaveCheck}
                updateApplyAndRefer={updateApplyAndRefer}
              />
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ padding: "10px" }}
              >
                {load ? (
                  <CircularProgress color="success" size={25} />
                ) : (
                  <>
                    {page >= totalPages ? (
                      null
                    ) : (
                      <Chip
                        label={"See more"}
                        onClick={() => { setPage((prevPage) => prevPage + 1) }}
                        disabled={load}
                        sx={{
                          border: "1px solid #40877B",

                          '& .MuiChip-label': {
                            fontWeight: "600",
                            display: 'block',
                            color: "#40877B",
                            whiteSpace: 'normal',
                            textAlign: "center",
                          },
                        }}
                      />
                    )}

                  </>
                )}

              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MainJobsList;
