import React, { useState,useEffect } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../assets/css/footer.css";
import CopyrightIcon from "@mui/icons-material/Copyright";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import Divider from "@mui/material/Divider";
import handImage from '../../assets/images/handImage.png';
import { findAllJobs } from "../../helpers/dataFetcher/jobs/jobs";

export default function Footer() {
  // const [seeMore, setSeeMore] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    getAllJobs({ fn_areas: searchParams.get("q") == null ? [] : searchParams.get("q")});
    setLoading(false);
  }, []);
  async function getAllJobs({ fn_areas }) {
    const { data } = await findAllJobs({
      job_post_title: "",
      location: "",
      job_type: [],
      experience: "",
      salary: [],
      functional_area: fn_areas,
      key_skills: [],
      offset: 0,
      limit: 10,
      work_mode:[]
    });

    setJobs(data);
  }

  const navigate = useNavigate();
  return (
    <>
      <div className="footer_main">
        <div className="footer_main_cont">
          <div className="footer_top_main_cont">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
              <span className="footer_top_cont_head">Trending Jobs</span>
                <div className="mt-4">
                <p>
                    <Link to="/jobs-list?title=&location=Hyderabad" className="footer_top_link">
                      Jobs based on Hyderabad
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=&location=Bangalore" className="footer_top_link">
                      Jobs based on Bangalore
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=&location=Pune" className="footer_top_link">
                      Jobs based on Pune
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=&location=Chennai" className="footer_top_link">
                      Jobs based on Chennai
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=&location=Noida" className="footer_top_link">
                      Jobs based on Noida
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=&location=Texas" className="footer_top_link">
                      Jobs based on Texas
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=&location=Remote" className="footer_top_link">
                      Remote Jobs
                    </Link>
                  </p>
                  {/* <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Hire an Agency
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Enterprise
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Payroll Services
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Direct Contracts
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Hire Worldwide
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Hire in USA
                  </Link>
                </p> */}
                </div>
              </div>
              {/* <Divider orientation="vertical" flexItem sx={{ height: 24, mx: 2 ,color:"red"}} /> */}
              <div
                style={{
                  borderLeft: "1px",
                  borderStyle: "solid",
                  color: "#ffffff",
                  opacity: "30%",
                  // height: "100px",
                  top: "10",
                  marginTop: "50px",
                  marginLeft: "30px",
                }}
                className="hr_line"
                id="line_divider"
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: "20px",
              }}
            >
              <div>
                {/* <span className="footer_top_cont_head">Trending Jobs</span> */}
                <div className="mt-5">
                  <p>
                    <Link to="/jobs-list?title=React" className="footer_top_link">
                      React Developer
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Angular" className="footer_top_link">
                      Angular Developer
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Fullstack" className="footer_top_link">
                      Fullstack Developer
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Python" className="footer_top_link">
                      Python Developer
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Devops" className="footer_top_link">
                      Devops
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Pega" className="footer_top_link">
                      Pega Developer
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=android+%26+ios" className="footer_top_link">
                      Android & IOS Developer
                    </Link>
                  </p>
                  {/* <p className="mt-1" onClick={()=>navigate("/jobs-list")}>
                    <Link href="" className="footer_top_link_see_more">
                      ++See More
                    </Link>
                  </p> */}
                  {/* {!seeMore ? (
                  <p className="mt-1" onClick={() => setSeeMore(!seeMore)}>
                    <Link to="#" className="footer_top_link">
                      ++See More
                    </Link>
                  </p>
                ) : null}
                {seeMore ? (
                  <>
                    <p className="mt-1">
                      <Link to="/jobs-list" className="footer_top_link">
                        Cyber security analyst
                      </Link>
                    </p>
                    <p className="mt-1">
                      <Link to="/jobs-list" className="footer_top_link">
                        Content writer
                      </Link>
                    </p>
                    <p className="mt-1">
                      <Link to="/jobs-list" className="footer_top_link">
                        Site Engineer
                      </Link>
                    </p>
                    <p className="mt-1">
                      <Link to="/jobs-list" className="footer_top_link">
                        Tableau Developer
                      </Link>
                    </p>
                    <p className="mt-1">
                      <Link to="/jobs-list" className="footer_top_link">
                        Data Analyst
                      </Link>
                    </p>
                    <p className="mt-1" onClick={() => setSeeMore(!seeMore)}>
                      <Link to="#" className="footer_top_link">
                        --See Less
                      </Link>
                    </p>
                  </>
                ) : null} */}
                </div>
              </div>
              <div
                style={{
                  borderLeft: "1px",
                  borderStyle: "solid",
                  color: "#ffffff",
                  opacity: "30%",
                  // height: "100px",
                  top: "10",
                  marginTop: "50px",
                }}
                className="hr_line"
                id="line_divider"
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: "20px",
              }}
            >
              <div style={{ paddingLeft: "20px" }}>
                {/* <span className="footer_top_cont_head">Resources</span> */}
                <div className="mt-45">
                  <p>
                    <Link to="/jobs-list?title=Django" className="footer_top_link">
                      Django Developers
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=NodeJs" className="footer_top_link">
                      NodeJs Developers
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Ruby" className="footer_top_link">
                      Ruby On Rails Developers
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=NextJs" className="footer_top_link">
                      NextJs Developers
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Flutter" className="footer_top_link">
                      Flutter Developers
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Php" className="footer_top_link">
                      Php Developers
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link to="/jobs-list?title=Mobile%20App" className="footer_top_link">
                      Mobile App Developers
                    </Link>
                  </p>
                </div>
              </div>
              <div
                style={{
                  borderLeft: "1px",
                  borderStyle: "solid",
                  color: "#ffffff",
                  opacity: "30%",
                  // height: "100px",
                  top: "10",
                  marginTop: "50px",
                }}
                className="hr_line"
                id="line_divider"
              ></div>
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <span className="footer_top_cont_head">Quick Links</span>
              <div className="mt-4">
                <p>
                  <Link to="" className="footer_top_link">
                    Home
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="/jobs-list" className="footer_top_link">
                    Jobs
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="/employer-list" className="footer_top_link">
                    Companies
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="/about-us" className="footer_top_link">
                    About
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="/refernow" className="footer_top_link">
                    Refer
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="/employerpage" className="footer_top_link">
                    Employer
                  </Link>
                </p>
               
                {/* <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Trust Safety & Security
                  </Link>
                </p>
                <p className="mt-1">
                  <Link to="#" className="footer_top_link">
                    Modern Slavery Statement
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
          <div className="footer_middle_main_cont">
            <div>
              <span className="footer_middle_main_cont_head">Follow us</span>
              <div className="footer_middle_main_left_icons_cont">
                <a
                  className="footer_social_icons"
                  href="https://www.facebook.com/tryjionee "
                  target="_blank"
                >
                  <FacebookIcon />
                </a>
                <a
                  className="footer_social_icons"
                  href="https://www.instagram.com/tryjoinee/"
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
                <a
                  className="footer_social_icons"
                  href="https://www.linkedin.com/company/tryjoinee/ "
                  target="_blank"
                >
                  <LinkedInIcon />
                </a>
                <a
                  className="footer_social_icons"
                  href="https://x.com/tryjoinee"
                  target="_blank"
                >
                  <TwitterIcon />
                </a>
                <a
                  className="footer_social_icons"
                  // href="https://www.linkedin.com/company/Shifyt/"
                  // target="_blank"
                >
                  <YouTubeIcon />
                </a>
              </div>
            </div>
            <div>
            {/* <img src={handImage} /> */}
              <span className="footer_middle_main_cont_head">Mobile app</span>
              <div className="footer_middle_main_left_icons_cont">
                <span className="footer_social_icons">
                  <AppleIcon />
                </span>
                <span className="footer_social_icons">
                  <AndroidIcon />
                </span>
              </div>
            </div>
          </div>
          <hr className="footer_hr_line" />
          <div className="footer_bottom_main_cont">
            <div>
              <p>
                <CopyrightIcon className="footer_copyright_icon footer_copyright" />{" "}
                <span className="footer_copyright">2015 - 2023 Joinee</span>
                {/* <i className="bx bxs-registered"></i>
                <span> Global Inc.</span> */}
              </p>
            </div>
            <div>
              <span className="footer_copyright">Term of Service</span>
            </div>
            <div>
              <span className="footer_copyright">Privacy Policy</span>
            </div>
            <div>
              <span className="footer_copyright">CA Notice at Collection</span>
            </div>
            <div>
              <span className="footer_copyright">Cookie Settings</span>
            </div>
            <div>
              <span className="footer_copyright">Accessibility</span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
