import React from 'react';
import { Grid } from "@mui/material";
import StayInTouch from './stayInTouch';
import Questions from './Questions';
import BannerSection from './Components/BannerSection';
import CompanyHired from './Components/CompanyHired';
import ServiceTypeSection from './Components/ServiceTypeSection';
import WorkingTypeSection from './Components/WorkingTypeSection';
import OurStorySection from './Components/OurStorySection';
import TestimonialSection from './Components/TestimonialSection';
import NewFooter from '../../../layouts/footer/NewFooter';
import NewsLatterSection from './Components/NewsLatterSection';
import LandingCategoriesSection from '../LandingCategoriesSection';
import TestimonialSectionFirst from './Components/TestimonialSectionFirst';
import SlideBlogSection from './Components/SlideBlogSection';

function CommonLandingPage() {
    return (
        <React.Fragment>
            <Grid container
                sx={{
                    background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
                    minHeight: "100vh",
                    margin: "auto",
                    // paddingLeft: { xs: "40px", sm: "40px", md: "80px", lg: "80px" },
                    paddingLeft: "5%",
                    paddingRight: "5%"

                }}
            >
                {/* Bnner Section */}
                <Grid item xs={12} sm={12}>
                    <BannerSection />
                </Grid>

                {/* Company Hired section */}
                <Grid item xs={12} sm={12}>
                    <CompanyHired />
                </Grid>

                {/* Service type section */}
                {/* <Grid item xs={12} sm={12}>
                    <ServiceTypeSection />
                </Grid> */}

                {/* Working type section */}
                <Grid item xs={12} sm={12}>
                    <WorkingTypeSection />
                </Grid>

                {/* Working type section */}
                <Grid item xs={12} sm={12}>
                    <LandingCategoriesSection />
                </Grid>

                {/* Testimonial Section  */}
                {/* <Grid item xs={12} sm={12}>
                    <TestimonialSection />
                </Grid> */}

                {/* New Testimonial Section */}
                <Grid item xs={12} sm={12}>
                    <TestimonialSectionFirst />
                </Grid>

                 {/* Slide Blog section */}
                 <Grid item xs={12} sm={12}>
                    <SlideBlogSection />
                </Grid> 

                {/* Our story section */}
                {/* <Grid item xs={12} sm={12}>
                    <OurStorySection />
                </Grid> */}

                {/* FAQ section */}
                <Grid item xs={12} sm={12}>
                    <Questions />
                </Grid>

                {/* Stay touch section */}
                {/* <Grid item xs={12} sm={12}>
                    <StayInTouch />
                </Grid> */}
            </Grid>
            <NewsLatterSection />
            <NewFooter />
        </React.Fragment>
    )
}

export default CommonLandingPage