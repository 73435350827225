import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { imageURL } from "../../../Constants/commonURLS";
import { ReferNowModal } from "./ReferModal";
import getSymbolFromCurrency from "currency-symbol-map";
import "../../../assets/JobList/AllJobs.css";
import {
  Stack,
  Grid,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Divider,
  Box,
  Avatar,
  Skeleton,
  Tooltip,
  Chip,
  IconButton,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useSelector } from "react-redux";
import { savedJobById } from "../../../helpers/dataFetcher/jobs/jobs";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toast } from "react-hot-toast";
import NotificationDrawer from "../../Notifications/NotificationDrawer";
import JobDrawer from "../../Notifications/JobDrawer";
import { motion } from "framer-motion";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import copy from "copy-to-clipboard";
const options = ["Share"];

import JobBagIcon from "../../../assets/icons/JobBagIcon";
import JobLocationIcon from "../../../assets/icons/JobLocationIcon";
import JobBookIcon from "../../../assets/icons/JobBookIcon";
import JobClockIcon from "../../../assets/icons/JobClockIcon";
import JobUsersIcon from "../../../assets/icons/JobUsersIcon";
import JobShareIcon from "../../../assets/icons/JobShareIcon";

function Lists(props) {
  /* share link code start here */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = React.useState({ id: "" });
  const loginUserId = localStorage.getItem("user_id");
  const openS = Boolean(anchorEl);
  const [jobsData, setJobsData] = useState([]);
  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const location = window.location.host;

  useEffect(() => {
    if (props.jobsList.length > 0) {
      setJobsData(props.jobsList);
    }
    //  else if (props.filterJobslist.length > 0) {

    //   setJobsData(props.filterJobslist)
    // } else if (props?.searchJobsList.length > 0) {
    //   setJobsData(props?.searchJobsList)
    // }
  }, [props.jobsList]);

  const handleClick = (event, id) => {
    setId({ id: id });
    setAnchorEl(event.currentTarget);
    handleCloseOption(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseOption = id => {
    if (loginUserId) {
      copy(
        `${location ?? "https://joinee.com"}/job?id=${id}&userId=${loginUserId}`
      );
    } else {
      copy(`${location ?? "https://joinee.com"}/job?id=${id}`);
    }
    toast.success("Link copied!");
    setAnchorEl(null);
  };

  /* share link code End here */

  const [open, setOpen] = React.useState("");
  const [heartOpen, setHeartOpen] = useState(true);
  const [type, setType] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });

  const user = useSelector(state => state.User.user);

  const abbreviateNumber = number => {
    number = +number;
    const scaledNumber = number / 1000;
    if (number >= 1000) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    } else {
      return `${Number.parseFloat(number).toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };

  function updateToggle(payload, status) {
    const id = payload?.job_post_id;
    const updateStatus = jobsData?.map(each => {
      if (each?.id === id && status === 201) {
        return {
          ...each,
          is_candidate_saved_the_job_post: 1,
        };
      } else if (each?.id == id && status === 200) {
        return {
          ...each,
          is_candidate_saved_the_job_post: 0,
        };
      } else {
        return {
          ...each,
        };
      }
    });
    setJobsData([...updateStatus]);
  }

  const handelSavedJobs = async payload => {
    const { data, status } = await savedJobById(payload);
    if ([200, 201].includes(status)) {
      toast.success(data?.return_message);
    }

    if ([200, 201].includes(status)) {
      updateToggle(payload, status);
    }

    if ([400].includes(status)) {
      toast.error(data?.return_message);
    }
  };

  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("user_id");

  return (
    <>
      <Box>
        <Grid container sx={{ paddingBottom: "20px" }}>
          {jobsData &&
            jobsData?.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{ padding: "10px" }}
                key={item?.id}
              >
                <Stack
                  sx={{
                    width: "100%",
                    border: "0.8px solid #DEDEDE",
                    borderRadius: "16px",
                    padding: "24px",
                    gap: "10px",
                    background: "#F9F9F9",
                    boxShadow: "0px 2px 54px -35px #0000008C",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignContent={"flex-start"}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      alignContent={"flex-start"}
                      sx={{
                        // display: "-webkit-box",
                        WebkitBoxOrient: "horizontal",
                        overflow: "hidden",
                        width: "100%",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Box
                        sx={{
                          height: "44px",
                          width: "44px",
                          minWidth: "44px",
                          borderRadius: "50%",
                          border: "0.5px solid rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Avatar
                          src={`${imageURL}${item?.company_details?.company_image}`}
                          style={{
                            borderRadius: "50%",
                            borderColor: "rgba(0, 0, 0, 0.25)",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          {item?.company_details?.company_name?.charAt(0)}
                        </Avatar>
                      </Box>
                      <Box
                        sx={{
                          // flexShrink: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",

                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#121212",
                          fontFamily: "Inter,sans-serif",
                        }}
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                      >
                        <Typography
                          noWrap
                          onClick={() =>
                            navigate(
                              `/job?id=${item?.id}&userId=${userId ?? ""}`
                            )
                          }
                        >
                          {item?.job_title ?? ""}
                          {/* {item?.job_title?.length <= 20
                          ? item?.job_title
                          : item?.job_title?.slice(0, 18) + ".."} */}
                        </Typography>
                        <Typography
                          noWrap
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          sx={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#121212",
                            opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                          }}
                          onClick={() =>
                            navigate(
                              `/employer-single-list?id=${item?.company_user_id}`
                            )
                          }
                        >
                          {item?.company_details?.company_name}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row">
                      <Box>
                        <Tooltip arrow placement="top" title={`
                        ${item?.job_post_refer_now_commission?.length > 0 && "Refer Now Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_now_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_now_commission?.[0]?.payout?.split("_")?.join(" ") || "")}
                        ${(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "") ? "- Refer Self Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_self_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_self_commission?.[0]?.payout?.split("_")?.join(" ") || "") : ""}
                      `}>
                          <Typography noWrap
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            sx={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "16px",
                              fontWeight: "Bold",
                              color: "#066a4c",
                              textAlign: "center",
                              // opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            {getSymbolFromCurrency(item?.currency) || ""}

                            {/* {item?.max_sal_by_am <= 1000
                          ? item?.max_sal_by_am
                          : abbreviateNumber(
                            (item?.max_sal_by_am * item?.commission_rate) /
                            100
                          )} */}

                            {item?.job_post_refer_now_commission?.length > 0 && (
                              <span style={{ color: "#066a4c" }}>
                                {abbreviateNumber(item?.job_post_refer_now_commission[0]?.flat_rate)}
                                {/* /{item?.job_post_refer_now_commission[0]?.payout?.split("_")?.[1]?.[0]} */}
                              </span>
                            )}
                            {(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "") ? (
                              <span style={{ color: "rgb(212, 133, 49)", marginLeft: "4px" }}>
                                {"- "}
                                {getSymbolFromCurrency(item?.currency) || ""}
                                {abbreviateNumber(item?.job_post_refer_self_commission[0]?.flat_rate)}
                              </span>
                            ) : ""}

                          </Typography>
                        </Tooltip>
                        <Typography noWrap
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          sx={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "13px",
                            fontWeight: "500",
                            color: "#121212",
                            fontFamily: "Inter,sans-serif",
                          }}
                        >Earn Upto</Typography>
                      </Box>

                      {/* <Box>
                      {item?.job_title && (
                        <JobDrawer
                          id={item.id}
                          isDrawerOpen={isDrawerOpen}
                          setDrawerOpen={setDrawerOpen}
                          title={item.job_title}
                        />
                      )}
                    </Box> */}
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      gap: "10px",
                      "& hr": {
                        height: "22px",
                      },
                    }}
                  >
                    <Stack
                      spacing={1}
                      sx={{
                        WebkitBoxOrient: "horizontal",
                        overflow: "hidden",
                        width: "100%",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                      }}
                      direction="row"
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                          flexShrink: 0,
                          overflow: "hidden",
                          gap: "5px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <JobBagIcon />
                        <Typography
                          noWrap
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {`${item?.min_exp}${item?.min_exp_months > 0
                              ? `.${item?.min_exp_months}`
                              : ""
                            }`}
                          -
                          {`${item?.max_exp}${item?.max_exp_months > 0
                              ? `.${item?.max_exp_months}`
                              : ""
                            }`}
                          Yrs
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem />
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                          flexShrink: 0,
                          overflow: "hidden",
                          gap: "5px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {item?.job_type?.replace("_", " ")}
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem />
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          flexShrink: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          <span style={{ fontSize: "15px", fontWeight: "600" }}>
                            {" "}
                            {getSymbolFromCurrency(item?.currency)
                              ? getSymbolFromCurrency(item?.currency)
                              : ""}{" "}
                          </span>
                          {/* {Math.round(item.min_sal / 1000, 2)}k-
                          {Math.round(item.max_sal / 1000, 2)}k
                          {`/${item?.payout}`} */}
                          {item?.min_sal_by_am <= 1000
                            ? item?.min_sal_by_am
                            : `${Math.round(item?.min_sal_by_am / 1000, 2)}k`}
                          -
                          {item?.max_sal_by_am <= 1000
                            ? item?.max_sal
                            : `${Math.round(item?.max_sal_by_am / 1000, 2)}k`}
                          {/* {`/${item?.payout}`} */}
                          {item?.job_type && item?.job_type === "Full_Time"
                            ? "/Year"
                            : item?.job_type === "Contract"
                              ? "/Hour"
                              : ""}
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem />
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          // flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          gap: "5px",
                        }}
                      >
                        <JobLocationIcon />
                        <Typography
                          noWrap
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {/* {item?.location?.length <= 17 ? item?.location : item?.location?.slice(0, 35) + ".."} */}
                          {item?.location}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          // flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          gap: "5px",
                        }}
                      >
                        <JobBookIcon />
                        <Typography
                          noWrap
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {item?.educational_qualification_details?.map(
                            (elem, index) => (
                              <span key={index}>
                                {elem?.education_qualification_spec_id?.name}/
                                {elem?.education_qualification_id?.name}.{" "}
                              </span>
                            )
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignContent="center"
                        spacing={1}
                        sx={{
                          // flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography noWrap>
                          {item?.key_skill_details?.map(each => (
                            <>
                              &#x2022;{" "}
                              <span
                                style={{
                                  backgroundColor: "#E9F0EE",
                                  borderRadius: "4px",
                                  fontSize: "14px",
                                  padding: "3px",
                                }}
                              >
                                {each?.name}
                              </span>{" "}
                            </>
                          ))}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      spacing={1}
                      sx={{
                        WebkitBoxOrient: "horizontal",
                        overflow: "hidden",
                        width: "100%",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                      }}
                      direction="row"
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          flexShrink: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          gap: "5px",
                        }}
                      >
                        <JobClockIcon />
                        <Typography
                          noWrap
                          sx={{ fontSize: "13.5px", fontWeight: "500" }}
                        >
                          {Math.floor(
                            Math.abs(
                              new Date() -
                              new Date(
                                item?.createdAt
                                  .slice(0, 10)
                                  .toLocaleString()
                                  .replace(/-/g, "/")
                              )
                            ) /
                            (1000 * 3600 * 24)
                          )}{" "}
                          days ago
                        </Typography>
                      </Stack>
                      <span>&#x2022; </span>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          flexShrink: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          gap: "5px",
                        }}
                      >
                        <JobUsersIcon />
                        <Typography
                          noWrap
                          sx={{ fontSize: "13.5px", fontWeight: "500" }}
                        >
                          {" "}
                          {`${item?.applicants_count}`} Applicants
                        </Typography>
                      </Stack>
                      <span>&#x2022; </span>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          // flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{ fontSize: "13.5px", fontWeight: "500" }}
                        >
                          {Math.floor(
                            Math.abs(
                              new Date() -
                              new Date(
                                item?.application_deadline
                                  ?.slice(0, 10)
                                  ?.toLocaleString()
                                  ?.replace(/-/g, "/")
                              )
                            ) /
                            (1000 * 3600 * 24)
                          ) -
                            Math.floor(
                              Math.abs(new Date() - new Date()) /
                              (1000 * 3600 * 24)
                            )}{" "}
                          days left to apply
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      borderTop: "0.8px dashed #BABABA",
                      paddingTop: "15px",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Button
                        type="submit"
                        size="small"
                        endIcon={<KeyboardArrowRightOutlinedIcon fontSize="small" sx={{}} />}
                        loading={false}
                        loadingPosition="end"
                        variant="contained"
                        sx={{
                          borderRadius: "8px",
                          boxShadow: "none",
                          background: "var(--app-button-color1)",
                          padding: "4px 8px",
                          fontSize: "14px",
                          "&:hover": {
                            boxShadow: "none",
                            background: "var(--app-button-color1)",
                          }
                        }}

                        onClick={() => {
                          if (
                            token !== undefined &&
                            token !== null &&
                            token !== ""
                          ) {
                            setReferModal({
                              modal: "center",
                              details: item,
                              purpose: "refer",
                            });
                          }
                          else {
                            navigate("/login");
                          }
                        }}
                      >
                        <span style={{ textTransform: "capitalize" }}>Refer Job</span>
                      </Button>
                      <Button
                        type="submit"
                        size="small"
                        loading={false}
                        loadingPosition="end"
                        variant="contained"
                        onClick={() => {
                          if (
                            token !== undefined &&
                            token !== null &&
                            token !== ""
                          ) {
                            setReferModal({
                              modal: "center",
                              details: item,
                              purpose: "self",
                            });
                          } else {
                            navigate("/login");
                          }
                        }}
                        sx={{
                          borderRadius: "8px",
                          boxShadow: "none",
                          background: "#E6F0ED",
                          padding: "4px 8px",
                          fontSize: "14px",
                          border: "0.8px solid var(--app-button-color1)",
                          color: "var(--app-button-color1)",
                          "&:hover": {
                            boxShadow: "none",
                            color: "#fff",
                            background: "var(--app-button-color1)",
                          },
                        }}
                      >
                        <span style={{ textTransform: "capitalize" }}>
                          {item?.is_candidate_applied_to_job_post == 0
                            ? "Apply"
                            : "Applied"}
                        </span>
                      </Button>
                    </Stack>
                    <Stack
                      spacing={1}
                      sx={{
                        WebkitBoxOrient: "horizontal",
                        overflow: "hidden",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                        "& hr": {
                          height: "22px",
                        },
                      }}
                      direction="row"
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          // flexShrink: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          gap: "5px",
                          cursor: "pointer",
                          color:
                            item?.is_candidate_saved_the_job_post === 1
                              ? "var(--app-button-color1)"
                              : "#5E6162",
                        }}
                        onClick={() => {
                          if (token) {
                            handelSavedJobs({
                              job_post_id: item?.id,
                              company_id: item?.company_details?.id,
                              type:
                                item?.is_candidate_saved_the_job_post == 0
                                  ? "save"
                                  : "unsave",
                            });
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        {item?.is_candidate_saved_the_job_post === 1 ? (
                          <BookmarkIcon fontSize="small" color="inherit" />
                        ) : (
                          <BookmarkBorderIcon
                            color="inherit"
                            fontSize="small"
                          />
                        )}
                        <Typography
                          noWrap
                          sx={{
                            marginTop: "-5px",
                            color: "#5E6162",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {item?.is_candidate_saved_the_job_post === 1
                            ? "Saved"
                            : "Save Job"}
                        </Typography>
                      </Stack>
                      <Divider orientation="vertical" flexItem />
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          // flexShrink: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={event => handleClick(event, item?.id)}
                      >
                        <JobShareIcon sx={{ color: "#5E6162" }} />{" "}
                        <Typography
                          noWrap
                          sx={{
                            color: "#5E6162",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Share
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            ))}
        </Grid>
      </Box>
      <ReferNowModal
        show={referModal.modal}
        purpose={referModal.purpose}
        onHide={modal =>
          setReferModal({
            modal: modal,
            details: {},
          })
        }
        params={referModal}
        updateApplyAndRefer={props?.updateApplyAndRefer}
      />
    </>
  );
}
export default Lists;
