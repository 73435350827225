export const findAllJobsModel = item => {
  return item?.map(data => {
    const {
      id,
      company_details: { company_image, company_name },
      job_title,
      job_type,
      job_desc,
      requirements: job_req,
      application_deadline,
      company_details,
      company_user_id,
      commission_rate_type,
      commission_rate,
      min_sal,
      min_exp_months,
      max_exp_months,
      max_sal,
      location,
      currency,
      work_mode,
      time_zone,
      min_exp,
      max_exp,
      key_skill_details,
      createdAt,
      no_of_vacancies,
      is_candidate_applied_to_job_post,
      is_candidate_saved_the_job_post,
      payout,
      min_sal_by_am,
      max_sal_by_am,
      ...rest
    } = data;

    return {
      id,
      image: company_image,
      job_title,
      job_type,
      job_desc,
      job_req,
      application_deadline,
      company_name,
      company_details,
      company_id: company_details?.id,
      commission_rate_type,
      commission_rate,
      company_user_id,
      min_sal,
      min_exp_months,
      max_exp_months,
      max_sal,
      location,
      currency,
      work_mode,
      time_zone,
      min_exp,
      max_exp,
      key_skill_details,
      createdAt,
      no_of_vacancies,
      is_candidate_applied_to_job_post,
      is_candidate_saved_the_job_post,
      payout: payout?.replace("_", ""),
      min_sal_by_am,
      max_sal_by_am,
      ...rest,
    };
  });
};

export const findAllFunctionalAreas = item => {
  return item?.map(each => {
    return {
      id: each.id,
      name: each.name,
      countOfJobs: each.job_posts_count,
      isChecked: false,
      image: each.image ?? "",
      skills: each.skills ?? [],
    };
  });
};

export const findAllJobTypes = item => {
  return item?.map((each, i) => {
    return {
      id: i,
      jobType: each.job_type,
      jobPostsCount: each.job_posts_count,
      isChecked: false,
    };
  });
};
export const findSalaryRanageModel = item => {
  return item?.map((each, i) => {
    return {
      id: i,
      salaryRange: each.sal,
      salaryValue: each.sal_value,
      jobsCountOnSalary: each.job_posts_count,
      isChecked: false,
    };
  });
};

export const findAllKeySkills = item => {
  return item?.map(each => {
    return {
      id: each.id,
      skill: each.name,
      jobsCount: each.job_posts_count,
      isChecked: false,
    };
  });
};

export const findAllWorkModes = item => {
  return item?.map(each => {
    return {
      workModeValue: each.dbValue,
      workModeShown: each.shownValue,
      workModeCount: each.work_mode_count,
      isChecked: false,
    };
  });
};

export const findBlogsModel = item => {
  return item?.map(each => {
    return {
      id: each.id,
      img: each.image,
      name: each.title,
      desc: each.description,
    };
  });
};
