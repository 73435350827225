export const formatdate = obj => {
    const dateString = obj;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const formattedDate = `${day} - ${month} - ${year}`;
    return formattedDate;

  };


export function formatDate2(dateString) {
    if(!dateString || dateString === "") {
      return ""
    }
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); 
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}
