import React, { useState } from "react";
import MyModal from "./components/Modal";
import OtpInput from "react18-input-otp";
import CountdownTimer from "../../Authentication/CountDown/CountdownTimer";
import { verifyOTP } from "../../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";

export default function OtpVerificationModal({ open, handleClose, email }) {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [hasError, setHasError] = useState(false);
    const handleChange = code => setOtp(code);
    const handleOTP = async () => {
        const { status } = await verifyOTP(email, otp);
        if (status !== 201) {
            setHasError(true);
            toast.error("invalid otp");
            return;
        }
        handleClose();
        navigate("/login");
        setHasError(false);
    };
    return (
        <MyModal
            open={open}
            handleClose={handleClose}
            sx={{ height: "fit-content" }}
            showCloseIcon
            title="Enter OTP"
            size="medium"
        >
            <Stack mb={2} mt={2}>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    shouldAutoFocus
                    isInputNum={true}
                    inputStyle="otp_input_items"
                    errorStyle={"otp_input_items_error"}
                    hasErrored={hasError}
                    focusStyle={"otp_input_items_focus"}
                    separator={<span> </span>}
                    onSubmit={handleOTP}
                />
            </Stack>

            <CountdownTimer email={email} totalSec={20 * 10000} />

            <Stack direction="row" justifyContent={"flex-end"}>
                <Button
                    type="submit"
                    size="large"
                    loading={false}
                    onClick={handleOTP}
                    loadingPosition="end"
                    variant="contained"
                    disabled={otp?.length !== 6}
                    sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                        padding: "6px 4px",
                        WebkitBoxOrient: "horizontal",
                        overflow: "hidden",
                        width: "100%",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                        "&:hover": {
                            boxShadow: "none",
                            background: "var(--app-button-color1)",
                        }
                    }}
                >
                    <span style={{ textTransform: "capitalize" }}>Verify OTP</span>
                </Button>

            </Stack>
        </MyModal>
    );
}
