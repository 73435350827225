import {
  Stack,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import AddEditEducation from "./AddEditEducation";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
import { useDispatch, useSelector } from "react-redux";

import JobBagIcon from "../../../assets/icons/JobBagIcon";
import JobLocationIcon from "../../../assets/icons/JobLocationIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProfileUpResumeIcon from "../../../assets/icons/ProfileUpResumeIcon";
import ProfileEduCardIcon from "../../../assets/icons/ProfileEduCardIcon";
import { openModal } from "../../../components/modals/modalsSlice";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { formatDate2 } from "../../../helpers/common/FormateDate";

export default function EducationInformation({ setRecall, reCall }) {
  const [educationModel, setEducationModel] = useState(false);
  const [editEducationModel, setEditEducationModel] = useState(false);
  const [updateType, setUpdateType] = useState("create");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [currentId, setCurrentId] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //redux dispatch
  const dispatch = useDispatch();

  const accountData = useSelector(state => state.User.user);

  function toggleModel() {
    setEducationModel(!educationModel);
  }
  function editToggleModel() {
    setEditEducationModel(!editEducationModel);
  }

  async function updateOrEditEducationalDetails(ed_data) {
    const isCurrPursuing = ed_data?.is_currently_pursuing;

    if (updateType?.includes("update")) {
      const obj1 = { ...ed_data };
      let updatedData;
      if (isCurrPursuing) {
        updatedData = accountData?.courses
          ?.map(item => ({
            ...item,
            is_currently_pursuing: false,
            end_date: moment(new Date()).format("MM/DD/YYYY"),
          }))
          ?.filter(item => item.id !== currentId);
      } else {
        updatedData = [...accountData?.courses]?.filter(
          item => item.id !== currentId
        );
      }

      const updatedEduData = Array.isArray(updatedData) ? updatedData : [];
      const obj = {
        courses: [...updatedEduData, obj1],
      };

      await updateUserProfileDetails("about_me", obj);
      setRecall(!reCall);
    } else {
      let updatedData;
      if (isCurrPursuing) {
        updatedData = accountData?.courses?.map(item => ({
          ...item,
          is_currently_pursuing: false,
          end_date: moment(new Date()).format("MM/DD/YYYY"),
        }));
      } else {
        updatedData = accountData?.courses;
      }
      const updatedEduData = Array.isArray(updatedData) ? updatedData : [];
      const obj = {
        courses: [...updatedEduData, { ...ed_data, id: uuidv4() }],
      };

      await updateUserProfileDetails("about_me", obj);
      setRecall(!reCall);
    }

    toggleModel();
  }

  /* Remove work experience code start here */
  const removeEducation = async id => {
    const filterEducation = [...accountData?.courses]?.filter(
      item => item.id !== id
    );

    const obj = {
      courses: [...filterEducation],
    };

    await updateUserProfileDetails("about_me", obj);

    setTimeout(() => {
      setRecall(!reCall);
    }, [1000]);
  };

  /* Remove work experience code End here */

  const handleClick = (event, index, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = event => {
    event.stopPropagation();

    setUpdateType(`update${updateIndex}`);
    toggleModel();
    handleClose();
  };

  return (
    <>
      <Grid container sx={{ paddingBottom: "20px" }}>
        {accountData?.courses &&
          accountData?.courses?.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ padding: "10px", position: "relative" }}
              key={item?.id}
            >
              <Stack
                sx={{
                  width: "100%",
                  border: "0.8px solid #DEDEDE",
                  borderRadius: "16px",
                  padding: "24px",
                  gap: "10px",
                  background: "#F9F9F9",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignContent={"flex-start"}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignContent={"flex-start"}
                    sx={{
                      // display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Box
                      sx={{
                        height: "44px",
                        width: "44px",
                        borderRadius: "50%",
                        border: "0.5px solid rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Avatar
                        src={`${item?.institute_image}`}
                        style={{
                          borderRadius: "50%",
                          borderColor: "rgba(0, 0, 0, 0.25)",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {item?.institute
                          ? item?.institute?.charAt(0)
                          : item?.institute_name}
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        // flexShrink: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "15px",
                        fontWeight: "700",
                        color: "#121212",
                        fontFamily: "Inter,sans-serif",
                      }}
                      fontWeight="md"
                      textColor="primary.plainColor"
                      mb={0.5}
                    >
                      <Typography noWrap>
                        {item?.certificate
                          ? item?.certificate?.length <= 20
                            ? item?.certificate
                            : item?.certificate?.slice(0, 18) + ".."
                          : item?.certificate_name?.length <= 20
                          ? item?.certificate_name
                          : item?.certificate_name?.slice(0, 18) + ".."}
                      </Typography>
                      <Typography
                        noWrap
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "#121212",
                          opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {item?.institute
                          ? item?.institute
                          : item?.institute_name ?? "-"}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row">
                    <Box sx={{ width: "20px" }}>
                      <IconButton
                        size="small"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={event => handleClick(event, index, item?.id)}
                        sx={{
                          position: "absolute",
                          top: "20px",
                          right: "15px",
                        }}
                      >
                        <MoreVertIcon fontSize="inherit" />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => {
                          handleClose();
                        }}
                        MenuListProps={{
                          "aria-labelledby": `edu-${index}-menu`,
                        }}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                            mt: 1.5,
                            width: "150px",
                            "& .MuiAvatar-root": {
                              width: 150,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={event => {
                            handleEditClick(event);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={event => {
                            dispatch(
                              openModal({
                                modalName: "confirmationModal",
                                modalProps: {
                                  title: "Are you sure?",
                                  message:
                                    "Are you sure you want to delete the selected course?",
                                  onConfirm: () => {
                                    removeEducation(currentId);
                                    handleClose();
                                  },
                                  onCancel: () => {},
                                },
                              })
                            );
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    gap: "10px",
                    "& hr": {
                      height: "22px",
                    },
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                    }}
                    direction="row"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        gap: "5px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.cource_type}
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobLocationIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {item?.location}
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        gap: "5px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {`${
                          item?.start_date
                            ? formatDate2(item?.start_date)
                            : "Start Date"
                        }`}
                        {" - "}
                        {`${
                          item?.is_currently_pursuing
                            ? "Till Date"
                            : item?.end_date
                            ? formatDate2(item?.end_date)
                            : "-"
                        }`}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobBagIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        <span>{item?.specialization}</span>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <ProfileEduCardIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        <span>{item?.desc}</span>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignContent="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography noWrap>
                        {item?.skill?.map(each => (
                          <>
                            &#x2022;{" "}
                            <span
                              style={{
                                backgroundColor: "#E9F0EE",
                                borderRadius: "4px",
                                fontSize: "14px",
                                padding: "3px",
                              }}
                            >
                              {each?.skillName?.name}
                            </span>{" "}
                          </>
                        ))}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          ))}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ padding: "10px", minHeight: "200px" }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              minHeight: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              padding: "24px",
              gap: "10px",
              background: "#F9F9F9",
            }}
            justifyContent="center"
            alignItems="center"
            direction="column"
            onClick={() => {
              toggleModel();
              setUpdateType("create");
            }}
          >
            <Box>
              <ProfileUpResumeIcon />
            </Box>
            <Typography
              textAlign={"center"}
              sx={{
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              + Add more
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <AddEditEducation
        toggleModel={toggleModel}
        open={educationModel}
        type={updateType}
        currentId={currentId}
        // currentData={accountData?.courses[updateIndex]}
        updateOrEditWorExperience={updateOrEditEducationalDetails}
      />
    </>
  );
}
