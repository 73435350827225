import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Accounts/Sidebar/Sidebar";
// import chatImg from "../../assets/images/chat.svg";
import chatImg from "../../assets/images/chatShiyft2.png";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import "./DemoChat.css";
import {
  findChatBasedOnId,
  getAllChatsByChannel,
  getOnlineUsers,
  sendUserMessages,
} from "../../helpers/dataFetcher/chats/chats";
import { getAllAppliedJobsList } from "../../helpers/dataFetcher/chats/chats";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { get_chat_active_user_list, deleteChatActiveUser } from "../../helpers/sockets";
import DemoChatSidebarDummy from "./DemoChatSidebar2";
import DemoChatMainDummy from "./DemoChatMainDummy";
import { useParams } from "react-router-dom";
import { Box, Grid, Stack } from '@mui/material';
import messageSent from '../../assets/alerts/tick2.mp3'
import { socket } from "../../Sockets/Socket";
import { useDebounce } from "../../hooks/useDebounce";

function ChatChannelIndex() {
  const [text, setText] = useState("");
  const [attachment, setAttachment] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [job, setJob] = useState();
  const [jobDesc, setJobDesc] = useState(false);
  const [selectedChannelData, setSelectedChannelData] = useState([]);
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState([])
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mesFromEvent, setMesFromEvent] = useState(true);

  const [channelsPage, setChannelsPage] = useState(false);
  const [selectedChannelDetails, setSelectedChannelDetails] = useState({});


  const localUserId = (localStorage.getItem("user_id"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [onlineUser, setOnlineUser] = useState(null);

  const [currPage, setCurrPage] = useState(1);

  const pageSize = 15;

  const userJobPostIds = searchParams.get("user-job-post-id") ?? "";
  const jobPostIds = searchParams.get("job-post-id") ?? "";
  const channelIDs = searchParams.get("channel");
  const chatQuery = searchParams.get("chat_query") ?? "";
  const receiverIds = searchParams.get("sender_id");

  const chatDebouncedQuery = useDebounce(chatQuery, 500);


  if (!userJobPostIds || !jobPostIds) {
    navigate("/demochat")
  }


  const userData = useSelector(state => state.User);


  // Handle Event when user is cloding the tab or window
  async function getOnlineUsersList() {
    if (document.visibilityState === 'visible') {
      const { data, status } = await getOnlineUsers();
      if (status === 200) {
        const activeUserId = data?.data?.filter(each => each.userId === receiverIds);
        setOnlineUser(activeUserId?.length === 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', getOnlineUsersList);

    return () => {
      document.removeEventListener('visibilitychange', getOnlineUsersList);
    };
  }, []);

  // Handle Event when user is cloding the tab or window

  useEffect(() => {
    setUserId(userData.user.id);
  }, [userData]);


  useEffect(() => {
    getjobById(jobPostIds);
  }, [jobPostIds])



  const getjobById = async id => {
    const jobDataList = await getJobPostById(id);
    setJob(jobDataList?.data);
    setJobDesc(true);
  };


  useEffect(() => {
    if (channelIDs && channelIDs !== "") {
      getIncomingMessages();
    }
    getOnlineUsersList();
  }, [currPage, channelIDs]);

  // Empty chat message after channal id changes
  useEffect(() => {
    setCurrPage(1);
    setSelectedChannelData([]);
  }, [channelIDs])

  useEffect(() => {
    if (channelIDs && channelIDs !== "") {
      getIncomingMessages();
    }
    setCurrPage(1);
    setSelectedChannelData([]);
  }, [chatDebouncedQuery])


  const getIncomingMessages = async () => {
    const { data, status } = await getAllChatsByChannel(channelIDs, currPage, pageSize, chatQuery ?? "");

    const sortedData = [...(data?.data_payload?.chats_list || [])].sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setSelectedChannelDetails(data?.data_payload?.sender_details);

    if (!data?.data_payload?.chats_list?.length) {
      return;
    }

    if (data?.pageNo === 1) {
      setSelectedChannelData(sortedData);
    } else {
      setSelectedChannelData((prevItems) => [...prevItems, ...sortedData].sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }));
    }
  };


  const handleListnerEvent = (data) => {
    if (data?.type === "chats") {
      setCurrPage(1);
      getIncomingMessages();
      setMesFromEvent(!mesFromEvent);
    }
  };


  useEffect(() => {
    const handleReconnect = () => {
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);




  const playMessageSentSound = () => {
    const audio = new Audio(messageSent);
    audio.play();
  };

  const sendUserMessage = async (attachment) => {
    setCurrPage(1);
    let type
    if (attachment?.length > 0) {
      const extension = attachment.split(".")[1]
      const isExtension = /\.(jpe?g|png|jpeg|gif|)$/i.test(attachment);
      const fileExtension = /\.(pdf|docx)$/i.test(attachment);
      if (isExtension) {
        type = "image"
      } else {
        type = extension
      }

    } else {
      const isExtension = /\.(jpe?g|png|jpeg|gif|pdf|docx|json)$/i.test(text);
      type = "text"
    }
    const data = {
      user_job_post_id: userJobPostIds,
      receiver_id: receiverIds,
      job_post_id: jobPostIds,
      message: text ? text : attachment,
      message_type: text ? "text" : attachment ? type : "text"
    };
    if (data.message !== "") {
      const { status } = await sendUserMessages(data);

      if (receiverIds) {
        if (status === 200) {
          playMessageSentSound();
          // socket.emit("message", {
          //   type: "chats",
          //   userId: [receiverIds],
          //   message: text
          // })
        }
      }

    }
    setText("");
    setAttachment("");
    getIncomingMessages();
    setChannelsPage(true)
  };


  return (
    <>
      <Grid container sx={{ height: "85vh" }}>
        <Grid
          item
          xs={12}
          sm={4.5}
          sx={{
            height: "100%",
          }}
        >
          <Stack
            direction="column"
            sx={{
              height: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0px 2px 54px -35px #0000008C",
              overflow: "hidden"
            }}
          >
            <DemoChatSidebarDummy
              // setReceiverId={setReceiverId}
              channelsPage={channelsPage}
              setChannelsPage={setChannelsPage}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7.5} sx={{
          paddingLeft: "15px",
          height: "100%",
          "@media (max-width: 600px)": {
            paddingLeft: "0px",
            marginTop: "10px"
          },
        }}>
          <Stack
            direction="column"
            sx={{
              width: "100%",
              height: "100%",
              minHeight: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0px 2px 54px -35px #0000008C",
              overflow: "hidden"
            }}

          >
            {channelIDs ? (
              <DemoChatMainDummy
                setShowPicker={setShowPicker}
                showPicker={showPicker}
                setText={setText}
                selectedChannelDetails={selectedChannelDetails}
                text={text}
                selectedChannelData={selectedChannelData}
                userId={userId}
                sendUserMessage={sendUserMessage}
                setAttachment={setAttachment}
                userStatus={userStatus}

                setCurrPage={setCurrPage}
                onlineUser={onlineUser}
                currPage={currPage}
                mesFromEvent={mesFromEvent}
              />

            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                  <div className="contact_no_show">
                    <img src={chatImg} alt="chat" />
                    <p>You haven't selected any chat yet..</p>
                    {/* <span className="pat-20 end_to_end">
                          End to End Encrypted
                        </span> */}
                  </div>
                </div>
              </div>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default ChatChannelIndex;
