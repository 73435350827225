import axios from "axios";
import callApi from "../../callApi";

export const resumeParser = async (file, token) => {
  const formData = new FormData();
  formData.append("resumecontent", file);
  formData.append("Authorization", token);

  // console.log(formData,"resumefile")
  //console.log(file,"fileeee")
  // console.log(token,"tokennn")

  return await axios({
    method: "POST",
    url: "https://zepul-resume-parser.codeace.us/home/resume_parser_details",
    data: formData,
  });
};

export const newResumeParser = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await callApi(`/resume-parser`, formData, "POST");
};
