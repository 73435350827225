  import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "accounts",
  initialState: {
    user: {},
    accGlobalLoad: true,
  },
  reducers: {
    // action to handle accounts
    updateUserAccount: (state, action) => {
      state.user = action.payload.user;
    },
    updateLoadAccount: (state, action) => {
      state.accGlobalLoad = action.payload.accGlobalLoad;
    },


  },
});

// Action creators are generated for each case reducer function
export const { updateUserAccount, updateLoadAccount } = accountSlice.actions;

export default accountSlice.reducer;
