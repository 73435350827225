import React from 'react';
import { Grid, Stack, Typography, Box, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from '../../../../assets/icons/LandingPage/ArrowIcon';
import { datailAllItem } from '../../../../data/blogData';

function OurStorySection() {

    const navigate = useNavigate();

    console.log(datailAllItem, "datailAllItem")

    const storyData = [
        {
            id: "1",
            image: "https://img.freepik.com/free-photo/male-female-business-people-working-tablet-office_1303-22826.jpg?w=2000&t=st=1703843662~exp=1703844262~hmac=379e2a13fe77c20b2a3bd8ca036865b209d68670eeaeac17e08a002b3118bcfc",
            title: "How to : Make a Budgeting Plan for Business",
            desc: "Some people maybe still confusing to arrange budgeting for their Business."
        },
        {
            id: "2",
            image: "https://img.freepik.com/free-photo/male-female-business-people-working-tablet-office_1303-22826.jpg?w=2000&t=st=1703843662~exp=1703844262~hmac=379e2a13fe77c20b2a3bd8ca036865b209d68670eeaeac17e08a002b3118bcfc",
            title: "Can We Build house with no Planning before?",
            desc: "Some people maybe still confusing to arrange budgeting for their Business"
        },
        {
            id: "3",
            image: "https://img.freepik.com/free-photo/male-female-business-people-working-tablet-office_1303-22826.jpg?w=2000&t=st=1703843662~exp=1703844262~hmac=379e2a13fe77c20b2a3bd8ca036865b209d68670eeaeac17e08a002b3118bcfc",
            title: "Choosing Material. Main Path of Architecture",
            desc: "Some people maybe still confusing to arrange budgeting for their Business."
        },
        
    ]

    return (
        <Grid container sx={{ paddingTop: "50px", paddingBottom: "100px" }}>
            <Grid item xs={12} sm={12} >
                <Stack sx={{ width: "100%", margin: "auto"}} spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Chip label="ARTICLE" onClick={() => { }}
                            sx={{
                                background: "#E6F0ED",
                                '& .MuiChip-label': {
                                    fontWeight: "700",
                                },
                            }}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            sx={{
                                fontSize: "45px",
                                fontWeight: "500",
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                            }}
                        >Check Our Recent Articles</Typography>
                        <Box
                            sx={{
                                border: "1px solid var(--app-button-color1)",
                                color: "#000",
                                padding: "10px 25px",
                                fontSize: "15px",
                                borderRadius: "50px",
                                fontWeight: "700",
                                background: "#fff",
                                width: "fit-content",
                                cursor: "pointer",
                                "&:hover": {
                                    background: "var(--app-button-color1)",
                                    color: "#fff",
                                }
                            }}
                            onClick={() => {
                                navigate("/blogs")
                            }}
                        >Go to Our Blog</Box>

                    </Stack>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
            >
                <Grid container sx={{
                    width: "100%", margin: "auto",
                    display: "flex",
                }}
                >
                    {datailAllItem?.slice(0,3)?.map((data, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={index}
                        >
                            <Stack
                                sx={{
                                    borderRadius: "10px",
                                    // boxShadow:
                                    //     "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                    minHeight: "300px"
                                }}
                                direction="column"
                                m={1}
                                spacing={1}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "fit-content",
                                        borderRadius: "10px",
                                        overflow: "hidden"
                                    }}
                                >
                                    <img src={data?.headImage}
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            maxHeight: "100%",
                                            borderRadius: "10px"
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "24px",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word"
                                        }}
                                    >{data?.headTitle}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word",
                                            color: "#5A7184"
                                        }}
                                    >{data?.desc}</Typography>
                                </Box>
                                <Link
                                    to={`/blog/${data?.id}`}
                                    style={{
                                        color: "var(--text-color)",
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        marginTop: "10px"
                                    }}
                                >Read more <ArrowIcon /> </Link>
                            </Stack>

                        </Grid>
                    ))}

                </Grid>
            </Grid>

        </Grid>
    )
}

export default OurStorySection;