import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy";
import { imageURL } from "../../../Constants/commonURLS";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";

import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import copy from "copy-to-clipboard";
import JobBagIcon from "../../../assets/icons/JobBagIcon";
import JobLocationIcon from "../../../assets/icons/JobLocationIcon";
import JobBookIcon from "../../../assets/icons/JobBookIcon";
import JobClockIcon from "../../../assets/icons/JobClockIcon";
import JobUsersIcon from "../../../assets/icons/JobUsersIcon";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Stack,
  Grid,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Divider,
  Box,
  Avatar,
  Skeleton,
  Tooltip,
  Chip,
  IconButton,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import JobInfoDescription from "../../BrowseJobsList/JobInfoDescription";

function SavedJobDescription(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const selectedUserJobPostId = searchParams.get("user-job-post-id");

  const abbreviateNumber = number => {
    number = +number;
    const scaledNumber = number / 1000;
    if (number >= 1000) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    } else {
      return `${Number.parseFloat(number).toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };

  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("user_id");

  const handleSendJobId = async data => {
    navigate(
      `/jobchat?user-job-post-id=${selectedUserJobPostId}&job-post-id=${data.job_post_id}`
    );
  };

  const location = window.location?.pathname;

  return (
    <React.Fragment>
      {props?.jobDetails?.data_payload &&
        [props?.jobDetails?.data_payload].map((item, index) => (
          <Stack
            sx={{
              width: "100%",
              height: "fit-content",
              minHeight: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              gap: "10px",
              background: "#fff",
              boxShadow: "0px 2px 54px -35px #0000008C",
              overflow: "hidden",
            }}
            key={item?.id}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignContent={"flex-start"}
              sx={{
                borderBottom:
                  location === "/demochat" ? "1px solid #E9E9E9" : "",
                padding: "24px",
                paddingTop: "15px",
                paddingBottom: location === "/demochat" ? "15px" : "0px",
                background: "#fff",
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignContent={"flex-start"}
                sx={{
                  // display: "-webkit-box",
                  WebkitBoxOrient: "horizontal",
                  overflow: "hidden",
                  width: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textOverflow: "ellipsis",
                }}
              >
                <Box
                  sx={{
                    height: "44px",
                    width: "44px",
                    borderRadius: "50%",
                    border: "0.5px solid rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Avatar
                    src={`${imageURL}${item?.company_details?.company_image}`}
                    style={{
                      borderRadius: "50%",
                      borderColor: "rgba(0, 0, 0, 0.25)",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {item?.company_details?.company_name?.charAt(0)}
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    // flexShrink: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#121212",
                    fontFamily: "Inter,sans-serif",
                  }}
                  fontWeight="md"
                  textColor="primary.plainColor"
                  mb={0.5}
                >
                  <Typography
                    noWrap
                    onClick={() =>
                      navigate(`/job?id=${item?.id}&userId=${userId ?? ""}`)
                    }
                  >
                    {item?.job_title}
                  </Typography>
                  <Typography
                    noWrap
                    fontWeight="md"
                    textColor="primary.plainColor"
                    mb={0.5}
                    sx={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#121212",
                      opacity: "50%",
                      fontFamily: "Inter,sans-serif",
                    }}
                    onClick={() =>
                      navigate(
                        `/employer-single-list?id=${item?.company_user_id}`
                      )
                    }
                  >
                    {item?.company_details?.company_name}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                sx={{ width: "fit-content" }}
              >
                {location === "/demochat" ? (
                  <Button
                    type="submit"
                    size="large"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSendJobId({
                        user_job_post_id: item?.id,
                        job_post_id: item?.id,
                      })
                    }}
                    loading={false}
                    loadingPosition="end"
                    variant="contained"
                    endIcon={<KeyboardArrowRightIcon />}
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      width: "100%",
                      minWidth: "200px",
                      background: "var(--app-button-color1)",
                      padding: "5px 10px",
                      wordWrap: "noWrap",
                      "&:hover": {
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                      }
                    }}
                  >
                    <span style={{ textTransform: "capitalize", fontWeight: "600", marginRight: "10px" }}>My Conversations</span>
                  </Button>
                ) : (
                  <Box>
                    <Tooltip arrow placement="top" title={`
                                            ${item?.job_post_refer_now_commission?.length > 0 && "Refer Now Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_now_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_now_commission?.[0]?.payout?.split("_")?.join(" ") || "")}
                                        ${(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "") ? "- Refer Self Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_self_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_self_commission?.[0]?.payout?.split("_")?.join(" ") || "") : ""}
                                        `}>
                      <Typography noWrap
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "18px",
                          fontWeight: "Bold",
                          color: "#066a4c",
                          textAlign: "center",
                          // opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {getSymbolFromCurrency(item?.currency) || ""}

                        {item?.job_post_refer_now_commission?.length > 0 && (
                          <span style={{ color: "#066a4c" }}>
                            {abbreviateNumber(item?.job_post_refer_now_commission[0]?.flat_rate)}
                          </span>
                        )}
                        {(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "") ? (
                          <span style={{ color: "rgb(212, 133, 49)", marginLeft: "4px" }}>
                            {"- "}
                            {getSymbolFromCurrency(item?.currency) || ""}
                            {abbreviateNumber(item?.job_post_refer_self_commission[0]?.flat_rate)}
                          </span>
                        ) : ""}

                      </Typography>
                    </Tooltip>
                    <Typography noWrap
                      fontWeight="md"
                      textColor="primary.plainColor"
                      mb={0.5}
                      sx={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#121212",
                        fontFamily: "Inter,sans-serif",
                      }}
                    >Earn Upto</Typography>
                  </Box>
                )}
              </Stack>
            </Stack>
            <Box
              sx={{
                padding: "24px",
                paddingTop: "10px",
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Stack
                  sx={{
                    gap: "10px",
                    "& hr": {
                      height: "22px",
                    },
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                    }}
                    direction="row"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        gap: "5px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <JobBagIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {`${item?.min_exp}${item?.min_exp_months > 0
                            ? `.${item?.min_exp_months}`
                            : ""
                          }`}
                        -
                        {`${item?.max_exp}${item?.max_exp_months > 0
                            ? `.${item?.max_exp_months}`
                            : ""
                          }`}
                        Yrs
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        gap: "5px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {item?.job_type?.replace("_", " ")}
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                          {" "}
                          {getSymbolFromCurrency(item?.currency)
                            ? getSymbolFromCurrency(item?.currency)
                            : ""}{" "}
                        </span>
                        {item?.min_sal_by_am <= 1000
                          ? item?.min_sal_by_am
                          : `${Math.round(item?.min_sal_by_am / 1000, 2)}k`}
                        -
                        {item?.max_sal_by_am <= 1000
                          ? item?.max_sal
                          : `${Math.round(item?.max_sal_by_am / 1000, 2)}k`}
                        {/* {`/${item?.payout}`} */}
                        {item?.job_type && item?.job_type === "Full_Time"
                          ? "/Year"
                          : item?.job_type === "Contract"
                            ? "/Hour"
                            : ""}
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobLocationIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {/* {item?.location?.length <= 17 ? item?.location : item?.location?.slice(0, 35) + ".."} */}
                        {item?.location}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobBookIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {item?.educational_qualification_details?.map(
                          (elem, index) => (
                            <span key={index}>
                              {elem?.education_qualification_spec_id?.name}/
                              {elem?.education_qualification_id?.name}.{" "}
                            </span>
                          )
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignContent="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography noWrap>
                        {item?.key_skills_details?.map(each => (
                          <>
                            &#x2022;{" "}
                            <span
                              style={{
                                backgroundColor: "#E9F0EE",
                                borderRadius: "4px",
                                fontSize: "14px",
                                padding: "3px",
                              }}
                            >
                              {each?.name}
                            </span>{" "}
                          </>
                        ))}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {location === "/demochat" ? (
                  <Stack>
                    <Tooltip
                      arrow
                      placement="top"
                      title={`
                                            ${item
                          ?.job_post_refer_now_commission
                          ?.length > 0 &&
                        "Refer Now Commission " +
                        (getSymbolFromCurrency(
                          item?.currency
                        ) || "") +
                        abbreviateNumber(
                          item
                            ?.job_post_refer_now_commission?.[0]
                            ?.flat_rate
                        ) +
                        "/" +
                        (item?.job_post_refer_now_commission?.[0]?.payout
                          ?.split("_")
                          ?.join(" ") || "")
                        }
                                        ${item?.job_post_refer_self_commission
                          ?.length > 0 &&
                          item
                            ?.job_post_refer_self_commission?.[0]
                            ?.flat_rate !== ""
                          ? "- Refer Self Commission " +
                          (getSymbolFromCurrency(
                            item?.currency
                          ) || "") +
                          abbreviateNumber(
                            item
                              ?.job_post_refer_self_commission?.[0]
                              ?.flat_rate
                          ) +
                          "/" +
                          (item?.job_post_refer_self_commission?.[0]?.payout
                            ?.split("_")
                            ?.join(" ") || "")
                          : ""
                        }
                                        `}
                    >
                      <Typography
                        noWrap
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "18px",
                          fontWeight: "Bold",
                          color: "#066a4c",
                          textAlign: "center",
                          // opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        {getSymbolFromCurrency(item?.currency) || ""}

                        {item?.job_post_refer_now_commission?.length > 0 && (
                          <span style={{ color: "#066a4c" }}>
                            {abbreviateNumber(
                              item?.job_post_refer_now_commission[0]?.flat_rate
                            )}
                          </span>
                        )}
                        {item?.job_post_refer_self_commission?.length > 0 &&
                          item?.job_post_refer_self_commission?.[0]?.flat_rate !==
                          "" ? (
                          <span
                            style={{
                              color: "rgb(212, 133, 49)",
                              marginLeft: "4px",
                            }}
                          >
                            {"- "}
                            {getSymbolFromCurrency(item?.currency) || ""}
                            {abbreviateNumber(
                              item?.job_post_refer_self_commission[0]?.flat_rate
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Tooltip>
                    <Typography
                      noWrap
                      fontWeight="md"
                      textColor="primary.plainColor"
                      mb={0.5}
                      sx={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#121212",
                        fontFamily: "Inter,sans-serif",
                      }}
                    >
                      Earn Upto
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderBottom: "0.5px dashed #BABABA",
                  paddingBottom: "15px",
                }}
              >
                <Stack
                  spacing={1}
                  sx={{
                    WebkitBoxOrient: "horizontal",
                    overflow: "hidden",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textOverflow: "ellipsis",
                  }}
                  direction="row"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      flexShrink: 0,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      gap: "5px",
                    }}
                  >
                    <JobClockIcon />
                    <Typography
                      noWrap
                      sx={{ fontSize: "13.5px", fontWeight: "500" }}
                    >
                      {Math.floor(
                        Math.abs(
                          new Date() -
                          new Date(
                            item?.createdAt
                              ?.slice(0, 10)
                              ?.toLocaleString()
                              ?.replace(/-/g, "/")
                          )
                        ) /
                        (1000 * 3600 * 24)
                      )}{" "}
                      days ago
                    </Typography>
                  </Stack>
                  <span>&#x2022; </span>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      flexShrink: 0,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      gap: "5px",
                    }}
                  >
                    <JobUsersIcon />
                    <Typography
                      noWrap
                      sx={{ fontSize: "13.5px", fontWeight: "500" }}
                    >
                      {" "}
                      {`${item?.applicants_count}`} Applicants
                    </Typography>
                  </Stack>
                  <span>&#x2022; </span>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      // flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{ fontSize: "13.5px", fontWeight: "500" }}
                    >
                      {Math.floor(
                        Math.abs(
                          new Date() -
                          new Date(
                            item?.application_deadline
                              ?.slice(0, 10)
                              ?.toLocaleString()
                              ?.replace(/-/g, "/")
                          )
                        ) /
                        (1000 * 3600 * 24)
                      ) -
                        Math.floor(
                          Math.abs(new Date() - new Date()) / (1000 * 3600 * 24)
                        )}{" "}
                      days left to apply
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <JobInfoDescription
                  jobDetails={props.jobDetails?.data_payload}
                />
              </Stack>
              <Stack pt={2}>
                <Typography
                  sx={{
                    paddingBottom: "12px",
                    color: "#000000",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  About Company
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {item?.company_details?.about}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        ))}
    </React.Fragment>
  );
}

export default SavedJobDescription;
