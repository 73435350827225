import { withRouter } from "react-router-dom";
import React, { useState, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone"
import NewOne from "./CropModal";
// const { Row, Col, Card, Form } = require("reactstrap");
import { Row, Col, Card, Form } from 'reactstrap';
import { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { toast } from "react-hot-toast";
import { Box, Modal, Stack, Typography } from "@mui/material";
import {
  BlackTypography,
  BorderedCardActionArea,
  GreyTypography,
  PrimaryButton,
  PrimaryTypography,
} from "../../../assets/MUI/materialUiStyles";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ProfileUpResumeIcon from "../../../assets/icons/ProfileUpResumeIcon";
export default function UploadModel({
  open,
  toggle,
  handleImage,
  doCrop = true,
}) {

  const [crop, setCrop] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    handleAcceptedFiles(acceptedFiles)
  }, [])

  const { acceptedFiles, fileRejections, getRootProps, isDragActive, isFocused, getInputProps } =
    useDropzone({
      accept: {
        'image/*': []
      },
      onDrop
    });




  const handleAcceptedFiles = files => {

    if (files[0]?.size / 1024 / 1024 > 5) {
      toast.error(`File size too large. File size should be less than 5MB`);
      return;
    }

    if (files && Object.values(files).length > 1) {
      toast.error(`Maximum file limit exceeded! Please select only one file.`);
      return;
    }

    if (!files || !files.length) {
      toast.error(`Incorrect file format selected! Please select a valid image file.`);
      return;
    }

    try {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          crop: false,
        })
      );

      setSelectedFiles(files);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {

    }


  };


  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function handleCrop() {
    setCrop(!crop);
  }

  // function handleAcceptedFiles(files) {
  //   if (files.length === 1) {
  //   }
  //   files.map(file =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //       crop: false,
  //     })
  //   );
  //   setSelectedFiles(files);
  // }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "400px",
    height: "fit-content",
    background: "white",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: 24,
  };
  return (
    <Modal
      open={open}
      onClose={toggle}
      aria-labelledby="edit-profile-modal-title"
      aria-describedby="edit-profile-modal-description"
    >
      <Box sx={style}>
        <div className="modal-header my-3">
          <PrimaryTypography variant="h2">Image Upload</PrimaryTypography>
        </div>
        <div className="modal-body">
          <Form>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                // height: "100%"
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  width: "100%",
                  border: `1.29px dashed ${isFocused || isDragActive ? "green" : "#DEDEDE"}`,
                  background: "#F9F9F9",
                  padding: "20px",
                  borderRadius: "20.66px",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Box>
                  <ProfileUpResumeIcon />
                </Box>
                <Box mt={2}>
                  <Typography textAlign={"center"}
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",

                    }}
                  >
                    Click or Drag file(s) here to upload
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography textAlign={"center"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "11px",
                      color: "#A9A7A7",

                    }}
                  >
                    Please only use these following formats: .jpg, .jpeg, .png, .gif, .bmp, .tiff, .svg, .webp <br />
                    Max file size: 5MB | Max files: 1
                  </Typography>
                </Box>
              </Stack>
            </Stack>

            {/* <div className="dropzone">
              <BorderedCardActionArea
                className="dz-message needsclick"
                sx={{ width: "100%" }}
                {...getRootProps()}
              >
                <Stack spacing={2} alignItems={"center"}>
                  <input
                    {...getInputProps()}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <FeatherIcon icon="upload-cloud" size="40" />
                  <BlackTypography variant="h6" component="h6">
                    Drop your Images here or
                  </BlackTypography>
                  <GreyTypography variant="h6" component="h6">
                    Supported File types (20) MB
                  </GreyTypography>{" "}
                </Stack>
              </BorderedCardActionArea>
            </div> */}

          </Form>
        </div>
        <Box mt={4}>
          {selectedFiles.map((f, i) => {
            return (
              <div key={i + "-file"}>
                <Stack className="align-items-center">
                  <Stack className="col-auto">
                    {selectedFiles[0].type.startsWith("image/") ? (
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={URL.createObjectURL(f)}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                  <Stack>
                    <p className="text-muted font-weight-bold" style={{ color: "gray", fontSize: "12px" }}>
                      {f.name}
                    </p>
                    <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>
                      <strong>{formatBytes(f.size)}</strong>
                    </p>
                  </Stack>
                </Stack>
              </div>
            );
          })}
        </Box>
        <div className="modal-footer my-2">
          <PrimaryButton
            sx={{
              width: "150px",
            }}
            onClick={() => {
              if (selectedFiles.length === 0) {
                toast.error(`Please select a file.`);
                return;
              }
              if (!doCrop) {
                handleImage(selectedFiles);
                return;
              }
              if (selectedFiles[0].type.startsWith("image/")) {
                handleCrop();
                return;
              }
              handleImage(selectedFiles);
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
        {selectedFiles?.length > 0 ? (
          <NewOne
            isOpen={crop}
            toggle={handleCrop}
            imageSrc={URL.createObjectURL(selectedFiles[0])}
            type={selectedFiles[0].type}
            handleImage={handleImage}
          />
        ) : (
          <></>
        )}
      </Box>
    </Modal>
  );
}