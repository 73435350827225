import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import google from "../../../assets/images/google.png";
import defaultCompanyImage from "../../../assets/images/company_default_img.svg";
import { imageURL } from "../../../Constants/commonURLS";
import { Avatar } from "@mui/material";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {},
  head: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    color: "#000000",
  },
  spanE: {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "#000000",
  },
  para: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.75)",
  },
  connect: {
    fontFamily: "Satoshi,sans-serif !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px !important",
    color: "rgba(0, 0, 0, 0.75)",
  },
});

function EmployerProfilePage1({ companyList }) {
 
  const classes = useStyles();
  const companyDetails = companyList?.company?.company;
  const urlList = {
    facebook: companyList?.company?.facebook_url
      ? company?.company?.facebook_url
      : "",
    twitter: companyList?.company?.twitter_url
      ? company.company.twitter_url
      : "",
    linkedin: companyList?.company?.linkedin_url
      ? company.company.linkedin_url
      : "",
  };
  const hasData = Object.values(urlList).some(value => value !== "");
  return (
    <>
      <section className="EmployerProfilePage1_sec">
        <div className="emp_prof_main_cont">
          <div className="col-sm-12 col-md-7 col-lg-8">
            <div className="compa_prf_cont1">
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase sx={{ width: 100, height: 100,  borderRadius: "50%", }}>
                    {/* <Img
                      alt="company Image"
                      // src={
                      //   companyDetails?.company_image
                      //     ? companyDetails?.company_image
                      //     : ""
                      // }
                      src={
                        companyDetails?.company_image
                          ? `${imageURL}${companyDetails.company_image}`
                          : defaultCompanyImage
                      }
                      onError={e => {
                        e.target.src = defaultCompanyImage;
                      }}
                      style={{ borderRadius: "50%" }}
                    /> */}
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                        border: "1px solid rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Avatar
                        src={`${imageURL}${companyDetails?.company_image}`}
                        style={{
                          borderRadius: "50%",
                          borderColor: "rgba(0, 0, 0, 0.25)",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {companyDetails?.company_name
                          ? companyDetails.company_name
                          : "NA"}
                      </Avatar>
                    </div>
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} lg={8} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        className={classes.head}
                      >
                        {companyDetails?.company_name
                          ? companyDetails.company_name
                          : "NA"}
                      </Typography>
                      {/* <Stack direction="row" spacing={1} className="stack_cont">
                        <Chip label="Industry" variant="outlined" className={classes.para} sx={{fontFamily:"Inter,sans-serif"}}/>
                        <Chip label="Financial Services" variant="outlined" className={classes.para}/>
                        <Chip label="Fintech Company" variant="outlined" className={classes.para}/>
                        <Chip
                          label="Cloud Storage Services"
                          variant="outlined"
                          className={classes.para}
                        />
                      </Stack> */}
                      {/* {companyList?.company?.user_industry_details[0] ? (
                        <div className="categories_c">
                          <Chip
                            label={
                              companyList?.company?.user_industry_details?.length > 0
                                ? companyList?.company?.user_industry_details[0].name
                                : ""
                            }
                            variant="outlined"
                            className={classes.para}
                            sx={{ fontFamily: "Inter, sans-serif" }}
                          />
                        </div>
                      ) : (
                        ""
                      )} */}
                      <p className="tagline">{companyList?.company?.company?.tagline?companyList.company.company.tagline:""}</p>
                      {companyList?.company?.user_industry_details?.length >
                        0 && (
                        <div className="categories_c">
                          {companyList.company.user_industry_details.map(
                            (industry, index) => (
                              <Chip
                                key={index}
                                label={industry.name}
                                variant="outlined"
                                className={classes.para}
                                sx={{ fontFamily: "Inter, sans-serif" }}
                              />
                            )
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="col-sm-12 col-md-5 col-lg-4">
            {hasData ? (
              <div className="compa_prf_cont2">
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.connect}
                >
                  Connect with{" "}
                  <span className={classes.spanE}>
                    {companyDetails?.company_name
                      ? companyDetails.company_name
                      : ""}
                  </span>
                </Typography>
                <Stack direction="row" spacing={1}>
                  {companyDetails?.company?.twitter_url && (
                    <a
                      className="footer_social_icons"
                      href={`https://${companyDetails?.company?.twitter_url}`}
                      target="_blank"
                    >
                      <FaTwitter size={20} color="#1DA1F2" />
                    </a>
                  )}
                  {companyDetails?.company?.facebook_url && (
                    <a
                      className="footer_social_icons"
                      href={`https://${companyDetails?.company?.facebook_url}`}
                      target="_blank"
                    >
                      <FaFacebook size={20} color="#3b5998" />
                    </a>
                  )}
                  {companyDetails?.company?.linkedin_url && (
                    <a
                      className="footer_social_icons"
                      href={`https://${companyDetails?.company?.linkedin_url}`}
                      target="_blank"
                    >
                      <FaLinkedin size={20} color="#0077B5" />
                    </a>
                  )}
                  {/* <FaTwitter size={20} color="#1DA1F2" />
                <FaFacebook size={20} color="#3b5998" />
                <FaLinkedin size={20} color="#0077B5" /> */}
                </Stack>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
}
2;
export default EmployerProfilePage1;
