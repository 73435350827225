import React from "react";
import { Typography, Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import getSymbolFromCurrency from "currency-symbol-map";

function JobInsights(props) {
  return (
    <Stack pt={4} >
      <Typography
        sx={{
          paddingBottom: "12px",
          color: "#000000",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >Job Role Insights</Typography>

      <Stack
        direction="row"

        sx={{
          gap: "10px",
          flexWrap: "wrap"
        }}>
        <Tooltip title="Job type" placement="top" arrow>
          <Chip
            label={props?.jobDetails?.job_type?.replace("_", " ")}
            sx={{
              background: "#ECFAE2",
              '& .MuiChip-label': {
                fontWeight: "600",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}

          />
        </Tooltip>

        <Tooltip title="Experience" placement="top" arrow>
          <Chip
            label={`${`${props?.jobDetails?.min_exp}${props?.jobDetails?.min_exp_months > 0
              ? `.${props?.jobDetails?.min_exp_months}`
              : ""
              }`} - ${`${props.jobDetails?.max_exp}${props?.jobDetails?.max_exp_months > 0
                ? `.${props?.jobDetails?.max_exp_months}`
                : ""
              }`}Yrs`}
            sx={{
              background: "rgba(8, 23, 83, 0.07)",

              '& .MuiChip-label': {
                fontWeight: "600",
                color: "#081753",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}
          />
        </Tooltip>

        <Tooltip title="Salary range" placement="top" arrow>
          <Chip
            label={`${getSymbolFromCurrency(props?.jobDetails?.currency)} 
            ${props?.jobDetails?.min_sal_by_am <= 1000
              ? props?.jobDetails?.min_sal_by_am
              : `${Math.round(props?.jobDetails?.min_sal_by_am / 1000, 2)}k`}
            -
            ${props?.jobDetails?.max_sal_by_am <= 1000
              ? props?.jobDetails?.max_sal
              : `${Math.round(
                props?.jobDetails?.max_sal_by_am / 1000,
                2
              )}k`}${`/${props?.jobDetails?.job_type && props?.jobDetails?.job_type === "Full_Time" ? "Year" : props?.jobDetails?.job_type === "Contract" ? "Hour" : ""}`}
              `}
              // ${`/${props?.jobDetails?.payout?.replace("_", "")}`}
            sx={{
              background: "#F2F8F6",
              '& .MuiChip-label': {
                fontWeight: "600",
                color: "#07A996",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}

          />
        </Tooltip>

        <Tooltip title="Payout" placement="top" arrow>
          <Chip
            label={`${`${props?.jobDetails?.payout?.replace("_", "")}`}`}
            sx={{
              background: "#F2F8F6",
              '& .MuiChip-label': {
                fontWeight: "600",
                color: "#07A996",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}

          />
        </Tooltip>

        <Tooltip title="Work mode" placement="top" arrow>
          <Chip
            label={props?.jobDetails?.work_mode?.replace("_", " ")}
            sx={{
              background: "#F2F8F6",
              '& .MuiChip-label': {
                fontWeight: "600",
                color: "#07A996",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}

          />
        </Tooltip>

        <Tooltip title="Job Location" placement="top" arrow>
          <Chip
            label={props?.jobDetails?.location?.length <= 17 ? props?.jobDetails?.location : props?.jobDetails?.location?.slice(0, 35)}
            sx={{
              background: "rgba(8, 23, 83, 0.07)",
              '& .MuiChip-label': {
                fontWeight: "600",
                color: "#081753",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}

          />
        </Tooltip>

        <Tooltip title="Citizenship Status" placement="top" arrow>
          <Chip
            label={props?.jobDetails?.citizenship_status?.length > 0
              ? props?.jobDetails?.citizenship_status.join(', ')
              : 'N/A'}
            sx={{
              background: "rgba(8, 23, 83, 0.07)",
              '& .MuiChip-label': {
                fontWeight: "600",
                color: "#081753",
                display: 'block',
                whiteSpace: 'normal',
                textAlign: "center",
              },
            }}

          />
        </Tooltip>

      </Stack>
      {/* <Typography variant="h6">
          {Array.isArray(props?.jobDetails?.citizenship_status) ? props?.jobDetails?.citizenship_status.join(', ') : 'N/A'}
        </Typography> */}
    </Stack>
  );
}

export default JobInsights;
