import React, { useEffect, useState, useRef } from "react";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
  Box,
  Stack,
  TextField,
  ListItemButton,
  Typography,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  Divider,
  Badge,
  Avatar,
  CircularProgress
} from "@mui/material";
import { getAllCompanyMenList } from "../../helpers/dataFetcher/chats/chats";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { makeStyles } from "@mui/styles";
import "../../assets/css/chat/chat.css";
import { get_chat_active_user_list } from "../../helpers/sockets";
import { imageURL } from "../../Constants/commonURLS";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import EyeJobDrawer from "../Notifications/EyeJobDrawer";
// import chatImg from "../../assets/images/chat.svg";
import chatImg from "../../assets/images/chatShiyft2.png";
import JobSearchIcon from "../../assets/icons/JobSearchIcon";
import moment from 'moment';
import { socket } from "../../Sockets/Socket";
import GlobalSearch from "../../hooks/GlobalSearch";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    width: "88%",
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "#cde2e7 !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "#cde2e7 !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
      textTransform: "capitalize",
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
});

function DemoChatSidebarDummy({
  setChannelsPage,
  channelsPage,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [read, setRead] = useState({ id: '' })
  const [searchParams, setSearchParams] = useSearchParams();
  const [sideChatLoading, setSideChatLoading] = useState(true);
  const [showChatSearch, setShowChatSearch] = useState(false);
  const [showHideActionModal, setShowHideActionModal] = useState(false);

  const [jobDetails, setJobDetails] = useState({});
  const [companyMembers, setCompanyMembers] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const userJobPostIds = searchParams.get("user-job-post-id") ?? "";
  const jobPostIds = searchParams.get("job-post-id") ?? "";
  const channelIDs = searchParams.get("channel") ?? "";
  const searchFilter = searchParams.get("q");
  const pageSize = 10;

  const peopleListRef = useRef();

  const userJobDetails = {
    user_job_post_id: userJobPostIds,
    job_post_id: jobPostIds,
  };


  const handleListnerEvent = (data) => {
    if (data?.type === "chats") {
      getCompanyMembers();
    }
  };


  useEffect(() => {
    const handleReconnect = () => {
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  useEffect(() => {
    getjobById();
  }, [jobPostIds])

  const getjobById = async () => {
    const { data, status } = await getJobPostById(jobPostIds);
    if (status === 200) {
      setJobDetails(data?.data_payload);
    }
  };

  useEffect(() => {
    getCompanyMembers();
  }, [read]);

  useEffect(() => {
    getCompanyMembers();
    setCurrPage(1);
    setCompanyMembers([]);
  }, [searchFilter])


  async function getCompanyMembers() {
    setSideChatLoading(true);
    const { data, status } = await getAllCompanyMenList(userJobDetails, 1, pageSize * currPage, searchFilter ?? "");

    if (!data?.data_payload?.length) {
      setSideChatLoading(false);
      return;
    }

    if (data?.overall_count >= data?.pageSize) {
      setCurrPage(currPage + 1);
    }

    setCompanyMembers(data?.data_payload);

    // setCurrPage((prevPage) => prevPage + 1);
    // setCompanyMembers((prevItems) => [...prevItems, ...data?.data_payload]);
    setChannelsPage(false);
    setSideChatLoading(false);
    return;
  }


  const handleSendChannelId = async each => {

    setRead({ id: each?.id })
    searchParams.set("channel", each?.id);
    searchParams.set("sender_id", each?.ucc_sender_id?.id);
    setSearchParams(searchParams);
  };


  useEffect(() => {
    getCompanyMembers();
  }, [channelsPage]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const onPeopleListScroll = () => {
    if (peopleListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = peopleListRef.current;

      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 2) {
        getCompanyMembers();
      }
    }
  };



  // const getLastMessageTime = (date) => {
  //   return moment(date).calendar(null, {
  //     sameTime: function (now) {
  //       if (now.diff(this, 'minutes') < 1) {
  //         return "Now";
  //       } 
  //     },
  //     sameDay: function (now) {
  //       if (now.diff(this, 'minutes') < 60 * 12) {
  //         return "LT";
  //       } else {
  //         return "[Today]";
  //       }
  //     },
  //     nextDay: function (now) {
  //       if (now.diff(this, 'minutes') < 60) {
  //         return "LT";
  //       } else {
  //         return "[Tomorrow]";
  //       }
  //     },
  //     nextWeek: "dddd",
  //     lastDay: "[Yesterday]",
  //     lastWeek: "DD/MM/YYYY",
  //     sameElse: "DD/MM/YYYY",
  //   });
  // };

  const getLastMessageTime = (date) => {
    if (!date) {
      return ""
    }
    const now = moment();
    const messageTime = moment(date);

    if (now.diff(messageTime, 'minutes') < 1) {
      return "Now";
    } else if (now.diff(messageTime, 'minutes') < 60 * 12 && now.isSame(messageTime, 'day')) {
      return messageTime.format('LT');
    } else if (now.isSame(messageTime, 'day')) {
      return "Today";
    } else if (now.diff(messageTime, 'minutes') < 60 && now.isBefore(messageTime, 'day')) {
      return messageTime.format('LT');
    } else if (now.diff(messageTime, 'days') < 2) {
      return "Yesterday";
    } else if (now.diff(messageTime, 'days') < 7) {
      return messageTime.format('dddd');
    } else {
      return messageTime.format('DD/MM/YYYY');
    }
  };

  return (
    <>
      <Stack
        sx={{
          height: "fit-content",
          height: "75px",
          minHeight: "75px",
          borderBottom: "1px solid #DEDEDE",
          padding: "10px",
          position: "relative"
        }}
      >
        <Box>
          <Stack
            sx={{
              width: "100%",
              overflow: "hidden",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              textOverflow: "ellipsis",
              // background: "#daece6",
              borderRadius: "8px",
              padding: "8px",
              cursor: "pointer",
              gap: "5px",
            }}
            alignItems="center"
            direction="row"
          >

            <Stack
              sx={{
                height: "100%",
                width: "35px",
              }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("../demochat");
                }}
              >
                <KeyboardArrowLeftIcon fontSize="medium" />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                width: "45px",
                height: "45px",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                loading="lazy"
                alt={jobDetails?.job_title ? jobDetails?.job_title?.charAt(0) : "U"}
                src={`${imageURL}${jobDetails?.company_details?.company_image}`}
                className={classes.avatar1}
                sx={{ background: "#cde2e7 !important", color: "rgba(0, 0, 0, 0.7) !important", textTransform: "capitalize" }}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                width: "calc(100% - 80px)",
              }}
            >
              <Box
                sx={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "80%",
                }}
              >
                <Typography className={classes.name} noWrap>
                  {jobDetails?.job_title}
                </Typography>
                <Typography className={classes.msg} noWrap>
                  {jobDetails?.company_details?.company_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px"
                }}
              >
                {/* <Box>
                  <Badge
                    badgeContent={companyMembers ? companyMembers?.length : "0"}
                    slotProps={{
                      badge: {
                        style: {
                          backgroundColor: "#066a4c",
                          color: "#ffe7bb",
                          fontSize: "10px",
                          fontWeight: 600,
                          marginRight: "10px"
                        },
                      },
                    }}
                  />
                </Box> */}
                <Box
                  onClick={() => {
                    setShowHideActionModal(!showHideActionModal);
                    setShowChatSearch(true);
                    if (searchParams.get("q") && searchParams.get("q") !== "") {
                      searchParams.delete("q");
                      setSearchParams(searchParams);
                    }
                  }}
                >
                  <JobSearchIcon />
                </Box>
                <Box>
                  <EyeJobDrawer
                    isDrawerOpen={isDrawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    jobDetailsData={jobDetails}
                  />
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Box>
        {showHideActionModal && (
          <Box
            sx={{
              minHeight: "40px",
              borderBottom: "1px solid #DEDEDE",
              position: "absolute",
              top: "75px",
              width: "100%",
              overflow: "hidden",
              zIndex: "99",
              left: "0px",
              background: "#fff",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >

            {/* Show Search Chat Box */}
            {showChatSearch && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    position: "relative",
                    marginTop: "5px",
                    marginBottom: "5px",
                    width: "100% !important",
                  }}
                >
                  <GlobalSearch autoFocus placeholder="Search Chats.." sx={{ width: "100%", background: "#E9E9E980", border: "none" }} />
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    size="small"
                    sx={{
                      position: "absolute!important",
                      right: "5px",
                    }}
                    onClick={() => {
                      setShowHideActionModal(!showHideActionModal);
                      setSearchParams(searchParams);
                      setShowChatSearch(false);
                      if (searchParams.get("q") && searchParams.get("q") !== "") {
                        searchParams.delete("q");
                        setSearchParams(searchParams);
                      }
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Box>
            )}
          </Box>
        )}

      </Stack>

      <Stack
        direction="column"
        sx={{
          height: "calc(100% - 75px)",
          maxHeight: "calc(100% - 75px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        onScroll={onPeopleListScroll}
        ref={peopleListRef}
      >
        {showHideActionModal && (<Box sx={{minHeight: "50px",  width: "100%",}}></Box>)}
        {sideChatLoading && companyMembers === null ? (
          <Stack
            sx={{
              height: "100%",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="success" />
          </Stack>
        ) : (
          <>
            {companyMembers?.length > 0 ? (
              companyMembers?.map((each, index) => (
                <>
                  <Stack
                    sx={{
                      width: "100%",
                      // overflow: "hidden",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                      backgroundColor:
                        channelIDs === each?.id
                          ? "#E6F0EDA3"
                          : "#FFFFFF",
                      padding: "10px !important",
                      cursor: "pointer",
                      borderLeft: channelIDs === each?.id
                        ? "4px solid #066A4C"
                        : "4px solid transparent",
                      gap: "10px",
                      borderBottom: companyMembers?.length - index - 1 === 0 ? "" : "0.4px solid #DEDEDE"
                    }}
                    key={each?.id}
                    alignItems="flex-start"
                    onClick={e => {
                      e.preventDefault();
                      handleSendChannelId(each);
                    }}
                    direction="row"
                  >

                    <Stack
                      sx={{
                        width: "45px",
                        height: "45px",
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Avatar
                        loading="lazy"
                        alt={`${each?.ucc_sender_id?.first_name?.charAt(0)}`}
                        // src={
                        //   each?.sender_type == "REFERAL_PARTNER"
                        //     ? `${imageURL}${each?.ucc_receiver_id?.image}`
                        //     : `${imageURL}${each?.ucc_sender_id?.image}`
                        // }
                        src={`${imageURL}${each?.ucc_sender_id?.image}`}
                        className={classes.avatar1}
                        sx={{ background: "#cde2e7 !important", color: "rgba(0, 0, 0, 0.7) !important", textTransform: "capitalize" }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        width: "calc(100% - 45px)",
                      }}
                    >
                      <Box
                        sx={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "80%",
                        }}
                      >
                        <Typography className={classes.name} noWrap>
                          {/* {each.sender_type == "REFERAL_PARTNER"
                                      ? each?.ucc_receiver_id?.first_name +
                                      each?.ucc_receiver_id?.last_name
                                      : each?.ucc_sender_id?.first_name +
                                      each?.ucc_sender_id?.last_name} */}
                          {each?.ucc_sender_id?.first_name + " " + each?.ucc_sender_id?.last_name}
                        </Typography>
                        <Typography className={classes.msg} noWrap>
                          {/* {each?.last_message?.last_message
                              ? each.last_message.last_message.slice(
                                0,
                                15
                              ) + "..."
                              : ""} */}
                          {each?.last_message?.last_message}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography noWrap
                          sx={{
                            fontSize: "12px",
                            color: (each?.last_message?.sender_details?.usertype !== "REFERAL_PARTNER") && (each?.unread_messages_count > 0) ? "#29B959" : "#8B8B8B",
                            fontWeight: "500",
                            textAlign: "right",
                          }}
                        >
                          {
                            getLastMessageTime(each?.last_message?.created_at)
                          }
                        </Typography>

                        {(each?.last_message?.sender_details?.usertype !== "REFERAL_PARTNER") && (each?.unread_messages_count > 0) ?
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              marginTop: "3px",
                            }}
                          >
                            <Box
                              // component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                borderRadius: "50%",
                                background: "#29B959",
                                color: "#fff",
                                height: "20px",
                                width: "20px",
                                padding: "3px",
                                textAlign: "center"
                              }}
                            >
                              {each?.unread_messages_count}
                            </Box>
                          </Box>
                          : ""}
                      </Box>
                    </Stack>
                  </Stack>
                </>
              ))
            ) : (
              <div className="">
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                    <div className="contact_no_show">
                      <img src={chatImg} alt="chat" />
                      <p>No Chats Found</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Stack>
    </>
  );
}

export default DemoChatSidebarDummy;
