import React, { useEffect,useState } from "react";
import "./Dashboard.css";
import DashBoardMain from "./DashboardMain";

function DashBoardIndex() {

  return (
    <React.Fragment>
        <DashBoardMain />
    </React.Fragment>
  );
}

export default DashBoardIndex;
