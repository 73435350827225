import { combineReducers } from "redux";
import modalsReducer from "../components/modals/modalsSlice";

import ProfileSidebar from "./Layout/reducers";
import CompanyReducer from "./company/reducers";
import JobApplyStatusReducer from './JobApplyStatus/reducer';

import accountReducer from "../pages/Accounts/accountSlice";
import globalJobReducer from "../pages/JobsList/globalJobSlice";

const rootReducer = combineReducers({
  ProfileSidebar :ProfileSidebar,
  User: accountReducer,
  CompanyReducer: CompanyReducer,
  JobApplyStatusReducer: JobApplyStatusReducer,
  modals: modalsReducer,
  globalJob: globalJobReducer
});

export default rootReducer;
