/**
 * Calculates the total number of years (including fractional years) between two dates.
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @returns {string} The total number of years (including fractional years) between startDate and endDate, formatted as a string.
 */
import moment from "moment";

export function calculateWorkYears(startDateStr, endDateStr) {
  if (!startDateStr && !endDateStr) return 0;
  // Parse start and end dates
  const startDate = new Date(parseDate(startDateStr));
  const endDate = new Date(parseDate(endDateStr));
  // Ensure the dates are valid
  if (
    !(startDate instanceof Date) ||
    !(endDate instanceof Date) ||
    startDate > endDate
  ) {
    return 0;
  }

  // Calculate the total number of days between the two dates
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

  // Average number of days in a year (considering leap years)
  const averageDaysInYear = 365;

  // Calculate the total number of years, including fractional years
  const totalYears = totalDays / averageDaysInYear;

  // Format the result with one decimal point, if necessary
  const formattedYears = totalYears.toFixed(1); // Format to one decimal place
  let totalYeears =
    parseFloat(formattedYears) % 1 === 0
      ? `${parseInt(formattedYears, 10)}`
      : formattedYears;
  return totalYeears && totalYeears != "NaN" ? totalYeears : 0;
}

//   // Example Usage
//   const startDate = new Date('2022-12-15');
//   const endDate = new Date('2023-12-25');

//   console.log(`Total work years: ${calculateWorkYears(startDate, endDate)}`); // Expected Output: "0.6" or "1" based on calculation

/**
 * Parses a date string into a moment object.
 * @param {string} dateStr - The date string.
 * @returns {moment.Moment} The parsed moment object.
 */
const formats = [
  "DD MMM YYYY",
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "MM/YYYY",
  "MMMM YYYY",
  "MM/YYYY",
  "MMM YYYY",
  "MMM. YYYY",
  "YYYY-MM-DD",
  "YYYY",
  "YYYY MMM",
  "YYYY MM",
];
export function parseDate(dateStr) {
  // Check for keywords and use current date
  const lowerCaseDateStr = dateStr?.trim()?.toLowerCase();
  if (["current", "present", "till"].includes(lowerCaseDateStr)) {
    return moment().format("YYYY-MM-DD");
  }

  // Parse the date with the defined formats
  return moment(dateStr, formats, true).format("YYYY-MM-DD");
}
export function isValidDate(dateString) {
  if (!dateString) return false;
  return moment(dateString, formats, true).isValid();
}
export function isValidNumber(value) {
  if (!value) return false;
  return !isNaN(Number(value)) && Number.isFinite(Number(value));
}
export function convertExperience(experience) {
  if (!experience) return "";
  // Parse the input to a float
  const experienceNumber = parseFloat(experience);

  // Extract the years and decimal part
  const years = Math.floor(experienceNumber);
  const months = Math.round((experienceNumber - years) * 12);

  // Return formatted result
  return `${years} Year(s), ${months} Month(s)`;
}
