import React, { useState, useEffect } from "react";
import { Input, InputLabel, Typography, Box, Grid, Stack, IconButton, FormControl } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
    PrimaryOpacitedButton,
} from "../../../assets/MUI/materialUiStyles";
import ProfileEditIcon from "../../../assets/icons/ProfileEditIcon";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux"
import { openModal } from "../../../components/modals/modalsSlice";
export default function ProfileCurrExpFieldForm({
    textField,
    currValue,
    sx,
    helperText,
    useFormProps,
    submitData,
    displayValue,
    salaryType = "current",
    ...rest
}) {


    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();


    const [fieldActive, setFieldActive] = useState(false);

    useEffect(() => {
        setValue(`${rest?.name}`, currValue)
    }, [fieldActive])


    function checkInputs() {
        if (currValue && currValue?.length > 0) {
            return false;
        }
        return true;
    }

    // async function submitedFunction(event) {
    //     const result = await submitData(event);

    //     if (result) {
    //         handleButtons()
    //     }
    // } 


    function handleButtons() {
        setFieldActive(!fieldActive);

    }


    return (
        <Grid container>
            <Grid item xs={4}>
                <Typography variant="h5" component="h5" sx={{ color: "#676767", fontWeight: "400", fontSize: "16px" }}>
                    {textField ?? ""}
                </Typography>
            </Grid>
            <>
                {checkInputs() ? (
                    <Grid item xs={8}>
                        <PrimaryOpacitedButton
                            onClick={() => {
                                handleButtons();
                                dispatch(
                                    openModal({
                                        modalName: "profileCurrExpSalaryModal",
                                        modalProps: {
                                            handleButtons: handleButtons,
                                            submitData: submitData,
                                            salaryType: salaryType,
                                            currValue: currValue,
                                            fieldName: `${rest?.name}`,
                                            fieldActive: fieldActive,
                                            edit: false,

                                        },
                                    })
                                );
                            }}
                            endIcon={<ProfileEditIcon disabled={false} />}
                        >
                            Add New
                        </PrimaryOpacitedButton>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={8}
                            sx={{
                                display: "flex",
                                direction: "row",
                                gap: "5px",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                varient="h6"
                                componenet="h6"
                                sx={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    letterSpacing: "-0.2px",
                                    // textOverflow: "ellipsis",
                                    // whiteSpace: "wrap",
                                    // textTransform: update_type === "gender" ? "capitalize" : ""
                                }}
                            >
                                : {displayValue}
                            </Typography>
                            <IconButton onClick={() => {
                                handleButtons();
                                dispatch(
                                    openModal({
                                        modalName: "profileCurrExpSalaryModal",
                                        modalProps: {
                                            handleButtons: handleButtons,
                                            submitData: submitData,
                                            salaryType: salaryType,
                                            currValue: currValue,
                                            fieldName: `${rest?.name}`,
                                            fieldActive: fieldActive,
                                            edit: true,
                                        },
                                    })
                                );
                            }} color="success">
                                <ProfileEditIcon />
                            </IconButton>
                        </Grid>
                    </>
                )}
            </>
        </Grid>
    );
}








// Usase example


// <ProfileCurrExpFieldForm
// textField={"Last Name"}
// id="last_name"
// placeholder="Enter Last Name"
// name="last_name"
// type="text"
// currValue={accountData?.last_name}
// submitData={submitData}
// useFormRef={{
//   required: "Last Name is required",
//   maxLength: {
//     value: 200,
//     message: "Last Name must not exceed 200 characters",
//   },
// }
// }
// />