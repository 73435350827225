import React, { useEffect, useState } from "react";
import MyModal from "./components/Modal";
import {
  Button,
  Stack,
  Grid,
  FormControl,
  TextField,
  IconButton,
  InputLabel,
  FormHelperText,
  createFilterOptions,
  Checkbox,
  Select,
  Input,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import {
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  autocompleteProps,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  SecondaryFilledChip,
  MenuProps,
} from "../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Add, Remove } from "@mui/icons-material";
import {
  getAllKeySkillsWithStatus,
  getProfile,
  updateUserProfileDetails,
} from "../../helpers/dataFetcher/profile/profile";
import MuiCustomTelInput from "../formElements/PhoneNumber/PhoneNumber";
import { matchIsValidTel } from "mui-tel-input";
import toast from "react-hot-toast";
import { updateUserAccount } from "../../pages/Accounts/accountSlice";
import { useDispatch } from "react-redux";
import { calculateWorkYears, isValidNumber } from "../../helpers/CalculateYear";

export default function ProfileUpdateConformationModal({
  open,
  handleClose,
  data,
  allSkills,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      work_exp: [{}],
      education_training: [{}],
      languages: data?.languages || [""],
      work_location: data?.work_location || [""],
    },
  });

  const [allKeySkills, setAllKeySkills] = useState(allSkills ? allSkills : []);
  async function getAllKeySkills(page, pageSize, searchText) {
    const { data: allSkills } = await getAllKeySkillsWithStatus(
      page,
      pageSize,
      searchText
    );
    const result = allSkills?.data_payload?.map(item => ({
      value: item.id,
      name: item.name,
      isActive: item.is_active,
    }));
    setAllKeySkills(result);
    if (result.length > 0) {
      let skillss =
        data?.skills && data?.skills?.length > 0
          ? result.filter(res => data.skills?.includes(res.value))
          : [];
      //   setValue("key_skills", skillss);
      //   setValue("key_skills", skillss.map(skill => ({ value: skill.value, name: skill.name })));
    }
  }
  const filter = createFilterOptions();
  useEffect(() => {
    getAllKeySkills(1, 10000, "");
  }, []);
  const {
    fields: workFields,
    append: appendWork,
    remove: removeWork,
  } = useFieldArray({
    control,
    name: "work_exp",
  });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({
    control,
    name: "education_training",
  });
  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: "languages",
  });

  const {
    fields: workLocationFields,
    append: appendWorkLocation,
    remove: removeWorkLocation,
  } = useFieldArray({
    control,
    name: "work_location",
  });
  useEffect(() => {
    // Initialize values if any
    setValue("email", data?.email || "");
    setValue("phone_number", data?.phone_number || "");
    setValue("first_name", data?.first_name || "");
    setValue("last_name", data?.last_name || "");
    setValue("dob", data?.dob || "");
    setValue("gender", data?.gender || "");
    setValue("nationality", data?.nationality || "");
    setValue("update_type", data?.update_type || "resume");
    setValue("resume_file", data?.resume_file || "");
    setValue("address", data?.address || "");
    setValue("current_address", data?.current_address || "");
    setValue("current_company", data?.current_company || "");
    setValue("current_postition", data?.current_postition || "");
    setValue("experience", data?.experience || 0);
    setValue("linkedIn", data?.links?.linkedIn || "");
    setValue("github", data?.links?.github || "");
    setValue("portfolio", data?.links?.portfolio || "");
    setValue("website", data?.links?.website || "");
    setValue("languages", data?.languages || [""]);
    setValue("work_location", data?.work_location || [""]);
    setValue("work_exp", data?.work_exp || [{}]);
    setValue("education_training", data?.education_training || [{}]);
    let skillls = [];
    console.log("length", allKeySkills?.length, data.skills?.length);
    if (allKeySkills?.length > 0 && data.skills?.length > 0) {
      skillls = allKeySkills.filter(res => data.skills.includes(res.value));
    } else if (data?.skills?.length > 0) {
      skillls = data?.skills;
    }
    setValue("key_skills", skillls);
  }, [data]);
  console.log({ data });
  async function submitProfileForm(event) {
    let totalExperience = 0;
    const {
      key_skills,
      work_exp,
      education_training,
      linkedIn,
      github,
      portfolio,
      website,
      ...rest
    } = event;
    let finalData = {
      ...rest,
      skills: key_skills.map(res => res.value),
      work_exp: work_exp.map(({ currently_working, ...rest }) => {
        totalExperience += Number(
          calculateWorkYears(rest.start_date, rest.end_date)
        );
        return {
          ...rest,
          is_currently_working: currently_working,
        };
      }),
      education_training: education_training.map(
        ({ currently_studying, ...rest }) => ({
          ...rest,
          is_currently_pursuing: currently_studying,
        })
      ),
      links: { linkedIn, github, portfolio, website },
      experience: isValidNumber(totalExperience)
        ? totalExperience.toFixed(1)
        : "0",
    };

    try {
      const response = await updateUserProfileDetails("resume", finalData);

      if (response.status == 200) {
        const { data: userData, status } = await getProfile();

        if (status === 200) {
          dispatch(
            updateUserAccount({
              user: {
                ...userData,
              },
            })
          );
        }
        handleClose();
      } else {
        toast.error(
          response?.data?.message ??
            "Something went wrong while updating profile."
        );
      }
    } catch (error) {
      toast.error("Something went wrong while updating profile.");
    }
    // if (result) {

    // }
  }
  let genderOption = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Prefer not to say", value: "prefer_not_to_say" },
  ];
  return (
    <MyModal
      open={open}
      handleClose={handleClose}
      sx={{ height: "100%", width: "80vw", minWidth: "300px" }}
      showCloseIcon
      title="Update Profile Details"
      size="medium"
    >
      <form
        onSubmit={handleSubmit(submitProfileForm)}
        style={{ height: "90vh", overflowY: "auto", padding: "0px 20px" }}
      >
        <Stack mb={3} mt={2}>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="first_name"
                  error={errors.first_name}
                >
                  First Name
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="John"
                  name="first_name"
                  {...register("first_name", {
                    required: "First Name is required",
                  })}
                  error={errors.first_name ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.first_name}>
                  {errors.first_name ? errors.first_name?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="last_name"
                  error={errors.last_name}
                >
                  Last Name
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="eg: John"
                  name="last_name"
                  {...register("last_name", {
                    required: "Last Name is required",
                  })}
                  error={errors.last_name ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.last_name}>
                  {errors.last_name ? errors.last_name?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="email"
                  error={errors.email}
                >
                  Email
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="example@example.com"
                  name="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Email is not valid",
                    },
                  })}
                  error={errors.email ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.email}>
                  {errors.email ? errors.email?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="phone_number"
                error={errors.phone_number}
              >
                Phone Number
              </PrimaryNormalInputLabel>

              <Controller
                name="phone_number"
                control={control}
                rules={{
                  required: "Phone number is required",
                  validate: value =>
                    matchIsValidTel(value) || "Phone number is invalid",
                }}
                render={({
                  field: { ref: fieldRef, value, ...fieldProps },
                  fieldState,
                }) => {
                  return (
                    <MuiCustomTelInput
                      {...fieldProps}
                      defaultCountry={"US"}
                      countryCodeEditable={true}
                      value={value ?? ""}
                      inputRef={fieldRef}
                      error={fieldState.invalid}
                      helperText={
                        errors?.phone_number?.type
                          ? errors?.phone_number?.message
                          : null
                      }
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  );
                }}
              />
            </Grid>
            {/* Gender */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="gender"
                  error={errors.gender}
                >
                  Gender
                </PrimaryNormalInputLabel>
                <Select
                  value={getValues("gender")}
                  {...register(`gender`, { required: "Gender is required" })}
                  input={
                    <PrimaryNormalInputs
                      sx={{ width: "100%" }}
                      error={errors.gender ? true : false}
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                    />
                  }
                  sx={{ borderRadius: "8px", outline: "none" }}
                  MenuProps={MenuProps}
                >
                  {genderOption?.map(item => {
                    return (
                      <MenuItem
                        key={item?.value}
                        value={item?.value}
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <PrimaryNormalFormHelperText error={errors.gender}>
                  {errors.gender ? errors.gender?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* DOB */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor={`dob`}
                  error={errors.dob}
                >
                  Date Of Birth
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  type="date"
                  placeholder="Date Of Birth"
                  name={`dob`}
                  {...register(`dob`, {
                    required: "Date Of Birth is required",
                  })}
                  error={errors.dob ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.dob}>
                  {errors.dob ? errors.dob?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* Nationality */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="nationality"
                  error={errors.nationality}
                >
                  Nationality
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="eg: India"
                  name="nationality"
                  {...register("nationality", {
                    required: "Nationality is required",
                  })}
                  error={errors.nationality ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.nationality}>
                  {errors.nationality ? errors.nationality?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* Current Address */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="current_address"
                  error={errors.current_address}
                >
                  Current Address
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="eg: Gachibowli, Hyderabad, Telangana"
                  name="current_address"
                  {...register("current_address", {
                    required: "Current Address is required",
                  })}
                  error={errors.current_address ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.current_address}>
                  {errors.current_address
                    ? errors.current_address?.message
                    : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* current company */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="current_company"
                  error={errors.current_company}
                >
                  Current Company
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="eg: TCS"
                  name="current_company"
                  {...register("current_company", {
                    required: "Current Company is required",
                  })}
                  error={errors.current_company ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.current_company}>
                  {errors.current_company
                    ? errors.current_company?.message
                    : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* current postition  */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="current_postition"
                  error={errors.current_postition}
                >
                  Current Position
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="eg: Full Stack Developer"
                  name="current_postition"
                  {...register("current_postition", {
                    required: "Current Position is required",
                  })}
                  error={errors.current_postition ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.current_postition}>
                  {errors.current_postition
                    ? errors.current_postition?.message
                    : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* experience  */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="experience"
                  error={errors.experience}
                >
                  Total Experience
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="eg: 1"
                  name="experience"
                  {...register("experience", {
                    required: "Total Experience is required",
                  })}
                  error={errors.experience ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.experience}>
                  {errors.experience ? errors.experience?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* linkedIn  */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="linkedIn"
                  error={errors.linkedIn}
                >
                  LinkedIn
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="LinkedIn URL"
                  name="linkedIn"
                  {...register("linkedIn", {
                    required: "LinkedIn is required",
                  })}
                  error={errors.linkedIn ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.linkedIn}>
                  {errors.linkedIn ? errors.linkedIn?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* github  */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="github"
                  error={errors.github}
                >
                  Github
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="Github URL"
                  name="github"
                  {...register("github", {
                    required: "Github is required",
                  })}
                  error={errors.github ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.github}>
                  {errors.github ? errors.github?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* portfolio  */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="portfolio"
                  error={errors.portfolio}
                >
                  Portfolio(optional)
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="Portfolio URL"
                  name="portfolio"
                  {...register("portfolio")}
                  error={errors.portfolio ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.portfolio}>
                  {errors.portfolio ? errors.portfolio?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* website  */}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <PrimaryNormalInputLabel
                  shrink
                  htmlFor="website"
                  error={errors.website}
                >
                  Website(optional)
                </PrimaryNormalInputLabel>
                <PrimaryNormalInputs
                  fullWidth
                  placeholder="Website URL"
                  name="website"
                  {...register("website")}
                  error={errors.website ? true : false}
                  variant="outlined"
                />
                <PrimaryNormalFormHelperText error={errors.website}>
                  {errors.website ? errors.website?.message : null}
                </PrimaryNormalFormHelperText>
              </FormControl>
            </Grid>
            {/* Similar Grid items for other fields like last_name, dob, gender, etc. */}

            {/* Work Experience Fields */}
            <Grid item xs={12}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="work_exp"
                style={{ fontSize: "24px" }}
              >
                Work Experience
              </PrimaryNormalInputLabel>

              {workFields.map((item, index) => (
                <div key={item.id} style={{ marginBottom: "16px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`work_exp[${index}].title`}
                          error={errors.work_exp?.[index]?.title}
                        >
                          Job Title
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Job Title"
                          name={`work_exp.${index}.title`}
                          {...register(`work_exp.${index}.title`, {
                            required: "Job Title is required",
                          })}
                          error={errors.work_exp?.[index]?.title ? true : false}
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.work_exp?.[index]?.title}
                        >
                          {errors.work_exp?.[index]?.title
                            ? errors.work_exp?.[index]?.title?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`work_exp[${index}].employer`}
                          error={errors.work_exp?.[index]?.employer}
                        >
                          Company Name
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Company Name"
                          name={`work_exp.${index}.employer`}
                          {...register(`work_exp.${index}.employer`, {
                            required: "Company Name is required",
                          })}
                          error={
                            errors.work_exp?.[index]?.employer ? true : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.work_exp?.[index]?.employer}
                        >
                          {errors.work_exp?.[index]?.employer
                            ? errors.work_exp?.[index]?.employer?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`work_exp[${index}].location`}
                          error={errors.work_exp?.[index]?.location}
                        >
                          Job Location
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Job Location"
                          name={`work_exp.${index}.location`}
                          {...register(`work_exp.${index}.location`, {
                            required: "Job Location is required",
                          })}
                          error={
                            errors.work_exp?.[index]?.location ? true : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.work_exp?.[index]?.location}
                        >
                          {errors.work_exp?.[index]?.location
                            ? errors.work_exp?.[index]?.location?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`work_exp[${index}].start_date`}
                          error={errors.work_exp?.[index]?.start_date}
                        >
                          Start Date
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          type="date"
                          placeholder="Start Date"
                          name={`work_exp.${index}.start_date`}
                          {...register(`work_exp.${index}.start_date`, {
                            required: "Start Date is required",
                          })}
                          error={
                            errors.work_exp?.[index]?.start_date ? true : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.work_exp?.[index]?.start_date}
                        >
                          {errors.work_exp?.[index]?.start_date
                            ? errors.work_exp?.[index]?.start_date?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    {!watch(`work_exp.${index}.currently_working`) ? (
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ width: "100%" }} variant="standard">
                          <PrimaryNormalInputLabel
                            shrink
                            htmlFor={`work_exp[${index}].end_date`}
                            error={errors.work_exp?.[index]?.end_date}
                          >
                            End Date
                          </PrimaryNormalInputLabel>

                          <PrimaryNormalInputs
                            fullWidth
                            type="date"
                            placeholder="End Date"
                            name={`work_exp.${index}.end_date`}
                            {...register(`work_exp.${index}.end_date`, {
                              required: "End Date is required",
                            })}
                            error={
                              errors.work_exp?.[index]?.end_date ? true : false
                            }
                            variant="outlined"
                          />
                          <PrimaryNormalFormHelperText
                            error={errors.work_exp?.[index]?.end_date}
                          >
                            {errors.work_exp?.[index]?.end_date
                              ? errors.work_exp?.[index]?.end_date?.message
                              : null}
                          </PrimaryNormalFormHelperText>
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={7}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch(
                                `work_exp.${index}.currently_working`
                              )}
                              onChange={e => {
                                const isChecked = e.target.checked;
                                setValue(
                                  `work_exp.${index}.currently_working`,
                                  isChecked
                                );
                                if (isChecked) {
                                  setValue(
                                    `work_exp.${index}.end_date`,
                                    "Present"
                                  );
                                  // Optionally clear end_date validation if needed
                                } else {
                                  setValue(`work_exp.${index}.end_date`, "");
                                  // Reapply validation if the checkbox is unchecked
                                  setValue(`work_exp.${index}.end_date`, {
                                    required: "End Date is required",
                                  });
                                }
                              }}
                            />
                          }
                          label="I am currently working in this role"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`work_exp[${index}].description`}
                          error={errors.work_exp?.[index]?.description}
                        >
                          Description
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          type="textarea"
                          multiline={true}
                          placeholder="Job Description"
                          name={`work_exp.${index}.description`}
                          {...register(`work_exp.${index}.description`, {
                            required: "Description is required",
                          })}
                          error={
                            errors.work_exp?.[index]?.description ? true : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.work_exp?.[index]?.description}
                        >
                          {errors.work_exp?.[index]?.description
                            ? errors.work_exp?.[index]?.description?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <IconButton onClick={() => removeWork(index)} color="error">
                    <Remove />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                onClick={() => appendWork({})}
                startIcon={<Add />}
              >
                Add Work Experience
              </Button>
            </Grid>

            {/* Education Training Fields */}
            <Grid item xs={12}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="education_training"
                style={{ fontSize: "24px" }}
              >
                Education
              </PrimaryNormalInputLabel>
              {educationFields.map((item, index) => (
                <div key={item.id} style={{ marginBottom: "16px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`education_training[${index}].certificate_name`}
                          error={
                            errors.education_training?.[index]?.certificate_name
                          }
                        >
                          Degree
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Degree"
                          name={`education_training.${index}.certificate_name`}
                          {...register(
                            `education_training.${index}.certificate_name`,
                            {
                              required: "Degree is required",
                            }
                          )}
                          error={
                            errors.education_training?.[index]?.certificate_name
                              ? true
                              : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={
                            errors.education_training?.[index]?.certificate_name
                          }
                        >
                          {errors.education_training?.[index]?.certificate_name
                            ? errors.education_training?.[index]
                                ?.certificate_name?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`education_training[${index}].institute_name`}
                          error={
                            errors.education_training?.[index]?.institute_name
                          }
                        >
                          University/College
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="University/College"
                          name={`education_training.${index}.institute_name`}
                          {...register(
                            `education_training.${index}.institute_name`,
                            {
                              required: "University/College is required",
                            }
                          )}
                          error={
                            errors.education_training?.[index]?.institute_name
                              ? true
                              : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={
                            errors.education_training?.[index]?.institute_name
                          }
                        >
                          {errors.education_training?.[index]?.institute_name
                            ? errors.education_training?.[index]?.institute_name
                                ?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`education_training[${index}].location`}
                          error={errors.education_training?.[index]?.location}
                        >
                          Location
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          placeholder="Location"
                          name={`education_training.${index}.location`}
                          {...register(`education_training.${index}.location`, {
                            required: "Location is required",
                          })}
                          error={
                            errors.education_training?.[index]?.location
                              ? true
                              : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.education_training?.[index]?.location}
                        >
                          {errors.education_training?.[index]?.location
                            ? errors.education_training?.[index]?.location
                                ?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`education_training[${index}].start_date`}
                          error={errors.education_training?.[index]?.start_date}
                        >
                          Start Date
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          type="date"
                          placeholder="Start Date"
                          name={`education_training.${index}.start_date`}
                          {...register(
                            `education_training.${index}.start_date`,
                            {
                              required: "Start Date is required",
                            }
                          )}
                          error={
                            errors.education_training?.[index]?.start_date
                              ? true
                              : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={errors.education_training?.[index]?.start_date}
                        >
                          {errors.education_training?.[index]?.start_date
                            ? errors.education_training?.[index]?.start_date
                                ?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                    {!watch(
                      `education_training.${index}.currently_studying`
                    ) ? (
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ width: "100%" }} variant="standard">
                          <PrimaryNormalInputLabel
                            shrink
                            htmlFor={`education_training[${index}].end_date`}
                            error={errors.education_training?.[index]?.end_date}
                          >
                            End Date
                          </PrimaryNormalInputLabel>
                          <PrimaryNormalInputs
                            fullWidth
                            type="date"
                            placeholder="End Date"
                            name={`education_training.${index}.end_date`}
                            {...register(
                              `education_training.${index}.end_date`,
                              {
                                required: "End Date is required",
                              }
                            )}
                            error={
                              errors.education_training?.[index]?.end_date
                                ? true
                                : false
                            }
                            variant="outlined"
                          />
                          <PrimaryNormalFormHelperText
                            error={errors.education_training?.[index]?.end_date}
                          >
                            {errors.education_training?.[index]?.end_date
                              ? errors.education_training?.[index]?.end_date
                                  ?.message
                              : null}
                          </PrimaryNormalFormHelperText>
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={7}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={watch(
                                `education_training.${index}.currently_studying`
                              )}
                              onChange={e => {
                                const isChecked = e.target.checked;
                                setValue(
                                  `education_training.${index}.currently_studying`,
                                  isChecked
                                );
                                if (isChecked) {
                                  setValue(
                                    `education_training.${index}.end_date`,
                                    "Present"
                                  );
                                  // Optionally clear end_date validation if needed
                                } else {
                                  setValue(
                                    `education_training.${index}.end_date`,
                                    ""
                                  );
                                  // Reapply validation if the checkbox is unchecked
                                  setValue(
                                    `education_training.${index}.end_date`,
                                    {
                                      required: "End Date is required",
                                    }
                                  );
                                }
                              }}
                            />
                          }
                          label="I am currently studying here"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%" }} variant="standard">
                        <PrimaryNormalInputLabel
                          shrink
                          htmlFor={`education_training[${index}].description`}
                          error={
                            errors.education_training?.[index]?.description
                          }
                        >
                          Description
                        </PrimaryNormalInputLabel>
                        <PrimaryNormalInputs
                          fullWidth
                          type="textarea"
                          multiline={true}
                          placeholder="Job Description"
                          name={`education_training.${index}.description`}
                          {...register(
                            `education_training.${index}.description`,
                            {
                              required: "Description is required",
                            }
                          )}
                          error={
                            errors.education_training?.[index]?.description
                              ? true
                              : false
                          }
                          variant="outlined"
                        />
                        <PrimaryNormalFormHelperText
                          error={
                            errors.education_training?.[index]?.description
                          }
                        >
                          {errors.education_training?.[index]?.description
                            ? errors.education_training?.[index]?.description
                                ?.message
                            : null}
                        </PrimaryNormalFormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <IconButton
                    onClick={() => removeEducation(index)}
                    color="error"
                  >
                    <Remove />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="outlined"
                onClick={() => appendEducation({})}
                startIcon={<Add />}
              >
                Add Educations
              </Button>
            </Grid>
            {/* Language Array Fields */}
            <Grid item xs={12}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="language"
                style={{ fontSize: "24px" }}
              >
                Languages
              </PrimaryNormalInputLabel>
              {languageFields?.map((item, index) => (
                <div key={item.id} style={{ marginBottom: "16px" }}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"center"}
                    spacing={2}
                  >
                    <PrimaryNormalInputs
                      fullWidth
                      placeholder="Language"
                      name={`languages.${index}`}
                      {...register(`languages.${index}`, {
                        required: "Language is required",
                      })}
                      //   error={errors?.languages[index] ? true : false}
                      variant="outlined"
                    />
                    <IconButton
                      onClick={() => removeLanguage(index)}
                      color="error"
                      style={{ marginLeft: "8px" }}
                    >
                      <Remove />
                    </IconButton>
                  </Stack>
                </div>
              ))}
              <Button
                variant="outlined"
                onClick={() => appendLanguage("")}
                startIcon={<Add />}
              >
                Add Language
              </Button>
            </Grid>

            {/* Work Location Array Fields */}
            <Grid item xs={12}>
              <PrimaryNormalInputLabel
                shrink
                htmlFor="work_location"
                style={{ fontSize: "24px" }}
              >
                Preferred Locations
              </PrimaryNormalInputLabel>
              {workLocationFields.map((item, index) => (
                <div key={item.id} style={{ marginBottom: "16px" }}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"center"}
                    spacing={2}
                  >
                    <TextField
                      fullWidth
                      placeholder="Preferred Locations"
                      {...register(`work_location.${index}`)}
                      label="Preferred Locations"
                      variant="outlined"
                    />
                    <IconButton
                      onClick={() => removeWorkLocation(index)}
                      color="error"
                      style={{ marginLeft: "8px" }}
                    >
                      <Remove />
                    </IconButton>
                  </Stack>
                </div>
              ))}
              <Button
                variant="outlined"
                onClick={() => appendWorkLocation("")}
                startIcon={<Add />}
              >
                Add Preferred Locations
              </Button>
            </Grid>
            {/* Skills */}
            <Grid item xs={12}>
              <PrimaryNormalInputLabel shrink htmlFor="work_location">
                Skills
              </PrimaryNormalInputLabel>
              <Controller
                render={props => {
                  return (
                    <PrimaryAutoComplete
                      sx={{ width: "100%" }}
                      {...props}
                      disableCloseOnSelect
                      componentsProps={autocompleteProps}
                      multiple={true}
                      options={allKeySkills}
                      limitTags={2}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <SecondaryFilledChip
                            deleteIcon={
                              <FeatherIcon
                                icon={"x"}
                                size="14"
                                style={{ color: "#fff" }}
                              />
                            }
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option, { selected }) =>
                        option.isActive ? (
                          <li key={option.id} {...props}>
                            <Checkbox
                              icon={<FeatherIcon icon="square" size="14" />}
                              checkedIcon={
                                <FeatherIcon icon="check-square" size="14" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        ) : null
                      }
                      getOptionLabel={option => option?.name}
                      renderInput={params => (
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputLabel
                            shrink
                            htmlFor="key-skills-outlined"
                            error={errors.key_skills}
                            sx={{
                              color: "#000",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Required Skills (select all that applies)
                          </InputLabel>
                          <MultipleSelectInputs
                            sx={{
                              ...(errors.key_skills && {
                                borderColor: "red",
                              }),
                            }}
                            variant="standard"
                            ref={params.InputProps.ref}
                            {...params}
                          />
                          <FormHelperText error={errors.key_skills}>
                            {errors.key_skills
                              ? errors.key_skills?.message
                              : null}
                          </FormHelperText>
                        </FormControl>
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // // Suggest the creation of a new value
                        const isExisting = options.some(
                          option => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            isActive: true,
                            name: `${inputValue}`,
                            id: uuid(),
                          });
                        }

                        return filtered;
                      }}
                      value={props?.field?.value}
                      onChange={async (_, data) => {
                        const lastIndex = data?.length - 1;
                        if (data && data[lastIndex]?.inputValue) {
                          const { data: skill } = await createKeySkills({
                            name: data[lastIndex].inputValue,
                            is_active: true,
                          });
                          await getAllKeySkills();
                          props?.field.onChange([
                            ...getValues("key_skills"),
                            {
                              name: skill?.key_skill_res.name,
                              value: skill?.key_skill_res.id,
                            },
                          ]);
                        } else {
                          props?.field.onChange(data);
                        }
                      }}
                    />
                  );
                }}
                name="key_skills"
                control={control}
                rules={{
                  required: "Key skills is required",
                  validate: value =>
                    (value && value?.length <= 20) ||
                    "Key skills should not be more than 20",
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          spacing={2}
        >
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{
              borderRadius: "8px",
              boxShadow: "none",
              background: "#E6F0ED",
              padding: "4px 8px",
              fontSize: "14px",
              border: "0.8px solid var(--app-button-color1)",
              color: "var(--app-button-color1)",
              "&:hover": {
                boxShadow: "none",
                color: "#fff",
                background: "var(--app-button-color1)",
              },
            }}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </MyModal>
  );
}
