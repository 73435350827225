import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Pagination, Box } from "@mui/material";
import { styleObj } from '../../assets/MUI/useStyles';
import { DataGridStyles } from '../../assets/MUI/useStyles';
import { getEarningTableData } from "../../helpers/dataFetcher/profile/update";
import { toast } from "react-hot-toast";
import { useSearchParams } from 'react-router-dom';
import { globalTheme } from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

function EarningsTable2() {
  const classes = DataGridStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const searchFilter = searchParams.get("q");
  const [page, setPage] = useState(searchParams.get("page") ?? 1);
  const [totalCount, setTotalCount] = useState(0)
  const pageSize = 10;


  useEffect(() => {
    if (!searchParams.get("page")) {
      searchParams.set("page", 1);
      setSearchParams(searchParams);
    }
    setPage(searchParams.get("page"));
    getTableData();
  }, []);

  useEffect(() => {
    if (tableData.length > 0) {
      searchParams.set("page", page);
      setSearchParams(searchParams);
    }
    getTableData()
  }, [page]);

  useEffect(() => {
    getTableData()
  }, [searchFilter]);


  /*getEarningTableData function is used for getting earnings table data */
  async function getTableData() {
    setLoading(true);
    const { data, status } = await getEarningTableData(page, pageSize, searchFilter ?? "", "refer");
    if ([200, 201].includes(status)) {
      setTableData(data?.data_payload);
      setTotalCount(data?.total_page_size)
      setLoading(false);
    } else if ([400, 422].includes(status)) {
      toast.error(resData?.return_message ?? "Something went wrong");
    }
    setLoading(false);
  }

  /*getWithTableData function is used for getting withdrawns table data */

  const rowsData = tableData?.map((item, index) => {
    return {
      id: `${item?.id}` ?? "-",
      ranking: index,
      userId: `${item?.user_id}`,
      apply_type: `${item?.apply_type}` ?? "-",
      candidateName: `${item?.earnings_user_job_post?.first_name} ${item?.earnings_user_job_post?.last_name}` ?? "-",
      companyName: `${item?.earnings_user_job_post?.company_details?.company_name}` ?? "-",
      companyId: `${item?.company_id}` ?? "-",
      date: `${moment(item?.createdAt).format("DD-MM-YYYY HH:mm")}` ?? "-",
      amount: `${getSymbolFromCurrency(item?.currency) ? getSymbolFromCurrency(item?.currency) : ""} ${item?.amount}` ?? "-",
      earnings_type: `${item?.earnings_type}` ?? "-",
      email: `${item?.earnings_user_job_post?.email}` ?? "-",
    };
  });

  const columns = [
    {
      field: "ranking",
      headerName: "Ranking",
      // headerClassName: "column_styles",
      headerClassName: classes.headerCellStyles,
      minWidth: 150,
      flex: 1
    },

    {
      field: "candidateName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Candidate Name",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      headerName: "Company",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "email",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value?.slice(0, 10)}</span>,
    },
    {
      field: "amount",
      headerClassName: classes.headerCellStyles,
      headerName: "Amount",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
  ];

  return (
    <ThemeProvider theme={globalTheme}>
      <Box style={{ height: "65vh", width: "100%", }}>
        <DataGrid
          rows={rowsData}
          columns={columns}
          className={classes.root}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          loading={loading}
          slots={{
            // loadIcon: LoaderIcon,
            // baseButton: PrimaryButton,
            // basePopper: TablePopper,
            // baseSwitch: PrimarySwitch,
            // toolbar: GridToolbar,
            // noRowsOverlay: CustomNoRowsOverlay,
            pagination: props => (
              <Pagination
                {...props}
                // color="primary"
                count={Number(totalCount ?? 1)}
                page={Number(page)}
                rowsPerPage={Number(pageSize)}
                onChange={(_, newPage) => setPage(newPage)}
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": {
                    "&:hover": {
                      backgroundColor: "#cde2e7",
                      color: "#066a4c",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#024430",
                      color: "#ffe7bb",
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                    },
                  },
                }}
              />
            ),
          }}
        />
      </Box>
    </ThemeProvider>
  )
}

export default EarningsTable2;