import * as React from "react";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  SecondaryFilledChip,
  autocompleteProps,
} from "../../../assets/MUI/materialUiStyles";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  Grid,
  Stack,
  createFilterOptions,
  Typography,
  InputLabel,
  FormHelperText
} from "@mui/material";
import { v4 as uuid } from "uuid"


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 2,
};
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getAllKeySkillsWithStatus, createKeySkills } from "../../../helpers/dataFetcher/profile/profile";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function AddEditKeySkills({
  currentData,
  open,
  toggleModel,
  onSubmit,
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [allKeySkills, setAllKeySkills] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(searchParams.get("page") ?? 1);
  const [searchText, setSearchText] = React.useState('')
  const pageSize = 20;

  //   const searchParams = SearchParamHook();
  const filter = createFilterOptions();

  React.useEffect(() => {
    getAllKeySkills(page, pageSize, searchText);
  }, []);
  async function getAllKeySkills(page, pageSize, searchText) {
    const { data } = await getAllKeySkillsWithStatus(page, pageSize, searchText);
    const result = data?.data_payload?.map(item => ({
      value: item.id,
      name: item.name,
      isActive: item.is_active
    }));
    setAllKeySkills(result);
  }
  React.useEffect(() => {
    if (currentData) {
      setValue("key_skills", currentData);
    }
  }, [currentData]);

  return (
    <Modal
      open={open}
      onClose={() => {
        toggleModel();
        reset();
      }}
      aria-labelledby="edit-profile-modal-title"
      aria-describedby="edit-profile-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{ marginBottom: "15px" }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            Edit Key Skills
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit(async data => {
            /*start here */
            const obj = {
              skills: data?.key_skills?.map(item => item.value),
            };
            let o = Object.fromEntries(
              Object.entries(obj).filter(([_, v]) => v != null)
            );

            await updateUserProfileDetails("about_me", o);
            /*End Here */

            onSubmit();
            toggleModel();
          })}

        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={props => {
                  return (
                    <PrimaryAutoComplete
                      sx={{ width: "100%" }}
                      {...props}
                      disableCloseOnSelect
                      componentsProps={autocompleteProps}
                      multiple={true}
                      options={allKeySkills}
                      limitTags={2}
                      isOptionEqualToValue={(option, value) => option.value === value.value}

                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <SecondaryFilledChip
                            deleteIcon={<FeatherIcon icon={"x"} size="14" style={{ color: "#fff" }} />}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option, { selected }) => (
                        option.isActive ?
                          <li key={option.id} {...props}>
                            <Checkbox
                              icon={<FeatherIcon icon="square" size="14" />}
                              checkedIcon={
                                <FeatherIcon icon="check-square" size="14" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li> : null
                      )}
                      getOptionLabel={option => option?.name}
                      renderInput={params => (
                        <FormControl
                          variant="standard"
                          sx={{ width: "100%" }}
                        >
                          <InputLabel
                            shrink
                            htmlFor="key-skills-outlined"
                            error={errors.key_skills}
                            sx={{
                              color: "#000",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Required Skills (select all that applies)
                          </InputLabel>
                          <MultipleSelectInputs
                            sx={{
                              ...(errors.key_skills && {
                                borderColor: "red",
                              }),
                            }}
                            variant="standard"
                            ref={params.InputProps.ref}
                            {...params}
                          />
                          <FormHelperText error={errors.key_skills}>
                            {errors.key_skills ? errors.key_skills?.message : null}
                          </FormHelperText>
                        </FormControl>
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // // Suggest the creation of a new value
                        const isExisting = options.some(
                          option => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            isActive: true,
                            name: `${inputValue}`,
                            id: uuid()
                          });
                        }

                        return filtered;
                      }}
                      value={getValues("key_skills")}
                      onChange={async (_, data) => {
                        const lastIndex = data?.length - 1;
                        if (data && data[lastIndex]?.inputValue) {
                          const { data: skill } = await createKeySkills({
                            name: data[lastIndex].inputValue,
                            is_active: true,
                          });
                          await getAllKeySkills();
                          props?.field.onChange([
                            ...getValues("key_skills"),
                            {
                              name: skill?.key_skill_res.name,
                              value: skill?.key_skill_res.id,
                            },
                          ]);
                        } else {
                          props?.field.onChange(data);
                        }
                      }}
                    />
                  );
                }}
                name="key_skills"
                control={control}
                rules={{
                  required: "Key skills is required",
                  validate: (value) => (value && value?.length <= 20) || 'Key skills should not be more than 20'
                }}
              />
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={5}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Button
                  size="small"
                  loading={false}
                  loadingPosition="end"
                  variant="contained"
                  onClick={() => {
                    toggleModel();
                  }}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "#E6F0ED",
                    padding: "4px 8px",
                    fontSize: "14px",
                    border: "0.8px solid var(--app-button-color1)",
                    color: "var(--app-button-color1)",
                    "&:hover": {
                      boxShadow: "none",
                      color: "#fff",
                      background: "var(--app-button-color1)",
                    }
                  }}

                >
                  <span style={{ textTransform: "capitalize" }}>Cancel</span>
                </Button>
                <Button
                  type="submit"
                  size="small"
                  loading={false}
                  loadingPosition="end"
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                    padding: "4px 8px",
                    fontSize: "14px",
                    "&:hover": {
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                    }
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    Save
                  </span>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
