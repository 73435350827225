import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { imageURL } from "../../../Constants/commonURLS";
import {
  Avatar,
  Box,
  Stack,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { getSavedJobsList } from "../../../helpers/dataFetcher/jobs/jobs";
import JobDescription from "../../BrowseJobsList/JobDescription";
import { getJobPostById } from "../../../helpers/dataFetcher/jobs/jobs";
import { getCompanyById } from "../../../helpers/dataFetcher/companies";
import getSymbolFromCurrency from "currency-symbol-map";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import { useLocation, useSearchParams } from "react-router-dom";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TuneIcon from "@mui/icons-material/Tune";
import JobBagIcon from "../../../assets/icons/JobBagIcon";
import JobLocationIcon from "../../../assets/icons/JobLocationIcon";
import JobBookIcon from "../../../assets/icons/JobBookIcon";
import JobClockIcon from "../../../assets/icons/JobClockIcon";
import JobUsersIcon from "../../../assets/icons/JobUsersIcon";
import SavedJobDescription from "./SavedJobDescription";

function MySavedJobs() {
  const [savedJobs, setSavedJobs] = useState(null);
  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const cardRef = useRef(null);
  const [jobLoading, setJobLoading] = useState(true);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [mount, setMount] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("q");
  const selectedJobPostId = searchParams.get("job-post-id");
  const selectedCompanyId = searchParams.get("company-id");
  const savedJobListRef = useRef();

  const [currPage, setCurrPage] = React.useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    if (!mount) {
      setMount(true);
      return;
    }
    getSavedJobs();
    if (searchFilter && searchFilter !== "") {
      setCurrPage(1);
      setSavedJobs([]);
    }
  }, [searchFilter, currPage, mount]);

  async function getSavedJobs() {
    setJobLoading(true);
    const { data, status } = await getSavedJobsList(
      currPage,
      rowsPerPage,
      searchFilter ?? ""
    );

    if (!data?.data_payload?.length) {
      setJobLoading(false);
      return;
    }

    if (data?.pageNo === 1) {
      setSavedJobs(data?.data_payload);
    } else {
      setSavedJobs(prevItems => [...prevItems, ...data?.data_payload]);
    }

    if (!selectedJobPostId || selectedJobPostId === "") {
      searchParams.set("job-post-id", data?.data_payload?.[0]?.job_post_id);
      setSearchParams(searchParams);
    }
    if (!selectedCompanyId || selectedCompanyId === "") {
      searchParams.set(
        "company-id",
        data?.data_payload?.[0]?.company_user_details?.id
      );
      setSearchParams(searchParams);
    }
    setJobLoading(false);
    return;
  }

  const getjobById = async () => {
    setJobDetailsLoading(true);
    const jobDataList = await getJobPostById(selectedJobPostId);

    // const company_data_list = await getCompanyById(selectedCompanyId);
    // setCompany(company_data_list?.data?.data_payload);
    setJob(jobDataList?.data);
    setJobDetailsLoading(false);
  };

  useEffect(() => {
    if (
      selectedJobPostId ||
      (selectedJobPostId !== "" && selectedCompanyId) ||
      selectedCompanyId !== ""
    ) {
      getjobById();
    }
  }, [selectedJobPostId, selectedCompanyId]);

  const abbreviateNumber = number => {
    number = +number;
    const scaledNumber = number / 1000;
    if (number >= 1000) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    } else {
      return `${Number.parseFloat(number).toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };

  const onJobsListScroll = () => {
    if (savedJobListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = savedJobListRef.current;
      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 4) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          height: "85vh",
        }}
      >
        <Grid
          item
          xs={12}
          sm={4.5}
          sx={{
            height: "100%",
          }}
        >
          <Stack
            direction="column"
            sx={{
              height: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0px 2px 54px -35px #0000008C",
            }}
          >
            <Stack
              sx={{
                height: "105px",
                borderBottom: "1px solid #DEDEDE",
                padding: "10px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  marginBottom: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                  noWrap
                >
                  Saved Jobs{" "}
                </Typography>
                <IconButton aria-label="more" id="long-button" size="medium">
                  <MoreHorizIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  position: "relative",
                }}
              >
                <GlobalSearch sx={{ width: "100%", background: "#E9E9E980" }} />
                <IconButton
                  aria-label="more"
                  id="long-button"
                  size="small"
                  sx={{
                    position: "absolute",
                    right: "5px",
                  }}
                >
                  <TuneIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Stack>
            <Stack
              direction="column"
              sx={{
                height: "calc(100% - 105px)",
                maxHeight: "calc(100% - 105px)",
                padding: "10px",
                overflow: "auto",
                gap: "10px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              onScroll={onJobsListScroll}
              ref={savedJobListRef}
            >
              {jobLoading ? (
                <Stack
                  sx={{
                    height: "100%",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <>
                  {savedJobs?.length > 0 ? (
                    savedJobs?.map((item, index) => (
                      <Box
                        key={item?.id}
                        ref={cardRef}
                        onClick={() => {
                          searchParams.set("job-post-id", item?.job_post_id);
                          searchParams.set(
                            "company-id",
                            item?.company_user_details?.id
                          );
                          setSearchParams(searchParams);
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            border:
                              selectedJobPostId === item?.job_post_id
                                ? "2px solid #589B87"
                                : "0.8px solid #DEDEDE",
                            borderRadius: "16px",
                            padding: "15px",
                            gap: "10px",
                            background:
                              selectedJobPostId === item?.job_post_id
                                ? "#E6F0ED3D"
                                : "#F9F9F9",
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="space-between"
                            alignContent={"flex-start"}
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignContent={"flex-start"}
                              sx={{
                                // display: "-webkit-box",
                                WebkitBoxOrient: "horizontal",
                                overflow: "hidden",
                                width: "100%",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "44px",
                                  width: "44px",
                                  minWidth: "44px",
                                  borderRadius: "50%",
                                  border: "0.5px solid rgba(0, 0, 0, 0.25)",
                                }}
                              >
                                <Avatar
                                  src={`${imageURL}${item?.company_image}`}
                                  style={{
                                    borderRadius: "50%",
                                    borderColor: "rgba(0, 0, 0, 0.25)",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                >
                                  {item?.company_name?.charAt(0)}
                                </Avatar>
                              </Box>
                              <Box
                                sx={{
                                  // flexShrink: 0,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",

                                  cursor: "pointer",
                                  textTransform: "capitalize",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#121212",
                                  fontFamily: "Inter,sans-serif",
                                }}
                                fontWeight="md"
                                textColor="primary.plainColor"
                                mb={0.5}
                              >
                                <Typography
                                  noWrap
                                  // onClick={() =>
                                  //     navigate(
                                  //         `/job?id=${item?.id}&userId=${userId ?? ""}`
                                  //     )
                                  // }
                                >
                                  {item?.job_title ?? ""}
                                  {/* {item?.job_title?.length <= 20
                                    ? item?.job_title
                                    : item?.job_title?.slice(0, 18) + ".."} */}
                                </Typography>
                                <Typography
                                  noWrap
                                  fontWeight="md"
                                  textColor="primary.plainColor"
                                  mb={0.5}
                                  sx={{
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    color: "#121212",
                                    opacity: "50%",
                                    fontFamily: "Inter,sans-serif",
                                  }}
                                  // onClick={() =>
                                  //     navigate(
                                  //         `/employer-single-list?id=${item?.company_user_id}`
                                  //     )
                                  // }
                                >
                                  {item?.company_name}
                                </Typography>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                            >
                              <Box>
                                <Tooltip arrow placement="top" title={`
                                ${item?.job_post_refer_now_commission?.length > 0 && "Refer Now Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_now_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_now_commission?.[0]?.payout?.split("_")?.join(" ") || "")}
                              ${(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "")  ? "- Refer Self Commission " + (getSymbolFromCurrency(item?.currency) || "") + abbreviateNumber(item?.job_post_refer_self_commission?.[0]?.flat_rate) + "/" + (item?.job_post_refer_self_commission?.[0]?.payout?.split("_")?.join(" ") || "") : ""}
                              `}>
                                  <Typography noWrap
                                    fontWeight="md"
                                    textColor="primary.plainColor"
                                    mb={0.5}
                                    sx={{
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                      fontSize: "16px",
                                      fontWeight: "Bold",
                                      color: "#066a4c",
                                      textAlign: "center",
                                      // opacity: "50%",
                                      fontFamily: "Inter,sans-serif",
                                    }}
                                  >
                                    {getSymbolFromCurrency(item?.currency) || ""}

                                    {item?.job_post_refer_now_commission?.length > 0 && (
                                      <span style={{ color: "#066a4c" }}>
                                        {abbreviateNumber(item?.job_post_refer_now_commission[0]?.flat_rate)}
                                      </span>
                                    )}
                                    {(item?.job_post_refer_self_commission?.length > 0 && item?.job_post_refer_self_commission?.[0]?.flat_rate !== "") ? (
                                      <span style={{ color: "rgb(212, 133, 49)", marginLeft: "4px" }}>
                                        {"- "}
                                        {getSymbolFromCurrency(item?.currency) || ""}
                                        {abbreviateNumber(item?.job_post_refer_self_commission[0]?.flat_rate)}
                                      </span>
                                    ) : ""}
                                  </Typography>
                                </Tooltip>
                                <Typography noWrap
                                  fontWeight="md"
                                  textColor="primary.plainColor"
                                  mb={0.5}
                                  sx={{
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    color: "#121212",
                                    fontFamily: "Inter,sans-serif",
                                  }}
                                >Earn Upto</Typography>
                              </Box>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              gap: "10px",
                              "& hr": {
                                height: "22px",
                              },
                            }}
                          >
                            <Stack
                              spacing={1}
                              sx={{
                                WebkitBoxOrient: "horizontal",
                                overflow: "hidden",
                                width: "100%",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                textOverflow: "ellipsis",
                              }}
                              direction="row"
                            >
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{
                                  flexShrink: 0,
                                  overflow: "hidden",
                                  gap: "5px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <JobBagIcon />
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {`${item?.min_exp}${
                                    item?.min_exp_months > 0
                                      ? `.${item?.min_exp_months}`
                                      : ""
                                  }`}
                                  -
                                  {`${item?.max_exp}${
                                    item?.max_exp_months > 0
                                      ? `.${item?.max_exp_months}`
                                      : ""
                                  }`}
                                  Yrs
                                </Typography>
                              </Stack>
                              <Divider orientation="vertical" flexItem />
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{
                                  flexShrink: 0,
                                  overflow: "hidden",
                                  gap: "5px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {item?.job_type?.replace("_", " ")}
                                </Typography>
                              </Stack>
                              <Divider orientation="vertical" flexItem />
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  flexShrink: 0,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    {getSymbolFromCurrency(item?.currency)
                                      ? getSymbolFromCurrency(item?.currency)
                                      : ""}{" "}
                                  </span>
                                  {item?.min_sal <= 1000
                                    ? item?.min_sal
                                    : `${Math.round(item?.min_sal / 1000, 2)}k`}
                                  -
                                  {item?.max_sal <= 1000
                                    ? item?.max_sal
                                    : `${Math.round(item?.max_sal / 1000, 2)}k`}
                                  {/* {`/${item?.payout}`} */}
                                  {item?.job_type &&
                                  item?.job_type === "Full_Time"
                                    ? "/Year"
                                    : item?.job_type === "Contract"
                                    ? "/Hour"
                                    : ""}
                                </Typography>
                              </Stack>
                              <Divider orientation="vertical" flexItem />
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  // flex: 1,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  gap: "5px",
                                }}
                              >
                                <JobLocationIcon />
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {/* {item?.location?.length <= 17 ? item?.location : item?.location?.slice(0, 35) + ".."} */}
                                  {item?.location}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Stack>
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  gap: "5px",
                                }}
                              >
                                <JobBookIcon />
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {item?.educational_qualification_details?.map(
                                    (elem, index) => (
                                      <span key={index}>
                                        {
                                          elem?.education_qualification_spec_id
                                            ?.name
                                        }
                                        /
                                        {elem?.education_qualification_id?.name}
                                        .{" "}
                                      </span>
                                    )
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack>
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignContent="center"
                                spacing={1}
                                sx={{
                                  // flex: 1,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography noWrap>
                                  {item?.key_skills_details?.map(each => (
                                    <>
                                      &#x2022;{" "}
                                      <span
                                        style={{
                                          backgroundColor: "#E9F0EE",
                                          borderRadius: "4px",
                                          fontSize: "14px",
                                          padding: "3px",
                                        }}
                                      >
                                        {each?.name}
                                      </span>{" "}
                                    </>
                                  ))}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack
                              spacing={1}
                              sx={{
                                WebkitBoxOrient: "horizontal",
                                overflow: "hidden",
                                width: "100%",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                textOverflow: "ellipsis",
                              }}
                              direction="row"
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  flexShrink: 0,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  gap: "5px",
                                }}
                              >
                                <JobClockIcon />
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "13.5px", fontWeight: "500" }}
                                >
                                  {Math.floor(
                                    Math.abs(
                                      new Date() -
                                        new Date(
                                          item?.createdAt
                                            .slice(0, 10)
                                            .toLocaleString()
                                            .replace(/-/g, "/")
                                        )
                                    ) /
                                      (1000 * 3600 * 24)
                                  )}{" "}
                                  days ago
                                </Typography>
                              </Stack>
                              <span>&#x2022; </span>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  flexShrink: 0,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  gap: "5px",
                                }}
                              >
                                <JobUsersIcon />
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "13.5px", fontWeight: "500" }}
                                >
                                  {" "}
                                  {`${item?.applicants_count}`} Applicants
                                </Typography>
                              </Stack>
                              <span>&#x2022; </span>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  // flex: 1,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography
                                  noWrap
                                  sx={{ fontSize: "13.5px", fontWeight: "500" }}
                                >
                                  {Math.floor(
                                    Math.abs(
                                      new Date() -
                                        new Date(
                                          item?.application_deadline
                                            ?.slice(0, 10)
                                            ?.toLocaleString()
                                            ?.replace(/-/g, "/")
                                        )
                                    ) /
                                      (1000 * 3600 * 24)
                                  ) -
                                    Math.floor(
                                      Math.abs(new Date() - new Date()) /
                                        (1000 * 3600 * 24)
                                    )}{" "}
                                  days left to apply
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          margin: "10px",
                          color: "var(--text-color-one)",
                        }}
                      >
                        No data found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7.5}
          sx={{
            height: "100%",
            overflow: "auto",
            paddingLeft: "15px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "@media (max-width: 600px)": {
              paddingLeft: "0px",
              marginTop: "10px",
            },
          }}
        >
          {jobDetailsLoading ? (
            <Stack
              sx={{
                height: "100%",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="success" />
            </Stack>
          ) : (
            <>
              {selectedJobPostId &&
              selectedJobPostId !== "" &&
              typeof Job === "object" &&
              Object.keys(Job).length > 0 ? (
                <SavedJobDescription jobDetails={Job} />
              ) : (
                <Stack
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    minHeight: "100%",
                    border: "0.8px solid #DEDEDE",
                    borderRadius: "16px",
                    padding: "24px",
                    gap: "10px",
                    background: "#fff",
                    boxShadow: "0px 2px 54px -35px #0000008C",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                      <div className="contact_no_show">
                        <img
                          src={noJobs}
                          style={{ width: "250px", height: "250px" }}
                          alt="no jobs"
                        />
                        <p>No Job Selected</p>
                      </div>
                    </div>
                  </div>
                </Stack>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default MySavedJobs;
