import React, { useState, useEffect } from "react";
import MyModal from "./components/Modal";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Autocomplete, FormControl, InputAdornment, Select, MenuItem, Grid, Input } from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { PrimaryCurrencyInputs, PrimaryNormalFormHelperText, PrimaryNormalInputLabel, PrimaryNormalInputs } from "../../assets/MUI/materialUiStyles";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatNumberWithCommas } from "../../pages/Accounts/LocalProfile/formatNumber";
import { FormProvider, useForm } from "react-hook-form";
import cc from "currency-codes";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        },
    },
};

export default function ProfileCurrExpSalaryModal({ open, handleClose, salaryType, currValue, fieldName, edit, submitData, handleButtons, fieldActive }) {

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const currencies = cc.codes()?.map(item => item);
    const currValueObj = (currValue && currValue.length > 0) ? currValue[0] : {};

    useEffect(() => {
        setValue(fieldName, currValueObj[fieldName] || "")
        setValue("pay", currValueObj?.pay || "")
        setValue("work", currValueObj?.work || "")
        setValue("currency", currValueObj?.currency || "")
    }, [fieldActive])

    async function submitSalaryForm(event) {

        let finalData = {};
        let formData = Object.fromEntries(
            Object.entries(event).filter(([_, v]) => v != null)
        );


        if(salaryType === "expected") {
            finalData = { expected_salary : [formData || {}]}
        } else {
            finalData = { current_salary : [formData || {}]}
        }

        const result = await submitData(finalData);

        if (result) {
            handleButtons();
            handleClose();
        }

    }


    return (
        <MyModal
            open={open}
            handleClose={handleClose}
            sx={{ height: "fit-content", width: "50vw", minWidth: "300px" }}
            showCloseIcon
            title={salaryType === "expected" ? "Expected Salary Details" : "Current Salary Details"}
            size="medium"
        >
            <form onSubmit={handleSubmit(submitSalaryForm)}>
                <Stack mb={3} mt={2}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <Autocomplete
                                    componentsProps={{
                                        paper: {
                                            style: {
                                                borderRadius: "8px",
                                                width: "100%",
                                                boxShadow:
                                                    "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                                            },
                                        },
                                    }}
                                    popupIcon={<FeatherIcon icon="chevron-down" size={14} />}
                                    value={watch("currency") || ""}
                                    onChange={(event, newValue) => {
                                        setValue("currency", newValue?.toUpperCase());
                                        clearErrors("currency");
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setValue("currency", newInputValue?.toUpperCase());
                                        clearErrors("currency");
                                    }}
                                    {...register("currency", { required: "Currency is required" })}
                                    id="controllable-states-demo-1"
                                    options={currencies}
                                    // autoComplete
                                    // includeInputInList
                                    // filterSelectedOptions
                                    noOptionsText="No currencies"
                                    // filterOptions={x => x}
                                    renderInput={params => (
                                        <FormControl variant="standard">
                                            <PrimaryNormalInputLabel
                                                shrink
                                                htmlFor="currency"
                                                error={!!errors.currency} 
                                            >
                                                Currency
                                            </PrimaryNormalInputLabel>
                                            <PrimaryCurrencyInputs
                                                ref={params.InputProps.ref}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <PrimaryNormalFormHelperText error={!!errors.currency}>  
                                    {errors.currency && errors.currency.message}
                                </PrimaryNormalFormHelperText>
                            </FormControl>

                        </Grid>

                        <Grid item lg={9} md={9}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <PrimaryNormalInputLabel
                                    shrink
                                    htmlFor="min-sal-outlined"
                                    error={errors[fieldName]}
                                >
                                    {salaryType === "current" ? "Current Salary" : "Expected Salary"}
                                </PrimaryNormalInputLabel>
                                {salaryType === "current" ? (
                                    <PrimaryNormalInputs
                                        fullWidth
                                        placeholder="2000000"
                                        name="current_salary"
                                        {...register("current_salary", {
                                            required: "Current Salary is required",
                                            // max: Number(watch("current_salary")),
                                        })}
                                        value={watch("current_salary")}
                                        // value={watch("current_salary")?.includes("-") || watch("current_salary")?.includes("/") ? watch("current_salary")?.split(/[-/]/)[1] : watch("current_salary")}
                                        onInput={e => {
                                            const rawValue = e.target.value.replace(/,/g, "");
                                            const formattedValue = formatNumberWithCommas(rawValue);
                                            setValue("current_salary", formattedValue);
                                        }}
                                        id="min-sal-outlined"
                                        autoFocus
                                        type={"text"}
                                        error={errors?.current_salary ? true : false}
                                        endAdornment={
                                            <InputAdornment
                                                sx={{
                                                    background: "white",
                                                    position: "absolute",
                                                    right: "10px",
                                                }}
                                                position="end"
                                            >
                                                {getSymbolFromCurrency(watch("currency"))}
                                            </InputAdornment>
                                        }
                                        variant="outlined"
                                    />
                                ) : (
                                    <PrimaryNormalInputs
                                    fullWidth
                                    placeholder="2000000"
                                    name="expected_salary"
                                    {...register("expected_salary", {
                                        required: "Expected Salary is required",
                                        // max: Number(watch("current_salary")),
                                    })}
                                    value={watch("expected_salary")}
                                    onInput={e => {
                                        const rawValue = e.target.value.replace(/,/g, "");
                                        const formattedValue = formatNumberWithCommas(rawValue);
                                        setValue("expected_salary", formattedValue);
                                    }}
                                    id="min-sal-outlined"
                                    autoFocus
                                    type={"text"}
                                    error={errors?.expected_salary ? true : false}
                                    endAdornment={
                                        <InputAdornment
                                            sx={{
                                                background: "white",
                                                position: "absolute",
                                                right: "10px",
                                            }}
                                            position="end"
                                        >
                                            {getSymbolFromCurrency(watch("currency"))}
                                        </InputAdornment>
                                    }
                                    variant="outlined"
                                />
                                )}
                                <PrimaryNormalFormHelperText
                                    error={errors[fieldName]}
                                >
                                    {errors[fieldName] ? errors[fieldName]?.message : null}
                                </PrimaryNormalFormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <PrimaryNormalInputLabel
                                    shrink
                                    htmlFor="min-sal-outlined"
                                    error={errors.pay}
                                >
                                    Pay out
                                </PrimaryNormalInputLabel>
                                <Select
                                    labelId="pay"
                                    id="pay"
                                    name="pay"
                                    type="select"
                                    value={watch("pay") || ""}
                                    {...register("pay", { required: "Employment Type is required" })}

                                    onChange={(event) => {
                                        setValue("pay", event.target.value);
                                        clearErrors("pay")
                                    }}
                                    input={
                                        <PrimaryNormalInputs
                                            sx={{ width: "100%" }}
                                            error={errors["pay"] ? true : false}
                                            {...register("pay", { required: "Pay Out is required", })}
                                        />
                                    }

                                    MenuProps={MenuProps}
                                >
                                    {[
                                        { name: "Hourly", value: "Hourly" },
                                        { name: "Weekly", value: "Weekly" },
                                        { name: "Monthly", value: "Monthly" },
                                        { name: "Annually", value: "Annually" },
                                    ]?.map(item => {
                                        return (
                                            <MenuItem
                                                key={item?.value}
                                                value={item?.value} // style={getStyles(name, personName, theme)}
                                            >
                                                {item?.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <PrimaryNormalFormHelperText
                                    error={errors?.pay}
                                >
                                    {errors?.pay ? errors?.pay?.message : null}
                                </PrimaryNormalFormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <PrimaryNormalInputLabel
                                    shrink
                                    htmlFor="min-sal-outlined"
                                    error={errors.work}
                                >
                                    Employment Type
                                </PrimaryNormalInputLabel>
                                <Select
                                    labelId={"work"}
                                    id={"work"}
                                    name="work"
                                    type="select"
                                    value={watch("work") || ""}
                                    {...register("work", { required: "Employment Type is required" })}
                                    onChange={(event) => {
                                        setValue("work", event.target.value);
                                        clearErrors("work")
                                    }}
                                    input={
                                        <PrimaryNormalInputs
                                            sx={{ width: "100%" }}
                                            error={errors["work"] ? true : false}
                                            {...register("work", { required: "Employment Type is required" })}
                                        />
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {[
                                        { name: "Permanent", value: "Permanent" },
                                        { name: "Temporary", value: "Temporary" },
                                        { name: "Contract", value: "Contract" },
                                        { name: "Intern", value: "Intern" },
                                    ]?.map(item => {
                                        return (
                                            <MenuItem
                                                key={item?.value}
                                                value={item?.value} // style={getStyles(name, personName, theme)}
                                            >
                                                {item?.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <PrimaryNormalFormHelperText
                                    error={errors?.work}
                                >
                                    {errors?.work ? errors?.work?.message : null}
                                </PrimaryNormalFormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>



                </Stack>
                <Stack direction="row" alignItems={"flex-end"} justifyContent={"flex-end"} spacing={2}>
                    <Button
                        size="small"
                        loading={false}
                        loadingPosition="end"
                        variant="contained"
                        onClick={() => {
                            handleClose()
                        }}
                        sx={{
                            borderRadius: "8px",
                            boxShadow: "none",
                            background: "#E6F0ED",
                            padding: "4px 8px",
                            fontSize: "14px",
                            border: "0.8px solid var(--app-button-color1)",
                            color: "var(--app-button-color1)",
                            "&:hover": {
                                boxShadow: "none",
                                color: "#fff",
                                background: "var(--app-button-color1)",
                            }
                        }}
                    >
                        <span style={{ textTransform: "capitalize" }}>
                            Cancel
                        </span>
                    </Button>
                    <Button
                        type="submit"
                        size="large"
                        loading={false}
                        loadingPosition="end"
                        variant="contained"
                        sx={{
                            borderRadius: "8px",
                            boxShadow: "none",
                            background: "var(--app-button-color1)",
                            padding: "4px 8px",
                            WebkitBoxOrient: "horizontal",
                            overflow: "hidden",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            textOverflow: "ellipsis",
                            "&:hover": {
                                boxShadow: "none",
                                background: "var(--app-button-color1)",
                            }
                        }}
                    >
                        <span style={{ textTransform: "capitalize" }}>Submit</span>
                    </Button>

                </Stack>
            </form>
        </MyModal>
    );
}
