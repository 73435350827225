import { Card, CardContent, Grid, Stack } from "@mui/material";

import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ProfileInputFieldForm from "./ProfileInputFieldForm";
import { FormProvider, useForm } from "react-hook-form";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
import ProfileSelectFieldForm from "./ProfileSelectFieldForm";
import ProfileLocationFieldForm from "./ProfileLocationFieldForm";
import ProfilePhoneFieldForm from "./ProfilePhoneFieldForm";

export default function CandidateInformation({ setRecall, reCall }) {


  const accountData = useSelector((state) => state.User.user);

  const ageObj = accountData?.dob ? `${moment(accountData.dob).format("DD/MM/YYYY")}-(${accountData?.age_of_candidate} Yrs)` : "N/A"
  const age = ageObj.toString();



  async function submitData(data) {

    let formData = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null)
    );

    const { status, data: resData } = await updateUserProfileDetails("about_me", formData);
    if ([201, 200].includes(status)) {
      setRecall(!reCall);
      return true;
    }

  }


  return (
    <Stack spacing={2} p={1}>
      <ProfileInputFieldForm
        textField={"First Name"}
        id="first_name"
        placeholder="Enter First Name"
        name="first_name"
        type="text"
        textValue={accountData?.first_name ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "First Name is required",
          maxLength: {
            value: 200,
            message: "First Name must not exceed 200 characters",
          },
        }}
      />

      <ProfileInputFieldForm
        textField={"Last Name"}
        id="last_name"
        placeholder="Enter Last Name"
        name="last_name"
        type="text"
        textValue={accountData?.last_name ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Last Name is required",
          maxLength: {
            value: 200,
            message: "Last Name must not exceed 200 characters",
          },
        }
        }
      />

      <ProfileSelectFieldForm
        textField={"Gender"}
        id="gender"
        placeholder="Enter Last Name"
        name="gender"
        type="select"
        options={[
          { name: "Male", value: "male" },
          { name: "Female", value: "female" },
          { name: "Prefer not to say", value: "prefer_not_to_say" },
        ]}
        textValue={accountData?.gender ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Gender is required",
        }
        }
      />

      {/* <InputButtonField
        text_field={"Current Company"}
        input_value={accountData?.current_company}
        // input_value={""}
        type="input"
        update_type={"current_company"}
        wholeData={accountData}
        setRecall={setRecall}
        reCall={reCall}
      />

      <InputButtonField
        text_field={"Current Designation"}
        input_value={accountData?.current_postition}
        // input_value={""}
        type="input"
        update_type={"current_postition"}
        wholeData={accountData}
        setRecall={setRecall}
        reCall={reCall}
      /> */}

      <ProfileInputFieldForm
        textField={"Current Department"}
        id="current_department"
        placeholder="Enter Current Department"
        name="current_department"
        type="text"
        textValue={accountData?.current_department ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Current Department is required",
          maxLength: {
            value: 200,
            message: "Current Department must not exceed 200 characters",
          },
        }}
      />
      <ProfileInputFieldForm
        textField={"Current Industry"}
        id="current_industry"
        placeholder="Enter Current Industry"
        name="current_industry"
        type="text"
        textValue={accountData?.current_industry ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Current Industry is required",
          maxLength: {
            value: 200,
            message: "Current Industry must not exceed 200 characters",
          },
        }}
      />
      <ProfileLocationFieldForm
        textField={"Current Location"}
        id="work_location"
        placeholder="Enter Current Location"
        name="work_location"
        type="text"
        textValue={accountData?.work_location?.[0] ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Current Location is required",
        }}
      />
      <ProfileInputFieldForm
        textField={"Birth Date"}
        id="dob"
        inputProps={{
          max: new Date().toISOString().slice(0, 10)
        }}
        // format="DD/MM/YYYY"
        placeholder="Enter Birth Date"
        name="dob"
        type="date"
        textValue={accountData?.dob ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Birth Date is required",
        }}
      />
      <ProfileInputFieldForm
        textField={"Current Address"}
        id="current_address"
        placeholder="Enter Current Address"
        name="current_address"
        type="text"
        textValue={accountData?.current_address ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Current Address is required",
          maxLength: {
            value: 200,
            message: "Current Address must not exceed 200 characters",
          },
        }}
      />

      <ProfilePhoneFieldForm
        textField={"Other Contact"}
        id="other_contact"
        placeholder="Enter Other Contact"
        name="other_contact"
        textValue={accountData?.other_contact ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Other Contact is required",
        }}
      />

    </Stack>
  );
}