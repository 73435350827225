import React, { useEffect, useState } from "react";
import "../../Accounts/Profiles/profile.css";
import ProfileModal from "../Profiles/ProfileModals/ProfileModals";
import UploadModel from "../../../components/common/uploadModel";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../../../assets/MUI/materialUiStyles";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import {
  getProfile,
  updateUserProfileDetails,
} from "../../../helpers/dataFetcher/profile/profile";
import FileViewer from "react-file-viewer";
import {
  updateDeleteProfile,
  updateProfileNormal,
} from "../../../helpers/dataFetcher/profile/update";
import { imageURL } from "../../../Constants/commonURLS";
import moment from "moment";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Skeleton,
  Grid,
  Stack,
  Typography,
  Box,
  Chip,
} from "@mui/material";

import { useSelector } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import { parseNumberInput } from "../../../hooks/customPhoneNumberParse";
import AddEditWorkExperience from "./AddEditWorkExperience";
import ProfileEditIcon from "../../../assets/icons/ProfileEditIcon";
import { convertExperience } from "../../../helpers/CalculateYear";

function ProfileHeader({ accLoading, dataFetcher }) {
  const [profileModal, setProfileModal] = useState({
    modal: false,
    type: "",
    action: "",
    modalType: "",
    details: {},
    file: "",
  });

  const [workExpModel, setWorkExpModel] = useState(false);
  const userData = useSelector(state => state.User.user);

  const [currentId, setCurrentId] = useState("");

  // const supportedFileTypes = ["pdf", "docx", "xlsx", "csv"];
  // const filePath = userData ? `${imageURL}${userData.resume_url}` : "";

  // let fileType = supportedFileTypes.find(type => {
  //   const fileExtension = filePath.split(".").pop().toLowerCase();
  //   return supportedFileTypes.includes(fileExtension);
  // });

  // // If no supported file type is found, set a default fileType
  // if (!fileType) {
  //   fileType = "docx";
  // }

  const currentStatus =
    userData?.work_exp?.filter(item => item?.is_currently_working)[0] ??
    userData?.work_exp?.[0] ??
    null;

  async function updateOrEditWorExperience(work_exp_data) {
    const isCurrWrking = work_exp_data?.is_currently_working;

    const obj1 = { ...work_exp_data };
    let updatedData;
    if (isCurrWrking) {
      updatedData = userData?.work_exp
        ?.map(item => ({
          ...item,
          is_currently_working: false,
          end_date: moment(new Date()).format("MM/DD/YYYY"),
        }))
        ?.filter(item => item.id !== currentId);
    } else {
      updatedData = [...userData?.work_exp]?.filter(
        item => item.id !== currentId
      );
    }
    const updatedWorkExpData = Array.isArray(updatedData) ? updatedData : [];
    const obj = {
      work_exp: [...updatedWorkExpData, obj1],
    };

    await updateUserProfileDetails("about_me", obj);
    dataFetcher();
    toggleModel();
  }

  function toggleModel() {
    setCurrentId(currentStatus?.id);
    setWorkExpModel(!workExpModel);
  }

  useEffect(() => {
    setCurrentId(currentStatus?.id);
  }, []);

  return (
    <>
      {!accLoading ? (
        <>
          <ProfileModal
            setProfileModal={doc => setProfileModal(doc)}
            show={profileModal.modal}
            onHide={data => {
              setProfileModal({
                modal: data,
                modalType: "",
                type: "",
                id: "",
                details: {},
              });
            }}
            params={profileModal}
          />
          <Grid container pb={2} spacing={2}>
            {/* Profile top left Section */}
            <Grid item xs={12} sm={6}>
              <Stack
                direction="row"
                sx={{
                  height: "100%",
                  border: "0.8px solid #DEDEDE",
                  borderRadius: "16px",
                  padding: "15px",
                  gap: "12px",
                  background: "#F9F9F9",
                  boxShadow: "0px 2px 54px -35px #0000008C",
                }}
              >
                <Stack
                  direction="column"
                  sx={{
                    minWidth: "130px",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "110px",
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={Number(`${100 - userData?.pending_percentage}`)}
                      sx={{
                        color:
                          userData?.pending_percentage === 0
                            ? "#589B87"
                            : userData?.pending_percentage <= 50 &&
                              userData?.pending_percentage > 0
                            ? "#fcba03"
                            : userData?.pending_percentage < 90
                            ? "#f0820e"
                            : "#d10000",
                      }}
                      thickness={3}
                      size={100}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        onClick={() =>
                          setProfileModal({
                            modal: "center",
                            type: "imageUpload",
                          })
                        }
                        sx={{
                          position: "relative",
                          padding: "0px!important",
                          "&:hover > div:first-child": {
                            display: "flex",
                          },
                        }}
                      >
                        <Avatar
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            display: "none",
                            alignItems: "center",
                            zIndex: 999,
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            fontSize: "13px",
                            color: "#fff",
                          }}
                        >
                          Edit
                        </Avatar>
                        <Avatar
                          alt="A"
                          src={`${imageURL}${userData?.profile_image}`}
                          sx={{
                            width: 83,
                            height: 83,
                            background: "#cde2e7",
                            color: "#066a4c",
                          }}
                        >
                          <span className="profile_letter_txt">
                            {userData.first_name?.charAt(0) +
                              " " +
                              userData.last_name?.charAt(0)}
                          </span>
                        </Avatar>
                      </IconButton>
                    </Box>
                  </Box>
                  <Chip
                    label={`${
                      userData?.pending_percentage === 0
                        ? "100% Completed"
                        : userData?.pending_percentage + "% Pending"
                    }`}
                    sx={{
                      background:
                        userData?.pending_percentage === 0
                          ? "var(--app-button-color1)"
                          : "#F2AD492E",
                      padding: "5px 0px",
                      marginTop: "10px",
                      borderRadius: "83.88px",
                      "& .MuiChip-label": {
                        fontWeight: "600",
                        display: "block",
                        color:
                          userData?.pending_percentage === 0 ? "#fff" : "#000",
                        whiteSpace: "normal",
                        textAlign: "center",
                        fontSize: "12px",
                      },
                    }}
                  />
                </Stack>
                <Stack
                  sx={{
                    width: "calc(100% - 130px)",
                    WebkitBoxOrient: "horizontal",
                    overflow: "hidden",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textOverflow: "ellipsis",
                  }}
                  justifyContent="center"
                  // alignItems="center"
                >
                  <Stack
                    direction="row"
                    sx={{
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "30px",
                        fontWeight: "600",
                      }}
                    >
                      {userData.first_name} {userData.last_name}
                    </Typography>
                    {/* <Box>{`>`}</Box> */}
                  </Stack>
                  <Box>
                    <Typography
                      noWrap
                      sx={{
                        color: "#7A7A7A",
                        fontSize: "14px",
                      }}
                    >
                      {userData?.email ? userData?.email : ""}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      noWrap
                      sx={{
                        color: "#7A7A7A",
                        fontSize: "14px",
                      }}
                    >
                      {userData?.phone_number
                        ? parseNumberInput(userData?.phone_number)
                        : ""}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>

            {/* Profile top right section */}
            <Grid item xs={12} sm={6}>
              <Stack
                direction="row"
                sx={{
                  gap: "10px",
                  height: "100%",
                  border: "0.8px solid #DEDEDE",
                  borderRadius: "16px",
                  padding: "15px",
                  gap: "10px",
                  background: "#FEF8EF",
                  boxShadow: "0px 2px 54px -35px #0000008C",
                }}
                justifyContent="space-between"
              >
                <Stack
                  direction="column"
                  sx={{
                    WebkitBoxOrient: "horizontal",
                    overflow: "hidden",
                    width: "100%",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Box mb={2}>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: "700" }}
                      noWrap
                    >
                      Current Status
                    </Typography>
                  </Box>
                  <Box mb={0.5}>
                    <Typography noWrap>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#676767",
                          width: "300px",
                        }}
                      >
                        Working at
                      </span>{" "}
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        :{" "}
                        {userData?.current_company &&
                        userData?.current_company != "-"
                          ? userData?.current_company
                          : currentStatus?.employer ?? "N/A"}
                      </span>
                    </Typography>
                  </Box>
                  <Box mb={0.5}>
                    <Typography noWrap>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#676767",
                        }}
                      >
                        Job Role
                      </span>{" "}
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        :{" "}
                        {userData?.current_postition &&
                        userData?.current_postition != "-"
                          ? userData?.current_postition
                          : currentStatus?.title ?? "N/A"}{" "}
                      </span>
                    </Typography>
                  </Box>
                  <Box mb={0.5}>
                    <Typography noWrap>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#676767",
                        }}
                      >
                        Experience
                      </span>{" "}
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        :{" "}
                        {(() => {
                          if (userData?.experience) {
                            return convertExperience(userData?.experience);
                          }
                          if (currentStatus?.start_date) {
                            const startDate = new Date(
                              currentStatus?.start_date
                                ?.slice(0, 10)
                                .replace(/-/g, "/")
                            );
                            const endDate = currentStatus?.end_date
                              ? new Date(
                                  currentStatus?.end_date
                                    ?.slice(0, 10)
                                    .replace(/-/g, "/")
                                )
                              : new Date();

                            const yearsDifference =
                              endDate.getFullYear() - startDate.getFullYear();
                            const monthsDifference =
                              endDate.getMonth() - startDate.getMonth();

                            let totalMonths =
                              yearsDifference * 12 + monthsDifference;

                            if (endDate.getDate() < startDate.getDate()) {
                              totalMonths--;
                            }
                            const years = Math.floor(totalMonths / 12);
                            const months = totalMonths % 12;

                            return `${years} Year(s), ${months} Month(s)`;
                          } else {
                            return `N/A`;
                          }
                        })()}{" "}
                      </span>
                    </Typography>
                  </Box>
                  <Box mb={0.5}>
                    <Typography noWrap>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#676767",
                        }}
                      >
                        Location
                      </span>{" "}
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        : {currentStatus?.location ?? "N/A"}
                      </span>
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography noWrap>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#676767",
                      }}
                    >
                      Last Updated On
                    </span>{" "}
                    :{" "}
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      {moment(userData?.updatedAt).format("DD/MM/YYYY")}
                    </span>
                  </Typography>

                  <Chip
                    label="Update Staus"
                    sx={{
                      background: "var(--app-button-color1)",
                      padding: "5px 15px",
                      "& .MuiChip-label": {
                        fontWeight: "600",
                        display: "block",
                        color: "#fff",
                        whiteSpace: "normal",
                        textAlign: "center",
                      },
                    }}
                    onDelete={() => {}}
                    onClick={() => {
                      setCurrentId(currentStatus?.id);
                      toggleModel();
                    }}
                    disabled={!currentStatus}
                    deleteIcon={<ProfileEditIcon color="#fff" />}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container pb={2} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Stack>
                    {" "}
                    <Skeleton variant="circular" width={90} height={90} />
                  </Stack>
                  <Stack width={"100%"}>
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                  </Stack>
                </Stack>
                <Stack spacing={2}>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Stack>
                    {" "}
                    <Skeleton variant="circular" width={90} height={90} />
                  </Stack>
                  <Stack width={"100%"}>
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                  </Stack>
                </Stack>
                <Stack spacing={2}>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      <ThemeProvider theme={globalTheme}>
        <AddEditWorkExperience
          toggleModel={toggleModel}
          open={workExpModel}
          type={"update"}
          currentId={currentId}
          // currentData={userData?.work_exp[updateIndex]}
          updateOrEditWorExperience={updateOrEditWorExperience}
        />
      </ThemeProvider>
    </>
  );
}

export default ProfileHeader;
