import { KeyboardArrowDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField, Input, Select, FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { React, useState } from "react";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "10px",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
};

export default function CustomSelect({
  label,
  options,
  value,
  setValue,
  sx,
  error,
  helperText,
  required,
  onChange,
  variant,
  useFormRef,
  ...rest
}) {

  const handleValueChange = (event) => {
    if (event) {
      setValue(event?.target?.value);
      onChange(event);
    };
  };

  return (
    <ThemeProvider theme={globalTheme}>
      {label ? <InputLabel required={required} sx={{ height: "fit-content", borderColor: error ? "var(--error-color)" : null }}>{label}</InputLabel> : null}
      <FormControl variant={variant ?? "standard"} sx={{ width: "100%" }}>
        <Select
          {...rest}
          MenuProps={MenuProps}
          value={value}
          {...useFormRef}
          onChange={handleValueChange}

          sx={[
            {
              backgroundColor: "var(--paper-color)",
              border: "1px solid #c1c1c1",
              borderRadius: "8px",
              // padding: "0px 10px 0px 10px",
              width: "100%",
              height: "41px !important",
              ".css-1g8i5j6-MuiSelect-select-MuiInputBase-input-MuiInput-input": {
                height: "unset !important",
              }
            },
            sx,
            error
              ? {
                borderColor: "var(--error-color)",
              }
              : null,
          ]}
          // input={
          //   <Input
          //     sx={[
          //       {
          //         width: "100%",
          //         textTransform: "capitalize"
          //       },
          //     ]}
          //     {...rest}
          //     error={error}
          //     {...useFormRef}
          //   />
          // }
      
        >
          {options?.map(item => {
            return (
              <MenuItem
                key={item?.value}
                value={item?.value}
                sx={{
                  fontSize: "13px"
                }}
              >
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
        {error ? (
          <Typography
            variant="body2"
            sx={{
              fontSize: '11px',
              fontWeight: '400',
              color: 'var(--error-color)',
            }}
          >
            {helperText}
          </Typography>
        ) : null}
      </FormControl>
    </ThemeProvider>
  );
}




// Commenting for better understanding

