import BlogCatImg2 from "../assets/icons/Blogs/BlogCatImg2.jpeg";
import BlogCatImg3 from "../assets/icons/Blogs/BlogCatImg3.jpeg";
import BlogCatImg4 from "../assets/icons/Blogs/BlogCatImg4.jpeg";
import BlogCatImg5 from "../assets/icons/Blogs/BlogCatImg5.jpeg";
import UserIcon from "../assets/icons/Careers/UserIcon";

export const datailAllItem = [
  {
    id: "1",
    headImage: BlogCatImg2,
    category: "Information Technology",
    date: "October 23, 2023",
    headTitle: "JOINEE COMMUNITY",
    desc: "In a world where traditional hiring processes often feel cumbersome and detached, Joinee.com emerges as a breath of fresh air, reshaping the recruitment landscape through community-driven collaboration.",
    blogPoster: {
      avatar: UserIcon,
      name: "Dileep Kumar",
      designation: "CEO at Syoft",
      socialMedia: [
        {
          id: "1",
          type: "linkedin",
          link: "https://joinee.com/",
        },
        {
          id: "2",
          type: "facebook",
          link: "https://joinee.com/",
        },
        {
          id: "3",
          type: "twitter",
          link: "https://joinee.com/",
        },
        {
          id: "4",
          type: "instagram",
          link: "https://joinee.com/",
        },
        {
          id: "5",
          type: "youtube",
          link: "https://joinee.com/",
        },
      ],
    },
    headText:
      "In a world where traditional hiring processes often feel cumbersome and detached, Joinee.com emerges as a breath of fresh air, reshaping the recruitment landscape through community-driven collaboration.",
    headDesc:
      "At its core, Joinee is not merely a platform; it's a movement that transforms every candidate into a recruiter and leverages the collective strength of a 100,000 strong network.",
    details: [
      {
        id: "1",
        title: "The Community-Driven Approach",
        description: "",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Collaboration at its Core:",
            description:
              "Joinee.com introduces a revolutionary concept – turning candidates into active participants in the hiring process. Imagine a vast community where individuals aren't just job seekers but also contributors to the recruitment ecosystem. This collaborative approach is what sets Joinee apart.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Empowering Candidates: ",
            description:
              "The traditional job search often feels like a one-way street. Joinee flips the script by empowering candidates to become recruiters. When individuals are given the tools to refer, connect, and actively shape the job market, the result is a dynamic and engaged community.",
            image: "",
          },
        ],
      },
      {
        id: "2",
        title: "How Joinee Works ",
        description: "",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Simple and Effective: ",
            description:
              "The process begins with employers posting job openings, setting reward percentages, and receiving shortlisted candidates with detailed scorecards. On the other side, job seekers explore opportunities, get referred by their network, and track their referral progress through a personalized dashboard. The simplicity is matched only by its effectiveness. ",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Transformative for Employers: ",
            description:
              "For employers, Joinee.com means more than just finding suitable candidates. It's about tapping into a vast talent pool, reducing hiring costs, and accelerating the time-to-fill positions. The community-driven referrals often lead to higher quality hires, thanks to the personal touch introduced by the candidates-turned-recruiters. ",
            image: "",
          },
          {
            id: "3",
            type: "text",
            heading: "Transformative for Employers: ",
            description:
              "Job seekers, too, experience a personalized journey. By leveraging their network, they go beyond traditional job applications. They get referred based on their skills and qualifications, leading to a more meaningful connection with potential employers. ",
            image: "",
          },
        ],
      },
      {
        id: "3",
        title: "The Impact on Traditional Hiring ",
        description:
          "Joinee.com challenges the status quo of traditional hiring processes. By blending technology with a human-centric approach, it addresses the limitations of conventional methods. The network effect ensures that both employers and job seekers benefit from a streamlined and efficient recruitment experience. ",
      },
      {
        id: "4",
        title: "Join the Revolution  ",
        description:
          "Whether you're an employer seeking top talent or an individual looking for the perfect job opportunity, Joinee.com invites you to join the revolution. Experience a recruitment process that goes beyond the transactional and embraces the power of community. \n\n Joinee.com is not just a platform; it's a paradigm shift in how we approach hiring. It's about fostering connections, building relationships, and creating a community where everyone plays a role in shaping their professional destinies. \n\n Are you ready to be a part of the Joinee revolution? Sign up today and unlock the power of community in recruitment!  ",
      },
    ],
  },
  {
    id: "2",
    headImage: BlogCatImg5,
    category: "Recruting",
    date: "July 13, 2024",
    headTitle:
      "Unveiling the Power of Automated Resume Screening: 5 Benefits Transforming Recruitment",
    desc: "In the ever-evolving landscape of recruitment, technology continues to play...",
    blogPoster: {
      avatar: UserIcon,
      name: "Dileep Kumar",
      designation: "CEO at Syoft",
      socialMedia: [
        {
          id: "1",
          type: "linkedin",
          link: "https://joinee.com/",
        },
        {
          id: "2",
          type: "facebook",
          link: "https://joinee.com/",
        },
        {
          id: "3",
          type: "twitter",
          link: "https://joinee.com/",
        },
        {
          id: "4",
          type: "instagram",
          link: "https://joinee.com/",
        },
        {
          id: "5",
          type: "youtube",
          link: "https://joinee.com/",
        },
      ],
    },
    headText: "",
    headDesc:
      "In the ever-evolving landscape of recruitment, technology continues to play a pivotal role in streamlining processes and enhancing efficiency. One such transformative tool is automated resume screening, a game-changer for employers seeking to navigate the sea of resumes efficiently. Let's delve into five key benefits that highlight the impact of automated resume screening in modern recruitment practices.",
    details: [
      {
        id: "1",
        title: "",
        description: "",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Time Efficiency Sorting Through the Resume Deluge",
            description:
              "Manually sifting through a large number of resumes can be a time-consuming task for recruiters. Automated resume screening swiftly analyzes resumes against predefined criteria, drastically reducing the time required to identify qualified candidates. This time efficiency enables recruiters to focus on strategic aspects of the hiring process.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Improved Accuracy and Consistency Eliminating Human Bias",
            description:
              "Automated systems follow predefined algorithms without succumbing to human biases, ensuring a fair and consistent screening process. This helps in mitigating unconscious bias, promoting diversity, and enhancing the objectivity of candidate evaluations. The result is a more equitable and merit-based selection process.",
            image: "",
          },
          {
            id: "3",
            type: "text",
            heading:
              "Enhanced Candidate Experience Swift Feedback and Transparency",
            description:
              "Candidates appreciate a swift and transparent recruitment process. Automated resume screening ensures that candidates receive timely feedback, creating a positive impression of the employer's efficiency. The improved candidate experience contributes to a favorable employer brand and can attract top talent.",
            image: "",
          },
          {
            id: "4",
            type: "text",
            heading: "Scalability Adapting to Growing Hiring Needs",
            description:
              "As companies grow, so does the volume of job applications. Automated resume screening scales effortlessly to handle an increasing number of resumes, ensuring that the recruitment process remains efficient and effective. This scalability is particularly crucial for organizations experiencing rapid expansion.",
            image: "",
          },
          {
            id: "5",
            type: "text",
            heading:
              "Focus on Strategic Decision-Making Shifting from Routine to Strategy",
            description:
              "Automated resume screening liberates recruiters from routine tasks, allowing them to concentrate on strategic decision-making. With more time at their disposal, recruiters can engage in thoughtful candidate interactions, conduct in-depth interviews, and contribute to shaping the overall recruitment strategy of the organization.",
            image: "",
          },
        ],
      },
      {
        id: "3",
        title: "Conclusion: A Strategic Evolution in Recruitment",
        description:
          "The adoption of automated resume screening marks a strategic evolution in recruitment practices. By leveraging technology to efficiently handle the initial stages of candidate evaluation, employers can save time, enhance accuracy, and provide a more positive experience for both recruiters and candidates. As the recruitment landscape continues to evolve, the benefits of automated resume screening position it as an indispensable tool for organizations aiming to build agile, diverse, and high-performing teams.",
      },
    ],
  },
  {
    id: "3",
    headImage: BlogCatImg4,
    category: "Staffings",
    date: "July 15, 2024",
    headTitle:
      "Embracing Remote Hiring: A Strategic Move for Startups and SMEs",
    desc: "In the dynamic landscape of modern business...",
    blogPoster: {
      avatar: UserIcon,
      name: "Dileep Kumar",
      designation: "CEO at Syoft",
      socialMedia: [
        {
          id: "1",
          type: "linkedin",
          link: "https://joinee.com/",
        },
        {
          id: "2",
          type: "facebook",
          link: "https://joinee.com/",
        },
        {
          id: "3",
          type: "twitter",
          link: "https://joinee.com/",
        },
        {
          id: "4",
          type: "instagram",
          link: "https://joinee.com/",
        },
        {
          id: "5",
          type: "youtube",
          link: "https://joinee.com/",
        },
      ],
    },
    headText: "",
    headDesc:
      "In the dynamic landscape of modern business, the rise of remote work has brought about a transformative shift, particularly for startups and small to medium-sized enterprises (SMEs). Remote hiring, once considered a necessity during unforeseen circumstances, is now emerging as a strategic choice for companies aiming to cut costs and build efficient, globally dispersed teams. Let's delve into the key reasons why remote hiring is becoming a game-changer for startups and SMEs.",
    details: [
      {
        id: "1",
        title: "",
        description: "",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Cost Savings Traditional Overheads vs. Remote Economies",
            description:
              "Traditional hiring often entails substantial overhead costs, including office space, utilities, and infrastructure. Remote hiring allows startups and SMEs to significantly reduce these expenses. By embracing a remote-first approach, companies can allocate resources more efficiently, channeling funds into critical areas such as talent acquisition, employee development, and innovation.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading:
              "Access to a Global Talent Pool Breaking Geographical Barriers",
            description:
              "Remote hiring dismantles the limitations imposed by geographical boundaries. Startups and SMEs gain access to a diverse and expansive talent pool, allowing them to cherry-pick the best-suited individuals for their teams. This globalized approach fosters creativity, diversity of thought, and a broad spectrum of skills that may not be readily available in a local talent market.",
            image: "",
          },
          {
            id: "3",
            type: "text",
            heading:
              "Enhanced Productivity and Flexibility Empowering the Workforce",
            description:
              "Remote work provides employees with the flexibility to choose their work environment, fostering a sense of autonomy. This autonomy often translates into increased job satisfaction and, consequently, heightened productivity. The absence of a daily commute and the ability to tailor their work hours to individual preferences contribute to a healthier work-life balance, benefiting both the company and its employees.",
            image: "",
          },
          {
            id: "4",
            type: "text",
            heading:
              "Reduced Turnover and Increased Retention Nurturing a Global Team Culture",
            description:
              "Remote work allows companies to build a team that values the culture and mission of the organization, irrespective of physical location. The resulting sense of belonging and connection to the company's values can contribute to reduced turnover rates. Employees feel more invested in their roles, leading to longer tenures and a more stable workforce.",
            image: "",
          },
          {
            id: "5",
            type: "text",
            heading: "Agile Scaling Flexibility in Growth",
            description:
              "Startups and SMEs are inherently agile entities. Remote hiring aligns seamlessly with their nimble structures, providing the flexibility to scale up or down based on business needs. Whether expanding into new markets or adjusting to changing economic conditions, remote teams allow for a more agile response to growth opportunities or challenges.",
            image: "",
          },
          {
            id: "6",
            type: "text",
            heading: "Technology as an Enabler Harnessing Collaboration Tools",
            description:
              "Advancements in technology have brought forth a plethora of collaboration tools that facilitate seamless communication and project management for remote teams. These tools empower startups and SMEs to maintain efficient workflows, ensuring that teams stay connected and projects progress smoothly, regardless of physical distances. ",
            image: "",
          },
          {
            id: "7",
            type: "text",
            heading:
              "Sustainability and Corporate Social Responsibility (CSR) Reducing Environmental Impact",
            description:
              "Remote work aligns with sustainability goals by reducing the environmental impact associated with daily commutes and traditional office setups. Companies that prioritize CSR can leverage remote hiring as a tangible way to contribute to a more eco-friendly and sustainable future.",
            image: "",
          },
        ],
      },
    ],
  },
 
  {
    id: "4",
    headImage: BlogCatImg3,
    category: "Engineering",
    date: "October 23, 2023",
    headTitle: "Latest Trends in Candidate Experience",
    desc: "The candidate experience landscape is rapidly evolving.",
    blogPoster: {
      avatar: UserIcon,
      name: "Dileep Kumar",
      designation: "CEO at Syoft",
      socialMedia: [
        {
          id: "1",
          type: "linkedin",
          link: "https://joinee.com/",
        },
        {
          id: "2",
          type: "facebook",
          link: "https://joinee.com/",
        },
        {
          id: "3",
          type: "twitter",
          link: "https://joinee.com/",
        },
        {
          id: "4",
          type: "instagram",
          link: "https://joinee.com/",
        },
        {
          id: "5",
          type: "youtube",
          link: "https://joinee.com/",
        },
      ],
    },
    headText: "",
    headDesc:
      "The candidate experience landscape is rapidly evolving. Here are some of the key trends shaping it:",

    details: [
      {
        id: "1",
        title: "Technology-Driven Transformation",
        description: "",
        type: "list",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "AI and Automation:",
            description:
              "From chatbots to AI-powered candidate matching, technology is streamlining the application process and improving candidate engagement.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Virtual and Augmented Reality:",
            description:
              "Immersive experiences are being used for virtual job tours, interviews, and onboarding, enhancing the candidate journey.",
            image: "",
          },
          {
            id: "3",
            type: "text",
            heading: "Mobile Optimization:",
            description:
              "Candidates expect a seamless experience on their mobile devices, necessitating mobile-friendly career sites and application processes.",
            image: "",
          },
        ],
      },
      {
        id: "2",
        title: "Candidate-Centric Focus",
        description: "",
        type: "list",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Personalization:",
            description:
              "Tailored candidate experiences, from job recommendations to communication, are becoming essential.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Transparency:",
            description:
              "Clear communication, timely feedback, and honest job descriptions build trust and improve the candidate experience.",
            image: "",
          },
          {
            id: "3",
            type: "text",
            heading: "Empathy:",
            description:
              "Organizations are prioritizing emotional connections with candidates, creating a supportive and caring environment.",
            image: "",
          },
        ],
      },
      {
        id: "3",
        title: "Diversity, Equity, and Inclusion (DEI)",
        description: "",
        type: "list",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Inclusive Hiring Practices:",
            description:
              "Companies are focusing on creating inclusive job descriptions, sourcing diverse talent pools, and providing equal opportunities.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Bias Mitigation:",
            description:
              "Technology and training are being used to reduce unconscious bias in the hiring process.",
            image: "",
          },
          {
            id: "3",
            type: "text",
            heading: "Candidate-Centric DEI:",
            description:
              "Ensuring that all candidates feel valued and respected regardless of their background.",
            image: "",
          },
        ],
      },
      {
        id: "4",
        title: "Employer Branding and Candidate Advocacy",
        description: "",
        type: "list",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Employer Brand as a Magnet:",
            description:
              "Strong employer brands attract top talent and create a positive candidate experience.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Candidate Advocacy:",
            description:
              "Empowering satisfied candidates to become brand ambassadors through referral programs and social media.",
            image: "",
          },
        ],
      },
      {
        id: "5",
        title: "Data-Driven Insights",
        description: "",
        type: "list",
        dataTypes: [
          {
            id: "1",
            type: "text",
            heading: "Candidate Experience Analytics:",
            description:
              "Measuring and analyzing candidate feedback to identify areas for improvement.",
            image: "",
          },
          {
            id: "2",
            type: "text",
            heading: "Predictive Analytics:",
            description:
              "Using data to optimize the candidate journey and improve hiring efficiency.",
            image: "",
          },
        ],
      },
      {
        id: "6",
        title: "",
        description:
          "These trends underscore the importance of putting candidates at the heart of the hiring process. By focusing on technology, personalization, diversity, and data, organizations can create exceptional candidate experiences that lead to stronger employer brands and better talent acquisition outcomes.",
      },
    ],
  },
];
