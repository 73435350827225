import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import { Avatar, Box, Stack, CircularProgress, Grid, IconButton, Typography, Divider, Badge } from "@mui/material";
import JobsListChat from "./JobsListChat";
// import chatImg from "../../assets/images/chat.svg";
import chatImg from "../../assets/images/chatShiyft2.png";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import "./DemoChat.css";
import {
  findChatBasedOnId,
  findContactsBasedOnUserId,
  getAllChats,
  sendUserMessages,
} from "../../helpers/dataFetcher/chats/chats";
import { getAllAppliedJobsList } from "../../helpers/dataFetcher/chats/chats";
import JobDrawChatDescription from "../BrowseJobsList/JobDrawChatDescription";
import { getJobDetailsById } from "../../helpers/dataFetcher/jobs/jobs";
import { useNavigate, useSearchParams } from "react-router-dom";
import SocketConnection from "../../hooks/socket";
import { get_chat_active_user_list } from "../../helpers/sockets";
import GlobalSearch from "../../hooks/GlobalSearch";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TuneIcon from '@mui/icons-material/Tune';
import SavedJobDescription from "../Accounts/SavedJobs/SavedJobDescription";

function DemoChat() {
  const [postImg, setPostImg] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [jobs, setJobs] = useState({});
  const [jobId, setJobId] = useState("");
  const [userId, setUserId] = useState("");

  const [jobLoading, setJobLoading] = useState(true);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(1);
  const [mount, setMount] = useState(false);

  const pageSize = 15;
  const searchFilter = searchParams.get("q");
  const selectedJobPostId = searchParams.get("job-post-id");

  const listInnerRef = useRef();



  const [appliedJobs, setAppliedJobs] = useState([]);

  const userData = useSelector(state => state.User);

  useEffect(() => {
    setUserId(userData.user.id);
  }, [userData]);


  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setPostImg(url);
    toggleModel();
  };



  jQuery(document).ready(function () {
    $(".company_chat_list a").click(function () {
      $(".chatbox").addClass("showbox");
      return false;
    });

    $(".chat-icon").click(function () {
      $(".chatbox").removeClass("showbox");
    });
  });

  useEffect(() => {
    if (!mount) {
      setMount(true);
      return;
    }
    getAllJobs();
    if (searchFilter && searchFilter !== "") {
      setCurrPage(1);
      setAppliedJobs([]);
    }
  }, [searchFilter, currPage, mount]);


  async function getAllJobs() {
    setJobLoading(true);
    const { data, status } = await getAllAppliedJobsList(currPage, pageSize, searchFilter ?? "");

    if (!data?.data_payload?.length) {
      setJobLoading(false);
      return;
    }

    if (data?.pageNo === 1) {
      setAppliedJobs(data?.data_payload);
    } else {
      setAppliedJobs((prevItems) => [...prevItems, ...data?.data_payload]);
    }
    if (!selectedJobPostId || selectedJobPostId === "") {
      searchParams.set("job-post-id", data?.data_payload[0]?.job_post_id);
      searchParams.set("user-job-post-id", data?.data_payload[0]?.id);
      setSearchParams(searchParams);
    }
    setJobLoading(false);
    return;
  }

  useEffect(() => {
    if (selectedJobPostId && selectedJobPostId !== "") {
      getjobById(selectedJobPostId);
    } else {
      setJobDetailsLoading(false);
    }
    localStorage.setItem("jobId", jobId)
  }, [selectedJobPostId]);

  const getjobById = async (selJobPostId) => {
    setJobDetailsLoading(true);
    const { data, status } = await getJobDetailsById(selJobPostId);
    if (status === 200) {
      setJobs(data)
      setJobDetailsLoading(false);
    }
    setJobDetailsLoading(false);
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 4) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          height: "85vh"
        }}
      >
        <Grid
          item
          xs={12}
          sm={4.5}
          sx={{
            height: "100%"
          }}
        >
          <Stack
            direction="column"
            sx={{
              height: "100%",
              border: "0.8px solid #DEDEDE",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0px 2px 54px -35px #0000008C",
              overflow: "hidden",
            }}
          >
            <Stack
              sx={{
                height: "105px",
                minHeight: "105px",
                borderBottom: "1px solid #DEDEDE",
                padding: "10px"
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
               
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ gap: "15px" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                    noWrap
                  >Jobs
                  </Typography>
                  {/* <Badge
                    badgeContent={appliedJobs ? appliedJobs.length : "0"}
                    slotProps={{
                      badge: {
                        style: {
                          backgroundColor: "#066a4c",
                          width: "15px",
                          height: "20px",
                          color: "#ffe7bb",
                          fontSize: "10px",
                          fontWeight: 600,
                        },
                      },
                    }}
                  /> */}
                </Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  // size="medium"
                >
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  position: "relative",
                  marginTop: "10px"
                }}
              >
                <GlobalSearch sx={{ width: "100%", background: "#E9E9E980", border: "none" }} />
                <IconButton
                  aria-label="more"
                  id="long-button"
                  size="small"
                  sx={{
                    position: "absolute!important",
                    right: "5px",
                  }}
                >
                  <TuneIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Stack>
            <Stack
              direction="column"
              sx={{
                height: "calc(100% - 105px)",
                maxHeight: "calc(100% - 105px)",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              onScroll={onScroll}
              ref={listInnerRef}
            >
              <JobsListChat
                appliedJobs={appliedJobs}
                setCurrPage={setCurrPage}
                currPage={currPage}
                jobLoading={jobLoading}
              />
            </Stack>
          </Stack >

        </Grid>
        <Grid
          item
          xs={12}
          sm={7.5}
          sx={{
            height: "100%",
            overflow: "auto",
            paddingLeft: "15px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "@media (max-width: 600px)": {
              paddingLeft: "0px",
              marginTop: "10px"
            },
          }}
        >
          {jobDetailsLoading ? (
            <Stack
              sx={{
                height: "100%",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="success" />
            </Stack>
          ) : (
            <>
              {selectedJobPostId && selectedJobPostId !== "" && typeof jobs === "object" && Object.keys(jobs).length > 0 ? (
                <SavedJobDescription jobDetails={jobs} />
              ) : (
                <Stack
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    minHeight: "100%",
                    border: "0.8px solid #DEDEDE",
                    borderRadius: "16px",
                    padding: "24px",
                    gap: "10px",
                    background: "#fff",
                    boxShadow: "0px 2px 54px -35px #0000008C"
                }}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                      <div className="contact_no_show">
                        <img src={chatImg} alt="chat" />
                        <p>Joinee Chat for Web</p>
                        <span className="pat-20 end_to_end">
                          End to End Encrypted
                        </span>
                      </div>
                    </div>
                  </div>
                </Stack>
              )}
            </>
          )}


        </Grid>
      </Grid>
    </>
  );
}

export default DemoChat;
