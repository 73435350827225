import React, { useState } from "react";
import { Card, Grid, Stack, Typography, IconButton, Button } from "@mui/material";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../../components/modals/modalsSlice";

function DashBoardPage1({ myReferedJob, appliedJobs, byRefer }) {

  const navigate = useNavigate();


  const dispatch = useDispatch();


  const dashBoardList = [
    { id: 0, name: byRefer?.tag ?? "Referred Jobs", count: byRefer?.overall_count ?? " ", latest: "+22", link: "/refered-jobs" },
    { id: 1, name: appliedJobs?.tag ?? "Applied Jobs", count: appliedJobs?.overall_count ?? " ", latest: "+34", link: "/applied-jobs" },
    { id: 2, name: myReferedJob?.tag ?? "My Referral Jobs", count: myReferedJob?.overall_count ?? " ", latest: "+26", link: "/my-referals" },
  ];


  return (
    <ThemeProvider theme={globalTheme}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          flexWrap: "wrap",
          gap: "20px"
        }}>
        {dashBoardList?.map((each, index) => (
          <Stack
            direction="column"
            justifyContent="space-between"
            key={index}
            sx={{
              gap: "10px",
              padding: "10px",
              // width: "fit-content", 
              minWidth: "250px",
              borderRadius: "16px",
              flex: 1,
              border: "1px solid #E9E9E9",
              boxShadow: "2px 2px 64px -44px #000000A6",
              backgroundImage: "linear-gradient(0deg, #30242400, #E9E9E9),linear-gradient(180deg, rgb(0 247 176 / 24%) 0%, rgba(252, 250, 243, 0) 100%)",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  textTransform: "capitalize"

                }}
              >
                {each?.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  fontSize: "50px",
                  fontWeight: "600",
                  color: "#066A4C"
                }}
              >
                {each?.count}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
            >
              <IconButton
                onClick={() => navigate(`${each?.link}`)}
                size="small"
              >
                <CallMadeIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </Stack>
        ))}

      </Stack>
    </ThemeProvider>
  );
}

export default DashBoardPage1;
