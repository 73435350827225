import FeatherIcon from "feather-icons-react";
import { toast } from "react-hot-toast";
import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import { Button, Stack, Typography, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { newResumeParser } from "../../../helpers/dataFetcher/resume/resume_parser";
import { useDispatch, useSelector } from "react-redux";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { imageURL } from "../../../Constants/commonURLS";
import updateUserProfile, {
  transformResumeData,
} from "../../../helpers/updateUserProfileWithResume";
import { closeModal, openModal } from "../../../components/modals/modalsSlice";
import { showAlert } from "../../../components/sweetalert";

export default function LayoutModalDialog({
  show,
  onHide,
  step,
  purpose,
  setStep,
  setStatus,
  details,
  setResumeDetails,
}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.User.user);
  const [loading, setLoading] = React.useState(false);
  const [approved, setApproved] = React.useState(false);

  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide("");
          setStep(1);
        }}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center"}
          sx={{
            maxHeight: "fit-content!important",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              flexShrink: 0,
              overflow: "hidden",
              gap: "5px",
              textOverflow: "ellipsis",
              borderBottom: "0.8px solid rgb(222, 222, 222)",
            }}
          >
            <Typography
              noWrap
              id="layout-modal-title"
              component={Link}
              level="inherit"
              to={`../job?id=${details?.id}`}
              sx={{ fontSize: "25px", fontWeight: "600" }}
            >
              {details?.job_title}
            </Typography>
          </Stack>
          {purpose === "self" ? (
            <div>
              <Typography
                id="layout-modal-title"
                component={"span"}
                level="inherit"
                className="text_resume_info"
                fontSize="14px"
                mb="0.25em"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Continue application with current resume?
              </Typography>
              <Button
                id="layout-modal-title"
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  boxShadow: "none",
                  background: "var(--app-button-color1)",
                  padding: "5px 30px",
                  width: "100%",
                  marginBottom: "14px",
                  marginTop: "10px",
                  "&:hover": {
                    boxShadow: "none",
                    background: "var(--app-button-color1)",
                  },
                }}
                onClick={() => {
                  setStep(3);
                  setResumeDetails({
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    location: user?.city,
                    email: user?.email,
                    phone_number: user?.phone_number,
                  });
                  setApproved(true);
                }}
                level="inherit"
                fontSize="14px"
                mb="0.25em"
              >
                <FeatherIcon
                  icon="file-text"
                  size="14"
                  style={{ marginRight: "5px" }}
                />
                <span style={{ textTransform: "capitalize" }}>Proceed</span>
              </Button>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Or
              </Typography>
            </div>
          ) : (
            <></>
          )}
          <Button
            variant="contained"
            sx={{
              borderRadius: "50px",
              boxShadow: "none",
              background: "var(--app-button-color1)",
              padding: "5px 30px",
              width: "100%",
              "&:hover": {
                boxShadow: "none",
                background: "var(--app-button-color1)",
              },
              "&:disabled": {
                background: "var(--app-button-color1)",
              },
            }}
            onClick={() => {
              const input_field = document.createElement("input");
              input_field.type = "file";
              input_field.accept = "application/*";
              // input_field.accept = "application/json";
              input_field.click();
              input_field.onchange = async e => {
                const file = e.target.files[0];
                setLoading(true);
                const resumeUrl = await getPreSignedURL(e.target.files);
                const resumeFileURL = resumeUrl.replace(imageURL, "");

                setStep(step + 3);
                // const { status, data } = await resumeParser(
                //   file,
                //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ilh5Zyt4flUlPklQU1MtO2oiLCJpYXQiOjE2NzAyMzY4NzN9.f86Y2GG0a9eQENcKPpfB7tZHNsNFWvuME9WbqPBfonU"
                // );
                const { status, data } = await newResumeParser(file);

                setLoading(false);
                setStatus(status);

                if (status === 200) {
                  let isSelf = purpose === "self";
                  let updatePhoneNumber = false;
                  let updateEmail = false;
                  setResumeDetails({
                    first_name: data?.data?.name?.split(" ")[0] ?? "",
                    last_name: data?.data?.name?.split(" ")[1] ?? "",
                    location: data?.data?.address ?? "",
                    resume: resumeFileURL ?? "",
                    email: isSelf ? user?.email : data?.data?.email ?? "",
                    // phone_number:
                    //   data?.ResumeParserData?.PhoneNumber[0]?.Number,
                    phone_number: isSelf
                      ? user.phone_number
                      : data?.data?.phoneNumber ?? "",
                  });
                  if (isSelf) {
                    if (data?.data && data?.data?.email != user?.email) {
                      const result = await showAlert({
                        title:
                          "Your email has changed. Do you want to update it?",
                        text: data?.data?.email ?? "",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      });

                      if (result.isConfirmed) {
                        // User confirmed
                        setResumeDetails(prevState => ({
                          ...prevState,
                          email: data?.data?.email ?? user?.email,
                        }));
                        updateEmail = true;
                      } else {
                        // User canceled
                        updateEmail = false;
                        console.log("no, update email");
                      }
                    }
                    if (
                      data?.data &&
                      data?.data?.phoneNumber != user?.phone_number
                    ) {
                      const result = await showAlert({
                        title:
                          "Your phone number has changed. Do you want to update it?",
                        text: data?.data?.phoneNumber ?? "",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      });

                      if (result.isConfirmed) {
                        // User confirmed
                        setResumeDetails(prevState => ({
                          ...prevState,
                          phone_number:
                            data?.data?.phoneNumber ?? user?.phone_number,
                        }));
                        updatePhoneNumber = true;
                        console.log("Yes update phone");
                      } else {
                        // User canceled
                        updatePhoneNumber = false;
                        console.log("no, update phone");
                      }
                    }
                  } else {
                    if (data?.data) {
                      const refferData = await transformResumeData(
                        data?.data,
                        resumeFileURL,
                        true,
                        true
                      );
                      let { update_type, resume_file, ...rest } = refferData;
                      setResumeDetails(prev => ({ ...rest, ...prev }));
                    }
                  }

                  setApproved(true);
                  setStep(3);

                  if (isSelf) {
                    await updateUserProfile(
                      data?.data ?? {},
                      resumeFileURL,
                      updatePhoneNumber,
                      updateEmail
                    );
                  }
                } else {
                  setStep(2);
                  toast.error(data?.error ?? "Something went wrong!");
                }
              };
            }}
            disabled={loading}
          >
            <span style={{ textTransform: "capitalize" }}>
              {loading ? (
                <CircularProgress size={18} sx={{ color: "#fff" }} />
              ) : purpose === "self" ? (
                "Upload a new resume"
              ) : (
                "Upload resume"
              )}
            </span>
          </Button>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              type="submit"
              size="small"
              onClick={() => {
                setStep(step - 1);
              }}
              loadingPosition="end"
              variant="contained"
              sx={{
                borderRadius: "50px",
                boxShadow: "none",
                background: "#E6F0ED",
                padding: "5px 30px",
                border: "0.8px solid var(--app-button-color1)",
                color: "var(--app-button-color1)",
                "&:hover": {
                  boxShadow: "none",
                  color: "#fff",
                  background: "var(--app-button-color1)",
                },
              }}
            >
              <span style={{ textTransform: "capitalize" }}>Back</span>
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
