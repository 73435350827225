export const companyFaqData = [
  {
    id: "1",
    que: "How does your platform help companies hire better and faster?",
    ans: "At Joinee, we're here to make hiring easier and faster for you. When you post a job on our platform, you'll get dedicated support from an account manager who will guide you through the process. We also provide specialized partners to help with tasks like resume screening and evaluating technical skills.",
  },
  {
    id: "2",
    que: "How does your platform save money on recruitment costs?",
    ans: (
      `<ui style="font-size: 13px;">
        At Joinee, we revolutionize recruitment cost savings: 
        <li>Free job postings: Post vacancies without paying fees.</li>
        <li>
          No charges for views or clicks: Say goodbye to sponsored ad costs.
        </li>
        <li>Pay for results: Invest in successful placements only.</li>
        <li>
          Flexible payment options: Pay based on salary/CTC or minimal
          percentage for high CTCs.
        </li>
        <li>
          No recruiter fees: Cut costs by avoiding traditional agency fees .
        </li>
      </ui>`
    ),
  },
  {
    id: "3",
    que: "Do you offer any additional services or support beyond job matching?",
    ans: "Beyond job matching, we offer background verification and technical interview services to ensure candidate authenticity and assess technical skills efficiently. These services are available as paid options for companies that require them.",
  },
  {
    id: "4",
    que: "Can I add staff to manage my hiring process?",
    ans: "Yes, you can add your staff to manage the hiring process on our platform.",
  },
  {
    id: "5",
    que: "What makes Joinee different from other platforms?",
    ans: "Joinee offers transparent hiring, making it easy and flexible to understand the progress of your job postings. Our platform ensures clear communication and real-time updates, giving you complete visibility throughout the hiring process.",
  },
];
export const jobSeekerFaqData = [
  {
    id: "1",
    que: "What types of jobs are available on your platform?",
    ans: "On our platform, you'll find a wide range of tech jobs spanning various industries. Whether you're seeking onsite, remote, or hybrid positions, we cater to all preferences. From entry-level roles to management positions, we've got you covered.",
  },
  {
    id: "2",
    que: "Can users refer multiple friends, and is there a limit on the number of referrals?",
    ans: "Users can indeed refer multiple friends, and there's no limit on the number of referrals they can make. Feel free to refer as many friends as you like!",
  },
  {
    id: "3",
    que: "Are there any restrictions or limitations on who can participate in the referral program?",
    ans: "Participation in our referral program is open to all users without limitations. You're free to refer as many individuals as you like. There are no restrictions; you simply need to be a registered user of our platform to participate.",
  },
  {
    id: "4",
    que: "Is there a fee for using your platform, either for employers or job seekers?",
    ans: "Using our platform incurs no fees whatsoever, whether you're an employer looking to post jobs or a job seeker searching for opportunities. Enjoy our services completely free of charge.",
  },
  {
    id: "5",
    que: "Are there any specific requirements for a referral to be considered successful?",
    ans: "To be considered a successful referral, two main criteria must be met. Firstly, your friend must approve the referral, which they receive when you refer them to a job. Secondly, your friend must be selected for the job. Once they are successfully placed, the referral will be considered successful.",
  },
];
