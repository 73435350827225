import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
import ProfileSelectFieldForm from "./ProfileSelectFieldForm";
import { transformedCountries, transformedLanguages } from "./Nationality";
import ProfileMultiSelectFieldForm from "./ProfileMultiSelectFieldForm";
import ProfileCurrExpFieldForm from "./ProfileCurrExpFieldForm";
import LinksField from "./LinksField";

export default function AdditionalInformation({ setRecall, reCall }) {
  const accountData = useSelector(state => state.User.user);

  const formatSalary = data => {
    if (!data || data.length === 0) {
      return ""; // Return an empty string if data is not provided or is empty
    }

    const formattedData = data?.map(item => {
      const formattedSalary = `${item?.currency} - ${item?.current_salary}`;
      // const formattedPay = item.pay.charAt(0).toUpperCase() + item.pay.slice(1);
      const formattedPay = item?.pay;

      return `${formattedSalary}/${formattedPay}`;
    });

    const formattedString = formattedData.join(", ");

    return formattedString;
  };

  const formatExpSalary = data => {
    if (!data || data.length === 0) {
      return ""; // Return an empty string if data is not provided or is empty
    }

    const formattedData = data?.map(item => {
      const formattedSalary = `${item.currency} - ${item.expected_salary}`;
      const formattedPay = item?.pay;

      return `${formattedSalary}/${formattedPay}`;
    });

    const formattedString = formattedData.join(", ");

    return formattedString;
  };

  async function submitData(data) {
    let formData = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null)
    );

    const { status, data: resData } = await updateUserProfileDetails(
      "about_me",
      formData
    );
    if ([201, 200].includes(status)) {
      setRecall(!reCall);
      return true;
    }
  }

  return (
    <Stack spacing={2} p={1}>
      <ProfileCurrExpFieldForm
        textField={"Current salary"}
        id="current_salary"
        name="current_salary"
        type="modal"
        salaryType="current"
        currValue={accountData?.current_salary ?? []}
        submitData={submitData}
        displayValue={formatSalary(accountData?.current_salary)}
      />

      {/* <InputButtonField
        text_field={"Current salary"}
        currency_field={"currency"}
        work_field={"worktype"}
        pay_field={"pay"}
        input_value={formatSalary(accountData?.current_salary)}
        // input_value={"INR-20000/Weekly"}
        input_type="number"
        type="salary"
        update_type={"current_salary"}
        update_type2={"symbol"}
        update_type3={"pay"}
        update_type4={"work"}
        // setSalaryModel={setSalaryModel}
        // salaryModel={salaryModel}
        wholeData={accountData}
        setRecall={setRecall}
        reCall={reCall}
        preDefineValue={accountData?.current_salary?.length ? accountData?.current_salary[0] : null}
      /> */}

      <ProfileCurrExpFieldForm
        textField={"Expected salary"}
        id="expected_salary"
        name="expected_salary"
        type="modal"
        salaryType="expected"
        currValue={accountData?.expected_salary ?? []}
        submitData={submitData}
        displayValue={formatExpSalary(accountData?.expected_salary)}
      />

      {/* <InputButtonField
        text_field={"Expected salary"}
        currency_field={"currency"}
        work_field={"worktype"}
        pay_field={"pay"}
        input_value={formatExpSalary(accountData?.expected_salary)}
        // input_value={"INR-30000/Weekly"}
        input_type="number"
        type="salary"
        update_type={"expected_salary"}
        update_type2={"symbol"}
        update_type3={"pay"}
        update_type4={"work"}
        // setSalaryModel={setSalaryModel}
        // salaryModel={salaryModel}
        wholeData={accountData}
        setRecall={setRecall}
        reCall={reCall}
        preDefineValue={accountData?.expected_salary?.length ? accountData?.expected_salary[0] : null}
      /> */}

      <ProfileSelectFieldForm
        textField={"Notice Period"}
        id="notice_period"
        placeholder="Enter Notice Period"
        name="notice_period"
        type="select"
        options={[
          { name: "Immediately", value: "Immediately" },
          { name: "10 days", value: "10 days" },
          { name: "20 days", value: "20 days" },
          { name: "30 days", value: "30 days" },
          { name: "40 days", value: "40 days" },
          { name: "50 days", value: "50 days" },
          { name: "60 days", value: "60 days" },
          { name: "70 days", value: "70 days" },
          { name: "80 days", value: "80 days" },
          { name: "90 days", value: "90 days" },
          { name: "100 days", value: "100 days" },
          { name: "110 days", value: "110 days" },
          { name: "120 days", value: "120 days" },
          { name: "120+ days", value: "120+ days" },
        ]}
        textValue={accountData?.notice_period ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Notice Period is required",
        }}
      />

      <ProfileSelectFieldForm
        textField={"Experience"}
        id="experience"
        placeholder="Enter Experience"
        name="experience"
        type="select"
        options={[
          { name: "1", value: "1" },
          { name: "2", value: "2" },
          { name: "3", value: "3" },
          { name: "4", value: "4" },
          { name: "5", value: "5" },
          { name: "6", value: "6" },
          { name: "7", value: "7" },
          { name: "8", value: "8" },
          { name: "9", value: "9" },
          { name: "10+", value: "10+" },
        ]}
        textValue={accountData?.experience ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Experience is required",
        }}
      />

      <ProfileSelectFieldForm
        textField={"Nationality"}
        id="nationality"
        placeholder="Enter Nationality"
        name="nationality"
        type="select"
        options={transformedCountries ?? []}
        textValue={accountData?.nationality ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Nationality is required",
        }}
      />

      <ProfileMultiSelectFieldForm
        textField={"Languages"}
        id="languages"
        placeholder="Enter Languages"
        name="languages"
        type="select"
        options={transformedLanguages ?? []}
        textValue={accountData?.languages ?? []}
        submitData={submitData}
        useFormProps={{
          required: "Languages is required",
        }}
      />

      <LinksField textField={"Links"} links={accountData?.links} />
    </Stack>
  );
}
