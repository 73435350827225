import Modal from "@mui/material/Modal";
import React from "react";
import Dropzone from "react-dropzone";
function UploadModel(props) {
  const [selectedFile, setSelectedFile] = React.useState([]);
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setSelectedFile(files);
  };

  return (
    <>
      <Modal
        hideBackdrop={true}
        {...props}
        className="profile_image_upload_modal"
        keyboard={false}
        size="lg"
      >
        <>
          <div className="modal-body">
            <p className="card-title-desc">
              {" "}
              DropzoneJS is an open source library that provides drag’n’drop
              file uploads with image previews.
            </p>

            <Dropzone
              onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to uplo.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {/* {selectedFile.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })} */}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                props.onHide();
              }}
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => props.handleImage(selectedFile)}
            >
              Confirm
            </button>
          </div>
        </>
      </Modal>
    </>
  );
}

export default UploadModel;
