import React, { useEffect, useState } from "react";
import Modal from "@mui/joy/Modal";
import { useSelector } from "react-redux";
import { getAllKeySkills } from "../../../helpers/dataFetcher/jobAttributes/keyskills";
import { useForm, Controller } from "react-hook-form";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import { createFilterOptions } from "@mui/material/Autocomplete";
import "../../../assets/css/applyForJob/index.css";
import { debounce } from "@mui/material/utils";
import { applyForJob } from "../../../helpers/dataFetcher/jobs/jobs";
import ModalClose from "@mui/joy/ModalClose";
import { ModalDialog } from "@mui/joy";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../helpers/dataFetcher/profile/profile";
import MuiCustomTelInput from "../../../components/formElements/PhoneNumber/PhoneNumber";

import { Grid, Button, Stack, Typography } from "@mui/material";
import TextField from "../../../components/formElements/TextField";
import { matchIsValidTel } from "mui-tel-input";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function CandidateDetails({
  show,
  onHide,
  setStep,
  step,
  details,
  resumeDetails,
  purpose,
  screeningQuestions,
  setScreeningQuestions,
  updateApplyAndRefer,
}) {
  // const {sendMessage:emitSocket} = SocketConnection()
  const [applyStatus, setApplyStatus] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [phone, setPhone] = React.useState("");

  const accountData = useSelector(state => state.User.user);

  const loaded = React.useRef(false);
  const filter = createFilterOptions();
  const navigate = useNavigate();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    getCurrentUserInfo();
    handleSkills();
  }, [step]);

  const getCurrentUserInfo = async () => {
    const res = await getUserInfo();
    const { data } = res?.data?.data_payload;
    if ([201, 200].includes(res?.status)) {
      setUserInfo(data);
    }
  };

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const [options, setOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  async function handleSkills() {
    const { data } = await getAllKeySkills();
    const skills = data?.data_payload?.map(item => ({
      id: item.id,
      title: item.name,
    }));
    setSkillOptions(skills);
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (resumeDetails) {
      setValue("first_name", resumeDetails.first_name);
      setValue("last_name", resumeDetails.last_name);
      setValue("email", resumeDetails.email);
      setValue("phone_number", resumeDetails.phone_number);
      setValue("location", resumeDetails.location);
      setValue("resume", resumeDetails.resume);
    }
  }, [resumeDetails]);

  const onSubmit = async data => {
    // getCurrentUserInfo();

    const resumeValueLocal = accountData?.resume || accountData?.resume_url;
    const resumeValueState = userInfo?.resume || userInfo?.resume_url;
    const resumeFromUploadState = data?.resume;

    const resumeUrlArr = [
      (resumeValueState !== "" &&
        resumeValueState !== null &&
        resumeValueState !== undefined) ||
      (resumeValueLocal !== "" &&
        resumeValueLocal !== null &&
        resumeValueLocal !== undefined)
        ? resumeValueState || resumeValueLocal
        : "",
    ];
    const newResumeData = [
      resumeFromUploadState
        ? resumeFromUploadState
        : resumeValueLocal
        ? resumeValueLocal
        : "",
    ];

    const result = await applyForJob(
      {
        ...data,
        ...resumeDetails,
        email: data?.email?.toLowerCase() || "",
        phone_number: data?.phone_number?.replace(/\s/g, "") || "",
        about_this_job: "about",
        location: data?.location || "",
        job_apply_type: purpose,
        resume: newResumeData,
        company_id: details?.company_id || "",
      },
      details?.id
    );
    setScreeningQuestions(result?.data);
    setApplyStatus(true);

    if (result && result?.status && [200, 201].includes(result.status)) {
      setApplyStatus(false);
      toast.success(result?.data?.message);
      if (updateApplyAndRefer) {
        updateApplyAndRefer({ job_post_id: details?.id });
      }
      setTimeout(() => {
        if (purpose === "refer") {
          setStep(1);
          onHide();
        } else {
          setStep(4);
        }
      }, 1000);
    } else {
      setApplyStatus(false);
      // onHide(false);
      toast.error(result?.data?.return_message ?? "Something went wrong.");
    }
  };

  const handleChangePhn = newPhone => {
    setValue("phone_number", newPhone);
    clearErrors("phone_number");
  };

  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide(false);
          setStep(1);
        }}
        keyboard={false}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        {/* {status === 200 ? ( */}
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center"}
          sx={{
            maxHeight: "fit-content!important",
            background: "#fff!important",
          }}
        >
          <ModalClose
            onClick={() => {
              onHide(false);
              setStep(1);
            }}
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              flexShrink: 0,
              overflow: "hidden",
              gap: "5px",
              textOverflow: "ellipsis",
              borderBottom: "0.8px solid rgb(222, 222, 222)",
            }}
          >
            <Typography
              noWrap
              id="layout-modal-title"
              component={Link}
              level="inherit"
              to={`../job?id=${details?.id}`}
              sx={{ fontSize: "25px", fontWeight: "600" }}
            >
              {details?.job_title}
            </Typography>
          </Stack>

          <form
            style={{
              overflowY: "scroll",
            }}
            onSubmit={handleSubmit(onSubmit)}
            className="candidate_details_fields"
          >
            <Grid container>
              <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                <TextField
                  size="small"
                  className="form-control"
                  label="First Name"
                  required
                  type="text"
                  placeholder="First Name"
                  useFormRef={{ ...register("first_name", { required: true }) }}
                  error={errors?.first_name}
                  helperText={
                    errors?.first_name ? "First Name is required" : ""
                  }
                  sx={{
                    width: "100%",
                    background: "#fff",
                    padding: "17px 16px 17px 16px",
                    borderRadius: "8px",
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                <TextField
                  size="small"
                  label="Last Name"
                  className="form-control"
                  required
                  type="text"
                  placeholder="Last Name"
                  useFormRef={{ ...register("last_name", { required: true }) }}
                  error={errors?.last_name}
                  helperText={errors?.last_name ? "Last Name is required" : ""}
                  sx={{
                    width: "100%",
                    background: "#fff",
                    padding: "17px 16px 17px 16px",
                    borderRadius: "8px",
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                <TextField
                  size="small"
                  label="Email"
                  type="email"
                  required
                  className="form-control"
                  disabled={purpose === "self" ? true : false}
                  useFormRef={{
                    ...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Email is invalid",
                      },
                    }),
                  }}
                  error={errors.email}
                  helperText={errors?.email ? errors?.email?.message : null}
                  sx={{
                    width: "100%",
                    background: "#fff",
                    padding: "17px 16px 17px 16px",
                    borderRadius: "8px",
                  }}
                  placeholder="Email (i.e: example@example.xyz)"
                />
              </Grid>
              <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                <Controller
                  name="phone_number"
                  control={control}
                  rules={{
                    required: "Phone Number is required",
                    validate: value =>
                      matchIsValidTel(value) || "Phone Number is invalid",
                  }}
                  render={({
                    field: { ref: fieldRef, value, ...fieldProps },
                    fieldState,
                  }) => {
                    return (
                      <MuiCustomTelInput
                        {...fieldProps}
                        required
                        defaultCountry={"IN"}
                        countryCodeEditable={true}
                        value={value ?? ""}
                        inputRef={fieldRef}
                        label="Phone Number"
                        error={fieldState.invalid}
                        helperText={
                          errors?.phone_number?.type
                            ? errors?.phone_number?.message
                            : null
                        }
                        sx={{
                          width: "100%",
                        }}
                      />
                    );
                  }}
                />

                {/* <MuiCustomTelInput
                  defaultCountry="IN"
                  fullWidth
                  label="Phone Number"
                  placeholder="Phone Number"
                  countryCodeEditable={true}
                  value={getValues("phone_number")}
                  // onChange={e => {
                  //   setValue("phone_number", e);
                  // }}
                  onChange={e => handleChangePhn(e)}
                  error={
                    errors?.phone_number
                  }
                  helperText={
                    errors?.phone_number ? errors?.phone_number?.message : null
                  }
                  InputProps={{
                    style: { borderRadius: "8px", height: "40px" },
                  }}
                /> */}
              </Grid>
              <Grid xs={12} md={12} mt={2}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    size="small"
                    loadingPosition="end"
                    variant="contained"
                    disabled={applyStatus}
                    sx={{
                      borderRadius: "50px",
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                      padding: "5px 30px",
                      "&:hover": {
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                      },
                    }}
                  >
                    <span style={{ textTransform: "capitalize" }}>
                      {applyStatus ? "Loading.." : "Submit"}
                    </span>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
