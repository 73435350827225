import React, { useEffect, useState } from "react";
import { Avatar, Box, Stack, CircularProgress, Grid, IconButton, Typography, Divider, Badge } from "@mui/material";
import ReferAndEarn from "./ReferEarnCard";
import { getLeaderShipBoardDetails } from "../../helpers/dataFetcher/profile/profile";
import { useSelector } from "react-redux";
import { imageURL } from "../../Constants/commonURLS";
import LeaderShipTable2 from './LeadershipTable2'
import ComingSoon from '../../Reusable/comingSoon';

function LeadershipBoard() {
  const user = useSelector(state => state.User.user);
  return (
    <Grid
      container
      sx={{
        height: "85vh",
        border: "0.8px solid #DEDEDE",
        borderRadius: "16px",
        background: "#fff",
        boxShadow: "0px 2px 54px -35px #0000008C",
        overflow: "hidden",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: "100%"
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ComingSoon />
      </Grid>
    </Grid>
  );
}

export default LeadershipBoard;
