import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { styleObj, paginateStyles } from "../../../assets/MUI/useStyles";
import { DataGridStyles } from "../../../assets/MUI/useStyles";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import CustomNoRowsFound from "../../../Nodatafound/customNoRowsFound";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { formatdate } from "../../../helpers/common/FormateDate";
import { useNavigate } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";

// import "./table.css";

function MyAppliedJobs({
  referData,
  page,
  totalCount,
  setPage,
  loading,
  pageSize,
}) {
  const classes = DataGridStyles();
  const navigate = useNavigate();

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    if (Number.parseFloat(scaledNumber).toFixed(0) > 0) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    } else {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };
  const rowsData = referData?.map((item, index) => {
    const createdAt = item?.createdAt;

    const deadlineTime = new Date(item?.application_deadline);

    const date = new Date(createdAt);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };

    const formattedTime = date.toLocaleString("en-US", options);
    const formattedDeadlineTime = deadlineTime.toLocaleString("en-US", options);

    return {
      id: `${item?.id}`,
      userId: `${item?.user_id}`,
      jobPostId: `${item?.job_post_id}`,
      Image: `${imageURL}${item.company_details?.company?.company_image}`,
      companyName: `${item?.company_details?.company?.company_name ?? "-"}`,
      jobTitle: `${item?.job_title ?? "-"}`,
      //  ctc: `${getSymbolFromCurrency(item?.currency) ? getSymbolFromCurrency(item?.currency) : ""} ${item?.max_sal ?? "-"}/${item?.payout?.replace(/_/g, ' ')}`,
      ctc: `${
        getSymbolFromCurrency(item?.currency)
          ? getSymbolFromCurrency(item?.currency)
          : ""
      } ${item?.max_sal ?? "-"}/${item?.payout?.replace(/_/g, " ")}`,
      status: `${item?.job_post_pipeline_stage?.stage_name}`,
      commission:
        item?.job_post_refer_self_commission.length > 0 &&
        item?.job_post_refer_self_commission[0]?.flat_rate !== 0
          ? `${getSymbolFromCurrency(item?.currency) || ""} ${
              item?.job_post_refer_self_commission[0]?.flat_rate
            }/${
              item?.job_post_refer_self_commission[0]?.payout?.replace(
                "_",
                " "
              ) || "N/A"
            }`
          : "-",
      resume: `${item?.resumes?.[0] ?? "N/A"}`,
      date: `${moment(item?.createdAt).format("DD-MM-YYYY HH:mm")}`,
      interviews: "",
      feedback: "",
      payout: `${item?.payout ? item?.payout?.replace(/_/g, " ") : ""}`,
      country: `${item?.company_details?.country ?? ""}`,
      state: `${item?.company_details?.state ?? ""}`,
      city: `${item?.company_details?.city ?? ""}`,
      // deadlineDate: formatdate(`${item?.application_deadline}`) + `${" "}` + `${formattedDeadlineTime}`,
      // appliedDate: formatdate(`${item?.createdAt}`) + `${" "}` + `${formattedTime}`,
      deadlineDate: `${moment(item?.application_deadline).format(
        "DD-MM-YYYY HH:mm"
      )}`,
      jobStatus: `${item?.is_active ? "Active" : "Closed"}`,
      // jobStatus: `${item?.is_active && new Date(item?.application_deadline) > new Date()
      //   ? "Active"
      //   : !item?.is_active && new Date(item?.application_deadline) <= new Date()
      //     ? "Expired"
      //     : item?.is_active && new Date(item?.application_deadline) <= new Date()
      //       ? "Closed"
      //       : ""
      //   }`
    };
  });

  const columns = [
    {
      field: "Image",
      headerName: "Image",
      headerClassName: classes.headerCellStyles,
      minWidth: 150,
      flex: 1,
      renderCell: params => (
        <img
          src={params.value}
          alt={params.value}
          style={{ width: 30, borderRadius: "50%" }}
        />
      ),
    },

    {
      field: "jobTitle",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Title",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => {
        return <span style={styleObj}>{value}</span>;
      },
    },

    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "ctc",
      headerClassName: classes.headerCellStyles,
      headerName: "CTC",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    // {
    //   field: "commission",
    //   headerClassName: classes.headerCellStyles,
    //   headerName: "Commission",
    //   minWidth: 200,
    //   flex: 1,
    //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    // },

    {
      field: "date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date & Time",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "deadlineDate",
      headerClassName: classes.headerCellStyles,
      headerName: "Application Deadline",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "resume",
      headerClassName: classes.headerCellStyles,
      headerName: "Resume",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <a
          href={`${imageURL}${value}`}
          target="_blank"
          onClick={e => e.stopPropagation()}
        >
          <Tooltip title="Preview">
            <VisibilityOutlinedIcon
              size="small"
              sx={{ color: "rgb(6, 106, 76)" }}
            />
          </Tooltip>
        </a>
      ),
    },

    {
      field: "jobStatus",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Status",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => {
        return <span style={styleObj}>{value}</span>;
      },
    },

    {
      field: "status",
      headerClassName: classes.headerCellStyles,
      headerName: "Application Status",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "8px",
            backgroundColor:
              value === "Rejected"
                ? "#ffe9e9"
                : value === "Applied"
                ? "#cde2e7"
                : value === "On boarded"
                ? "#d7f6dd"
                : value === "Technical Evalutation(External)"
                ? "#ffc6f6"
                : "rgba(8, 23, 83, 0.2)",
            color:
              value === "Rejected"
                ? "#a10e25"
                : value === "Applied"
                ? "#066a4c"
                : value === "On boarded"
                ? "#066a4c"
                : value === "Technical Evalutation(External)"
                ? "#066a4c"
                : "#066a4c",
            fontWeight: 500,
            fontSize: "12px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
          }}
        >
          {value}
        </div>
      ),
    },

    // {
    //   field: "country",
    //   headerClassName: classes.headerCellStyles,
    //   headerName: "Country",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: ({ value }) => (
    //     <span style={styleObj}>{value}</span>
    //   ),
    // },

    // {
    //   field: "state",
    //   headerClassName: classes.headerCellStyles,
    //   headerName: "State",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: ({ value }) => (
    //     <span style={styleObj}>{value}</span>
    //   ),
    // },

    // {
    //   field: "city",
    //   headerClassName: classes.headerCellStyles,
    //   headerName: "City",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: ({ value }) => (
    //     <span style={styleObj}>{value}</span>
    //   ),
    // },

    // {
    //   field: "interviews",
    //   headerClassName: classes.headerCellStyles,
    //   headerName: "Interviews",
    //   minWidth: 100,
    //   flex: 1,
    //   renderCell: ({ value }) => <span style={styleObj}>N/A</span>,
    // },
    // {
    //   field: "feedback",
    //   headerClassName: classes.headerCellStyles,
    //   headerName: "Feedback",
    //   minWidth: 100,
    //   flex: 1,
    //   renderCell: ({ value }) => <span style={styleObj}>N/A</span>,
    // },
  ];

  return (
    <ThemeProvider theme={globalTheme}>
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: "20px" }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            Applied Jobs{" "}
          </Typography>
          <GlobalSearch placeholder="Search by Company name, Job title.." />
        </Box>
        <div style={{ height: "70vh", width: "100%" }} className="ShifytScroll">
          <DataGrid
            loading={loading}
            rows={rowsData}
            className={classes.root}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            onRowClick={(params, event, details) => {
              navigate(
                `../job?id=${params?.row?.jobPostId}&userId=${params?.row?.userId}`
              );
            }}
            slots={{
              pagination: props => (
                <Pagination
                  {...props}
                  // color="primary"
                  // count={Math.ceil(rowsData.length / rowsPerPage)}
                  count={Number(totalCount)}
                  page={Number(page)}
                  rowsPerPage={Number(pageSize)}
                  onChange={(_, newPage) => setPage(newPage)}
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#024430",
                        color: "#ffe7bb",
                        "&:hover": {
                          backgroundColor: "#cde2e7",
                          color: "#066a4c",
                        },
                      },
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default MyAppliedJobs;
