import React, { useState } from "react";
import "./login.css";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { updatePassword } from "../helpers/dataFetcher/profile/update";
import { Link, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import toast from "react-hot-toast";
import TextField from "../components/formElements/TextField";

function ChangePassword() {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const changePassword = async ChangePasswordData => {

    const res = await updatePassword({
      old_password: ChangePasswordData.oldPassword,
      password: ChangePasswordData.newPassword
    });
    const { data, status } = res;
    if ([200, 201].includes(status)) {
      toast.success(data?.return_message);
      reset()
    }
    if (status === 400) {
      toast.error(data.return_message ?? "Something went wrong");
    }
    if (status === 422) {
      toast.error(data.return_message ?? "Something went wrong");
    }
  };

  /*show & hide password start*/
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  /*show & hide password End*/

  return (
    <div className="main_container">
      <h1 className="password_heading">Change Password</h1>
      <p className="password_paragraph" style={{
        color: "#979797"
      }}>
        Please enter your current password to change your password
      </p>
      {/* <hr /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(changePassword)}
        // noValidate
        sx={{
          marginTop: "25px"
        }}
        className="form_container"
      >
        <div className="bank_card_cont">
          <p>Old Password</p>
          <div className="bank_input_filed_card">
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              // type="password"
              autoComplete="password"
              error={errors?.oldPassword?.type === "required" ? true : false}
              useFormRef={{ ...register("oldPassword", { required: true }) }}
              helperText={
                errors?.oldPassword?.type === "required" ? "Old Password is required" : null
              }
              type={showOldPassword ? "text" : "password"}
              placeholder="Old password"
              sx={{
                background: "#E9E9E966",
                padding: "13px 16px 13px 16px",
                borderRadius: "8px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showOldPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>
        <br />
        <div className="bank_card_cont">
          <p>New Password</p>
          <div className="bank_input_filed_card">
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              autoComplete="password"
              useFormRef={{
                ...register("newPassword", {
                  required: true,
                  required: "New password is required",
                  minLength: {
                    value: 5,
                    message: "New password must be at least 5 characters",
                  },
                  maxLength: {
                    value: 15,
                    message: "New password must not exceed 15 characters",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
                    message: "New password must contain at least one capital letter, one numeric, and one special character",
                  },
                })
              }}
              error={Boolean(errors.newPassword)}
              helperText={
                errors.newPassword
                  ? errors.newPassword.message
                  : "New password must be between 5 and 10 characters"
              }
              type={showNewPassword ? "text" : "password"}
              placeholder="New password"
              sx={{
                background: "#E9E9E966",
                padding: "13px 16px 13px 16px",
                borderRadius: "8px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showNewPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>
        <br />
        <div className="bank_card_cont">
          <p>Confirm Password</p>
          <div className="bank_input_filed_card">
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              autoComplete="password"
              error={errors?.confirmPassword?.type === "required" || errors?.confirmPassword?.type === "validate" ? true : false}
              useFormRef={{ ...register("confirmPassword", { required: true, validate: (value) => value !== "" ? value === watch("newPassword") : false }) }}
              helperText={
                errors?.confirmPassword?.type === "required" ? "Confirm Password is required" : "New Password and Confirm Password should match"
              }
              type={showPassword ? "text" : "password"}
              placeholder="Confirm password"
              sx={{
                background: "#E9E9E966",
                padding: "13px 16px 13px 16px",
                borderRadius: "8px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="d-flex submit-button mt-4">
          <Stack spacing={2} direction="row">
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{
                borderRadius: "8px",
                marginLeft: "10px",
                boxShadow: "none",
                fontSize: "14px",
                background: "var(--app-button-color1)",
                "&:hover": {
                  boxShadow: "none",
                  background: "var(--app-button-color1)",
                }
              }}
            >
              <span style={{ textTransform: "capitalize" }}> Update now </span>
            </Button>
          </Stack>
        </div>
      </Box>
    </div>
  );
}

export default ChangePassword;
