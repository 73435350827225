import * as React from "react";
import { TextField, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import InputLabel from '@mui/material/InputLabel';

const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function Geolocation({
  id,
  placeholder,
  label,
  startAdornment,
  variant,
  sx,
  error,
  helperText,
  disabled,
  required,
  locValue,
  onChange,
  useFormRef,
  ...rest
}) {
  const [value, setValue] = React.useState(locValue ? locValue : null);
  const [inputValue, setInputValue] = React.useState(locValue ? locValue : "");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  // console.log(geoLocation,"kkk");
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
      setValue(locValue ? locValue : null);
      setInputValue(locValue ? locValue : "");
  
  }, [locValue])

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <ThemeProvider theme={globalTheme}>
      {label ? <InputLabel required={required} sx={{ height: "fit-content", borderColor: error ? "var(--error-color)" : null }}>{label}</InputLabel> : null}
      <Autocomplete
        id="google-map-demo"
        fullWidth
        {...useFormRef}
        sx={[
          {
            backgroundColor: "var(--paper-color)",
            border: "1px solid #c1c1c1",
            borderRadius: "8px",
            padding: "0px 10px 0px 10px",
            height: "fit-content !important",
            position: "relative !important"
          },
          sx,
          error
            ? {
              borderColor: "var(--error-color)",
            }
            : null,
        ]}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        {...rest}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value ? value : ""}
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          if (newValue) {
            setOptions([newValue, ...options]);
            setValue(newValue);
            onChange(event, newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            placeholder={placeholder}
            {...useFormRef}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "calc(100% - 44px)",
                    wordWrap: "break-word",
                  }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{
                        fontWeight: part.highlight ? "bold" : "regular",
                      }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      {error ? (
        <Typography
          variant="body2"
          sx={{
            fontSize: '11px',
            fontWeight: '400',
            color: 'var(--error-color)',
          }}
        >
          {helperText}
        </Typography>
      ) : null}
    </ThemeProvider>
  );
}
