import { Card, CardContent, Chip, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryChip,
  PrimaryDivider,
  PrimaryTypography,
  deleteIconContainer,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles";
import { useEffect, useState } from "react";
import AddEditKeySkills from "./addKeySkills";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
// import SearchParamHook from "hooks/searchParams";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import { updateUserAccount, updateLoadAccount } from "../accountSlice";

export default function CandidateSkills() {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.User.user);

  async function dataFetcher() {
    dispatch(
      updateLoadAccount({
        accGlobalLoad: true,
      })
    );
    const { data, status } = await getProfile();
    if (status === 200) {
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          }
        })
      );
    }
    dispatch(
      updateLoadAccount({
        accGlobalLoad: false,
      })
    );
  }

 
  async function handleDelete(skillIndex) {
    let skill = accountData?.skill_details?.map(item => ({value: item.id,name: item.name }));
    let updatedSkills = skill?.filter((each) => {
      if (each.value !== skillIndex) {
        return each.value
      }
    })
    const extract = updatedSkills?.map((each) => each.value)

    const obj = {
      skills: [...extract],
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    await updateUserProfileDetails("about_me", o);
    /*test */

    dataFetcher();
  }
  const [skillModel, setSkillModel] = useState(false);
  function toggleModel() {
    setSkillModel(!skillModel);
  }

  return (
    <Stack spacing={2} p={1}>
      <Stack
        direction="row"
        spacing={1}
        style={{
          gap: "10px",
        }}
        useFlexGap
        flexWrap="wrap"
      >
        {[...accountData?.skill_details?.map(item => ({value: item.id,name: item.name }))]?.map((skill, skillIndex) => (
          <PrimaryChip
            color="primary"
            variant="filled"
            // label={skill}
            label={skill.name}
            onDelete={() => {
              handleDelete(skill.value);
            }}
            sx={{
              border: "1px solid #DEDEDE",
            }}
            deleteIcon={
              <CloseIcon size="small" sx={{
                color: "#000!important"
              }} />
            }
          />
        ))}
        <PrimaryChip
          color="primary"
          variant="filled"
          label={`Add More Skills`}
          onClick={() => {
            toggleModel();
          }}
          onDelete={() => {  toggleModel(); }}
          sx={{
            background: "#056045!important",
            color: "#fff!important",

          }}
          deleteIcon={
            <AddIcon fontSize="small" color="inherit" />
          }
        />
      </Stack>
      {skillModel ? <AddEditKeySkills
        toggleModel={toggleModel}
        open={skillModel}
        onSubmit={dataFetcher}
        currentData={accountData?.skill_details?.map(
          item => ({
            value: item.id,
            name: item.name,
          })
        )}
      /> : null}
    </Stack>
  );
}
