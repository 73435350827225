import React, { useEffect, useState } from "react";
import SignUpForJobs from "../../JobsList/SignUpForJobs/SignUpForJobs";
import JobsOpening from "../../Reusable/JobsOpening";
import AboutEmployer from "./AboutEmployer";
import EmployerBranches from "./EmployerBranches";
import EmployerOffices from "./EmployerOffices";
import EmployerOverview from "./EmployerOverview";
import EmployerNavbarProfile from "./EmployerProfile";
import EmployerVacancies from "./EmployerVacancies";
import EmployerDescription from "./EmployerDescription";
// import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import "./index.css";
import "./companyProfile.css";
import { Button } from "reactstrap";
import EastIcon from "@mui/icons-material/East";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Footer from "../../../layouts/footer/Footer";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllCompanies,
  getCompanyById,
  getCompanyJobsById
} from "../../../helpers/dataFetcher/companies";


function Employer(props) {
  const [companies, setCompanies] = useState();
  const [jobsList, setJobsList] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const jobApply = useSelector((state) => state.JobApplyStatusReducer)

  // const { id } = useParams();

  /* trials */
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get('id');

  /* trials */


  useEffect(() => {
    dataFetcher(id);
  }, [jobApply])

  useEffect(() => {
    dataFetcher(id);
  }, [id]);

  async function dataFetcher(id) {
    setIsLoading(true);
    const { data } = await getCompanyById(id);
    if(id) {
      renderJobsList(id)
    }
    setCompanies(data?.data_payload);
    setIsLoading(false);
  }

  async function renderJobsList(id) {
    const { data } = await getCompanyJobsById(id)
    setJobsList(data?.data_payload)
  }

  const [open, setOpen] = useState(true);
  const [forceClose, setForceClose] = useState(false);
  const [value, setValue] = React.useState("1");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const handleForceClose = () => {
    setForceClose(true);
    setOpen(false);
  };
  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      !forceClose
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  return (
    <>
      {!isLoading ? (
        <div className="mb-8">
          <EmployerNavbarProfile company={companies} isLoading={isLoading} />
          <EmployerDescription company={companies} list={jobsList} />
           
        </div>
      ) : (
        <div className="content-page">
          <Box sx={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress color="success" />
          </Box>
        </div>
      )}
    </>
  );
}
export default Employer;
