import React from "react";
import Lists from "./Lists";
import "../../../assets/JobList/AllJobs.css";
// import noJobs from "../../../assets/images/no_jobs.svg";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import Skeleton from "@mui/material/Skeleton";

function JobsList(props) {
  // const sidebarFilter = e => {};

  if (props?.load) {
    return (
      <>
        {Array.from(new Array(10)).map((item, index) => (
          <div className="card-employers" id="card-employers" key={index}>
            <div className="align-items-center comp_card_main_cont">
              <div className="comp_employers_logo">
                <div
                  className="comp_profile_card_cont"
                  id="comp_profile_card_cont"
                >
                  <Skeleton variant="rectangular" width={80} height={80} />
                </div>
              </div>
              <div className="employer_name_cont">
                <p className="employer_name">
                  <span>
                    <strong className="comp_name">
                      <Skeleton width="100%" />
                    </strong>
                  </span>
                </p>
                <div className="d-flex comp_position_cont">
                  <Skeleton width="100%" />
                </div>
                <div className="comp_specs_cont">
                  <Skeleton width="100%" />
                </div>
              </div>
              <div className="comp_employers_redirection_cont"></div>
            </div>
          </div>
        ))}
      </>
    )
  }
  return (
    <>
      {
        (props?.jobsList?.length) > 0 ? (
          <Lists
            saveJobsList={props.saveJobsList}
            searchJobsList={props.searchJobsList}
            setSaveCheck={props.setSaveCheck}
            jobsList={props.jobsList}
            filterJobslist={props?.filterJobslist}
            updateApplyAndRefer={props?.updateApplyAndRefer}
          />
        ) : (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <div className="d-flex align-items-center flex-column">
              <img
                src={noJobs}
                style={{ width: "300px", height: "300px" }}
                alt="no jobs"
              />
              <p>No Jobs Found</p>
            </div>
          </div>
        )}
    </>
  );
}
export default JobsList;
