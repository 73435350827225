import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-hot-toast";
import { handleResendOTP } from "../../helpers/dataFetcher/otpVerifiy/verify";
import { Button } from "@mui/material";
import { sendOtp } from "../../helpers/dataFetcher/profile/update";

const CountdownTimer = ({ email, mobile, totalSec }) => {
  const countRef = useRef("");
  const [restart, setRestart] = useState(true);
  const [timee, setTime] = useState(Date.now() + totalSec);


  const resendVerificationCode = async (e) => {
    e.preventDefault();
    if(email) {
      const { data, status } = await handleResendOTP(email);
      if ([200, 201].includes(status)) {
        toast.success(data?.message ?? "OTP sent successfully");
        setTime(Date.now() + totalSec)
      }
      if ([400].includes(status)) {
        toast.error(data?.message ?? "Something went wrong");
      }
      if ([500].includes(status)) {
        toast.error(data?.message ?? "Something went wrong");
      }
    } else if(mobile) {
      const { status, data } = await sendOtp("mobile", {...mobile});
      if ([200, 201].includes(status)) {
        setTime(Date.now() + totalSec)
        toast.success(data?.message ?? "OTP sent successfully");
      }
      if ([400].includes(status)) {
        toast.error(data?.message ?? "Something went wrong");
      }
      if ([500].includes(status)) {
        toast.error(data?.message ?? "Something went wrong");
      }
    }
  
  };

  useEffect(() => {
    if (restart) {
      if(countRef?.current) {
        countRef?.current?.start();
      }
    }
  }, [restart, timee]);

  const renderer = ({ hours, minutes, seconds, completed, api }) => {
    if (completed) {
      return (
        <div className="d-flex justify-content-center pt-4">
          <Button
            type="submit"
            size="large"
            onClick={e => resendVerificationCode(e, api)}
            loadingPosition="end"
            variant="contained"
            sx={{
              borderRadius: "8px",
              boxShadow: "none",
              background: "#E6F0ED",
              padding: "4px 8px",
              fontSize: "14px",
              width: "100%",
              marginBottom: "10px",
              border: "0.8px solid var(--app-button-color1)",
              color: "var(--app-button-color1)",
              "&:hover": {
                boxShadow: "none",
                color: "#fff",
                background: "var(--app-button-color1)",
              }
            }}
          >
            <span style={{ textTransform: "capitalize" }}>Resend</span>
          </Button>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          <div className="p-3">
            <span>{minutes > 9 ? minutes : `0 ${minutes}`}</span>
            <span>{" : "}</span>
            <span>{seconds > 9 ? seconds : `0 ${seconds}`}</span>
          </div>
        </div>
      );
    }
  };


  return (
    <Countdown
      date={timee}
      renderer={renderer}
      autoStart={false}
      ref={countRef}
    ></Countdown>
  );
};

export default CountdownTimer;
