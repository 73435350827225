import React, { useState, useEffect } from "react";
import { Input, InputLabel, Typography, Box, Grid, Stack, IconButton, FormControl } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
    PrimaryOpacitedButton,
} from "../../../assets/MUI/materialUiStyles";
import ProfileEditIcon from "../../../assets/icons/ProfileEditIcon";
import { FormProvider, Controller, useForm } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";
import { matchIsValidTel } from "mui-tel-input";


export default function ProfilePhoneFieldForm({
    textField,
    textValue,
    sx,
    helperText,
    useFormProps,
    submitData,
    InputProps,
    ...rest
}) {

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        control,
        formState: { errors },
    } = useForm();


    const [fieldActive, setFieldActive] = useState(false);

    useEffect(() => {
        setValue(`${rest?.name}`, `${textValue}`)
    }, [fieldActive])


    function checkInputs() {
        if (textValue === "" || textValue === null || textValue === undefined) {
            return true;
        }
        return false;
    }
    async function submitedFunction(event) {
        const propertyName = rest?.name;  
        const propertyValue = event?.[propertyName]?.replace(/\s/g, '') ?? '';
    
        if (propertyName && propertyValue !== undefined) {
            event = { ...event, [propertyName]: propertyValue };
        }
    
        const result = await submitData(event);
    
        if (result) {
            handleButtons();  
        }
    }
    


    function handleButtons() {
        setFieldActive(!fieldActive);
    }


    return (
        <Grid container>
            <Grid item xs={4}>
                <Typography variant="h5" component="h5" sx={{ color: "#676767", fontWeight: "400", fontSize: "16px" }}>
                    {textField ?? ""}
                </Typography>
            </Grid>
            <>
                {fieldActive ? (
                    <Grid item xs={8}>
                        <form onSubmit={handleSubmit(submitedFunction)}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <Stack direction="row" spacing={"1"} justifyContent={"center"} alignItems={"center"}>
                                    <Controller
                                        name={`${rest?.name}`}
                                        control={control}
                                        rules={
                                            {
                                                ...useFormProps,
                                                validate: {
                                                    // matchesNewNumber: (value) => {
                                                    //     const newNumber = watch(`${rest?.name}`);
                                                    //     return value === newNumber || `${textField ?? ""} must match New Number`;
                                                    // },
                                                    isValidTel: (value) => matchIsValidTel(value) || `${textField ?? ""} is invalid`
                                                }
                                            }}
                                        render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => {
                                            return (
                                                <MuiTelInput
                                                    fullWidth
                                                    {...rest}
                                                    {...fieldProps}
                                                    defaultCountry={"IN"}
                                                    countryCodeEditable={true}
                                                    value={value ?? ''}
                                                    inputRef={fieldRef}
                                                    error={fieldState.invalid}
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...InputProps,
                                                        style: {
                                                            height: "40px",
                                                            borderRadius: "6px",
                                                            // border: "1px solid #c1c1c1",
                                                            // borderColor: error ? "var(--error-color)" : "#c1c1c1",
                                                            padding: "0px",
                                                            // "&:focus": {
                                                            //   border: "none",
                                                            // },
                                                            // "&::active": {
                                                            //   border: "none",
                                                            // },
                                                            "&:hover": {
                                                                border: `none !important`
                                                            },
                                                            ...(InputProps && InputProps.style),
                                                        },
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                    <IconButton type="submit" color="success" size="small">
                                        <DoneIcon fontSize="inherit" color="inherit" />
                                    </IconButton>
                                    <IconButton onClick={() => handleButtons()} color="error" size="small">
                                        <CloseIcon fontSize="inherit" color="inherit" />
                                    </IconButton>
                                </Stack>
                                {errors[`${rest?.name}`] ? (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            color: "var(--error-color)",
                                        }}
                                    >
                                        {errors[`${rest?.name}`] ? errors[`${rest?.name}`]?.message : null}
                                    </Typography>
                                ) : null}
                            </FormControl>
                        </form>
                    </Grid>
                ) : (
                    <>
                        {checkInputs() ? (
                            <Grid item xs={8}>
                                <PrimaryOpacitedButton
                                    onClick={() => handleButtons()}
                                    endIcon={<ProfileEditIcon disabled={false} />}
                                >
                                    Add New
                                </PrimaryOpacitedButton>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={8}
                                    sx={{
                                        display: "flex",
                                        direction: "row",
                                        gap: "5px",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        varient="h6"
                                        componenet="h6"
                                        sx={{
                                            overflow: "hidden",
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            letterSpacing: "-0.2px",
                                            // textOverflow: "ellipsis",
                                            // whiteSpace: "wrap",
                                            // textTransform: update_type === "gender" ? "capitalize" : ""
                                        }}
                                    >
                                        : {textValue}
                                    </Typography>
                                    <IconButton onClick={() => handleButtons()} color="success">
                                        <ProfileEditIcon />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </>
        </Grid>
    );
}








// Usase example

{/* <ProfilePhoneFieldForm
textField={"Other Contact"}
id="other_contact"
placeholder="Enter Other Contact"
name="other_contact"
textValue={accountData?.other_contact}
submitData={submitData}
useFormProps={{
  required: "Other Contact is required",
}}
/> */}