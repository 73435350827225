import {
  Card,
  CardContent,
  Grid,
  Box,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { Avatar, CircularProgress, Skeleton } from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  newResumeParser,
  resumeParser,
} from "../../../helpers/dataFetcher/resume/resume_parser";
import { useEffect, useState, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import { imageURL } from "../../../Constants/commonURLS";
import FileViewer from "react-file-viewer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllKeySkillsWithStatus,
  getProfile,
} from "../../../helpers/dataFetcher/profile/profile";
// import SearchParamHook from "hooks/searchParams";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { updateProfileNormal } from "../../../helpers/dataFetcher/profile/update";
import Dropzone, { useDropzone } from "react-dropzone";
import ProfileUpResumeIcon from "../../../assets/icons/ProfileUpResumeIcon";
import { updateUserAccount } from "../accountSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  documentMIMITypes,
  savePdfFileAsViaUrl,
} from "../../../helpers/common/download";
import { makeStyles } from "@mui/styles";
import toast from "react-hot-toast";
import updateUserProfile, {
  transformResumeData,
} from "../../../helpers/updateUserProfileWithResume";
import { showAlert } from "../../../components/sweetalert";
import { openModal } from "../../../components/modals/modalsSlice";

const useStyles = makeStyles(theme => ({
  pdfContainer: {
    width: "100%",
    "& .pg-viewer-wrapper .pg-viewer .pdf-viewer-container .pdf-viewer .pdf-canvas > canvas":
      {
        width: "100% !important",
      },
  },
}));

export default function ResumeSummary({ setRecall, reCall }) {
  const [resumeLoad, setResumeLoad] = useState(false);
  const [preview, setPreview] = useState(false);
  const classes = useStyles();

  //redux dispatch
  const dispatch = useDispatch();
  const accountData = useSelector(state => state.User.user);

  const supportedFileTypes = ["pdf", "docx", "xlsx", "csv"];
  const filePath = accountData ? `${imageURL}${accountData.resume_url}` : "";

  let fileType = supportedFileTypes.find(type => {
    const fileExtension = filePath.split(".").pop().toLowerCase();
    return supportedFileTypes.includes(fileExtension);
  });

  // If no supported file type is found, set a default fileType
  if (!fileType) {
    fileType = "docx";
  }

  useEffect(() => {
    dataFetcher();
  }, []);

  async function dataFetcher() {
    const { data, status } = await getProfile();

    if (status === 200) {
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          },
        })
      );
      setRecall(!reCall);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    handleAcceptedFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isFocused,
    isDragActive,
  } = useDropzone({
    accept: {
      "text/pdf": [".docx", ".doc", ".pdf", ".rtf", ".txt"],
    },
    onDrop,
  });

  const handleAcceptedFiles = async files => {
    if (files[0]?.size / 1024 / 1024 > 5) {
      toast.error(`File size too large. File size should be less than 5MB`);
      return;
    }

    if (files && Object.values(files).length > 1) {
      toast.error(`Maximum file limit exceeded! Please select only one file.`);
      return;
    }

    // if (files && !documentMIMITypes.includes(files?.type)) {
    //   toast.error(`Incorrect file format selected! Please select a valid document file.`);
    //   return;
    // }
    if (!files || !files.length) {
      toast.error(
        `Incorrect file format selected! Please select a valid document file.`
      );
      return;
    }

    setResumeLoad(true);
    try {
      const preSignedURL = await getPreSignedURL(files);

      const resumeFileURL = preSignedURL.replace(imageURL, "");

      // const { status } = await updateProfileNormal("resume", { resume_file: resumeFileURL });
      const { status, data } = await newResumeParser(files[0]);
      // let updatePhoneNumber = false;
      // let updateEmail = false;
      // if (data?.data?.email != accountData?.email) {
      //   const result = await showAlert({
      //     title: "Your email has changed. Do you want to update it?",
      //     text: data?.data?.email ?? "",
      //     showCancelButton: true,
      //     confirmButtonText: "Yes",
      //     cancelButtonText: "No",
      //   });
      //   if (result.isConfirmed) {
      //     updateEmail = true;
      //   } else {
      //     updateEmail = false;
      //   }
      // }
      // if (data?.data?.phoneNumber != accountData?.phone_number) {
      //   const result = await showAlert({
      //     title: "Your phone number has changed. Do you want to update it?",
      //     text: data?.data?.phoneNumber ?? "",
      //     showCancelButton: true,
      //     confirmButtonText: "Yes",
      //     cancelButtonText: "No",
      //   });
      //   if (result.isConfirmed) {
      //     updatePhoneNumber = true;
      //     console.log("Yes update phone");
      //   } else {
      //     // User canceled
      //     updatePhoneNumber = false;
      //     console.log("no, update phone");
      //   }
      // }
      // await updateUserProfile(
      //   data?.data ?? {},
      //   resumeFileURL,
      //   updatePhoneNumber,
      //   updateEmail
      // );
      if (data?.data) {
        let resumeData = await transformResumeData(
          data?.data,
          resumeFileURL,
          true,
          true
        );
        const { data: allSkills } = await getAllKeySkillsWithStatus(
          1,
          10000,
          ""
        );
        const result = allSkills?.data_payload?.map(item => ({
          value: item.id,
          name: item.name,
          isActive: item.is_active,
        }));
        dispatch(
          openModal({
            modalName: "profileUpdateConformationModal",
            modalProps: { data: resumeData, allSkills: result },
          })
        );
      } else {
        toast.error(
          "We are currently experiencing difficulties with parsing the resume. Please try again later or proceed to update your profile manually."
        );
      }
      // if ([200, 201].includes(status)) {
      //   await dataFetcher();
      // } else {
      //   toast.error("Something went wrong");
      // }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while uploading the resume.");
    } finally {
      setResumeLoad(false);
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        height: "100%",
        gap: "15px",
        position: "relative",
        minHeight: "50vh",
        userSelect: resumeLoad ? "none" : "",
      }}
    >
      {resumeLoad && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: "99",
            background: "rgb(255 255 255 / 62%)",
          }}
        >
          <CircularProgress thickness={1} color="primary" />
        </Stack>
      )}

      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{
          width: "100%",
          padding: "10px",
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            {accountData?.resume_url}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            gap: "10px",
          }}
        >
          <IconButton
            sx={{
              background: "var(--app-color-one)",
            }}
            disabled={
              !accountData?.resume_url || accountData?.resume_url === ""
            }
            justifyContent="center"
            size="small"
            alignItems="center"
            onClick={() => {
              setPreview(!preview);
            }}
          >
            {!preview ? (
              <Visibility fontSize="inherit" />
            ) : (
              <VisibilityOff fontSize="inherit" />
            )}
          </IconButton>
          <IconButton
            sx={{
              background: "var(--app-color-one)",
            }}
            disabled={
              !accountData?.resume_url || accountData?.resume_url === ""
            }
            size="small"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              savePdfFileAsViaUrl(
                `${imageURL}${accountData?.resume_url}`,
                `${accountData?.first_name}_resume.pdf`
              );
            }}
          >
            <DownloadRoundedIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Stack>
      {preview ? (
        <Stack
          direction="column"
          sx={{
            width: "100%",
            padding: "10px",
          }}
        >
          <Box
            sx={{ width: "100%", height: "350px", overflow: "auto" }}
            className={classes.pdfContainer}
          >
            <FileViewer
              fileType={fileType}
              filePath={`${imageURL}${accountData?.resume_url}`}
              style={{ width: "100%" }}
            />
          </Box>
        </Stack>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={
            {
              // height: "100%"
            }
          }
        >
          {/* <Dropzone
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
            maxSize={5 * 1024 * 1024}
            maxFiles={1}
          > */}
          {/* {({ getRootProps, getInputProps, isFocused, isDragActive }) => ( */}
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{
              width: "100%",
              border: `1.29px dashed ${
                isFocused || isDragActive ? "green" : "#DEDEDE"
              }`,
              background: "#F9F9F9",
              padding: "20px",
              borderRadius: "20.66px",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Box>
              <ProfileUpResumeIcon />
            </Box>
            <Box mt={2}>
              <Typography
                textAlign={"center"}
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Click or Drag file(s) here to upload
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography
                textAlign={"center"}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#A9A7A7",
                }}
              >
                Please only use these following formats: .dox, .doc, .pdf, .rtf,
                .txt <br />
                Max file size: 5MB | Max files: 1
              </Typography>
            </Box>
          </Stack>
          {/* )} */}
          {/* </Dropzone> */}
        </Stack>
      )}
    </Stack>
  );
}
