import { makeStyles } from "@mui/styles";

export const DataGridStyles = makeStyles({
  root: {
    borderColor: "rgba(0, 0, 0, 0.3)",
    border: "0.825815px solid rgba(14, 55, 75, 0.22)",
    borderRadius: "16px !important",
    fontFamily: "Inter , sans-serif !important",
    color: "rgba(0, 0, 0, 0.5)",
    boxShadow: "2px 2px 64px -44px #000000A6",
    background: "#fff",
    overflow: "hidden",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 13.213,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& input": {
      padding: "12px 14px",
      height: 20,
      borderColor: " rgba(14, 55, 75, 0.22)",
      borderRadius: "4px",
    },
  },
  headerCellStyles: {
    backgroundImage: "linear-gradient(0deg, #DEDEDE, #DEDEDE), linear-gradient(0deg, #F5F5F5, #F5F5F5)",
    lineHeight: "20px",
    "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
      fontWeight: "500",
      fontSize: "13.213px",
      lineHeight: "20px",
      color: "#000",
    },
  },
  menuItem: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  menuPaper: {
    background: "red",
  },
  menuList: {
    color: "black",
  },

  ".MuiMenu-paper.MuiPopover-paper .MuiList-root .MuiMenuItem-root:hover": {
    backgroundColor: "blue !important",
  },
});

  export const styleObj = {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11.5614px",
    color: "rgba(0, 0, 0, 0.8)",
  };
  export const paginateStyles = {
    "& .MuiPaginationItem-root": {
      "&:hover": {
        backgroundColor: "",
        color: "",
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(8, 23, 83, 0.2)",
        color: "",
        "&:hover": {
          backgroundColor: "rgba(8, 23, 83, 0.2)",
          color: "",
        },
      },
    },
  }