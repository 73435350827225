export default function ProfileWorkAuthorizationIcon(selected) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1954_32778)">
                <path d="M1.66663 6.66699V5.00033C1.66663 3.15938 3.15901 1.66699 4.99996 1.66699H6.66663M13.3333 1.66699H15C16.8409 1.66699 18.3333 3.15938 18.3333 5.00033V6.66699M18.3333 13.3337V15.0003C18.3333 16.8413 16.8409 18.3337 15 18.3337H13.3333M6.66663 18.3337H4.99996C3.15901 18.3337 1.66663 16.8413 1.66663 15.0003V13.3337M10.8333 8.33366V10.8337M6.66663 14.167L6.95258 13.881C7.83664 12.997 8.33329 11.7979 8.33329 10.5477V8.33366C8.33329 6.95295 9.45258 5.83366 10.8333 5.83366C12.214 5.83366 13.3333 6.95295 13.3333 8.33366V10.5477C13.3333 11.7979 12.8366 12.997 11.9526 13.881L11.6666 14.167" stroke={`${selected ? "white" : "#4F4F4F"}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1954_32778">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
