import React from "react";
import {
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  Chip,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BlogCatImg1 from "../../../assets/icons/Blogs/BlogCatImg1.svg";
import Banner from "../../../assets/icons/Blogs/BlogBanner.svg";
import airtable from "../../../assets/icons/Careers/airtable.svg";
import gumroad from "../../../assets/icons/Careers/gumroad.svg";
import mailChimp from "../../../assets/icons/Careers/mailChimp.svg";
import notion from "../../../assets/icons/Careers/notion.svg";
import ArrowRight from "../../../assets/icons/LandingPage/ArrowRight";
import UserIcon from "../../../assets/icons/Careers/UserIcon";
import LinkedinIcon from "../../../assets/icons/LandingPage/LinkedinIcon";
import InstagramIcon from "../../../assets/icons/LandingPage/InstagramIcon";
import TwitterIcon from "../../../assets/icons/LandingPage/TwitterIcon";
import YouTubeIcon from "../../../assets/icons/LandingPage/YouTubeIcon";
import FacebookIcon from "../../../assets/icons/LandingPage/FacebookIcon";
import { datailAllItem } from "../../../data/blogData";
function BlogContentSection() {
  const { blogId } = useParams();
  /**
   *
   * redux dispatch
   *
   */
  const dispatch = useDispatch();

  /**
   *
   * navigate
   *
   */
  const navigate = useNavigate();

  const datailItem = datailAllItem?.filter(blog => blog?.id === blogId)?.[0];

  return (
    <Grid
      container
      sx={{
        paddingTop: "50px",
        paddingBottom: "60px",
        width: "70%",
        margin: "auto",
        "@media (max-width: 600px)": {
          paddingTop: "40px",
          paddingBottom: "40px",
          width: "100%",
        },
      }}
    >
      <Grid item xs={12} sm={12}>
        <Stack
          sx={{ width: "100%", margin: "auto" }}
          spacing={4}
          direction="column"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Chip
              label={datailItem?.category}
              sx={{
                background: "#ECFAE2",
                "& .MuiChip-label": {
                  fontWeight: "700",
                },
              }}
            />
            <Chip
              label={datailItem?.date}
              size="medium"
              sx={{
                backgroundColor: "transparent",
              }}
            />
          </Stack>
          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "45px",
                fontWeight: "600",
                textAlign: "center",
                "@media (max-width: 900px)": {
                  fontSize: "20px",
                },
              }}
            >
              {datailItem?.headTitle}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#737373",
                textAlign: "center",
                "@media (max-width: 900px)": {
                  fontSize: "13px",
                },
              }}
            >
              {datailItem?.headText}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
              }}
            >
              <img
                src={datailItem?.headImage}
                alt="blogs"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Stack>
          <Divider flexItem />
          <Stack direction="column" spacing={2}>
            <Typography
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                fontSize: "15px",
                fontWeight: "500",
                color: "#737373",
                "@media (max-width: 900px)": {
                  fontSize: "12px",
                },
              }}
            >
              {datailItem?.headDesc}
            </Typography>

            {datailItem?.details?.map((item, index) => (
              <Box
                key={index}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#000",
                    "@media (max-width: 900px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#737373",
                    "@media (max-width: 900px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {item?.description}
                </Typography>

                {item.type === "list" ? (
                  <ul style={{ padding: "5px 20px" }}>
                    {item?.dataTypes?.map((el, ind) => (
                      <li style={{ marginTop: "5px" }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "700",
                            color: "#000",
                            "@media (max-width: 900px)": {
                              fontSize: "15px",
                            },
                          }}
                        >
                          {el?.heading}
                        <Typography
                        variant="span"
                          sx={{
                            display:"inline",
                            marginLeft:"5px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "#737373",
                            "@media (max-width: 900px)": {
                              fontSize: "12px",
                            },
                          }}
                        >
                          {el?.description}
                        </Typography>
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  item?.dataTypes?.map((el, ind) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                      key={ind}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#000",
                          "@media (max-width: 900px)": {
                            fontSize: "15px",
                          },
                        }}
                      >
                        {ind + 1}. {el?.heading}
                      </Typography>
                      <Typography
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#737373",
                          "@media (max-width: 900px)": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        {el?.description}
                      </Typography>

                      {el?.image && el?.image !== "" && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "fit-content",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <img
                            src={el?.image}
                            alt="blogs"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))
                )}
              </Box>
            ))}
          </Stack>
          <Divider flexItem />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Stack
                sx={{
                  height: "45px",
                  minWidth: "45px",
                  width: "45px",
                  borderRadius: "50%",
                  background: "var(--app-color-one)",
                }}
                justifyContent="center"
                alignItems="center"
              >
                {datailItem?.blogPoster?.avatar()}
              </Stack>
              <Box
                sx={{
                  width: "80%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                  noWrap
                >
                  {datailItem?.blogPoster?.name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "gray",
                  }}
                  noWrap
                >
                  {datailItem?.blogPoster?.designation}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                flexWrap: "wrap",
              }}
            >
              {datailItem?.blogPoster?.socialMedia?.map((item, index) => (
                <Stack
                  key={index}
                  sx={{
                    height: "35px",
                    minWidth: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    background: "#FAFAFA",
                    border: "1px solid #E5E5E5",
                    cursor: "pointer",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    window.open(item?.link, "_blank");
                  }}
                >
                  {item?.type === "linkedin" ? (
                    <LinkedinIcon />
                  ) : item?.type === "facebook" ? (
                    <FacebookIcon />
                  ) : item?.type === "twitter" ? (
                    <TwitterIcon />
                  ) : item?.type === "instagram" ? (
                    <InstagramIcon />
                  ) : item?.type === "youtube" ? (
                    <YouTubeIcon />
                  ) : null}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default BlogContentSection;
