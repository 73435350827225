import React, { useEffect, useState } from "react";
// import noJobs from "../../assets/images/no_jobs.svg";
import noJobs from "../../assets/images/nojobsShiyft.png";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import {
  getAllInterviewsList,
  getAllMyReferals,
} from "../../helpers/dataFetcher/jobs/jobs";
import moment from "moment";
import { Skeleton, Grid } from "@mui/material";
//TODO PUT A DEFAULT IMAGE AND LOADER HERE
import { styled } from "@mui/system";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Avatar, Button, CssVarsProvider } from "@mui/joy";
import { imageURL } from "../../Constants/commonURLS";
import { deepPurple } from "@mui/material/colors";
import MyInterviewsList from './MyInterviewsList';
import SocketConnection from "../../hooks/socket";
import ComingSoon from '../../Reusable/comingSoon';
function createData(name, calories, fat) {
  return { name, calories, fat };
}

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
  }
  `
);
function Interviews() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = useState("");
  const { lastMessage } = SocketConnection();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [status, setStatus] = useState(false);
  const [referJob, setReferJob] = useState([]);
  useEffect(() => {
    getAllJobs();
  }, []);
  useEffect(() => {
    searchFilterData();
  }, [search]);
  useEffect(() => {
    getAllJobs();
  }, [search == ""]);

  /*socket integration for interviews page start */

  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "interviews") {
          getAllJobs()
        }
      }
    }
  }, [lastMessage]);
  console.log(lastMessage, "lstmsg")
  /*socket integration for interviews page End */


  async function searchFilterData() {
    const filterData = referJob.filter(item => {
      if (item?.schedule_interview_company?.company_name.toLowerCase().includes(search.toLowerCase())) {
        return item;
      } else if (item.job_post_details
        ?.job_post_in_user_job_post?.job_title.toLowerCase().includes(search.toLowerCase())) {
        return item;
      }
    });
    setReferJob(filterData);
  }

  async function getAllJobs() {
    const { data, status } = await getAllInterviewsList();
    setReferJob(data?.data);
    if (status === 200) {
      setStatus(true);
    }
  }



  return (
    <Grid
      container
      sx={{
        height: "85vh",
        border: "0.8px solid #DEDEDE",
        borderRadius: "16px",
        background: "#fff",
        boxShadow: "0px 2px 54px -35px #0000008C",
        overflow: "hidden",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: "100%"
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ComingSoon />
      </Grid>
    </Grid>
  );
}

export default Interviews;
