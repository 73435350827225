import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import Button from "@mui/joy/Button";
import { useDispatch, useSelector } from "react-redux";

function UserLanguages(props) {
  const options = [
    { value: "Telugu", label: "Telugu" },
    { value: "Hindi", label: "Hindi" },
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.User.user);
  const [error, setError] = useState(false);
  async function addLanguages(data) {
    const result = selected.map(item => {
      return item.value;
    });
    const obj = {
      update_type_to_send_email: "email",
      languages: [...result],
      last_name: accountData.last_name,
      first_name: accountData.first_name,
      email: accountData.email,
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
    if (o.languages.length > 0) {
      await updateProfileNormal("languages", o);
      const { data } = await getProfile();
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          }
        })
      );
      props.onHide();
    } else {
      setError(true);
    }
  }
  useEffect(() => {
    setSelected(
      accountData?.languages?.map(item => ({
        label: item,
        value: item,
      }))
    );
  }, [accountData?.languages]);
  async function closeModal() {
    props.onHide();
  }
  return (
    <>
      <div className="box">
        <label htmlFor="languages">Languages</label>
        <div className="mb-3 react_select_main_cont" >
          <ReactSelect
            style={{ zIndex: "999" }}
            isMulti={true}
            options={options}
            value={selected}
            onChange={e => {
              setSelected(e);
              setError(false)
            }}
            classNamePrefix="select2-selection"

            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                // borderColor: (state.isFocused || state.selectProps.menuIsOpen) ? '#066a4c !important' : '#066a4c !important',
                borderColor: (state.isFocused )? '#cde2e7 !important' : state.selectProps.menuIsOpen?'#066a4c !important':"",
                boxShadow:"0 0 0 1px #066a4c",
                '&:hover': {
                  borderColor: '#cde2e7',
                },
              }),
            }}

            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#cde2e7',
              },
            })}
          />
          {error ? (
            <span style={{ color: "red" }}>Please enter Languages</span>
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-end submit-button mt-2">
        <div className="d-flex justify-content-end">
          <Button
        variant="outlined"
        color="primary"
        className=" mt-4"
        type="submit"
        onClick={() => addLanguages()}
        style={{
          margin: "3px",
          background: "rgb(236, 236, 236)",
          color: "rgb(8, 23, 83)",
          border:0
        }}
      >
        Save
      </Button>
        </div>
        <Button
        variant="outlined"
        color="primary"
        className=" mt-4"
        type="submit"
        onClick={() => closeModal()}
        style={{
          margin: "3px",
          background: "rgb(236, 236, 236)",
          color: "rgb(8, 23, 83)",
          border:0
        }}
      >
        Close
      </Button>
      </div>
    </>
  );
}

export default UserLanguages;
