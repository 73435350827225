import { Box, Stack, IconButton, CircularProgress, Grid, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import MyModal from "./components/Modal";
import { useState, useEffect } from "react";
import AutoComplete from "../autocomplete/Autocomplete";
import MultiSelect from "../formElements/MultiSelect";
import Geolocation from "../formElements/Geolocation";
import CustomSelect from "../formElements/CustomSelect";
import { getAllKeySkillsWithStatus } from "../../helpers/dataFetcher/profile/profile";

export default function FormComponentCheckModal({
  open,
  handleClose,
  edit,
  setOpen
}) {
  const [listData, setListData] = useState([{
    label: "Test 1",
    value: "1",
  },
  {
    label: "Test 2",
    value: "2",
  },
  {
    label: "Test 3",
    value: "3",
  },
  {
    label: "Test 4",
    value: "4",
  },
  {
    label: "Test 5",
    value: "5",
  },
  {
    label: "Test 6",
    value: "6",
  }]);



  // const getKeySkills = async (page, pageSize, searchText) => {
  //   const { data, status } = await getAllKeySkillsWithStatus(page, pageSize, searchText);
  //   setListData(data?.data_payload)
  // }


  // useEffect(() => {
  //   getKeySkills(1, 20, "");
  // })





  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      showCloseIcon={true}
      size="medium"
      sx={{
        width: "60vw",
        height: "600px",
        padding: "10px",
        position: "relative",
      }}
    >


      <Grid container >
        <Grid items sm={12} sx={{
          padding: "10px",
          border: '1px solid green'
        }}>

          <AutoComplete
            id="list23"
            name="list23"
            label="Select List"
            placeholder="Enter list name"
            required
            value={"One"}
            setFormikValue={() => { }}
            setValue={(value) => {
              console.log(value, "SettedValue")
            }}
            createNewHandler={(value) => { console.log(value) }}
            error={
              false
            }
            multiple
            selectOnFocus
            // clearOnBlur
            freeSolo
            handleHomeEndKeys
            variant="standard"
            // deleteOption={(value) => {
            //   console.log(value, "jsadassada")
            // }}
            helperText={"Provide Proper Data"}
            options={(listData || [])
              .map(item => ({
                label: item.label,
                value: item.value,
              }))}
            onInputChange={(e) => {
              if (e.target.value) {
                console.log(e.target.value, "Testing")
                // setFormData({ title: e.target.value, id: "" });
                // searchParams.set("list", e.target.value);
                // setSearchParams(searchParams);
              }
            }}
            onChange={(e) => {
              console.log(e, "OnChangeValue")
            }}
          />

        </Grid>
        <Grid items sm={12} sx={{
          padding: "10px",
          border: '1px solid green'
        }}>

          <MultiSelect
            id="list23"
            name="list23"
            label="Select List"
            placeholder="Enter list name"
            required
            value={[listData?.[1]]}
            // setFormikValue={() => { }}
            // setValue={(value) => {
            //   console.log(value, "SettedValue")
            // }}
            // createNewHandler={(value) => { console.log(value) }}
            // error={
            //   false
            // }
            limitTags={2}
            multiple
            selectOnFocus
            // clearOnBlur
            freeSolo
            handleHomeEndKeys
            variant="standard"
            // deleteOption={(value) => {
            //   console.log(value, "jsadassada")
            // }}
            helperText={"Provide Proper Data"}
            options={(listData || [])
              .map(item => ({
                label: item.label,
                value: item.value,
              }))}
          // onInputChange={(e) => {
          //   if (e.target.value) {
          //     console.log(e.target.value, "Testing")
          //   }
          // }}
          // onChange={(e) => {
          //   console.log(e, "OnChangeValue")
          // }}
          />

        </Grid>
        <Grid items sm={12} sx={{
          padding: "10px",
          border: '1px solid green'
        }}>
          <Geolocation
            id="locationtest"
            name="locationtest"
            label="Select Location"
            placeholder="Enter Location name"
            //  required 
            //  value={[listData?.[1]]}
            // setFormikValue={() => { }}
            // setValue={(value) => {
            //   console.log(value, "SettedValue")
            // }}
            // createNewHandler={(value) => { console.log(value) }}
            // error={
            //   false
            // }
            //  limitTags={2}
            //  multiple
            selectOnFocus
            // clearOnBlur
            freeSolo
            handleHomeEndKeys
            variant="standard"
            // deleteOption={(value) => {
            //   console.log(value, "jsadassada")
            // }}
            helperText={"Provide Proper Data"}
          //  options={(listData || [])
          //    .map(item => ({
          //      label: item.label,
          //      value: item.value,
          //    }))}
          // onInputChange={(e) => {
          //   if (e.target.value) {
          //     console.log(e.target.value, "Testing")
          //   }
          // }}
          // onChange={(e) => {
          //   console.log(e, "OnChangeValue")
          // }}
          />
        </Grid>
        <Grid items sm={12} sx={{
          padding: "10px",
          border: '1px solid green'
        }}>

          <CustomSelect
            id="locationtest"
            name="locationtest"
            label="Select Location"
            placeholder="Enter Location name"
            required
            value={"1"}
            setValue={(value) => {
              console.log(value, "SettedValue")
            }}
            error={
              true
            }
            helperText={"Provide Proper Data"}
            options={(listData || [])
              .map(item => ({
                label: item.label,
                value: item.value,
              }))}
            onChange={(e) => {
              console.log(e, "OnChangeValue")
            }}
          />
        </Grid>
      </Grid>

    </MyModal>
  );
}
