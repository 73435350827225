import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Suspense } from "react";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
export default function AuthOutlet() {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");

    // console.log(token, "tokentokentoken")
    // if (token || token !== "" ) {
    //     navigate("/")
    // }

    return (
        <React.Fragment>
            <main className="">
                <Suspense
                    fallback={
                        <Box
                            sx={{
                                display: "flex",
                                height: "100vh",
                                width: "100vw",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                >
                    <Outlet />
                </Suspense>
            </main>
        </React.Fragment>
    )

}
