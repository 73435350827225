import { Card, CardContent, Grid, Stack } from "@mui/material";
import {
  BorderCard,
  PrimaryDivider,
  PrimaryOpacitedButton,
  PrimaryTypography,
  GrayDivider,
  BlackTypography,
  SecondaryTypography
} from "../../../assets/MUI/materialUiStyles";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import InputButtonField from "./inputButtonField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";
import { transformedCountries } from "./Nationality";
import ProfileSelectFieldForm from "./ProfileSelectFieldForm";
import ProfileInputFieldForm from "./ProfileInputFieldForm";
// import SearchParamHook from "hooks/searchParams";

export default function WorkAuthInformation({ fetchUserJobPostDetails, setRecall, reCall }) {
  const accountData = useSelector((state) => state.User.user);
  let visaDetailsObj = accountData?.visa_details?.[0] || {};

  async function submitData(data) {

    let formData = {};

    if (data?.from || data?.visa || data?.to || data?.file) {
      let visaDetails = accountData?.visa_details?.[0] || {};
      if (data?.visa === "No") {
        visaDetails = {
          from: "",
          visa: "No",
          to: "",
          file: ""
        };
      } else {
        visaDetails = {
          ...visaDetails,
          ...data
        };
      }

      formData = { visa_details: [visaDetails] };
    } else {
      formData = { ...data };
    }


    const { status, data: resData } = await updateUserProfileDetails("about_me", formData);
    if ([201, 200].includes(status)) {
      setRecall(!reCall);
      return true;
    }

  }

  const currentDate = new Date().toISOString().slice(0, 10);

  const minDate = visaDetailsObj?.from ? visaDetailsObj?.from : currentDate;



  return (
    <Stack spacing={2} p={1}>
      <ProfileSelectFieldForm
        textField={"Country"}
        id="country"
        placeholder="Enter Country"
        name="country"
        type="select"
        options={transformedCountries ?? []}
        textValue={accountData?.country}
        submitData={submitData}
        useFormProps={{
          required: "Country is required",
        }
        }
      />

      <ProfileSelectFieldForm
        textField={"Do you have a visa?"}
        id="visa"
        placeholder="Enter Visa"
        name="visa"
        type="select"
        options={[
          { name: "Yes", value: "Yes" },
          { name: "No", value: "No" },
        ]}
        textValue={visaDetailsObj?.visa ?? ""}
        submitData={submitData}
        useFormProps={{
          required: "Visa is required",
        }
        }
      />

      {visaDetailsObj?.visa == "Yes" ? (
        <>

          <ProfileInputFieldForm
            textField={"From"}
            id="from"
            placeholder="Enter From Date"
            name="from"
            type="date"
            textValue={visaDetailsObj?.from ?? ""}
            // value={dateValue.toISOString().slice(0, 10)}
            submitData={submitData}
            useFormProps={{
              required: "From Date is required",
            }}
          />

          <ProfileInputFieldForm
            textField={"To"}
            id="to"
            placeholder="Enter To Date"
            name="to"
            type="date"
            inputProps={{
              // max: new Date().toISOString().slice(0, 10)
              min: minDate
            }}
            textValue={visaDetailsObj?.to ?? ""}
            submitData={submitData}
            useFormProps={{
              required: "To Date is required",
            }}
          />
 
          <ProfileInputFieldForm
            textField={"Type"}
            id="file"
            placeholder="Enter Type"
            name="file"
            type="text"
            textValue={visaDetailsObj?.file ?? ""}
            submitData={submitData}
            useFormProps={{
              required: "Type is required",
              maxLength: {
                value: 200,
                message: "Type must not exceed 200 characters",
              },
            }}
          />
        </>
      ) : (
        ""
      )}
    </Stack>
  );
}
