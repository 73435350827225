import React, { useState, useEffect } from "react";
import { Grid, Stack, Typography, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import noJobs from "../../assets/images/nojobsShiyft.png";
import { findAllFuncArea } from "../../helpers/dataFetcher/jobs/jobs";
import { imageURL } from "../../Constants/commonURLS";

function LandingCategoriesSection() {
  const [FunctionalArea, setFunctionalArea] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   *
   * Navigation context
   * */
  const navigate = useNavigate();

  useEffect(() => {
    dataFetcher();
  }, []);

  const dataFetcher = async () => {
    setLoading(true);
    const { data: fn_areas } = await findAllFuncArea("");
    if (fn_areas) {
      setLoading(false);
    }
    setLoading(false);

    setFunctionalArea(
      fn_areas
        ?.sort((a, b) => {
          if (a.countOfJobs === 0 && b.countOfJobs === 0) {
            return 0;
          } else if (a.countOfJobs === 0) {
            return 1;
          } else if (b.countOfJobs === 0) {
            return -1;
          } else {
            return a.countOfJobs - b.countOfJobs;
          }
        })
        ?.map(item => ({
          name: item.name,
          id: item.id,
          count: item.countOfJobs,
          image: item.image ?? "",
          skills: item.skills ?? "",
          icon: "fas fa-envelope",
          url: `/jobs-list?fn_areas=${item.id}`,
        }))
    );
  };

  return (
    <Grid container sx={{ paddingTop: "10px", paddingBottom: "40px" }}>
      <Grid item xs={12} sm={12}>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "44.94px",
              letterSpacing: "-0.02em",
              textAlign: "center",
            }}
          >
            Job Categories
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "15px",
            }}
          >
            Unleash your career potential with millions of jobs across countless
            industries
          </Typography>
        </Stack>
        <Stack>
          {loading ? (
            <Stack
              sx={{
                color: "grey.500",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={2}
              direction="row"
            >
              <CircularProgress color="success" />
            </Stack>
          ) : (
            <>
              {FunctionalArea?.length > 0 ? (
                <Box
                  sx={{
                    display: "grid",
                    width: "80%",
                    margin: "auto",
                    // justifyContent: "space-between",
                    // gridTemplateColumns: "repeat(4, 1fr)",
                    // gap: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                    // "@media (max-width: 1200px)": {
                    //     gridTemplateColumns: "repeat(3, 1fr)",
                    // },
                    // "@media (max-width: 900px)": {
                    //     gridTemplateColumns: "repeat(2, 1fr)",
                    //     width: "90%",
                    // },
                    // "@media (max-width: 600px)": {
                    //     gridTemplateColumns: "repeat(1, 1fr)",
                    //     width: "95%",
                    // },
                  }}
                >
                  {/* {FunctionalArea?.slice(0, 8).map(item => (
                                        <Box
                                            key={item?.id}
                                            onClick={() =>
                                                navigate(`../jobs-list?fn_areas=${item.id}`)
                                            }
                                            sx={{
                                                height: "79px",
                                                borderRadius: "7px",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                padding: "10px",
                                                margin: "3px",
                                                borderRadius: "16px",
                                                backgroundColor: "#116e522e",
                                                color: "#066a4c",
                                                width: "100%",
                                                minWidth: "200px",
                                                overflow: "hidden",
                                                "&:hover": {
                                                    // boxShadow: "rgb(17 12 46 / 15%) 0px 48px 100px 0px",
                                                    cursor: "pointer"
                                                }
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    minWidth: "40px",
                                                    minHeight: "40px",
                                                    background: "#FFFFFF",
                                                    borderRadius: "50%",
                                                    textAlign: "center",
                                                    marginRight: "10px"
                                                }}
                                            >
                                                <img
                                                    src={profileIcon}
                                                    style={{ paddingTop: "10px" }}
                                                />
                                            </Box>
                                            <Stack
                                                sx={{
                                                    display: "-webkit-box",
                                                    WebkitBoxOrient: "vertical",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <Typography sx={{
                                                    fontSize: "15px",
                                                    wordWrap: "break-word",
                                                    overflowWrap: "break-word"
                                                }}>
                                                    {item?.name}
                                                </Typography>
                                            </Stack>
                                            <KeyboardArrowRight />
                                        </Box>
                                    ))} */}

                  <Grid container spacing={3}>
                    {FunctionalArea?.slice(0, 12).map(item => (
                      <Grid item xs={12} sm={6} md={3} key={item?.id}>
                        <Stack
                          sx={{
                            borderRadius: "18px",
                            minHeight: "200px",
                            height: "250px",
                            overflow: "hidden",
                            background: "#FFFFFF",
                            border: "0.75px solid #DDDDDD",
                            boxShadow: "0px 4.5px 16.5px 0px #00000014",
                          }}
                        >
                          <Stack
                            sx={{
                              height: "calc(100% - 50px)",
                              // justifyContent: "center",
                              alignItems: "center",
                              padding: "18px",
                            }}
                          >
                            <Box
                              sx={{
                                borderRadius: "15px",
                                minHeight: "60px",
                                height: "60px",
                                width: "60px",
                                minWidth: "60px",
                                background: "#F6F6F6",
                                overflow: "hidden",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                padding: "5px",
                              }}
                            >
                              <img
                                src={
                                  item?.image
                                    ? imageURL + item.image
                                    : "https://img.freepik.com/free-vector/fruit-juice-logo-design_23-2148814570.jpg?w=2000"
                                }
                                alt=""
                                height={"100%"}
                                width={"100%"}
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "top",
                                  borderRadius: "50%",
                                }}
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography
                                textAlign={"center"}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  width: "250px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  lineHeight: "24px",
                                }}
                                // noWrap
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                textAlign={"center"}
                                sx={{
                                  fontSize: "13px",
                                }}
                              >
                                {Number(item?.count) >= 150
                                  ? "150+"
                                  : item?.count}{" "}
                               Jobs Available
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flexWrap: "wrap",
                                maxWidth: "300px",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                              mt={2}
                            >
                              {item.skills?.slice(0, 4)?.map((el, index) => (
                                <span
                                  key={index}
                                  style={{
                                    padding: "4px 6px 4px 6px",
                                    borderRadius: "99px",
                                    margin: "5px",
                                    background: "#ededed",
                                    fontSize: "12px",
                                  }}
                                >
                                  {el}
                                </span>
                              ))}
                            </Box>
                          </Stack>
                          <Stack
                            sx={{
                              height: "50px",
                              borderTop: "0.75px solid #E1E1E1",
                              backgroundColor: "#f3f0ec",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              textAlign={"center"}
                              sx={{
                                ":hover": {
                                  color: "blue",
                                  cursor: "pointer",
                                },
                                textDecoration: "underline",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                              onClick={() =>
                                navigate(`../jobs-list?fn_areas=${item?.id}`)
                              }
                            >
                              View All
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Stack direction="column" alignItems="center">
                    <img
                      src={noJobs}
                      style={{ width: "200px" }}
                      alt="no jobs"
                    />
                    <Typography
                      sx={{
                        color: "var(--text-color-one)",
                      }}
                    >
                      Job categories not found
                    </Typography>
                  </Stack>
                </Box>
              )}
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default LandingCategoriesSection;
