import React from "react";
import Certificates from "../../../../Reusable/Files";
import Languages from "../../../../Reusable/Languages";
import ResumePreview from '../../../../Reusable/ResumePreview';
import Skills from "../../../../Reusable/Skills";
import USVisaStatus from "../../../../Reusable/VisaStatus";
import TrainingAndEducation from "../../../../Reusable/TrainingAndEducation";
import WorkExperience from "../../../../Reusable/WorkExperience";
import Aboutus from "../../../Reusable/Profile/AboutUs";
import "./profilemodal.css";
import EditWorkExperience from "../../../../Reusable/EditWorkExperience";
import EditTrainingAndEducation from "../../../../Reusable/EditTrainingAndEducation";
import Modal from "@mui/material/Modal";
import ProfileDescription from '../../../Reusable/Profile/ProfileDescription'
import ImageUpload from '../../../Reusable/Profile/ImageUpload';
import { Box, Stack, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
function ProfileModal(props) {
  const { onHide, setProfileModal, show } = props;
  return (
    <Box>
      <Modal
        onClose={() => onHide(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={!!show}
      >
        <Stack
          sx={[
            {
              backgroundColor: "var(--paper-color)",
              border: 0,
              borderRadius: "6px",
              outline: "none",
              position: "relative",
              padding: "10px",
            },
          ]}
        >
          <Box
            sx={{
              position: "absolute",
              right: "-10px",
              top: "-10px",
              width: "fit-content",
              height: "fit-content",
              borderRadius: "50%",
              backgroundColor: "var(--paper-color)",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              zIndex: "5"
            }}
          >
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                onHide(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {props.params.type === "profileDescription" ? (
            <ProfileDescription
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "imageUpload" ? (
            <ImageUpload
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "profile" ? (
            <Aboutus
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "skills" ? (
            <Skills
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "experience" ? (
            <WorkExperience
              onHide={onHide}
              setProfileModal={setProfileModal}
            />
          ) : null}
          {props.params.type === "edit-experience" ? (
            <EditWorkExperience
              id={props?.params?.id}
              onHide={onHide}
              setProfileModal={setProfileModal}
            />
          ) : null}
          {props.params.type === "us_visa" ? (
            <USVisaStatus
              onHide={onHide}
            />
          ) : null}
          {/* Expected ctc  */}
          {props.params.type === "ctc" ? (
            <ExpectedCtc
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "Training" ? (
            <TrainingAndEducation
              onHide={onHide}
              setProfileModal={setProfileModal}
            //isEdit = {isEdit}
            //setIsEdit = {setIsEdit}
            />
          ) : null}

          {props.params.type === "edit-education" ? (
            <EditTrainingAndEducation
              id={props?.params?.id}
              onHide={onHide}
              setProfileModal={setProfileModal}
            />
          ) : null}

          {props.params.type === "Languages" ? (
            <Languages
              onHide={onHide}
            />
          ) : null}
          {props.params.type === "Files" ? (
            <Certificates
              onHide={onHide}
              setProfileModal={setProfileModal}
            />
          ) : null}
          {props.params.type === "resumePreview" ? (
            <ResumePreview
              onHide={onHide}
              setProfileModal={setProfileModal}
            />
          ) : null}
        </Stack>
      </Modal>
    </Box>
  );
}
export default ProfileModal;
