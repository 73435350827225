import React, { useState } from "react";
import { CircularProgress, Grid, Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import EarningsTable1 from './EarningsTable1';
import EarningsTable2 from './EarningsTable2';
import WithDrawTable1 from "./TransactionTable";
import ComingSoon from '../../Reusable/comingSoon';

import { Link, useNavigate, useSearchParams } from "react-router-dom";


function MyEarningsTabs() {

  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("category");

  const [profileTab, setProfileTab] = useState(!searchFilter || searchFilter == "" ? "self earnings" : searchFilter);

  function handleTypeChange(_, data) {
    if (data) {
      setProfileTab(data);
      searchParams.set("category", data);
      setSearchParams(searchParams);
    }
  }

  const tabData = [
    {
      id: "1",
      keyword: "self earnings"
    },
    {
      id: "2",
      keyword: "refer earnings"
    },
    // {
    //   id: "2",
    //   keyword: "transcations"
    // },

  ];

  return (
    <>
      <Grid container spacing={2} >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Stack
            direction="column"
            sx={{
              height: "100%",

            }}
          >
            <Stack
              direction="row"
              sx={{
              }}
            >
              <ToggleButtonGroup
                value={profileTab}
                exclusive
                onChange={handleTypeChange}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  display: "flex !important",
                  flexDirection: "row !important",
                  overflow: "scroll",
                  gap: "10px",
                  justifyContent: "flex-start !important",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
                size="small"
              >
                {tabData?.map((item, index) => (
                  <ToggleButton value={item?.keyword}
                    key={index}
                    sx={{
                      border: "1px solid #DEDEDE",
                      borderRadius: "50px !important",
                      fontSize: "14px",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                      padding: "6px 20px",
                      borderLeft: "1px solid #DEDEDE !important",
                      textTransform: "capitalize",
                      background: "#FFFFFF",
                      "&.Mui-selected": {
                        backgroundColor: "var(--app-button-color1) !important",
                        color: "#fff !important",
                        borderRadius: "50px !important",
                      }
                    }}
                    noWrap
                  >
                    {item?.keyword}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
            <Stack
              sx={{
                marginTop: "10px",
                // border: "0.8px solid #DEDEDE",
                // borderRadius: "16px",
                // background: "#fff",
                // boxShadow: "0px 2px 54px -35px #0000008C",
                // overflow: "hidden",
              }}
            >
              {profileTab === "self earnings" ? (
                <EarningsTable1  />
              ) : profileTab === "transcations" ? (
                <>
                  <Stack
                    sx={{
                      height: "65vh",
                      border: "0.8px solid #DEDEDE",
                      borderRadius: "16px",
                      background: "#fff",
                      boxShadow: "0px 2px 54px -35px #0000008C",
                      overflow: "hidden",
                    }}
                  >
                    <ComingSoon />
                  </Stack>
                  {/* <WithDrawTable1 withDrawTableData={props.withDrawTableData} /> */}
                </>
              ) : profileTab === "refer earnings" ? (
                  <EarningsTable2 />
              ) : null }
            </Stack>
          </Stack>
        </Grid>
      </Grid>

    </>
  );
}

export default MyEarningsTabs;
