import React, { useState, useEffect } from "react";
import { Input, InputLabel, Typography, Box, Grid, Stack, IconButton, FormControl, Select, MenuItem } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
    PrimaryOpacitedButton,
} from "../../../assets/MUI/materialUiStyles";
import ProfileEditIcon from "../../../assets/icons/ProfileEditIcon";
import { FormProvider, useForm } from "react-hook-form";
import GoogleMaterialInputLocation from "../../../components/InputLocation";



export default function ProfileLocationFieldForm({
    textField,
    textValue,
    sx,
    helperText,
    useFormProps,
    submitData,
    options,
    ...rest
}) {

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();


    const [fieldActive, setFieldActive] = useState(false);

    useEffect(() => {
        setValue(`${rest?.name}`, `${textValue}`)
    }, [fieldActive])


    function checkInputs() {
        if (textValue === "" || textValue === null || textValue === undefined) {
            return true;
        }
        return false;
    }

    async function submitedFunction(event) {
        if (event?.work_location) {
            event = { ...event, work_location: [event?.work_location] }
        }
        const result = await submitData(event);

        if (result) {
            handleButtons()
        }
    }


    function handleButtons() {
        setFieldActive(!fieldActive);
    }


    return (
        <Grid container>
            <Grid item xs={4}>
                <Typography variant="h5" component="h5" sx={{ color: "#676767", fontWeight: "400", fontSize: "16px" }}>
                    {textField ?? ""}
                </Typography>
            </Grid>
            <>
                {fieldActive ? (
                    <Grid item xs={8}>
                        <form onSubmit={handleSubmit(submitedFunction)}>
                            <FormControl sx={{ width: "100%" }} variant="standard">
                                <FormProvider
                                    register={register}
                                    watch={watch}
                                    errors={errors}
                                    formSetValue={setValue}
                                >
                                    <Stack direction="row" spacing={"1"} justifyContent={"center"} alignItems={"center"}>
                                        <GoogleMaterialInputLocation updatingContent={`${rest?.name}`} />
                                        <IconButton type="submit" color="success" size="small">
                                            <DoneIcon fontSize="inherit" color="inherit" />
                                        </IconButton>
                                        <IconButton onClick={() => handleButtons()} color="error" size="small">
                                            <CloseIcon fontSize="inherit" color="inherit" />
                                        </IconButton>
                                    </Stack>
                                </FormProvider>
                                {errors[`${rest?.name}`] ? (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            color: "var(--error-color)",
                                        }}
                                    >
                                        {errors[`${rest?.name}`] ? errors[`${rest?.name}`]?.message : null}
                                    </Typography>
                                ) : null}
                            </FormControl>
                        </form>
                    </Grid>
                ) : (
                    <>
                        {checkInputs() ? (
                            <Grid item xs={8}>
                                <PrimaryOpacitedButton
                                    onClick={() => handleButtons()}
                                    endIcon={<ProfileEditIcon disabled={false} />}
                                >
                                    Add New
                                </PrimaryOpacitedButton>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={8}
                                    sx={{
                                        display: "flex",
                                        direction: "row",
                                        gap: "5px",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        varient="h6"
                                        componenet="h6"
                                        sx={{
                                            overflow: "hidden",
                                            fontWeight: "600",
                                            fontSize: "15px",
                                            letterSpacing: "-0.2px",
                                            // textOverflow: "ellipsis",
                                            // whiteSpace: "wrap",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        : {textValue}
                                    </Typography>
                                    <IconButton onClick={() => handleButtons()} color="success">
                                        <ProfileEditIcon />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </>
        </Grid>
    );
}








// Usase example

{/* <ProfileLocationFieldForm
        textField={"Current Location"}
        id="work_location"
        placeholder="Enter Current Location"
        name="work_location"
        type="text"
        textValue={accountData?.work_location?.[0]}
        submitData={submitData}
        useFormProps={{
          required: "Current Location is required",
        }}
      /> */}