import React, { useRef, useEffect, useState } from 'react';
import { Grid, Stack, Typography, Box, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from '../../../../assets/icons/LandingPage/ArrowIcon';
import { datailAllItem } from '../../../../data/blogData';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    carouselStyle: {
        overflow: "visible !important",
        zIndex: 0,
        position: "relative",
        // background: "#fff",
        // borderRadius: "10px",
    },

});

function SlideBlogSection() {

    const navigate = useNavigate();

    const carouselRef = useRef();


    const classes = useStyles();
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop2: {
            breakpoint: { max: 3001, min: 1600 },
            items: 4,
        },
        desktop1: {
            breakpoint: { max: 1601, min: 1000 },
            items: 3,
            // gap: 2,
        },
        tablet2: {
            breakpoint: { max: 1001, min: 770 },
            items: 2,
        },
        tablet1: {
            breakpoint: { max: 771, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 465, min: 0 },
            items: 1,
        },
    };


    return (
        <Grid container sx={{ paddingTop: "50px", paddingBottom: "100px" }}>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
            >
                <Typography
                    sx={{
                        fontSize: "32px",
                        fontWeight: "600",
                        lineHeight: "44.94px",
                        letterSpacing: "-0.02em",
                        textAlign: "center"

                    }}
                >
                    ARTICLES
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontSize: "15px",
                    }}
                >
                    Check out our recent blogs for the latest updates and insights!
                </Typography>
            </Stack>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
            >
                <Grid container sx={{
                    width: "100%", margin: "auto",
                    display: "flex",
                }}
                >
                    {datailAllItem?.slice(0, 4)?.map((data, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={index}
                        >
                            <Stack
                                sx={{
                                    borderRadius: "10px",
                                    boxShadow: "0px 0px 8px 0px #00000014",
                                    // minHeight: "300px",
                                    height: "100%",
                                    background: "#FFFFFF",
                                    borderRadius: "16px",
                                    border: "1px solid #DEDCD4",
                                    opacity: "0px",
                                    padding: "10px",
                                    cursor: "pointer"
                                }}
                                direction="column"
                                m={1}
                                spacing={1}
                                onClick={() => {
                                    navigate(`/blog/${data?.id}`);
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "fit-content",
                                        borderRadius: "10px",
                                        overflow: "hidden"
                                    }}
                                >
                                    <img src={data?.headImage}
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            maxHeight: "100%",
                                            borderRadius: "10px",
                                            height: "230px"
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word"
                                        }}
                                    >{data?.headTitle}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                            overflowWrap: "break-word",
                                            color: "#5A7184"
                                        }}
                                    >{data?.desc}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    ))}

                </Grid>
            </Grid>

        </Grid >
    )
}

export default SlideBlogSection;