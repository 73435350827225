import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { CompanyData, JobSeekerData } from "../../../../data/TestimonialData";



function TestimonialSectionFirst() {
  const { pathname } = useLocation();
  
  return (
    <Grid container sx={{ paddingTop: "10px", paddingBottom: "40px" }}>
      <Grid item xs={12} sm={12}>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "44.94px",
              letterSpacing: "-0.02em",
              textAlign: "center",
            }}
          >
            Testimonials
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "15px",
            }}
          >
            {pathname === "/"
              ? "Job Seeker Success Stories"
              : `Here's What Companies Are Saying About Us`}
          </Typography>
        </Stack>
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          {(pathname === "/" ? JobSeekerData : CompanyData).map(item => (
            <Grid item xs={12} sm={6} md={4} key={item?.id}>
              <Stack
                sx={{
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Stack
                  sx={{
                    height: "60px",
                    padding: "5px",
                  }}
                  direction={"row"}
                  spacing={1}
                >
                  <Box
                    sx={{
                      borderRadius: "5px",
                      minHeight: "50px",
                      height: "50px",
                      width: "50px",
                      minWidth: "50px",
                      overflow: "hidden",
                      padding: "5px",
                    }}
                  >
                    <img
                      src={item?.image}
                      alt=""
                      height={"100%"}
                      width={"100%"}
                      style={{ objectFit: "cover", borderRadius: "5px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      {item?.location}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    height: "calc(100% - 60px)",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <Box mt={1}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        textAlign: "left",
                      }}
                    >
                      {item?.comments}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TestimonialSectionFirst;
