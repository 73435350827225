import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CompanyAndJobProfile from "./CompanyAndJobProfile";
import JobDetails from "./JobDetails";
import JobInsights from "./JobInsights";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getCompanyById } from "../../helpers/dataFetcher/companies";
import CompanyAndJobProfileDesc from "./CompanyAndJobProfileDesc";
import { getCompanyJobsById } from "../../helpers/dataFetcher/companies";
import JoineeLoader from "../../Loader/JoineeLoader";
import { useSelector } from "react-redux";
import JobDescription from "./JobDescription";

function BrowseJobsList() {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get('id');
  const userId = searchParams.get("userId")

  if (!id && id === "") {
    navigate("/jobs-list")
  }

  const jobApply = useSelector((state) => state.JobApplyStatusReducer)

  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const [compJobList, setCompJobList] = useState()
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    dataFetcher();
  }, [jobApply])

  useEffect(() => {
    window.scrollTo(0, 0);
    dataFetcher();
  }, []);

  useEffect(() => {
    dataFetcher(id)
  }, [id])


  async function dataFetcher(searchId, userId) {
    setLoader(true)
    let profileData
    if (searchId) {
      const { data } = await getJobPostById(searchId, userId ?? "");
      profileData = data?.data_payload
      setJob(data);
    } else {
      const { data } = await getJobPostById(id, userId ?? "");
      profileData = data?.data_payload
      setJob(data);

    }


    const { data: company_data } = await getCompanyById(profileData?.company_user_id ?? "");



    setCompany(company_data?.data_payload);


    const CompanyJobsData = await getCompanyJobsById(profileData?.company_user_id ?? "")


    const removeCurrentCompany = CompanyJobsData?.data?.data_payload?.filter((each) => each.id !== id)

    setCompJobList(removeCurrentCompany)

    setLoader(false)
  }

  if (loader) {
    return <JoineeLoader />;
  }

  return (
    <React.Fragment>
      <Grid container
        sx={{
          background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
          minHeight: "100vh",
          margin: "auto",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "30px",
          paddingBottom: "30px",
          "@media (max-width: 900px)": {
            paddingLeft: "5%",
            paddingRight: "5%",
          },
          "@media (max-width: 600px)": {
            paddingLeft: "3%",
            paddingRight: "3%",
          },
        }}
      >
        {/* Banner Section */}
        <Grid item xs={12} sm={12}>
          <JobDescription jobDetails={Job} company={company} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default BrowseJobsList;
