import { createSlice } from "@reduxjs/toolkit";

export const globalJobSlice = createSlice({
  name: "globalJob",
  initialState: {
    details: {},
    jobsProps: {},
    myFunction: () => {},
  },
  reducers: {
    // action to handle accounts
    updateJobDetails: (state, action) => {
      state.details = action.payload.details;
    },

    updatedAppliedJobStatus: (state, action) => {
      // state.myFunction() {

      // } 
    },

  },
});

// Action creators are generated for each case reducer function
export const { updateJobDetails, updatedAppliedJobStatus } = globalJobSlice.actions;

export default globalJobSlice.reducer;
