import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  BlackTypography,
  PrimaryCheckbox,
  PrimaryOutlinedButton
} from "../../../assets/MUI/materialUiStyles";
import UploadModel from "./ImageUpload";
import { useForm, Controller, FormProvider, } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Typography, FormControl, Grid, Stack, IconButton, TextareaAutosize, Select, MenuItem, Autocomplete, Button } from "@mui/material";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { toast } from "react-hot-toast";

import { useSelector } from "react-redux";
import Geolocation from "../../../components/formElements/Geolocation";
import CustomSelect from "../../../components/formElements/CustomSelect";
import TextField from "../../../components/formElements/TextField";
import TextBox from "../../../components/formElements/TextBox";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  width: "50%",
  p: 4,
  height: "70vh",
  minHeight: "300px",
  overflow: "auto",
};


export default function AddEditEducation({
  type,
  open,
  currentId,
  toggleModel,
  updateOrEditWorExperience,
}) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();


  const accountData = useSelector(state => state.User.user);
  const [currentData, setCurrentData] = useState(accountData?.courses?.filter((item) => item.id === currentId)[0] ?? {});

  useEffect(() => {
    setCurrentData(accountData?.courses?.filter((item) => item.id === currentId)[0] ?? {})
  }, [currentId, open, type])


  const [model, setModel] = React.useState(false);


  const handleChange = event => {
    setValue("is_currently_pursuing", event.target.checked);
  };


  React.useEffect(() => {
    if (type?.includes("update")) {
      setValue("certificate", currentData.certificate);
      setValue("institute", currentData.institute);
      setValue("start_date", currentData.start_date);
      setValue("end_date", currentData.end_date);
      setValue("institute_image", currentData.institute_image);
      setValue("desc", currentData.desc);
      setValue("specialization", currentData.specialization)
      setValue("cource_type", currentData.cource_type);
      setValue("location", currentData.location);
      setValue("is_currently_pursuing", currentData?.is_currently_pursuing);
    } else if (type === "create") {
      reset();
    }
  }, [type, open, currentId, currentData]);




  function imageModel(edit) {
    setModel(!model);

  }


  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    toast.success("Uploaded successful")
    setValue("institute_image", url);
    imageModel();
    //  setTimeout(()=>{
    //   if(!editImage){
    //     imageModel();
    //   }

    //   // imageModel()
    //  },1000)

  };


  const currentDate = new Date().toISOString().slice(0, 10);

  const minDate = watch("start_date") ? watch("start_date") : currentDate;

  return (
    <>
      {model && <UploadModel open={model} toggle={imageModel} handleImage={handleImageUpload} />}
        <Modal
          open={open}
          onClose={() => {
            toggleModel();
            reset();
          }}
        >
          <Box sx={{ ...style }}>
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
              <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
                {type === "create" ? "Create" : "Edit"} Education
              </BlackTypography>
              <IconButton
                onClick={() => {
                  toggleModel();
                  reset();
                }}
                sx={{ top: "-4px" }}
              >
                <FeatherIcon icon="x" />
              </IconButton>
            </Stack>

            <form
              onSubmit={handleSubmit(data => {
                updateOrEditWorExperience({ ...data, id: currentData?.id ?? "" });
                reset();
              })}
            >
              <Grid container spacing={2}>

                <Grid item md={12} lg={12} sm={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter your certificate"
                    useFormRef={{ ...register("certificate", { required: true }) }}
                    id="certificate"
                    autoFocus
                    type={"text"}
                    label="Degree"
                    required
                    name="certificate"
                    error={errors.certificate}
                    // variant="outlined"
                    helperText={errors.certificate ? "Certificate is required" : null}
                  />
                </Grid>
                <Grid item md={12} lg={12} sm={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter your institute"
                    useFormRef={{ ...register("institute", { required: true }) }}
                    id="institute"
                    type={"text"}
                    label="Institute"
                    required
                    name="institute"
                    error={errors.institute}
                    // variant="outlined"
                    helperText={errors.institute ? "Institute is required" : null}
                  />
                </Grid>
                <Grid item md={12} lg={12} sm={12}>

                  {!watch("institute_image") ? (
                    <PrimaryOutlinedButton
                      sx={{ maxWidth: "220px" }}
                      onClick={() => {
                        imageModel();
                      }}
                      startIcon={<FeatherIcon icon="edit" size="14" />}
                    >
                      Institute image
                    </PrimaryOutlinedButton>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        placeholder="Enter institute image url"
                        useFormRef={{ ...register("institute_image", { required: true }) }}
                        id="wor-exp-employer-outlined"
                        required
                        label="Institute Image"
                        type={"text"}
                        name="institute_image"
                        error={errors.institute_image}
                        readOnly={true}
                        helperText={errors.institute_image ? "Institute image is required" : null}
                      />
                      <PrimaryOutlinedButton
                        sx={{ maxWidth: "220px", marginTop: "8px" }}
                        onClick={() => {
                          imageModel("edit");
                        }}
                        startIcon={<FeatherIcon icon="edit" size="14" />}
                      >
                        Upload image
                      </PrimaryOutlinedButton>
                    </>
                  )}
                </Grid>


                <Grid item md={12} lg={12} sm={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter your specialization"
                    useFormRef={{ ...register("specialization", { required: true }) }}
                    id="specialization"
                    label="Specialization"
                    type={"text"}
                    name="specialization"
                    required
                    error={errors.specialization}
                    // variant="outlined"
                    helperText={errors.specialization ? "Specialization is required" : null}
                  />
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <CustomSelect
                    id="cource_type"
                    name="cource_type"
                    label="Cource type"
                    placeholder="Select Cource type"
                    required
                    value={watch("cource_type") || ""}
                    useFormRef={{ ...register("cource_type", { required: true }) }}
                    setValue={(value) => {
                      console.log(value, "SettedValue")
                    }}
                    error={
                      errors.cource_type
                    }
                    helperText={errors.cource_type ? "Cource type is required" : null}
                    options={[
                      { label: "Regular", value: "regular" },
                      { label: "Distance", value: "distance" },
                    ]}
                    onChange={(event) => {
                      setValue("cource_type", event?.target?.value || "");
                      clearErrors("cource_type");
                    }}
                  />

                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <Geolocation
                    id="locationtest"
                    name="locationtest"
                    label="Location"
                    placeholder="Enter location"
                    required
                    error={
                      errors?.location
                    }
                    //  limitTags={2}
                    //  multiple
                    useFormRef={{ ...register("location", { required: true }) }}
                    locValue={getValues("location")}
                    selectOnFocus
                    // clearOnBlur
                    freeSolo
                    handleHomeEndKeys
                    variant="standard"
                    onChange={(event, newValue) => {
                      setValue("location", newValue?.description || "");
                      clearErrors("location");
                    }}
                    helperText={errors.location ? "Location is required" : null}
                  />

                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter your specialization"
                    useFormRef={{ ...register("start_date", { required: true }) }}
                    id="wor-exp-certificate-outlined"
                    type="date"
                    name="start_date"
                    inputProps={{
                      max: currentDate
                      // min: new Date().toISOString().slice(0, 16)
                    }}
                    required
                    label={"Start Date"}
                    error={errors.start_date}
                    disableFuture={true}
                    helperText={errors.start_date ? "Start date is required" : null}
                  />
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <Stack
                    direction="row"
                    justifyContent=""
                    alignItems="center"
                    spacing={1}
                  >
                    <PrimaryCheckbox
                      checked={watch("is_currently_pursuing") ? watch("is_currently_pursuing") : false}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <BlackTypography>
                      I am currently pursuing now
                    </BlackTypography>
                  </Stack>
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  {watch("is_currently_pursuing") ? (
                    <TextField
                      fullWidth
                      useFormRef={{ ...register("expect_end_date", { required: watch("is_currently_pursuing") }) }}
                      id="expect_end_date"
                      type="date"
                      name="expect_end_date"
                      label="Expected End Date"
                      required
                      inputProps={{
                        // max: new Date().toISOString().slice(0, 10)
                        min: minDate
                      }}
                      error={errors.expect_end_date}
                      helperText={errors.expect_end_date ? "Expected end date is required" : null}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      useFormRef={{ ...register("end_date", { required: !watch("is_currently_pursuing") }) }}
                      id="expect_end_date"
                      type="date"
                      name="end_date"
                      label="End Date"
                      required
                      inputProps={{
                        // max: new Date().toISOString().slice(0, 10)
                        min: minDate
                      }}
                      error={errors.end_date}
                      helperText={errors.end_date ? "End date is required" : null}
                    />
                  )}
                </Grid>

                <Grid item md={12} lg={12} sm={12}>
                  <TextBox
                    fullWidth
                    placeholder="Enter your description"
                    useFormRef={{ ...register("desc", { required: false }) }}
                    minRows={3}
                    label="Description"
                    type={"text"}
                    name="desc"
                    // required
                    error={Boolean(errors.desc)}
                    // variant="outlined"
                    helperText={errors.desc ? "Description is required" : null}
                  />
                </Grid>

                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                  <Stack direction="row" alignItems={"flex-end"} justifyContent={"flex-end"} spacing={2}>
                    <Button
                      size="small"
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                      onClick={() => {
                        toggleModel();
                      }}
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        background: "#E6F0ED",
                        padding: "5px 15px",
                        fontSize: "14px",
                        border: "0.8px solid var(--app-button-color1)",
                        color: "var(--app-button-color1)",
                        "&:hover": {
                          boxShadow: "none",
                          color: "#fff",
                          background: "var(--app-button-color1)",
                        }
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        Cancel
                      </span>
                    </Button>
                    <Button
                      type="submit"
                      size="large"
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                        padding: "5px 15px",
                        WebkitBoxOrient: "horizontal",
                        overflow: "hidden",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                        "&:hover": {
                          boxShadow: "none",
                          background: "var(--app-button-color1)",
                        }
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>Submit</span>
                    </Button>

                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
    </>
  );
}