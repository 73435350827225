import {
  createKeySkills,
  getAllKeySkillsWithStatus,
} from "./dataFetcher/profile/profile";

// Function to fetch existing skills from the API
export async function fetchSkills(searchText) {
  const { data } = await getAllKeySkillsWithStatus(1, 10000, searchText);
  return (
    data?.data_payload?.map(item => ({
      id: item.id,
      name: item.name,
      isActive: item.is_active,
    })) || []
  );
}

// Function to add a new skill to the database
async function addSkill(skillName) {
  const { data: skill } = await createKeySkills({
    name: skillName,
    is_active: true,
  });
  return skill?.key_skill_res?.id;
}

// Main function to get skill IDs
export default async function getSkillIds(skillNames) {
  // Fetch all existing skills from the database
  const existingSkills = await fetchSkills("");
  const existingSkillsMap = new Map(
    existingSkills.map(skill => [skill.name.toLowerCase()?.trim(), skill.id])
  );

  // Check if skills already exist and if not, add them to the database
  const skillIds = [];
  for (const skillName of skillNames) {
    const skillNameLower = skillName.toLowerCase();
    if (existingSkillsMap.has(skillNameLower?.trim())) {
      // Skill exists, add its ID to the list
      skillIds.push(existingSkillsMap.get(skillNameLower));
    } else {
      // Skill does not exist, add it to the database
      const newSkillId = await addSkill(skillName);
      if (newSkillId) {
        skillIds.push(newSkillId);
      }
    }
  }

  return skillIds;
}

//   // Example usage:
//   const skillNames = ['JavaScript', 'React', 'Node.js'];
//   getSkillIds(skillNames).then(skillIds => {
//     console.log('Skill IDs:', skillIds);
//   });
