import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import { updateProfileNormal } from "../../../helpers/dataFetcher/profile/update";
import UploadFileModal from "../../../Reusable/FileUpload";
import "../../Accounts/Profiles/profile.css";
import { imageURL } from "../../../Constants/commonURLS";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link, useNavigate } from "react-router-dom";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import { findAllFuncArea } from "../../../helpers/dataFetcher/jobs/jobs";
import {
  getUserByToken,
  getUserWithToken,
  login,
} from "../../../helpers/dataFetcher/profile/profile";

import {
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Typography,
  NativeSelect,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { LocationCityOutlined, LocationOnOutlined } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { CssVarsProvider, Button } from "@mui/joy";
// import Button from '@mui/material/Button';
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function Aboutus(props) {
  const [completed, setCompleted] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [jobOptions, setJobOptions] = useState();
  const [selected, setSelected] = useState([]);
  const accountData = useSelector(state => state.User.user);
  const dispatch = useDispatch();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    fetchJobRoles();
  }, []);

  useEffect(() => {
    // Set the default time zone offset to 0
    dayjs.extend(require("dayjs/plugin/utc"));
    dayjs.extend(require("dayjs/plugin/timezone"));
    dayjs.tz.setDefault("Etc/UTC");
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const [modelOpen, setModelOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = async data => {
    const result = selected?.map(item => item.id);
    const finalResult = [...result];
    console.log(data, "data");
    const obj = {
      profile_image: data.profile_image,
      last_name: data.last_name,
      first_name: data.first_name,
      email: data.email,
      dob: data.dob,
      // dob: "dob",
      gender: data.gender,
      resume_file: data.resume_file,
      phone_number: data?.phone_number,
      country: data.country,
      state: data.state,
      city: data?.city,
      // gender: data?.gender,
      postal_code: data?.postal_code,
      address: data?.address?.description,
      description: data?.about,
      // job_role:finalResult,
      job_role: [data?.job_role],
      current_ctc: data?.current_ctc,
      expected_ctc: data?.expected_ctc,
      notice_period: data?.notice_period,
      work_mode: data?.work_mode,
      update_type_to_send_email: "email",
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );

    const { status } = await updateProfileNormal("about_me", o);
    if (status !== 200) {
      toast.error("Something went wrong");
      return;
    }
    setCompleted(true);

    props.onHide();

    const { data: response } = await getProfile();
    dispatch(
      updateUserAccount({
        user: {
          ...response,
        }
      })
    );
  };

  function disableFutDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return date => {
      return Date.parse(date) > startSeconds;
    };
  }
  useEffect(() => {
    if (accountData) {
      console.log(accountData, "useDeatils");
      setValue("first_name", accountData.first_name);
      setValue("last_name", accountData.last_name);
      setValue("profile_image", accountData.profile_image);
      setValue("email", accountData.email);
      setValue("dob", dayjs(accountData.dob));
      setValue("gender", accountData.gender?.toLowerCase());
      setValue("resume_file", accountData.resume_url);
      setValue("country", accountData.country);
      setValue("state", accountData.state);
      setValue("city", accountData?.city);
      setValue("phone_number", accountData?.phone_number);
      setValue("postal_code", accountData?.postal_code);
      setValue("address", accountData?.location);
      setValue("about", accountData?.about);
      setValue("job_role", accountData?.job_role);
      setValue("notice_period", accountData?.notice_period);
      setValue("work_mode", accountData?.work_mode);
      setValue("current_ctc", accountData?.current_ctc);
      setValue("expected_ctc", accountData?.expected_ctc);
      setValue("location", accountData?.location);
    }
  }, [accountData]);

  async function fetchJobRoles() {
    const { data: items } = await findAllFuncArea();
    const data = items.map(iterator => {
      return {
        id: iterator.id,
        label: iterator.name,
        value: iterator.name,
      };
    });
    setJobOptions(data);
  }

  const updateAboutMe = async () => {};

  const toggleUploadModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setValue("profile_image", url.replace(imageURL, ""));
    toggleUploadModel();
  };

  return (
    <>
      <UploadFileModal
        isOpen={modelOpen}
        toggle={toggleUploadModel}
        handleImage={handleImageUpload}
      />

      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="form-group mb-3">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-lables">
                Profile
              </label>
            </div>
          </div>
        </div>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} className="about_us_form">
        <Row>
          {/* <div className="profile_img_cont_main">
            <img
              className="profile_img_cont"
              onError={e => {
                e.target.src =
                  "https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo=";
              }}
              src={`${imageURL}${getValues("profile_image")}`}
            />
            <div>
              <CssVarsProvider>
                <Button
                  variant="solid"
                  type="button"
                  onClick={toggleUploadModel}
                  // className="profile_edit_btn_large mx-2"
                  color="primary"
                  style={{
                    margin: "3px",
                    background: "rgb(236, 236, 236)",
                    color: "rgb(8, 23, 83)",
                  }}
                >
                  Update Image
                </Button>
              </CssVarsProvider>
            </div>
          </div> */}
          <ThemeProvider theme={globalTheme}>
            {/* <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="first Name"
                name="first_name"
                error={errors.first_name ? true : false}
                id="first_name"
                type="text"
                // defaultValue={accountData?.first_name}
                className="form-control"
                {...register("first_name", { required: true })}
              />
            </Col>
            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="last name"
                className="form-control"
                error={errors.last_name ? true : false}
                name="last_name"
                type="text"
                {...register("last_name", { required: true })}
              />
            </Col> */}

            <Col xs={12} md={6} className="mt-4">
              <TextField
                disabled={true}
                variant="outlined"
                size="small"
                label="phone Number"
                className="form-control"
                type="tel"
                error={errors.phone_number ? true : false}
                // defaultValue={accountData?.phone_number}
                {...register("phone_number", { required: true })}
              />
            </Col>

            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="Email"
                name="email"
                disabled={true}
                error={errors.email ? true : false}
                className="form-control"
                type="email"
                {...register("email", { required: true })}
              />
            </Col>
            <Col xs={12} md={6} className="mt-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <Controller
                    name="dob"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <MobileDatePicker
                        disableFuture
                        label="Date of Birth"
                        inputFormat="MM/DD/YYYY"
                        value={dayjs(getValues("dob")) || ""}
                        // onChange={newValue => {
                        //   setValue("dob", newValue);
                        // }}
                        onChange={newValue => {
                          const formattedDate =
                            dayjs(newValue).format("MM/DD/YYYY");
                          setValue("dob", formattedDate);
                          clearErrors("dob");
                          if (!newValue) {
                            setError("dob", { type: "disabled" });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...register("dob", { required: true })}
                            error={errors.dob}
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Col>
            {/* <Col xs={12} md={6} className="mt-4">
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Gender
              </InputLabel>
              <NativeSelect
                size="small"
                inputProps={{
                  name: "Gender",
                  id: "uncontrolled-native",
                }}
                value={watch("gender")}
                onChange={e => {
                  setValue("gender", e.target.value);
                }}
              >
                <option value={"male"}>male</option>
                <option value={"female"}>female</option>
                <option value={"female"}>Prefer Not To Say</option>
              </NativeSelect>
            </FormControl>
          </Col> */}

            <Col xs={12} md={6} className="mt-4">
              <Autocomplete
                size="small"
                id="google-map-demo"
                // value={getValues("location") || ""}
                defaultValue={accountData?.location || ""}
                getOptionLabel={option =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={x => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                noOptionsText="No locations"
                {...register("address", { required: false })}
                onChange={(event, newValue) => {
                  setValue("address", newValue);
                  setOptions(newValue ? [newValue, ...options] : options);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={errors?.address ? true : false}
                    label="Add a location"
                    fullWidth
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];

                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map(match => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", width: 44 }}>
                          <LocationCityOutlined
                            sx={{ color: "text.secondary" }}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {parts.map((part, index) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? "bold" : "regular",
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </Col>

            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="Postal Code"
                className="form-control"
                error={errors.postal_code ? true : false}
                name="Postal Code"
                type="text"
                {...register("postal_code", { required: true })}
              />
            </Col>

            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="job Role"
                className="form-control"
                error={errors.job_role ? true : false}
                name="Job Role"
                type="text"
                {...register("job_role", { required: true })}
              />

              {/* <ReactSelect
                style={{ zIndex: "999"}}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#081753' : '',
                    borderRadius:8,
                    height:58
                    // height:52
                  }),
                }}
                isMulti={true}
                options={jobOptions}
                value={selected}
                onChange={e => {
                  setSelected(e);
                  setError(false);
                }}
                error={errors.job_role ? true : false}
                // {...register("job_role", { required: true })}
                classNamePrefix="select2-selection"
              /> */}
            </Col>
            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="Current CTC"
                className="form-control"
                error={errors.current_ctc ? true : false}
                name="Current CTC"
                type="text"
                {...register("current_ctc", { required: true })}
              />
            </Col>
            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="Expected CTC"
                className="form-control"
                error={errors.expected_ctc ? true : false}
                name="Expected CTC"
                type="text"
                {...register("expected_ctc", { required: true })}
              />
            </Col>
            <Col xs={12} md={6} className="mt-4">
              <TextField
                variant="outlined"
                size="small"
                label="Notice Period"
                className="form-control"
                error={errors.notice_period ? true : false}
                name="NoticePeriod"
                type="text"
                {...register("notice_period", { required: true })}
              />
            </Col>
            <Col xs={12} md={6} className="mt-4">
              <FormControl
                variant="outlined"
                size="small"
                className="form-control"
                style={{ height: 58 }}
              >
                <InputLabel id="employment-type-label">
                  Employment Type
                </InputLabel>
                <Select
                  labelId="employment-type-label"
                  label="Employment Type"
                  // value={getValues("work_mode") || ""}
                  defaultValue={accountData?.work_mode || ""}
                  name="work_mode"
                  error={errors.work_mode ? true : false}
                  {...register("work_mode", { required: true })}
                >
                  <MenuItem value="Full_Time">FullTime</MenuItem>
                  <MenuItem value="Part_Time">PartTime</MenuItem>
                  <MenuItem value="Freelance">Freelance</MenuItem>
                </Select>
              </FormControl>
            </Col>

            <Col
              xs={12}
              md={6}
              className="mt-4"
              // style={{ height: "10vh", overflowY: "scroll" }}
            >
              {/* <TextField
              style={{ width: "100%" }}
              // id="outlined-multiline-static"
              label="About"
              error={errors.about ? true : false}
              multiline
              row={12}
              {...register("about", { required: true })}
            /> */}
              {/* <TextField
                  style={{ width: "100%" }}
                  // id="outlined-multiline-static"
                  label="About"
                  error={errors.about ? true : false}
                  row={5}
                  {...register("about", { required: true })}
                /> */}

              {/* <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Type in here…"
                error={Boolean(errors.about)}
                {...register("about", { required: true })}
                style={{
                  minHeight: "60px",
                  maxHeight: "60px",
                  borderRadius: "8px",
                  borderColor: errors.about ? "red" : "#ced4da",
                }}
              /> */}
            </Col>
          </ThemeProvider>
          <Row className="mt-3">
            <div className="d-flex justify-content-end">
              <Button
                style={{
                  margin: "3px",
                  // background: "rgb(236, 236, 236)",
                  // color: "rgb(8, 23, 83)",
                  background: "#cde2e7",
                  color: "#066a4c",
                }}
                type="submit"
              >
                Save
              </Button>
              <Button
                style={{
                  margin: "3px",
                  // background: "rgb(236, 236, 236)",
                  // color: "rgb(8, 23, 83)",
                  background: "#ffe7bb",
                  color: "#066a4c",
                }}
                type="submit"
              >
                Close
              </Button>
            </div>
            {/* </div> */}
          </Row>
        </Row>
      </Form>
    </>
  );
}

export default Aboutus;
