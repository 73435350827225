import React from 'react';
import { Grid, Stack, Typography, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Frame1 from '../../../../assets/icons/Frame1';
import Frame2 from '../../../../assets/icons/Frame2';
import ApplyIcon from '../../../../assets/icons/LandingPage/ApplyIcon';
import HireIcon from '../../../../assets/icons/LandingPage/HireIcon';
import ReferIcon from '../../../../assets/icons/LandingPage/ReferIcon';
import ArrowIcon from '../../../../assets/icons/LandingPage/ArrowIcon';
import clutchIcon from '../../../../assets/icons/LandingPage/clutchIcon.svg';
import goodFirms from '../../../../assets/icons/LandingPage/goodFirms.svg';
import g2Icons from '../../../../assets/icons/LandingPage/g2Icons.svg';
import StarDarkIcons from '../../../../assets/icons/LandingPage/StarDarkIcons';

import { makeStyles } from "@mui/styles";
import VideoPauseIcon from '../../../../assets/icons/LandingPage/VideoPauseIcon';
import { openModal } from '../../../../components/modals/modalsSlice';

const useStyles = makeStyles({
    rotatingTextWrapper: {
        height: '100px',
        width: "fit-content",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: "hidden",
    },
    rotatingText: {
        margin: 0,
        animationDuration: '6s',
        animationIterationCount: 'infinite',
        opacity: 0,
        width: "fit-content",
        background: "#FFCA6C36",
        padding: "0px 15px"
    },
    rotatingText1: {

        animationName: '$rotatingText1',
    },
    rotatingText2: {

        animationName: '$rotatingText2',
    },
    rotatingText3: {

        animationName: '$rotatingText3',
    },
    '@keyframes rotatingText1': {
        '0%': {
            transform: 'translateY(200%)',
        },
        '33%': {
            transform: 'translateY(100%)',
            opacity: 1,
        },
        '34%': {
            opacity: 0,
        },
    },
    '@keyframes rotatingText2': {
        '33%': {
            transform: 'translateY(100%)',
            opacity: 0,
        },
        '66%': {
            transform: 'translateY(0)',
            opacity: 1,
        },
        '67%': {
            opacity: 0,
        },
    },
    '@keyframes rotatingText3': {
        '66%': {
            transform: 'translateY(0)',
            opacity: 0,

        },
        '99%': {
            transform: 'translateY(-100%)',
            opacity: 1,
        },
        '100%': {
            transform: 'translateY(-100%)',
            opacity: 0,
        },
    },
    "@media (max-width: 1600px)": {
        rotatingTextWrapper: {
            height: '70px',
        },
    },
    "@media (max-width: 1200px)": {
        rotatingTextWrapper: {
            height: '50px',
        },
    },
    "@media (max-width: 600px)": {
        rotatingTextWrapper: {
            height: '40px',
        },
    },


});


function BannerSection() {

    const authToken = localStorage.getItem("token");

    const classes = useStyles();

      /**
   *
   * redux dispatch
   *
   */
  const dispatch = useDispatch();


      /**
   *
   * navigate
   *
   */
      const navigate = useNavigate();




    return (
        <Grid container sx={{
            paddingTop: "80px", paddingBottom: "50px",
            "@media (max-width: 600px)": {
                paddingTop: "50px", paddingBottom: "30px",
            },
        }}>
            <Grid item xs={12} sm={12}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    sx={{
                        position: "relative",
                    }}
                    direction="row"
                    p={4}
                >
                    <Stack
                        sx={{
                            fontSize: "80px",
                            textAlign: "center",
                            position: "relative",
                            "@media (max-width: 1600px)": {
                                fontSize: "50px",
                            },
                            "@media (max-width: 1200px)": {
                                fontSize: "40px",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "30px",
                            },
                            "@media (max-width: 600px)": {
                                fontSize: "25px",
                            },
                        }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* <Box
                            sx={{
                                position: "absolute",
                                bottom: "0",
                                left: "0",
                                width: "fit-content",
                                height: "fit-content",
                                marginLeft: "-55px",
                                marginBottom: "-33px"
                            }}
                        ><Frame1 /></Box> */}
                        <Box>Hiring Top Talent Made Easy</Box>
                        <Box className={classes.rotatingTextWrapper}>
                            <Box className={`${classes.rotatingText} ${classes.rotatingText1}`}>Trusted Network</Box>
                            <Box className={`${classes.rotatingText} ${classes.rotatingText2}`}>Empowered by People</Box>
                            <Box className={`${classes.rotatingText} ${classes.rotatingText3}`}>Boosted by Technology</Box>
                        </Box>


                        <Box sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            width: "fit-content",
                            height: "fit-content",
                            marginRight: "-41px",
                            marginTop: "-45px",
                            "@media (max-width: 1200px)": {
                                width: "100px",
                                marginTop: "-20px",
                                marginRight: "-60px",
                            },
                        }}><Frame2 /></Box>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography sx={{
                        fontSize: "15px",
                        "@media (max-width: 900px)": {
                            fontSize: "12px",
                        },
                    }}>"Simplifying the Hiring Process,  Where Talent Meets Opportunity"</Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    mt={3}
                >
                    <Box
                        sx={{
                            border: "1px solid #066a4c",
                            color: "#fff",
                            padding: "8px 15px",
                            fontSize: "15px",
                            borderRadius: "50px",
                            background: "#066a4c",
                            cursor: "pointer",
                            "&:hover": {
                                background: "#fff",
                                color: "#000",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "12px",
                            },

                        }}
                        onClick={() => {
                            navigate("/registration")
                        }}
                    >Signup for free</Box>
                    {/* <Stack direction="row" spacing={1}
                        sx={{
                            border: "1px solid black",
                            color: "black",
                            padding: "8px 15px",
                            fontSize: "15px",
                            borderRadius: "50px",
                            cursor: "pointer",
                            "&:hover": {
                                background: "#066a4c",
                                color: "#fff",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "12px",
                            },
                        }}
                        onClick={() => {
                            dispatch(
                                openModal({
                                    modalName: "requestDemoOneModal",
                                    modalProps: {
                                    },
                                })
                            );
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <VideoPauseIcon />
                        </Box>
                        <Box>Request for demo</Box>

                    </Stack> */}
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
            >
                <Grid container sx={{ width: "90%", margin: "auto" }}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Stack
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                minHeight: "300px"
                            }}
                            p={2}
                            m={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ApplyIcon />
                            <Box mt={1} mb={2}>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >Top Companies are hiring now </Typography>
                            </Box>
                            <Link
                                to={authToken && authToken !== "" ? "/jobs-list" : "/login"}
                                style={{
                                    color: "var(--text-color)",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    marginTop: "10px"
                                }}
                            >Apply for a job <ArrowIcon /> </Link>
                        </Stack>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Stack
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                minHeight: "300px"
                            }}
                            p={2}
                            m={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <HireIcon />
                            <Box mt={1} mb={2}>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >Hire the top 1% candidates through joinee.com </Typography>
                            </Box>
                            <Link
                                to="/company"
                                style={{
                                    color: "var(--text-color)",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    marginTop: "10px"
                                }}
                            >Hire Candidates <ArrowIcon /> </Link>
                        </Stack>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Stack
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                minHeight: "300px"
                            }}
                            p={2}
                            m={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ReferIcon />
                            <Box mt={1} mb={2}>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >Refer a job with your connection and earn money</Typography>
                            </Box>
                            <Link
                                to="/jobs-list"
                                style={{
                                    color: "var(--text-color)",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    marginTop: "10px"
                                }}
                            >Refer & Earn <ArrowIcon /> </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
            >
                <Grid container
                    sx={{
                        width: "50%",
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            width: "100%",
                        },
                    }}>
                    <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                    >
                        <Stack
                            p={2}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img src={clutchIcon} />
                            <Box mt={2} mb={1}
                                sx={{ fontSize: "15px", fontWeight: "700" }}
                            >
                                <StarDarkIcons /> <span>4.8/5</span>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >127 Reviews</Typography>
                            </Box>
                        </Stack>

                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                    >
                        <Stack
                            p={2}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img src={g2Icons} />
                            <Box mt={2} mb={1}
                                sx={{ fontSize: "15px", fontWeight: "700" }}
                            >
                                <StarDarkIcons /> <span>4.8/5</span>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray", textAlign: "center" }}
                                >87% gives better experiences</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                    >
                        <Stack
                            p={2}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img src={goodFirms} />
                            <Box mt={2} mb={1}
                                sx={{ fontSize: "15px", fontWeight: "700" }}
                            >
                                <StarDarkIcons /> <span>4.8/5</span>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >127 Reviews</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default BannerSection;