import React, { useState, useEffect } from 'react';
import { Grid, Stack, Typography, Box, Button, Chip, InputAdornment, IconButton, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BlogCatImg1 from "../../../assets/icons/Blogs/BlogCatImg1.svg";
import ArrowRight from '../../../assets/icons/LandingPage/ArrowRight';
import { datailAllItem } from '../../../data/blogData';

function BlogsCategoriesSection() {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchFilter = searchParams.get("category");
    const [blogsData, setBlogsData] = useState(datailAllItem || [])

    const [blogTab, setBlogTab] = useState(searchFilter ?? "All Blogs");

    /**
    *
    * redux dispatch
    *
    */
    const dispatch = useDispatch();


    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();

    const dummyBlogsData = [
        {
            id: "1",
            categories: "Engineering",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
        {
            id: "2",
            categories: "Engineering",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
        {
            id: "3",
            categories: "",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
        {
            id: "4",
            categories: "",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
        {
            id: "5",
            categories: "",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
        {
            id: "6",
            categories: "",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
    ];


    function handleTypeChange(_, data) {
        if (data) {
            setBlogTab(data);
            searchParams.set("category", data);
            setSearchParams(searchParams);
        }
    }


    const uniqueKeywords = {};
    const tabData = [{ id: "all blog", keyword: "All Blogs" }, ...(datailAllItem || []).filter((el) => {
        const keyword = el?.category;
        if (!uniqueKeywords[keyword]) {
            uniqueKeywords[keyword] = true;
            return true;
        }
        return false;
    }).map((el) => ({ id: el?.id, keyword: el?.category }))];


    useEffect(() => {
        setBlogsData(datailAllItem?.filter((el) => el?.category === blogTab || blogTab === "All Blogs") || []);
    }, [blogTab, datailAllItem]);


    return (
        <Grid container sx={{
            paddingTop: "50px", paddingBottom: "45px",
            "@media (max-width: 600px)": {
                paddingTop: "30px",
                paddingBottom: "30px",
            },
        }}>
            <Grid
                item
                xs={12}
                sm={12}
                mb={3}
            >
                <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "scroll"
                    }}
                >
                    <ToggleButtonGroup
                        value={blogTab}
                        exclusive
                        onChange={handleTypeChange}
                        sx={{
                            border: "none",
                            borderRadius: "10px",
                        }}
                        size="small"
                    >
                        {tabData?.map((item, index) => (
                            <ToggleButton value={item?.keyword}
                                key={index}
                                sx={{
                                    border: "none",
                                    borderRadius: "50px !important",
                                    fontSize: "13px",
                                    marginRight: "5px",
                                    padding: "6px 20px",
                                    textTransform: "capitalize",
                                    "&.Mui-selected": {
                                        backgroundColor: "var(--app-button-color1) !important",
                                        color: "#9FE870 !important",
                                        borderRadius: "50px !important",
                                    }
                                }}
                            >
                                {item?.keyword}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>

                </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>

                {blogsData?.map((item, index) => (
                    <Grid container
                        key={index}
                        sx={{
                            border: "1px solid var(--border-color-2)",
                            padding: "10px",
                            boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                            background: "#fff",
                            marginBottom: "25px"
                        }}
                    >
                        <Grid item xs={12} sm={5}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    height: "100%"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "fit-content",
                                        "@media (max-width: 600px)": {
                                            marginBottom: "20px"
                                        },
                                    }}
                                >
                                    <img src={item?.headImage} alt="blogs" style={{ width: "100%", height: "auto" }} />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={7}
                            sx={{
                                minHeight: "100%",
                            }}
                        >
                            <Stack sx={{
                                width: "100%", margin: "auto", padding: "40px",
                                "@media (max-width: 600px)": {
                                    padding: "0px",
                                },
                            }} spacing={3}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={1}
                                >
                                    <Chip label={item?.category || ""}
                                        sx={{
                                            background: "#ECFAE2",
                                            '& .MuiChip-label': {
                                                fontWeight: "700",
                                            },
                                        }}
                                    />
                                    <Chip label="October 23, 2023" size="medium"
                                        sx={{
                                            backgroundColor: "transparent"
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    direction="column"
                                    sx={{
                                        width: "90%",
                                        "@media (max-width: 900px)": {
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "30px",
                                            fontWeight: "600",
                                            "@media (max-width: 1200px)": {
                                                fontSize: "25px",
                                            },
                                            "@media (max-width: 900px)": {
                                                fontSize: "18px",
                                            },
                                        }}
                                    >{item?.headTitle}</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#737373",
                                            "@media (max-width: 1200px)": {
                                                fontSize: "14px",
                                            },
                                            "@media (max-width: 900px)": {
                                                fontSize: "13px",
                                            },
                                        }}
                                    >
                                        {item?.desc}
                                    </Typography>
                                    <Button
                                        type="submit"
                                        size="large"
                                        onClick={() => { navigate(`/blog/${item?.id}`) }}
                                        loading={false}
                                        loadingPosition="end"
                                        variant="contained"
                                        sx={{
                                            borderRadius: "50px",
                                            boxShadow: "none",
                                            width: "fit-content",
                                            background: "#fff",
                                            color: "#000",
                                            padding: "8px 30px",
                                            border: "1px solid var(--border-color-2)",
                                            "&:hover": {
                                                boxShadow: "none",
                                                background: "var(--app-button-color1)",
                                                color: "#fff",
                                            },
                                            marginTop: "30px",
                                        }}
                                        endIcon={<IconButton
                                            edge="end"
                                            size="small"
                                        >
                                            <ArrowRight />
                                        </IconButton>}
                                    >
                                        <span style={{ textTransform: "capitalize", fontWeight: "600" }}>Read More</span>
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                ))}


            </Grid>



        </Grid >
    )
}

export default BlogsCategoriesSection;