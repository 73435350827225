import React, { useEffect, useState } from "react";
import StepThree from "../../Refernow/StepThree";
import JobDetails from "../Apply/JobDetails";
import UserResume from "../Apply/userResume";
import CandidateDetails from "../Apply/CandidateDetails";
import ScreeningQuestions from "../Apply/ScreeningQuestions";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottiles/pdf_search.json";
import { Grid, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { getScreeningQuestions } from "../../../helpers/dataFetcher/jobs/jobs";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const resumeObj = {
  // courses: [
  //   {
  //     Specialization: "",
  //     certificate: "",
  //     institute: "",
  //     start_date: "",
  //     end_date: "",
  //   },
  // ],
  // dob: "",
  email: "",
  first_name: "",
  last_name: "",
  location: "",
  phone_number: "",
  // skills: [],
  // work_exp: [{ employer: "", title: "", start_date: "", end_date: "" }],
};
export const ReferNowModal = props => {
  const [step, setStep] = useState(1);
  const [purpose, setPurpose] = useState("");
  const [resumeDetails, setResumeDetails] = useState(resumeObj);
  const [screeningQuestions, setScreeningQuestions] = useState([])
  const { params } = props;
  const [status, setStatus] = useState();

  const { details } = params;

  useEffect(() => {
    setPurpose(props.purpose);
    // dataFetcher(details?.id)
  }, [props.purpose]);



  // useEffect(()=>{
  //   dataFetcher(details?.id)
  // },[])

  // async function dataFetcher(id) {
  //   const result = await getScreeningQuestions(id);
  //   setScreeningQuestions(result?.data?.job_screening_questions_details);
  // }
  switch (step) {
    case 1: {
      return (
        <JobDetails
          step={step}
          show={props.show}
          onHide={data => props.onHide(data)}
          purpose={purpose}
          setStep={setStep}
          details={details}
          setPurpose={setPurpose}
        />
      );
    }

    case 2: {
      return (
        <UserResume
          step={step}
          setStatus={setStatus}
          show={props.show}
          onHide={data => props.onHide(data)}
          setStep={setStep}
          details={details}
          purpose={purpose}
          setPurpose={setPurpose}
          setResumeDetails={setResumeDetails}
        />
      );
    }

    case 4: {
      return (
        <ScreeningQuestions
          step={step}
          setStatus={setStatus}
          show={props.show}
          onHide={data => props.onHide(data)}
          setStep={setStep}
          details={details}
          purpose={purpose}
          setPurpose={setPurpose}
          setResumeDetails={setResumeDetails}
          screeningQuestions={screeningQuestions}
          setScreeningQuestions={setScreeningQuestions}
        />
      );
    }

    case 3: {
      return (
        <CandidateDetails
          step={step}
          show={props.show}
          onHide={data => props.onHide(data)}
          setStep={setStep}
          details={details}
          purpose={purpose}
          status={status}
          setPurpose={setPurpose}
          setResumeDetails={setResumeDetails}
          resumeDetails={resumeDetails}
          screeningQuestions={screeningQuestions}
          setScreeningQuestions={setScreeningQuestions}
          updateApplyAndRefer={props?.updateApplyAndRefer}
        />
      );
    }

    default:
      return (
        <Modal
          open={props?.show}
          // onClose={() => props.onHide("")} 
          onClose={() => {
            props.onHide("");
            setStep(1);
          }}

          style={{ overflowY: "scroll", maxHeight: "100vh" }}
        >
          <ModalDialog
            size="lg"
            aria-labelledby="layout-modal-title"
            aria-describedby="layout-modal-description"
            layout={props?.show || undefined}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.body",
              }}
            />

            <Typography
              id="layout-modal-title"
              level="inherit"
              className="text_headling_p d-flex align-items-center gap-2 justify-content-start"
              fontSize="1.25em"
              mb="0.25em"
            >
              {details?.job_title}
            </Typography>
            <Grid container>
              <Grid xs={12} style={{ placeItems: "center" }}>
                <Typography
                  id="layout-modal-title"
                  style={{ textAlign: "center" }}
                  level="inherit"
                  fontSize="0.7em"
                  mb="0.25em"
                >
                  parsing Your Resume
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Lottie options={defaultOptions} height={200} width={200} />
              </Grid>
            </Grid>
          </ModalDialog>
        </Modal>
      );
  }
};
