export const saveImageFileAs = (data, fileName) => {
  const blob = new Blob([data], { type: "octect/stream" });
  const url = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.setAttribute("href", url);
  anchor.setAttribute("download", fileName);
  anchor.click();
  URL.revokeObjectURL(url);
};

export const savePdfFileAsViaUrl = (data, fileName) => {
  try {
    pdfUrlToBlob(data)
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.setAttribute("href", url);
        anchor.setAttribute("download", fileName);
        anchor.click();
      })
      .catch(error => {
        console.error("Error:", error);
      });

  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};

async function pdfUrlToBlob(pdfUrl) {
  try {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error occurred while converting PDF URL to blob:", error);
    throw error;
  }
}


export const savePdfFileAsViaLocalData = (data, fileName) => {
  try {
    const blob = new Blob([data], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", fileName);
    anchor.click();

    // Removed: URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};


export const downLoadFileViaUrl = (url, fileName) => {
  const anchor = document.createElement('a');
  anchor.setAttribute("href", url);
  anchor.setAttribute("download", fileName);
  anchor.click();
  // revoke url after using it
  URL.revokeObjectURL(url);
};



 
// Document MIMI Type
export const documentMIMITypes = ['application/docx', 'application/doc', 'application/pdf', 'application/rtf', 'text/plain'];
