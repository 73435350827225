import FeatherIcon from "feather-icons-react";
import { CssVarsProvider } from "@mui/joy/styles";
import moment from "moment";
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { getScreeningQuestions } from "../../../helpers/dataFetcher/jobs/jobs";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Box, Grid, Button, Stack, Typography } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { updateScreeningQuestion } from "../../../helpers/dataFetcher/jobs/jobs";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jobApplyStatus } from "../../../store/JobApplyStatus/action";
import TextField from "../../../components/formElements/TextField";
export default function ScreeningQuestions({
  show,
  onHide,
  step,
  purpose,
  setStep,
  setStatus,
  details,
  setResumeDetails,
  screeningQuestions,
  setScreeningQuestions,
}) {
  const user = useSelector(state => state.User.user);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const [screeningQuestionsList, setScreeningQuestionsList] = useState([]);
  const dispatch = useDispatch();

  const textareaRef = useRef();

  const [approved, setApproved] = React.useState(false);

  const onSubmit = async data => {
    const answerValue = getValues();
    //  let answersList = [data]
    let answersList = [answerValue];

    const filteredScreeningData = screeningQuestions?.questions_data?.filter(
      each => {
        const question = each.job_post_question[0];
        return Object.keys(answersList[0]).includes(question);
      }
    );

    const finalData = filteredScreeningData.map((each, i) => {
      return {
        // question: each.id,
        id: each.id,
        answer: Object.values(answersList[0])[i],
        user_job_post_id: each.user_job_post_id,
      };
    });

    const { status } = await updateScreeningQuestion({
      job_post_id: screeningQuestions?.questions_data[0]?.job_post_id,
      answers_data: finalData,
    });
    if (status == 200) {
      toast.success("Answers submitted successfully");
      dispatch(jobApplyStatus(true));
      setStep(1);
      onHide();
    } else {
      toast.error("Something went error");
      onHide();
    }
  };

  const escapeQuotes = question => {
    return question.replace(/"/g, '\\"'); // Replace double quotes with escaped double quotes
  };

  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide("");
          setStep(1);
        }}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center"}
          sx={{
            maxHeight: "fit-content!important"
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              flexShrink: 0,
              overflow: "hidden",
              gap: "5px",
              textOverflow: "ellipsis",
              borderBottom: "0.8px solid rgb(222, 222, 222)"
            }}
          >
            <Typography
              noWrap
              id="layout-modal-title"
              component={Link}
              level="inherit"
              to={`../job?id=${details?.id}`}
              sx={{ fontSize: "25px", fontWeight: "600" }}>{details?.job_title}</Typography>
          </Stack>
          {purpose === "self" ? (
            <>
              <form
                style={{
                  overflowY: "scroll",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container sx={{ width: "100%", minHeight: "50px", overflowX: "hidden" }}>
                  {screeningQuestions?.questions_data?.map(each => (
                    <Grid
                      sx={{
                        padding: "10px"
                      }}
                      xs={12}
                      md={12}
                      lg={12}
                      key={each?.id}
                      items
                    >
                      <Box style={{ maxHeight: "100%", overflowY: "scroll" }}>
                        <Typography sx={{ fontSize: "14px" }}>
                          {each?.job_post_question?.[0]?.replace(/["']/g, "")}
                        </Typography>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Answer here…"
                          {...register(
                            escapeQuotes(
                              each?.job_post_question?.[0]?.replace(/["']/g, "")
                            ),
                            { required: true }
                          )}
                          style={{
                            minHeight: "60px",
                            maxHeight: "61px",
                            borderRadius: "8px",
                            overflowY: "scroll !important",
                            width: "100%",
                          }}
                          // ref={textareaRef}
                          innerRef={textareaRef}
                        />
                        {errors[
                          escapeQuotes(
                            each?.job_post_question?.[0]?.replace(/["']/g, "")
                          )
                        ] && (
                            <Typography sx={{ color: "red", fontSize: "11px" }}>
                              Please answer the questions
                            </Typography>
                          )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    size="small"
                    loadingPosition="end"
                    variant="contained"
                    sx={{
                      borderRadius: "50px",
                      boxShadow: "none",
                      background: "var(--app-button-color1)",
                      padding: "5px 30px",
                      "&:hover": {
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                      }
                    }}
                  >
                    <span style={{ textTransform: "capitalize" }}>Submit</span>
                  </Button>
                </Stack>
              </form>
            </>
          ) : (
            <></>
          )}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
