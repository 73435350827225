import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import { CssVarsProvider } from "@mui/joy";
import { imageURL } from "../../Constants/commonURLS";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";
import JobInsights from "./JobInsights";
import SkillsSection from "./SkillsSection";
import JobInfoDescription from "./JobInfoDescription";
import JobInfoRequirements from "./JobInfoRequirements";
import CompanyJobsCardList from "./CompanyJobsCardList";

import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { savedJobById } from "../../helpers/dataFetcher/jobs/jobs";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import copy from "copy-to-clipboard";
import JobBagIcon from "../../assets/icons/JobBagIcon";
import JobLocationIcon from "../../assets/icons/JobLocationIcon";
import JobBookIcon from "../../assets/icons/JobBookIcon";
import JobClockIcon from "../../assets/icons/JobClockIcon";
import JobUsersIcon from "../../assets/icons/JobUsersIcon";
import JobShareIcon from "../../assets/icons/JobShareIcon";

import {
  Stack,
  Grid,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Divider,
  Box,
  Avatar,
  Skeleton,
  Tooltip,
  Chip,
  IconButton,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";

function JobDescription(props) {
  const [ReferStatus, setReferStatus] = useState({
    modal: "",
    type: "",
    action: "",
    modalType: "",
    details: {},
  });

  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
    details: {},
  });
  const user = useSelector(state => state.User.user);

  const navigate = useNavigate();

  const abbreviateNumber = number => {
    number = +number;
    const scaledNumber = number / 1000;
    if (number >= 1000) {
      return `${Number.parseFloat(scaledNumber).toFixed(0)}k`;
    } else {
      return `${Number.parseFloat(number).toFixed(0)}`;
    }
    // return `${scaledNumber.toFixed(0)}k`;
  };

  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("user_id");

  return (
    <React.Fragment>
      <Grid container sx={{ paddingTop: "10px", paddingBottom: "40px" }}>
        {props?.jobDetails?.data_payload &&
          [props?.jobDetails?.data_payload].map((item, index) => (
            <Grid item xs={12} sm={12} key={item?.id}>
              <Stack
                sx={{
                  width: "100%",
                  border: "0.8px solid #DEDEDE",
                  borderRadius: "16px",
                  padding: "24px",
                  gap: "10px",
                  background: "#F9F9F9",
                  boxShadow: "0px 2px 54px -35px #0000008C",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignContent={"flex-start"}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignContent={"flex-start"}
                    sx={{
                      // display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Box
                      sx={{
                        height: "44px",
                        width: "44px",
                        minWidth: "44px",
                        borderRadius: "50%",
                        border: "0.5px solid rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Avatar
                        src={`${imageURL}${item?.company_details?.company_image}`}
                        style={{
                          borderRadius: "50%",
                          borderColor: "rgba(0, 0, 0, 0.25)",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {item?.company_details?.company_name?.charAt(0)}
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        // flexShrink: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#121212",
                        fontFamily: "Inter,sans-serif",
                      }}
                      fontWeight="md"
                      textColor="primary.plainColor"
                      mb={0.5}
                    >
                      <Typography
                        noWrap
                        onClick={() =>
                          navigate(`/job?id=${item?.id}&userId=${userId ?? ""}`)
                        }
                      >
                        {item?.job_title ?? ""}
                        {/* {item?.job_title?.length <= 20
                        ? item?.job_title
                        : item?.job_title?.slice(0, 18) + ".."} */}
                      </Typography>
                      <Typography
                        noWrap
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "#121212",
                          opacity: "50%",
                          fontFamily: "Inter,sans-serif",
                        }}
                        onClick={() =>
                          navigate(
                            `/employer-single-list?id=${item?.company_user_id}`
                          )
                        }
                      >
                        {item?.company_details?.company_name}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row">
                    <Box>
                      <Tooltip
                        arrow
                        placement="top"
                        title={`
                        ${
                          item?.job_post_refer_now_commission?.length > 0 &&
                          "Refer Now Commission " +
                            (getSymbolFromCurrency(item?.currency) || "") +
                            abbreviateNumber(
                              item?.job_post_refer_now_commission?.[0]
                                ?.flat_rate
                            ) +
                            "/" +
                            (item?.job_post_refer_now_commission?.[0]?.payout
                              ?.split("_")
                              ?.join(" ") || "")
                        }
                       ${
                         item?.job_post_refer_self_commission?.length > 0 &&
                         item?.job_post_refer_self_commission?.[0]
                           ?.flat_rate !== ""
                           ? "- Refer Self Commission " +
                             (getSymbolFromCurrency(item?.currency) || "") +
                             abbreviateNumber(
                               item?.job_post_refer_self_commission?.[0]
                                 ?.flat_rate
                             ) +
                             "/" +
                             (item?.job_post_refer_self_commission?.[0]?.payout
                               ?.split("_")
                               ?.join(" ") || "")
                           : ""
                       }
                      `}
                      >
                        <Typography
                          noWrap
                          fontWeight="md"
                          textColor="primary.plainColor"
                          mb={0.5}
                          sx={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            fontWeight: "Bold",
                            color: "#066a4c",
                            textAlign: "center",
                            // opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                          }}
                        >
                          {getSymbolFromCurrency(item?.currency) || ""}

                          {item?.job_post_refer_now_commission?.length > 0 && (
                            <span style={{ color: "#066a4c" }}>
                              {abbreviateNumber(
                                item?.job_post_refer_now_commission[0]
                                  ?.flat_rate
                              )}
                              {/* /{item?.job_post_refer_now_commission[0]?.payout?.split("_")?.[1]?.[0]} */}
                            </span>
                          )}
                          {item?.job_post_refer_self_commission?.length > 0 &&
                          item?.job_post_refer_self_commission?.[0]
                            ?.flat_rate !== "" ? (
                            <span
                              style={{
                                color: "rgb(212, 133, 49)",
                                marginLeft: "4px",
                              }}
                            >
                              {"- "}
                              {getSymbolFromCurrency(item?.currency) || ""}
                              {abbreviateNumber(
                                item?.job_post_refer_self_commission[0]
                                  ?.flat_rate
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Tooltip>
                      <Typography
                        noWrap
                        fontWeight="md"
                        textColor="primary.plainColor"
                        mb={0.5}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#121212",
                          fontFamily: "Inter,sans-serif",
                        }}
                      >
                        Earn Upto
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    gap: "10px",
                    "& hr": {
                      height: "22px",
                    },
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                    }}
                    direction="row"
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        gap: "5px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <JobBagIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {`${item?.min_exp}${
                          item?.min_exp_months > 0
                            ? `.${item?.min_exp_months}`
                            : ""
                        }`}
                        -
                        {`${item?.max_exp}${
                          item?.max_exp_months > 0
                            ? `.${item?.max_exp_months}`
                            : ""
                        }`}
                        Yrs
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        gap: "5px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {item?.job_type?.replace("_", " ")}
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                          {" "}
                          {getSymbolFromCurrency(item?.currency)
                            ? getSymbolFromCurrency(item?.currency)
                            : ""}{" "}
                        </span>
                        {/* {Math.round(item.min_sal / 1000, 2)}k-
                          {Math.round(item.max_sal / 1000, 2)}k
                          {`/${item?.payout}`} */}
                        {item?.min_sal_by_am <= 1000
                          ? item?.min_sal_by_am
                          : `${Math.round(item?.min_sal_by_am / 1000, 2)}k`}
                        -
                        {item?.max_sal_by_am <= 1000
                          ? item?.max_sal
                          : `${Math.round(item?.max_sal_by_am / 1000, 2)}k`}
                        {/* {`/${item?.payout}`} */}
                        {item?.job_type && item?.job_type === "Full_Time"
                          ? "/Year"
                          : item?.job_type === "Contract"
                          ? "/Hour"
                          : ""}
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobLocationIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {/* {item?.location?.length <= 17 ? item?.location : item?.location?.slice(0, 35) + ".."} */}
                        {item?.location}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobBookIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {item?.educational_qualification_details?.map(
                          (elem, index) => (
                            <span key={index}>
                              {elem?.education_qualification_spec_id?.name}/
                              {elem?.education_qualification_id?.name}.{" "}
                            </span>
                          )
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignContent="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography noWrap>
                        {item?.key_skills_details?.map(each => (
                          <>
                            &#x2022;{" "}
                            <span
                              style={{
                                backgroundColor: "#E9F0EE",
                                borderRadius: "4px",
                                fontSize: "14px",
                                padding: "3px",
                              }}
                            >
                              {each?.name}
                            </span>{" "}
                          </>
                        ))}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderTop: "0.8px dashed #BABABA",
                    paddingTop: "15px",
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      WebkitBoxOrient: "horizontal",
                      overflow: "hidden",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      textOverflow: "ellipsis",
                    }}
                    direction="row"
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobClockIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "13.5px", fontWeight: "500" }}
                      >
                        {Math.floor(
                          Math.abs(
                            new Date() -
                              new Date(
                                item?.createdAt
                                  ?.slice(0, 10)
                                  ?.toLocaleString()
                                  .replace(/-/g, "/")
                              )
                          ) /
                            (1000 * 3600 * 24)
                        )}{" "}
                        days ago
                      </Typography>
                    </Stack>
                    <span>&#x2022; </span>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        flexShrink: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        gap: "5px",
                      }}
                    >
                      <JobUsersIcon />
                      <Typography
                        noWrap
                        sx={{ fontSize: "13.5px", fontWeight: "500" }}
                      >
                        {" "}
                        {`${item?.applicants_count}`} Applicants
                      </Typography>
                    </Stack>
                    <span>&#x2022; </span>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        // flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ fontSize: "13.5px", fontWeight: "500" }}
                      >
                        {Math.floor(
                          Math.abs(
                            new Date() -
                              new Date(
                                item?.application_deadline
                                  ?.slice(0, 10)
                                  ?.toLocaleString()
                                  ?.replace(/-/g, "/")
                              )
                          ) /
                            (1000 * 3600 * 24)
                        ) -
                          Math.floor(
                            Math.abs(new Date() - new Date()) /
                              (1000 * 3600 * 24)
                          )}{" "}
                        days left to apply
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Button
                      type="submit"
                      size="small"
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                      disabled={!item?.is_active}
                      onClick={() => {
                        if (
                          token !== undefined &&
                          token !== null &&
                          token !== ""
                        ) {
                          setReferModal({
                            modal: "center",
                            details: {
                              ...item,
                              company_id: item?.company_details?.id,
                            },
                            purpose: "self",
                          });
                        } else {
                          navigate("/login");
                        }
                      }}
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        background: "#E6F0ED",
                        padding: "4px 8px",
                        fontSize: "14px",
                        border: item?.is_active
                          ? "0.8px solid var(--app-button-color1)"
                          : "",
                        color: "var(--app-button-color1)",
                        "&:hover": {
                          boxShadow: "none",
                          color: "#fff",
                          background: "var(--app-button-color1)",
                        },
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        {item?.is_candidate_applied_to_job_post == 0
                          ? "Apply"
                          : "Applied"}
                      </span>
                    </Button>
                    <Button
                      type="submit"
                      size="small"
                      endIcon={
                        <KeyboardArrowRightOutlinedIcon
                          fontSize="small"
                          sx={{}}
                        />
                      }
                      loading={false}
                      loadingPosition="end"
                      disabled={!item?.is_active}
                      variant="contained"
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        background: "var(--app-button-color1)",
                        padding: "4px 8px",
                        fontSize: "14px",
                        "&:hover": {
                          boxShadow: "none",
                          background: "var(--app-button-color1)",
                        },
                      }}
                      onClick={() => {
                        if (
                          token !== undefined &&
                          token !== null &&
                          token !== ""
                        ) {
                          setReferModal({
                            modal: "center",
                            details: {
                              ...item,
                              company_id: item?.company_details?.id,
                            },
                            purpose: "refer",
                          });
                        } else {
                          navigate("/login");
                        }
                      }}
                    >
                      <span style={{ textTransform: "capitalize" }}>
                        Refer Job
                      </span>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          ))}

        <Grid item xs={12} sm={12}>
          <JobInfoDescription jobDetails={props.jobDetails?.data_payload} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SkillsSection jobDetails={props.jobDetails?.data_payload} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <JobInsights jobDetails={props.jobDetails?.data_payload} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <JobInfoRequirements jobDetails={props.jobDetails?.data_payload} />
        </Grid>

        <Grid item xs={12} sm={12}>
          {props?.company && (
            <Stack pt={4}>
              <Typography
                sx={{
                  paddingBottom: "12px",
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                About Company
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {props?.company?.company?.about}
              </Typography>

              <Box
                sx={{
                  paddingBottom: "12px",
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                More Jobs..
              </Box>

              <Box mt={2} mb={3}>
                <CompanyJobsCardList
                  companyId={props?.company?.id}
                  companyName={""}
                />
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
      <ReferNowModal
        show={referModal.modal}
        purpose={referModal.purpose}
        onHide={modal =>
          setReferModal({
            modal: modal,
            details: {},
          })
        }
        params={referModal}
      />
    </React.Fragment>
  );
}

export default JobDescription;
