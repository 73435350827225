import { Box, Input, InputLabel, Typography, TextareaAutosize, FormControl } from "@mui/material";
import { forwardRef } from "react";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";

export default function TextBox(
  { label, required, error, helperText, children, useFormRef, style, ...rest }
) {
  // console.log("rest operator", ...rest)
  return (
    <ThemeProvider theme={globalTheme}>
      <Box>
        {/**
       *
       *
       * input field label if any
       *
       */}
        {label ? <InputLabel required={required} sx={{ height: "fit-content", borderColor: error ? "var(--error-color)" : null }}>{label}</InputLabel> : null}

        {/**
       *
       *
       * textarea
       *
       */}

        <TextareaAutosize
          fullWidth
          multiline
          aria-label="minimum height"
          {...rest}
          {...useFormRef}
          style={{
            minHeight: "100px",
            maxHeight: "110px",
            borderRadius: "8px",
            fontSize: "13px",
            borderColor: error ? "var(--error-color)" : "#c1c1c1", 
            overflowY: "scroll !important",
            ...(style)
          }}
        />
        {/**
       *
       *
       * error message if any
       *
       */}
        {children}

        {error ? (
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              color: "var(--error-color)",
            }}
          >
            {helperText}
          </Typography>
        ) : null}
      </Box>  
      </ThemeProvider>
  );
};
