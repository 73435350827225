import React, { useState, Fragment, useEffect } from "react";
import Earningbonus from "./EarningBonus";
import EarningCandidateReport from "./EarningCandidateReport";
import EarningClientReport from "./EarningClientReport";
import EarningPartnerReport from "./EarningPartnerReport";
import WithdrawMoney from "../MyMoney/WithdrawMoney";
import "../../../src/assets/css/AboutUs/MyEarning/MyEarning.css";
import AspectRatio from "@mui/joy/AspectRatio";

import { FaWallet } from "react-icons/fa";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import { ModalClose, ModalDialog } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  getAllInterviewsList,
  getAllMyReferals,
} from "../../helpers/dataFetcher/jobs/jobs";
import {
  getEarning,
  getEarningTableData,
  getWithAmount,
  getWithTableData,
} from "../../helpers/dataFetcher/profile/update";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import {
  TextField,
  MenuItem,
  InputLabel,
  OutlinedInput,
  // FormControl,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  Modal, IconButton
} from "@mui/material";
import { globalTheme } from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import { getPickersFadeTransitionGroupUtilityClass } from "@mui/x-date-pickers/CalendarPicker/pickersFadeTransitionGroupClasses";
import { AddModeratorSharp } from "@mui/icons-material";
import { MdLogin } from "react-icons/md";
import MyEarningsTabs from "./MyEarningsTabsTable";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector } from "react-redux";
import webSocketUrl from "../../assets/sockets";
import toast from "react-hot-toast";
import SocketConnection from "../../hooks/socket";
import ComingSoon from '../../Reusable/comingSoon';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};


const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "#FFFFFF",
  borderRadius: "8px",
  p: 4,
  "@media (max-width: 420px)": {
    width: 300,
    top: "50%",
  },
};

const useStyles = makeStyles({
  withdrawtxt: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
  },
  amountSpan: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "15px",
    color: "rgba(0, 0, 0, 0.7) !important",
    paddingLeft: "8px",
  },

  bankCard: {
    "&.css-a62g8g-JoySheet-root": {
      background: "rgba(8, 23, 83, 0.06) !important",
      borderRadius: "8px !important",
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      marginTop: "12px",
    },
  },
  accountDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    paddingLeft: "30px",

    "& > *": {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: "700 !important",
      fontSize: "14px !important",
    },
  },
  label: {
    color: "rgba(0, 0, 0, 0.7) !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    "&.css-1soe16i-JoyRadio-label": {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: "700 !important",
      fontSize: "14px !important",
    },
  },
  input: {
    "&.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "48px",

      border: "0.5px solid rgba(0, 0, 0, 0.24)",
      borderRadius: "8px",
    },
  },
});

function MyEarning() {
  const classes = useStyles();
  const [withDrawlModal, setWithDrawlModal] = useState(false);
  const [moneyData, setMoneyData] = useState();

  const [withDrawTableData, setWithDrawTableData] = useState();
  const [withDrawData, setWithDrawData] = useState();
  const userData = useSelector(state => state.User);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };


  const handleCloseModal = () => setWithDrawlModal(!withDrawlModal);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();



  // const [totalEarning, setTotalEarning] = useState({});
  // const [totalWithDrawFund, setTotalWithDrawFund] = useState({})
  // const [totalPendingFund, setTotalPendingFund] = useState({})

  // useEffect(() => {
  //   getAllJobs();
  // }, []);

  // async function getAllJobs() {
  //   try {
  //     const [totalEarningData, totalWithDrawFundData, totalPendingFundData] = await Promise.all([
  //       getMyReferralJobDashboardMatrix(),
  //       getAppliedJobDashboardMatrix(),
  //       getReferredJobDashboardMatrix()
  //     ]);

  //     if ([200, 201].includes(totalEarningData?.status)) {
  //       setTotalEarning(totalEarningData?.data);
  //     }
      
  //     if ([200, 201].includes(totalWithDrawFundData?.status)) {
  //       setTotalWithDrawFund(totalWithDrawFundData?.data);
  //     }
      
  //     if ([200, 201].includes(totalPendingFundData?.status)) {
  //       setTotalPendingFund(totalPendingFundData?.data);
  //     }

      
  //     if ([totalEarningData?.status, totalWithDrawFundData?.status, totalPendingFundData?.status].includes(400)) {
  //       const errorMessage = totalEarningData?.return_message || totalWithDrawFundData?.return_message || totalPendingFundData?.return_message;
  //       toast.error(errorMessage);
  //     }
 
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }
  
  // const dashBoardList = [
  //   { id: 0, name: totalEarning?.tag ?? "Referred Jobs", count: totalEarning?.overall_count ?? " ", latest: "22", link: "#" },
  //   { id: 1, name: totalWithDrawFund?.tag ?? "Applied Jobs", count: totalWithDrawFund?.overall_count ?? " ", latest: "34", link: "#" },
  //   { id: 2, name: totalPendingFund?.tag ?? "My Referral Jobs", count: totalPendingFund?.overall_count ?? " ", latest: "26", link: "#" },
  // ];
 


  async function getWithdrawTableData() {
    const { data } = await getWithTableData();
    const finalTableData = data?.data?.map(item => {
      return {
        firstName: item.earnings_user_job_post.first_name,
        lastName: item.earnings_user_job_post.last_name,
        date: item.createdAt,
        email: item.earnings_user_job_post.email,
        finalCommission: item.final_commisson_received_value,
      };
    });
    setWithDrawTableData(finalTableData);
  }

  /*getEarning function is used for getting amounts data start*/
  async function fetchData() {
    const { data, status } = await getEarning();
    setMoneyData(data?.data);
    getWithdrawTableData();
  }
  /*getEarning function is used for getting amounts data End*/

  /* Amount withdrawn from modal start */
  const onSubmit = async Adata => {
    console.log(typeof parseInt(Adata.amount));
    const availableAmount = moneyData.earnings_amount;
    const requestingAmount = parseInt(Adata.amount);
    if (requestingAmount <= availableAmount) {
      const { data, status } = await getWithAmount(parseInt(Adata.amount));
      setWithDrawData([data.data]);
    } else {
      toast.error("Insufficient Amount");
    }
  };
  /* Amount withdrawn from modal end */



  return (
    <>
      <Box>
           
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                flexWrap: "wrap",
                gap: "20px"
              }}>

              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  gap: "10px",
                  padding: "10px",
                  // width: "fit-content", 
                  minWidth: "300px",
                  borderRadius: "16px",
                  flex: 1,
                  border: "1px solid #E9E9E9",
                  boxShadow: "2px 2px 64px -44px #000000A6",
                  backgroundImage: "linear-gradient(0deg, #30242400, #E9E9E9),linear-gradient(180deg, rgb(0 247 176 / 24%) 0%, rgba(252, 250, 243, 0) 100%)",
                }}

              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",

                    }}
                  >
                    Total Earnings
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "60px",
                      fontWeight: "600",
                      color: "#066A4C"
                    }}
                  >
                    0
                  </Typography>
                </Stack>
                {/* <Stack
                  direction="row"
                  justifyContent="flex-end"
                >
                  <IconButton
                    size="small"
                  >
                    <CallMadeIcon fontSize="inherit" />
                  </IconButton>
                </Stack> */}
              </Stack>

              <Stack
                direction="column"
                justifyContent="space-between"

                sx={{
                  gap: "10px",
                  padding: "10px",
                  // width: "fit-content", 
                  minWidth: "300px",
                  borderRadius: "16px",
                  flex: 1,
                  border: "1px solid #E9E9E9",
                  boxShadow: "2px 2px 64px -44px #000000A6",
                  backgroundImage: "linear-gradient(0deg, #30242400, #E9E9E9),linear-gradient(180deg, rgb(0 247 176 / 24%) 0%, rgba(252, 250, 243, 0) 100%)",
                }}

              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",

                    }}
                  >
                    Withdraw Funds
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "60px",
                      fontWeight: "600",
                      color: "#066A4C"
                    }}
                  >
                    0
                  </Typography>
                </Stack>
                {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    gap: "10px"
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "50%",
                    }}
                  >
                    <Button
                      type="submit"
                      size="large"
                      onClick={() => setWithDrawlModal(true)}
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        width: "100%",
                        background: "var(--app-button-color1)",
                        padding: "5px 10px",
                        "&:hover": {
                          boxShadow: "none",
                          background: "var(--app-button-color1)",
                        }
                      }}
                    >
                      <span style={{ textTransform: "capitalize", fontWeight: "600", marginRight: "10px" }}>Withdraw Now</span> <ArrowForwardIosIcon fontSize="small" color="inherit" />
                    </Button>
                  </Box>

                </Stack> */}
              </Stack>


              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  gap: "10px",
                  padding: "10px",
                  // width: "fit-content", 
                  minWidth: "300px",
                  borderRadius: "16px",
                  flex: 1,
                  border: "1px solid #E9E9E9",
                  boxShadow: "2px 2px 64px -44px #000000A6",
                  backgroundImage: "linear-gradient(0deg, #30242400, #E9E9E9),linear-gradient(180deg, rgb(0 247 176 / 24%) 0%, rgba(252, 250, 243, 0) 100%)",
                }}

              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",

                    }}
                  >
                    Pending Funds
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "60px",
                      fontWeight: "600",
                      color: "#066A4C"
                    }}
                  >
                    0
                  </Typography>
                </Stack>
                {/* <Stack
                  direction="row"
                  justifyContent="flex-end"
                >
                  <IconButton
                    size="small"
                  >
                    <CallMadeIcon fontSize="inherit" />
                  </IconButton>
                </Stack> */}
              </Stack>

            </Stack>

            {withDrawlModal ? (
              <>
                <Modal
                  open={withDrawlModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...style, top: "50%" }}>
                    <ModalClose
                      onClick={() => setWithDrawlModal(false)}
                      variant="outlined"
                      sx={{
                        top: "calc(-1/4 * var(--IconButton-size))",
                        right: "calc(-1/4 * var(--IconButton-size))",
                        boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                        borderRadius: "50%",
                        bgcolor: "background.body",
                      }}

                    />
                    <Typography
                      component="h2"
                      className={classes.withdrawtxt}
                    >
                      Withdraw Amount
                    </Typography>

                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { width: "90%" },
                        marginTop: "8px",
                      }}
                      // noValidate
                      autoComplete="off"
                    >
                      <div>
                        <ThemeProvider theme={globalTheme}>

                            <OutlinedInput
                              placeholder="Amount"
                              className={classes.input}
                              fullWidth
                              type="number"
                              autoComplete="email"
                              autoFocus
                            />
                        </ThemeProvider>
                        <div style={{ marginTop: "8px" }}>
                          <FaWallet />
                          <span className={classes.amountSpan}>
                            Withdrawable Amount :
                            <span className={classes.withdrawtxt}>
                              2165/-
                            </span>
                          </span>
                        </div>

                        <div className="bank_details_cards">
                          {[0, 1].map((each, index) => (
                            <StyledEngineProvider injectFirst>
                              <CssVarsProvider>
                                <Sheet
                                  variant="outlined"
                                  sx={{
                                    boxShadow: "sm",
                                    borderRadius: "sm",
                                    p: 1,
                                  }}
                                  className={classes.bankCard}
                                >
                                  <RadioGroup
                                    name={`tiers-${each}`}
                                    value={selectedValue}
                                    onChange={handleRadioChange}
                                    sx={{ gap: 1, "& > div": { p: 1 } }}
                                  >
                                    <FormControl>
                                      <Radio
                                        overlay
                                        value={`medium-${each}`}
                                        label="State Bank of India"
                                        className={classes.label}
                                      />
                                      <div
                                        className={classes.accountDetails}
                                      >
                                        <FormHelperText>
                                          A/C : 8734*******7589
                                        </FormHelperText>
                                        <FormHelperText>
                                          IFSC Code : 38******DB
                                        </FormHelperText>
                                        <FormHelperText>
                                          Hyderabad, India
                                        </FormHelperText>
                                      </div>
                                    </FormControl>
                                  </RadioGroup>
                                </Sheet>
                              </CssVarsProvider>
                            </StyledEngineProvider>
                          ))}
                        </div>

                        <div className="withbtn_cont">

                          <Button
                            type="submit"
                            size="large"
                            onClick={() => navigate("../settings")}
                            loading={false}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                              borderRadius: "8px",
                              boxShadow: "none",
                              width: "100%",
                              background: "var(--app-button-color1)",
                              padding: "5px 10px",
                              "&:hover": {
                                boxShadow: "none",
                                background: "var(--app-button-color1)",
                              }
                            }}
                          >
                            <span style={{ textTransform: "capitalize", fontWeight: "600", marginRight: "10px" }}>Add Bank Details</span>
                          </Button>
                          <Button
                            type="submit"
                            size="large"

                            loading={false}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                              borderRadius: "8px",
                              boxShadow: "none",
                              width: "100%",
                              background: "var(--app-button-color1)",
                              padding: "5px 10px",
                              "&:hover": {
                                boxShadow: "none",
                                background: "var(--app-button-color1)",
                              }
                            }}
                          >
                            <span style={{ textTransform: "capitalize", fontWeight: "600", marginRight: "10px" }}>Withdraw Now</span>
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Modal>
              </>
            ) : null}

            <Box style={{ paddingTop: "20px" }}>
              <MyEarningsTabs
                withDrawTableData={withDrawTableData}
              />
            </Box>
      </Box>
    </>
  );
}

export default MyEarning;
