import React, { useEffect, useState } from "react";
import ProfileSummary from "./LocalProfile/TestProfile";
import { useDispatch, useSelector } from "react-redux";
import ProfileHeader from "./LocalProfile/ProfileHeader";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../../assets/MUI/materialUiStyles";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import { updateUserAccount } from "./accountSlice";
import { getProfile } from "../../helpers/dataFetcher/profile/profile";

function AccountsIndex() {
  //redux dispatch
  const dispatch = useDispatch();
  const [accLoading, setAccLoading] = useState(true);

  const accountData = useSelector((state) => state.User.user);

  useEffect(() => {
    dataFetcher();
  }, []);

  async function dataFetcher() {
    setAccLoading(false)
    const { data, status } = await getProfile();
    if (status === 200) {
      dispatch(
        updateUserAccount({
          user: {
            ...data,
          }
        })
      );
    }
    setAccLoading(false);
  }


  return (
    <div>
      <ProfileHeader accLoading={accLoading} dataFetcher={dataFetcher}/>
      <ThemeProvider theme={globalTheme}>
        <ProfileSummary accLoading={accLoading} />
      </ThemeProvider>
    </div>
  );
}

export default AccountsIndex;
